/**
 * @description:房源基本信息
 * @Author: xulihua
 * @Date: 2018-02-24 18:21:16
 * @Last Modified by: xulihua
 * @Last Modified time: 2018-02-24 18:41:53
 */
import {
  LeaseBaseInfoDTO,
  LeaseCustomRentBaseInfoDTO,
  LeaseRoomQueryDTO, LeaseSimpleDetailResponse,
  ProjectUnitDTO,
  RoomGroupStyleDTO,
  RoomTypeBaseDTO
} from '../lease/lease.model';
import {PageReq, PageResp} from '../page/page';
import {SysUser, UserListDTO} from '../user/user.model';
import {DevElemeterInfoDTO, DevWatermeterInfoDTO, RoomDoorLockDTO} from '../device/device.model';
import {DoorLockInfo} from '../workbench/workbench.model';
import {LeaseRenterInfoDTO} from "../renter/renter.model";
import {EbusinessPageRequest, OrderManagerResponse} from "../ebusiness/ebusiness.model";
import {HotelProject} from "./project.model";

export class RoomInfo {
  room_id?: number;
  project_id?: number;
  unit_id?: number;
  room_name?: string;
  room_num?: number;
  bedroom_num?: number;
  hall_num?: number;
  kitchen_num?: number;
  bath_room_num?: number;
  room_area?: string;
  base_price?: string;
  floor?: number;
  room_step?: string;
  asset_confirm?: string;
  lease_room_id?: number;
  effect_time?: number;
  room_type_id?: number;

  city_id?: number;
  area_id?: number;
  business_type?: string;
  bottom_price?:string;
  public_area?:string;
  covered_area?:string;
  data_type?:string;
}

/**
 * 床型
 */
export class BedDTO {
  id?: number;
  bed_code?: number;
  bed_desc?: string;
}

export class Beds {
  beds: Array<BedDTO>;
}

// 房型
export class RoomType {
  id: number;
  project_id: number;
  room_type_name: number;
  // 基础价格
  base_price?: number;
}

/**
 * 证件类型
 * @type {{id: string; name: string}[]}
 */
export const CERTIFICATE_TYPE = [
  {id: 'ID_CARD', name: '居民身份证'},
  {id: 'OFFICAL_CF', name: '军官证'},
  {id: 'POLICE_OFFICAL_CF', name: '武警军官证'},
  {id: 'RETIREMENT_CF', name: '退休证'},
  {id: 'CIVILIAN_CADRE_CF', name: '文职干部证'},
  {id: 'SOLDIER_CF', name: '士兵证'},
  {id: 'STUDENT_ID_CD', name: '学生证'},
  {id: 'HKMO_PASS_CF', name: '港澳居民往来内地通行证'},
  {id: 'TW_PASS_CF', name: '台湾居民往来大陆通行证'},
  {id: 'CHINA_GUIDE_CD', name: '中华人民共和国旅行证'},
  {id: 'OTHER', name: '其他'},
];

/**
 * 添加房源请求封装
 */
export class RoomCreateReqParam {
  // 项目ID
  public project_id?: number;
  // 单元ID
  public unit_id?: number;
  // 室号
  public room_num?: string;
  // 床位数量
  public bedroom_num?: number;
  // 房价
  public room_price?: string;
  // 面积
  public room_area?: string;
  // 楼层
  public room_floor?: number;
}

/**
 * 请求封装-获取房源详情
 */
export class RoomDetailReqParm {
  room_id ?: number;
}

/**
 * 删除房间，配置停房
 */
export class RoomDeleteReqParm {
  room_id?: number;
  type?: string;
  project_id?: number;
  remark?: string;
}

/**
 * 批量配置停房
 */
export declare class RoomBatchUpdateReqParm {
  room_ids?: Array<number>;
  type?: string;
  remark?: string;
  project_id?: number;
}

/**
 * 房间信息
 */
export class RoomInfoDTO {
  project_id?: number;
  unit_id?: number;
  unit_name?: string;
  room_id?: number;
  room_name?: string;
  room_num?: number;
  bedroom_num?: number;
  hall_num?: number;
  kitchen_num?: number;
  base_price?: string;
  room_area?: string;
  floor?: string;
  room_status?: string;
  rent_type?: string;
  room_type_id?: string;
  logic_floor?: string;
  vacant_index?: string;
  asset_confirm?: string;
  avaliable_checkin_num?: number;

  checked?: boolean = false;
  manage_users?: Array<SysUser>;


  data_type?: string;
}


/**
 * 房间模糊查询
 */
// @ts-ignore
export class RoomAndBedDTO extends RoomInfoDTO {
  room_beds?: RoomBedDTO[];

  id?: any;
  name?: any;
  children?: RoomAndBedDTO[];
}

export class RoomBedDTO {
  room_bed_info_id?: number;
  room_id?: number;
  bed_logic_num?: number;
  bed_name?: string;
  bed_status?: string;
}

export class RoomStatusListResponse {
  page_count: number;
  total: number;
  room_status_list: Array<RoomStatusInfoDTO>;
}

export class RoomStatusInfoDTO extends RoomInfoDTO {
  project_name: string;
  unit_name: string;
  lease_bed_count: number;
  floor_room_count: number;
  lease_room_status_list: Array<string>;
  // @Remark("电表设备：为空时表示无，OFFLINE:异常")
  electricity_alarm: string;
  door_off_line_text: string;
  // @Remark("门锁设备：为空时表示无，OFFLINE:异常")
  door_alarm: string;
  // @Remark("水表设备：为空时表示无，OFFLINE:异常")
  water_alarm: string;
  // @Remark("热水表设备：为空时表示无，OFFLINE:异常")
  hot_water_alarm: string;
  // @Remark("已签约的房间入住的月均价")
  month_avg_rent: string;
  // @Remark("下份租约开始天数")
  day_count: number;
  // 当前租约结束天数
  out_day_count:number;
  // @Remark("租约ID，占房态的租约或者是将要开始的租约，房间无有效租约时为空")
  status_leaseId: number;
  select_room_flag: boolean = false;
  remark?: string;
  reminder_num?: number;
  sex_tag?:string;

  sign_name?: string;
}

export class RoomStatusReqParam extends PageReq {
  project_id?: number;
  unit_id?: any;
  room_type_id?: any;
  room_group_style_id?: any;
  orientation?: any;
  status?: string;
  search_text?: string;
  real_time?: boolean;
  start_time?: number;
  end_time?: number;
  period?: number;
  room_time_stamp:string;

  // CENTER 集中式房源 DISPERSE 分散式
  business_type?: string;

  // ROOM 房间 BED 床位
  data_type?: string;
}

// 自定义房态图格式
export class UnitDetail {
  unit_id: number;
  unit_name: string;
  project_name: string;
  selectUnitFlag: boolean;
  floorList: Array<FloorDetail>;
}

export class FloorDetail {
  floor: string;
  floor_room_count: number;

  selectFloorFlag: boolean;
  room: Array<RoomStatusInfoDTO>;
}

export class RoomStatusInfoResponse {
  project_name: string;
  unit_name: string;
  // 租约集合+租约房间信息+收租提醒
  // 租约集合
  lease_list: Array<RoomStatusLeaseDTO>;
  // 账单数据
  bill_list: Array<RoomBillListDTO>;
  // 续租信息
  lease_info: LeaseBaseInfoDTO;
  // 下期交租时间
  nextTaxesTime: string;
  // 能否升级设备
  device_upgrade_enum: 'FULL' | 'PART' | 'DONE';
  // @Remark("能否删除")
  can_delete: boolean;
  // 维修日志
  room_log_dto: RoomLogDTO;

  dev_elemeter_info_dto: DevElemeterInfoDTO;
  room_door_lock_dto: RoomDoorLockDTO;

  door_lock_dto: DoorLockInfo;
}

export class RoomStatusLeaseDTO extends LeaseBaseInfoDTO {
  //租约业务状态说明
  service_status: string;
  // 是否可以催租：fasle：不能，true:可以")
  remind: boolean;
  //@Remark("租约房间信息")
  lease_room_info: LeaseRoomQueryDTO;
  // @Remark("灵活租金集合")
  custom_rent_list: Array<LeaseCustomRentBaseInfoDTO>;
  // 催租次数
  rush_to_rent_count: number;
  // @Remark("企业名称")
  company_name: string;
}

export class RoomBillListDTO {
  //@Remark("账单金额")
  bill_amount: string;
  //@Remark("账单开始时间")
  bill_start_time: string;
  //@Remark("账单结束时间")
  bill_end_time: string;
  //@Remark("实际收款时间")
  pay_time: string;
  //@Remark("应收款时间")
  plan_pay_time: string;
  //@Remark("账单状态：FINISH:已收款，NOFINSH：未收款，OWE：欠款")
  bill_status: string;
  //@Remark("收款项")
  bill_type_name: string;
  //@Remark("收款项ID")
  bill_type_id: number;
}

export class RoomFuzzyRequest {
  /**
   * 房间名
   */
  room_name: string;
  /**
   * 是否需要床信息，需要：IS_NEED，不需要：NO_NEED
   */
  is_necessary: string;
  page_num: number;
  page_size: number;
}

export class RoomLogDTO {
  log_id: number;

  room_id: number;

  unit_id: number;

  project_id: number;

  operation_id: number;

  operation_content: string;

  user_info_dto: UserListDTO;

  create_time: number;
}

export class RoomElemeterEnableLogRequest extends PageReq {
  room_id: number;
}

export class RoomElemeterEnableLogResponse extends PageResp {
  room_log_dtolist: Array<RoomLogDTO>;
}

/**
 * @Description: 根据房间id列表获取房间列表请求
 * @Author: 陆炳彬
 * @Date: 2018/9/13 下午3:22
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/9/13 下午3:22
 */
export class GetRoomListByIdsRequest {
  room_ids: number[];

  constructor(room_ids: number[]) {
    this.room_ids = room_ids;
  }
}

/**
 * @Description:  1.08期 单个房间房源房源信息
 * @Author: zhoujiahao
 * @Date: 2018-10-25  11:36
 */
export class RoomRealTimeInfoResponse {
  //@Remark("入住人信息:当前正在入住的信息")
  lease_renter_dtos?: Array<LeaseRenterInfoDTO>;
  //@Remark("房间租约信息")
  room_real_time_info_dtos?: Array<RoomRealTimeInfoDTO>;
  //@Remark("维修日志:只展示最近的一条,只有当是维修状态时才展示")
  room_log_dto?: RoomLogDTO;
  dev_watermeter_info_dto?: DevWatermeterInfoDTO;
  dev_elemeter_info_dto?: DevElemeterInfoDTO;
  room_door_lock_dto?: RoomDoorLockDTO;
  checkin_lease_id?: number;
  //房间标签
  room_group_label?: string;
  prepay_total?: string;
  room_reserved?: RoomReservedDTO;
  //预订/签约主体类型（COMPANY-企业，PERSON-个人）
  subject_type?: string;
  //判断同一合同或者不同合同（企业）下是否有其他房间可转移预付费
  transfer?: boolean;
  //房间列表是否显示 直接显示房间列表【过滤掉合同类型选择】 //true 直接显示房间列表 显示   false:不显示
  room_list_show?: boolean;
  check_show?: boolean;
  out_check_show?: boolean;
  first_rent_pay?: boolean;
  deposit_need_pay?: boolean;
  room_entry_status?: string;
  overdue_lease_id?: number;
  overdue_lease_room_id?: number;
  checkin_lease_room_id?:number;
  went_dutch?: boolean;
}

export class RoomRealTimeDetailInfoResponse extends RoomRealTimeInfoResponse {
  room_status_info_dto: RoomStatusInfoDTO;
  simpleDetail: LeaseSimpleDetailResponse;
}

/**
 * @Description:  当个房间租约详情：会包含几天可签等信息   1.08
 * @Author: zhoujiahao
 * @Date: 2018-10-25  11:49
 */
export class RoomRealTimeInfoDTO {
  //@Remark("租约ID")
  lease_id: number;

  contract_num: string;

  //@Remark("租约房间ID")
  lease_room_id: number;

  //@Remark("房间ID")
  room_id: number;

  //@Remark("租约状态：BOOKING:预定:LEASE:签约")
  lease_step: string;

  //@Remark("提前或N天后可租:负数表示提前")
  day_count: number;

  //@Remark("签约人/预定人")
  sign_name: string;

  //原始签约人名称
  origin_sign_name: string;

  //@Remark("签约人/预定人手机号码")
  sign_phone: string;

  tel_conceal?: string;

  //@Remark("联系人")
  contact_name: string;

  //@Remark("租约开始时间")
  start_time: number;

  //@Remark("租约结束时间")
  end_time: number;

  // 预付账户余额
  prepay_total?: string;

  // @Remark("租约类型 原生 ORIGIN、续租 RELET ，SUBLEASE 转租")
  lease_type?: string;

  // @Remark("转租约日期/预订保留日期")
  to_lease_date?: number;

  // 账单生成方式
  generate_bill_type?: string;

  lease_business_status?: string;
}

export class RoomStatusRequest {
  // @Remark("房间ID不能为空")
  // @NotNull(message = "房间ID不能为空")
  room_id?: number;
  // @Remark("开始时间：为空默认当前月份")
  start_time?: number;
  // @Remark("截止时间:为空默认当前月份")
  end_time?: number;
}

export class RoomDateStatusResponse {
  room_date_status_dtos?: Array<RoomDateStatusDTO>;
}

export class RoomDateStatusDTO {
  // @Remark("日期精确到秒")
  every_day_time?: number;
  // @Remark("房间当前日状态")
  room_status?: string;
}

/**
 * @Description:  1.08期 房源置维修
 * @Author: zhoujiahao
 * @Date: 2018-10-25  11:36
 */
export class RoomOperateInfoMoel {
  //@Remark("入住人信息:当前正在入住的信息")
  room?: Array<RoomStatusInfoDTO>;
  //维修信息
  remark?: string;
}

/**
 * 置保留房
 */
export class RoomReservedRequest {
  room_name?: string;
  room_id?: number;
  reserved_start_time?: Date;
  reserved_end_time?: Date;
  remark?: string;
}

export class RoomDetails {

}

export class RoomDetailReq extends PageReq {
  room_id?: number;
  active?: string;
  lease_id?: number;
  equipment_type?: string;
}

export class RoomNowOccupantDTO {
  occupant_name?: string;
  occupant_sex?: string;
  checkin_time?: number;
  phone?: string;
}

export class RoomBookingDetailDTO {
  lease_id?: number;
  type?: string;
  start_time?: number;
  end_time?: number;
  signer?: string;
  contact_phone?: string;
  emergency_name?: string;
  emergency_phone?: string;
}

export class RoomEquipmentLogDTO {
  type?: string;
  record_time?: number;
  value?: number;
}

export class RoomElementerLogDTO {
  type?: string;
  time?: number;
  operator?: string;
  content?: string;
}

export class DoorEventInfoDTO {
  room_id?: number;
  /**单元名*/
  unit_name?: string;
  /**房间号*/
  room_num?: string;
  /**开锁时间*/
  event_time?: any;
  /**开锁类型*/
  event_type?: string;
  /**电话*/
  phone?: string;
  /**密码类型*/
  pass_type?: string;
  /**开门人*/
  name?: string;
}

//当前入住人
export class RoomNowOccupantResponse {
  room_now_occupant_dtos?: Array<RoomNowOccupantDTO>;
  total?: number;
}

//签约预定详情
export class RoomBookingDetailResponse {
  room_booking_detail_dtos?: Array<RoomBookingDetailDTO>;
  total?: number;
}

//水电读数
export class RoomEquipmentLogResponse {
  room_equipment_log_dtos?: Array<RoomEquipmentLogDTO>;
  total?: number;
  hot_water: boolean;
}

//电表禁用、维修/验收
export class RoomElementerLogResponse {
  room_elementer_log_dtos?: Array<RoomElementerLogDTO>;
  total?: number;
}

//开门记录
export class RoomOpenDoorLogResponse {
  door_event_info_dtos?: Array<DoorEventInfoDTO>;
  total?: number;
}

/**
 * 物品清单实体
 */
export class RoomTypeGoodsDTO {
  good_name: string;
  position: string;
  price: string;
  unit: string;
  sort: number;
  isHave: boolean;
  isBad: number;
  initHave: boolean;
  initBad: number;
  quantity: number;
  focus: boolean;
}

/**
 * 物品清单备注
 */
export class RoomGoodsRemark {
  create_user_name: string;
  create_time: Date;
  remark: string;
}

export class ProjectUnitResponse {
  project_units?: Array<ProjectUnitDTO>;
  /**
   * 房型列表
   */
  room_type_base_dtos?: Array<RoomTypeBaseDTO>;
  /**
   * 装修风格列表
   */
  room_group_style_dtos?: Array<RoomGroupStyleDTO>;
}

export class ManageRoomListResponse extends PageResp {
  room_list_model_list?: Array<RoomListResponse>;
}

export class RoomCheckDetailResponse {

  hotel_project?: HotelProjectResp;

  room_info?: RoomInfoResp;

  smart_dev_list?: Array<SmartDevResp>;

}

export class ManageRoomEditRecordListResponse extends PageResp {

  room_edit_list_model_list?: Array<RoomEditRecordListResponse>;

}

export class RoomEditRecordListResponse {

  create_time?: Date;

  room_edit_record_id?: number;

  create_user_name?: string;

  record_user_name?: string;

  record_status?: string;

}

export class RoomEditInfoResponse {

  room_edit_record_id?: number;

}

export class HotelProjectResp {

  project_name?: string;

  project_full_name?: string;

  project_address?: string;

}

export class RoomInfoResp {

  unit_name?: string;

  room_name?: string;

  room_num?: string;

  floor?: number;

  base_price?: string;

  room_area?: string;

  need_smart_dev_radio?: string;

  effect_time?: Date;

  room_type_model?: RoomTypeResp;

  business_type?: string;

  city_name?: string;
  area_name?: string;

  bottom_price?:string;
  public_area?:string;
  covered_area?:string;
  data_type?:string;
  data_type_name?:string;

}

export class RoomTypeResp {

  room_type_name?: string;

}

export class SmartDevResp {

  install_name?: string;

  brand_name?: string;

}

export class ProjectListUnitRequest {
  project_id_list?: Array<number>;
}

export class ManageRoomListRequest extends PageReq {

  search_txt?: string;

  need_smart_dev_radio?: string;

  room_status?: number;

  business_type?: string;
}

export class ManageRoomEditRecordListRequest extends PageReq {

}

export class RoomListResponse {

  unit_id?: number;

  unit_name?: string;

  room_id?: number;

  room_name?: string;

  room_num?: string;

  bedroom_num?: number;

  floor?: number;

  room_status_name?: string;

  room_status?: string;

  need_smart_dev_radio?: string;

  serial?: number;

  create_time?: Date;

  effect_time?: Date;

  sign?: boolean;

}

export class RoomReservedDTO {
  id?: number;
  room_id?: number;
  reserved_start_time?: Date;
  reserved_end_time?: Date;
  creator_name?: string;
  remark?: string;
  create_time?: Date;
}


export class RoomSmartRechargeRequest extends PageReq {
  lease_room_id?: number;
}

export class PaymentRecordDTO {
  // @Remark("支付时间")
  pay_time?: number;
  // @Remark("支付联系方式")
  payment_contact?: string;
  // @Remark("支付渠道")
  pay_channel?: string;
  // @Remark("充值金额")
  recharge_amount?: string;
  // @Remark("交易流水号")
  trade_no?: string;
  // @Remark("是否为优惠券充值")
  coupon?: boolean;

  // @Remark("充值人账户")
  recharge_account?: string;
  // @Remark("充值人")
  recharge_user_name?: string;
}

export class RoomSmartRechargeResponse extends PageResp {
  payment_records?: Array<PaymentRecordDTO>;
}

export class RoomIntelligDeviceResponse {
  intellig_device_ele_meter:boolean;
  intellig_device_walter_meter:boolean;
  intellig_device_lock:boolean;
  intellig_device_hot_walter_meter:boolean;
  ele_reading_amount_type?:string;
  walter_reading_amount_type?:string;
  hot_reading_amount_type?:string;

  enable_state?: string;
  outage_text?: string;
}

export class RoomIntelligDeviceRequest {
  room_id: number;
}

export class RoomLeaseRequest {
  room_id: number;
}

export class RoomLeaseResponse {
  lease_id: number;
}

export class RoomEquipmentIndicationRequest {
  type: string;
  room_ids: Array<number>;
}

export class RoomEquipmentIndicationResponse {
  rooms: Array<RoomEquipmentIndicationDTO>;
}


export class RoomEquipmentIndicationDTO {
  confirm: boolean;

  room_id: number;

  elemeter_info_dto: DevElemeterInfoDTO;

  cold_watermeter_info_dto: DevWatermeterInfoDTO;

  hot_watermeter_info_dto: DevWatermeterInfoDTO;
}


export class RoomEquipmentOrIndicationRequest {
  type: string;
  rooms: Array<RoomEquipmentIndicationDTO>;
}

export class BatchEditInfoRequest {

  room_edit_list: Array<RoomEditListDTO>;

  work: number;
}

export class DownLoadEditInfoRequest {

  room_edit_record_id: number;

  project_id: number;

}

export class RoomEditListDTO {

  room_id: number;

  room_type_id: number;

  room_type_name: string;

  base_price: number;

  room_area: string;

  room_name: string;

  prepay_total: number;

  bottom_price:number;

  public_area:string;

  covered_area:string;
}

export class PrepayRoomListDTO {

  room_id: number;

  lease_room_id: number;

  room_name: string;

  prepay_total: number;

}

export class PrepayRoomListRequest extends PageReq {
  subject_type: string;

  room_id: number;

  lease_id: number;

  transfer_type: string;

  search_room_name: string;
}


export class PrepayRoomListResponse extends PageResp {
  prepay_room_list_dtos: Array<PrepayRoomListDTO>;
}

export class PrepayTransferAuditListResponse {
  audit_user?: Array<SysUser>;
}

export class PrepayTransferAuditCodeRequest {
  room_id: number;

  lease_id: number;

  lease_room_name: string;

  transfer_balance: number;

  transfer_lease_room_name: string;

  phone: string;

}

export class TransferPrepayBalnaceRequest {
  old_lease_id: number;
  old_room_id: number;
  new_lease_room_id: number;
  transfer_balance: number;
  audit_phone: string;
  audit_code: string;
}

export class TransferPrepayBalnaceResponse {
  result: string;
}

export class PrepayTransferLogRequest extends PageReq {
  lease_id: number;
  room_id: number;
}

export class PrepayTransferLogDTO {
  transfer_type: string;
  create_time: Date;
  execute_user_name: string;
  room_name: string;
  transfer_balance: string;
  transfer_room_name: string;

}

export class PrepayTransferLogResponse extends PageResp {
  transfer_log_dtos?: Array<PrepayTransferLogDTO>;
}

export class AuditRoomEditReq {
  room_edit_record_id: number;
  record_status: string;
  remark: string;
}

export class HandleWithdrawRecordReq {
  withdraw_record_id: number;
  handle_type: string;
  audit_reason: string;
  idempotent_key: string;
}

export class PrepayLogRequest extends PageReq {
  query_date: string;
  room_id: number;
  transfer_type: string;
}

export class PrepayLogResponse extends PageResp {
  prepay_transfer_log_dtos?: Array<PrepayTransferLogDTO>;
}


export class PropayConsumeTotalLogRequest extends PageReq {
  room_id: number;
  query_date: string;
}

export class PropayConsumeTotalLogDTO {
  type: string;
  transfer_balance: string;
  create_time: Date;
}

export class PropayConsumeTotalLogResponse extends PageResp {
  propay_consume_total_log_dtos?: Array<PropayConsumeTotalLogDTO>;
}

export class RoomDirtyStatusRequest {

  room_ids?: Array<number>;
  // @Remark("是否为脏房")
  dirty?: boolean;
}

export class SetingRoomTimeStampRequest {

  room_id_list?: Array<number>;
  end_time?: number;
}

export class CancelRoomTimeStampRequest {

  room_id_list?: Array<number>;
}

export class RoomTimeStamp{
  room_id:number;
  start_time:number;
  end_time:number;


  cancel_time?: number;
  stamp_status?: number;

  creator_name?: string;
  create_remark?: string;

  cancel_name?: string;
  cancel_remark?: string;

}

export class RoomStampResponse {
  room_stamp_dtolist?: Array<RoomTimeStamp>;
}

export class EffectiveLeaseNoticeDTO {
  lease_id?: number;

  contract_num?: string;

  subject_name?: string;

  sign_time?: number;

  start_time?: number;

  end_time?: number;

  room_count?: number;

  room_name_list?: Array<string>;

  checked = false;
}

export class EffectiveLeaseNoticeResponse {
  effective_lease_notice_list?: Array<EffectiveLeaseNoticeDTO>;
}

