export class BookingListResponse {
  lease_booking_list?: BookingListDTO[];
  // @Remark("数据总条数")
  data_count?: number;
}

export class BookingListRequest {
  // @Remark("搜索内容 - 姓名或手机号")
  search_content?: string;
  // @Remark("状态 - 预定 ：BOOKING、取消：CANCEL、已签约：TO_LEASE、未提交：UNCOMMITTED、已提交：COMMITTED、已签署：EFFECTIVE")
  status?: string[];
  // @Remark("搜索类型 - 预定：BOOKING、租约：lease")
  search_type?: string;
  // @Remark("业务搜索状态 - 折扣待审核：TOAUDIT")
  service_status?: string;
  page_num?: number;
  page_size?: number;

  order_by_type?: string;
  order_by_desc?: boolean;
  lease_date_type?: string;
  start_time?: number;
  end_time?: number;
  lease_type?: string;
  ihome?:number;
}

export class BookingListDTO {
  //合同号
  contract_num?:string;
  // @Remark("预定或签约编号")
  serial_number?: number;
  // @Remark("预定或签约时间")
  create_time?: Date;
  // @Remark("月租金")
  month_price?: string;
  // @Remark("租约开始时间")
  start_time?: Date;
  // @Remark("租约结束时间")
  end_time?: Date;
  // @Remark("联系人")
  contact?: string;
  // @Remark("联系人电话")
  contact_phone?: string;
  // @Remark("状态描述")
  status_describe?: string;
  // @Remark("状态code")
  status_code?: string;
  // @Remark("业务类型 - BOOKING：预定，LEASE：租约")
  service_type?: string;
  // @Remark("审核状态 - WAITING_AUDIT：待审核，AUDIT_SUCCESS：审核通过，AUDIT_FAIL：审核失败，NO_ADUIT：无需审核")
  audit_status?: string;
  // @Remark("签约人")
  sign_name:string;
  // @Remark("签约人电话")
  sign_phone:string;
  // 业务状态描述
  business_status_desc: string;
  // 业务状态
  business_status: string;

  // 渠道来源
  source_name:string;

  lease_subject_info_dto: LeaseSubjectInfoDTO;
}

export class LeaseSubjectInfoDTO {
  subject_type?: string;//主题类型
  name?: string;//联系人名称
  tel?: string;//联系人手机号
  cret_type?: string;//证件类型
  cret_num?: string;//证件号
  zip?: string;//邮编
  address?: string;//联系人地址
  company_name?: string;//公司名称
  invoice_type?: string;//发票类型（NO_NEED 不需要发票，NORAML_INVOICE 普通发票，VAT_INVOICE 增值税发票)
  invoice_title?: string;//发票抬头
  taxpayer_num?: string;//纳税人编号
  card_number?: string;//银行账号
  bank_name?: string;//开户银行
  company_address?: string;//企业地址
  sex?:any;//性别
}
export class LeaseTypeDTO {
  code?: string;
  value?: string;
}

export class LeaseTypeListResponse {
  lease_type_list?: Array<LeaseTypeDTO>;
}
