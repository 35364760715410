import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import { AvaliableRoomInfo } from '../../../../model/lease/room_choose.model';
import { RoomChangeService } from '../../../../service/lease/roomchange.service';
import {ChangeRoomNowTermRentBillReq, RoomChangeApplyReq, RoomChangeSettingReq} from '../../../../model/lease/roomchange.model';
import { CustomRentInfo, CustomRentInfoWithIndex, LeaseCustomRentDTO, LeaseRoomInfoDTO } from '../../../../model/lease/lease.model';
import { CustomRentModalComponent } from '../../../lease/custom-rent-modal/custom-rent-modal.component';
import {getIntervalMonth, getIntervalMonthByNaturalMonth} from '../../../../common/date.util';
import { MessageModalInfo } from '../../../../model/modal/message-model';
import { WorkModel } from '../../../../model/common/common.model';
import { WorkService } from '../../../../service/common/work.service';
import {RoominspentService} from "../../../../service/roominspent/roominspent.service";

@Component({
  selector: 'app-cr-room-choose-set',
  templateUrl: './cr-room-choose-set.component.html',
  styleUrls: ['./cr-room-choose-set.component.css']
})
export class CrRoomChooseSetComponent implements OnInit {

  @Input()
  lease_id: number;

  @Input()
  origin_lease_room_id: number;

  @Input()
  new_room_info: AvaliableRoomInfo;

  @Input()
  change_time: Date;

  @Input()
  end_time: Date;

  @Input()
  generate_bill_type: string;

  @Output()
  go_step: EventEmitter<any> = new EventEmitter();

  // 原始房间租约信息
  origin_lease_room_info: LeaseRoomInfoDTO;

  // 入住人姓名列表
  renter_name_list_str: string;

  // 换房申请请求
  apply_req: RoomChangeApplyReq = new RoomChangeApplyReq();

  // 换房申请原因错误
  apply_reason_error: string;

  // 灵活租金是否被填充
  custom_rent_list_filled: boolean = false;

  // 月租金错误
  month_rent_error: string;

  // 押金错误
  room_deposit_error: string;

  //是否为内部无合同租约
  is_internal_lease:boolean = false;

  custom_rent_list: CustomRentInfo[];

  @ViewChild('custom_rent_modal', { static: true }) customRentModal: CustomRentModalComponent;
  @ViewChild('room_change_confirm_modal', { static: true }) modal: ModalDirective;

  constructor(private roomChangeService: RoomChangeService,
              private workService: WorkService,
              private roominspentService: RoominspentService) {

  }

  ngOnInit() {
    this.clear();
  }

  clear() {
    this.month_rent_error = null;
    this.room_deposit_error = null;
    this.apply_reason_error = null;
    this.custom_rent_list_filled = false;
    this.renter_name_list_str = '';
    this.custom_rent_list = [];

    this.apply_req = new RoomChangeApplyReq();
    this.apply_req.apply_type = 'RENTER_APPLY';
    this.apply_req.new_room_info = new LeaseRoomInfoDTO();
    this.apply_req.new_room_info.rent_type = 'NORMAL';
  }

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  resetModal(lease_id: number, origin_lease_room_id: number, new_room_info: AvaliableRoomInfo, change_time: Date, end_time: Date,
             generate_bill_type: string) {
    this.clear();

    this.lease_id = lease_id;
    this.origin_lease_room_id = origin_lease_room_id;
    this.new_room_info = new_room_info;
    this.change_time = change_time;
    this.end_time = end_time;
    this.generate_bill_type = generate_bill_type;
    this.getRoomChangeInfo();
  }

  getRoomChangeInfo() {
    let room_change_req = new RoomChangeSettingReq();
    room_change_req.lease_id = this.lease_id;
    room_change_req.origin_lease_room_id = this.origin_lease_room_id;

    this.roomChangeService.getRoomChangeOriginInfo(room_change_req).then(data => {
      // if(data.lease_step === 'INTERNAL_LEASE'){
      //   this.directSubmit();
      // } else {
      this.is_internal_lease = data.lease_step === 'INTERNAL_LEASE';
      this.origin_lease_room_info = data.origin_lease_room_info;
      if (this.origin_lease_room_info.renter_list && this.origin_lease_room_info.renter_list.length > 0) {
        let renter_name_list = [];
        this.origin_lease_room_info.renter_list.forEach(renter => {
          renter_name_list.push(renter.name + "(" + (renter.sex === 'MALE' ? '男' : '女') + ")");
        });
        this.renter_name_list_str = renter_name_list.join(', ');
      }
      // }
    });
  }

  customRentModalShow(canEdit:boolean) {
    let changeRoomNowTermRentBillReq = new ChangeRoomNowTermRentBillReq();
    changeRoomNowTermRentBillReq.lease_id = this.lease_id;
    changeRoomNowTermRentBillReq.origin_lease_room_id = this.origin_lease_room_id;
    changeRoomNowTermRentBillReq.origin_room_id = this.origin_lease_room_info.room_id;
    changeRoomNowTermRentBillReq.change_time = this.change_time.getTime();
    this.roomChangeService.queryChangeRoomNowTermRentBill(changeRoomNowTermRentBillReq).then(data => {
      let change_start_time = this.change_time;
      if (data && data.bill_start_time) {
        change_start_time = new Date(data.bill_start_time);
      }
      if (!this.custom_rent_list || this.custom_rent_list.length === 0) {
        let month_interval: number;
        let custom_rent_list: CustomRentInfo[] = [];
        if (this.generate_bill_type === 'NATURAL_MONTH') {
          month_interval = getIntervalMonthByNaturalMonth(change_start_time, this.end_time);
        } else {
          month_interval = getIntervalMonth(change_start_time, this.end_time);
        }
        for (let i = 0; i < month_interval; i++) {
          let custom_rent = new CustomRentInfo(i);
          custom_rent_list.push(custom_rent);
        }
        this.customRentModal.custom_rent_list = custom_rent_list;
      } else {
        this.customRentModal.custom_rent_list = this.custom_rent_list;
      }
      this.customRentModal.canEdit = canEdit;
      this.customRentModal.resetCustomRentList();
      this.customRentModal.show(this.new_room_info.base_price);
    });
  }

  confirmCustomRent(custom_rent_with_index: CustomRentInfoWithIndex) {
    this.custom_rent_list = custom_rent_with_index.custom_rent_list;
    this.custom_rent_list_filled = true;
  }
  directSubmit() {
    this.apply_req.change_time = this.change_time.getTime();
    this.apply_req.lease_id = this.lease_id;
    this.apply_req.origin_lease_room_id = this.origin_lease_room_id;
    this.apply_req.new_room_info.room_id = this.new_room_info.room_id;
    this.roomChangeService.roomChangeApply(this.apply_req).then(data => {
      this.goNext(data.record_id);
    });
  }
  doConfirm() {
    this.apply_req.change_time = this.change_time.getTime();
    this.apply_req.lease_id = this.lease_id;
    this.apply_req.origin_lease_room_id = this.origin_lease_room_id;
    this.apply_req.new_room_info.room_id = this.new_room_info.room_id;
    if (this.apply_req.apply_type === 'HOTEL_APPLY') {
      if (!this.apply_req.reason || this.apply_req.reason.length === 0) {
        this.apply_reason_error = 'required';
        return;
      }

      if (this.apply_req.reason.length > 50) {
        this.apply_reason_error = 'formatError';
        return;
      }

      this.apply_reason_error = '';
    }

    if (this.apply_req.new_room_info.rent_type === 'NORMAL') {
      if (!this.apply_req.new_room_info.month_rent || this.apply_req.new_room_info.month_rent.length === 0) {
        this.month_rent_error = 'required';
        return;
      }

      /*if (!/^\d+$/.test(this.apply_req.new_room_info.month_rent)
        || parseInt(this.apply_req.new_room_info.month_rent, 0) < 0
        || parseInt(this.apply_req.new_room_info.month_rent, 0) > 100000) {
        this.month_rent_error = 'formatError';
        return;
      }*/

      if (this.validateNormalRent(this.apply_req.new_room_info.month_rent, true)) {
        this.month_rent_error = 'formatError';
        return;
      }

      this.month_rent_error = '';
      delete this.apply_req.new_room_info.custom_rent_list;
    } else {
      if (!this.custom_rent_list || this.custom_rent_list.length === 0) {
        this.month_rent_error = 'required';
        return;
      }

      this.month_rent_error = '';
      delete this.apply_req.new_room_info.month_rent;

      // 填充req中的灵活租金
      this.apply_req.new_room_info.custom_rent_list = [];
      this.custom_rent_list.forEach(custom_rent => {
        let item = new LeaseCustomRentDTO();
        item.custom_rent = custom_rent.custom_rent + '';
        this.apply_req.new_room_info.custom_rent_list.push(item);
      });
    }

    if (!this.apply_req.new_room_info.room_deposit) {
      this.room_deposit_error = 'required';
      return;
    }

    /*if (!/^\d+$/.test(this.apply_req.new_room_info.room_deposit)
      || parseInt(this.apply_req.new_room_info.room_deposit, 0) < 0
      || parseInt(this.apply_req.new_room_info.room_deposit, 0) > 100000) {
      this.room_deposit_error = 'formatError';
      return;
    }*/

    if (this.validateNormalRent(this.apply_req.new_room_info.room_deposit, true)) {
      this.room_deposit_error = 'formatError';
      return;
    }

    this.room_deposit_error = '';

    // 判断是否需要审核
    const is_need_audit = this.isNeedAudit(this.apply_req.new_room_info.rent_type, this.new_room_info.base_price,
      parseInt(this.apply_req.new_room_info.month_rent, 0),
    this.apply_req.new_room_info.custom_rent_list, this.apply_req.apply_type);

    if (is_need_audit) {
      let messageModalInfo = new MessageModalInfo();
      messageModalInfo.operationMessage = '新房间价格需要审核，是否确认并提交审核？';
      messageModalInfo.cancel = true;
      messageModalInfo.callback.subscribe(() => {
        this.roomChangeService.roomChangeApply(this.apply_req).then(data => {
          let messageModalInfo2 = new MessageModalInfo();
          messageModalInfo2.type = 2;
          messageModalInfo2.timeout = 3000;
          messageModalInfo2.title = "操作提醒";
          messageModalInfo2.html = `
      <h4 class="text-center">已提交至审核流程！</h4>
      <p class="text-center">可至合同详情页面-【换房记录】查看进度</p>
    `;
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo2));
          // 如果房间是一房两锁, 给出提示
          this.roominspentService.getRoomDoorLockList({"room_id":this.apply_req.new_room_info.room_id}).then(data1 => {
            if(data1 && data1.lock_list && data1.lock_list.length > 1){
              this.workService.showErrorModal('新房间为一房两锁房型，换房完成后请在房态图中为入住人补发门锁密码。');
            }
          });
          // 刷新换房记录
          this.refreshRecordList();
        });
      });
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
    } else {
      // 如果房间是一房两锁, 给出提示
      this.roominspentService.getRoomDoorLockList({"room_id":this.apply_req.new_room_info.room_id}).then(data => {
        if(data && data.lock_list && data.lock_list.length > 1){
          this.workService.showErrorModal('新房间为一房两锁房型，换房完成后请在房态图中为入住人补发门锁密码。');
        }
      });
      this.roomChangeService.roomChangeApply(this.apply_req).then(data => {
        this.goNext(data.record_id);
      });
    }
  }

  // 是否需要审核
  isNeedAudit(rent_type: string, base_price: number, month_rent: number, custom_list: LeaseCustomRentDTO[], apply_type: string): boolean {
    if (apply_type === 'HOTEL_APPLY') {
      return true;
    }

    if (rent_type === 'NORMAL') {
      // 固定租金
      if (month_rent < base_price) {
        return true;
      }
    } else {
      let sum_price = 0;
      let num = 0;
      // 灵活租金
      custom_list.forEach(custom_rent => {
        sum_price += parseInt(custom_rent.custom_rent + '', 0);
        num++;
      });
      if ((sum_price / num) < base_price) {
        return true;
      }
    }

    return false;
  }

  goBack() {
    this.go_step.emit({ 'type': 'back' , 'start_time': this.change_time, 'new_room_id': [this.new_room_info.room_id]});
    this.hide();
  }

  goNext(record_id: number) {
    this.go_step.emit({ 'type': 'next', 'record_id': record_id });
    this.hide();
  }

  refreshRecordList() {
    this.go_step.emit({ 'type': 'refresh' });
    this.hide();
  }

  /**
   * @Description:   阻止向上冒泡
   * @Author: zhoujiahao
   * @Date: 2018-11-02  9:55
   */
  stopP($event) {
    $event.stopPropagation();
  }

  validateNormalRent(value, is_digtal) {
    if (is_digtal) {
      if (value != null && value.split(".").length <= 2 && value.substring(value.length - 1, value.length) !== "."
        && Number.parseFloat(value) >= 0 && Number.parseFloat(value) <= 200000) {
        if (value.split(".").length === 2 && value.split(".")[1].length > 2
          || (value.substring(0, 1) === "0" && value.length > 1 && value.substring(1, 2) !== ".")) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    } else {
      let reg = new RegExp(/^(-)?\d+$/);
      if (value) {
        if (!reg.test(value) || value < 0 || value > 200000) {
          return true;
        }
      }
    }
    return false;
  }
}
