import {ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Chart} from "angular-highcharts";
import Decimal from "decimal.js";
import * as $ from "jquery";
import {ModalDirective} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {TabsetComponent} from "ngx-bootstrap/tabs";
import {UMeditorComponent} from "ngx-umeditor";
import * as _ from 'underscore';
import {resizeCharts} from "../../../common/charts.random.util";
import {countDownTime, filterFormData, isInApp, trim} from "../../../common/common";
import {OperationType} from '../../../common/constants';
import {addDate, dateTimeCountDown, generatePluginDate, YYYYmmdd2} from "../../../common/date.util";
import {LocalStorage} from "../../../common/localstorage";
import {
  AppointListRequest,
  AppointListResponse,
  AppointmentHouseApplySubmitRequest,
  AppointmentProjectGroupDTO, AppointmentProjectTimesDTO
} from "../../../model/appoint/appoint.model";
import {
  ApprovalLockPasswordResponse,
  ApprovalRenterCheckoutDepositResponse,
  ApprovalResultResponse
} from "../../../model/approv/approv.model";
import {
  AssetChangeRemindDTO,
  AssetChangeRemindListResponse,
  AssetCheckListRequest,
  AssetCheckListRespone,
  AssTransAuditDetailRequest,
  AssTransAuditDetailResponse,
  AuditAssetEditReq
} from "../../../model/asset/asset.model";
import {
  MoneyPaymentConfirmRequest,
  UploadPaymentCertificateRequest,
  UploadPaymentCertificateResponse
} from "../../../model/clientinfo/client-info.model";
import {WorkModel} from '../../../model/common/common.model';
import {SystemConfigDTO} from "../../../model/company/company.model";
import {
  ComplaintsDealRequest,
  ComplaintsInfoDTO,
  ComplaintsListRequest,
  ComplaintsListResponse,
  ComplaintsLogInfoDTO,
  ComplaintsLogListResponse
} from '../../../model/complaints/complaints.model';
import {SysUserDTO} from "../../../model/CustomResponse";
import {
  ApprovEditRequest,
  ChangeRoomAvailableDaysFlowAuditResponse,
  CheckInLimitDetailResponse,
  CheckInLimitResponse,
  CheckoutFinanceUncheckListRequest,
  CheckoutFinanceUncheckListResponse,
  CheckoutFinanceUnconfirmListResponse,
  ClientNameIsExistResponse,
  FrozenPasswordFlowAuditResponse,
  LeaseBillFlowAuditResponse,
  LeaseLog,
  LeaseSubjectFlowAuditResponse,
  LogListReq,
  PrepayArrearageResponse,
  RenterCheckoutFinanceNotFinishRequest,
  RenterCheckoutFinanceNotFinishResponse,
  RenterCheckoutNotFinishRequest,
  RenterCheckoutNotFinishResponse,
  RenterCheckoutRemindListRequest,
  RenterCheckoutRemindListResponse,
  RenterCheckoutTransferErrorDealRequest,
  RenterCheckoutTransferErrorRemindListRequest,
  RenterCheckoutTransferErrorRemindListResponse,
  RenterCheckoutTransferErrorRemindModel,
  WaitSignContractListResponse
} from "../../../model/lease/lease.model";
import {AuditRoomChangeReq, RoomChangeAuditDetailResponse, RoomEditAuditDetailResponse} from '../../../model/lease/roomchange.model';
import {SubleaseChangeRemind, SubleaseChangeRoomRemindListResponse} from "../../../model/lease/sublease_change_remind.model";
import {BaseEnum} from "../../../model/modal/base-model";
import {MessageModalInfo} from "../../../model/modal/message-model";
import {OrderInfoDTO} from "../../../model/order/order.model";
import {HotelProject} from "../../../model/room/project.model";
import {CleanTaskListRequest, CleanTaskListResponse} from "../../../model/room/repair.model";
import {AuditRoomEditReq} from "../../../model/room/room.model";
import {RptTemplate} from "../../../model/rpt/rpt-template.model";
import {ScheduleOperateModel} from '../../../model/schedule/schedule.model';
import {ShopLeaseInfoDTO, ShopLeasePriceResponseDTO} from "../../../model/shop/shop-detail.model";
import {SysUser} from "../../../model/user/user.model";
import {
  AcceptanceResParam,
  AddEvolveRemarkRequest,
  ALERT_TYPE,
  AlertInfo,
  AlertListReqParam,
  AuditDetailReqParam,
  AuditDTO,
  AuditRoomGroupDTO,
  AuditRoomGroupReqParam,
  CarportAuditDetailResponse,
  ClientNameCertcodeRequest,
  ClientNameCertcodeResponse,
  DataStatInfo,
  DataStatReqParm,
  DelayRefundInfo,
  DiscountInfo,
  DiscountInfoList,
  DiscountReqParam,
  DoorLockInfo,
  DoorLockInfoList,
  DoorLockListReqParam,
  DueAlertInfos,
  EvolveInfo,
  EvolveReqParam,
  FaultAlertInfos,
  FireAlertListResp,
  FrozenDoorPasswordAuditRequest,
  FrozenDoorPasswordSmsCodeRequest,
  LeaseAuditReqParam,
  LeaseFCReviewDTO,
  LFCReviewResponse,
  NoAuditListRequest,
  NoAuditListResponse,
  NoCheckoutAlertInfos,
  NoticeType,
  NoticeUserDTO,
  NoticeUserRequest,
  OrderIfRelateListReqParam,
  OrderIfRelateResponse,
  OrderInfoLogDTO,
  PersonSafeAlarmDTO,
  PersonSafeAlarmListRequest,
  PersonSafeAlarmListResponse,
  ProjectDto,
  ProjectFileAuditRequest,
  ProjectFileDTO,
  QueryAccountNameRequest,
  QueryAccountNameResponse,
  QueryClientNameRequest,
  QueryEvolveRequest,
  RelevanceRoomParam,
  RelevanceRoomResponse,
  REPAIR_HANDLE_TYPE,
  RepairHandleReqParam, RepairImage,
  RepairInfo,
  RepairInfoList,
  RepairListReqParam,
  RepairOverHoursDTO,
  RepairOverHoursDTOList,
  RestPswReqParam,
  RoomPrice,
  RoomPriceList1,
  RoomPriceReqParam,
  SignatoryDTO,
  SweepCodeNotPayRequest,
  ThirdpayPaymentConfirmRequest,
  UnFrozenDoorPasswordRequest,
  WorkbenchDataResponse,
  WorkbenchEmailHistoryDTO,
  WorkbenchEmailHistoryRequest,
  WorkbenchMonthPriceRequest,
  WorkbenchMonthPriceResponse,
  WorkbenchSendEmailRequest,
} from '../../../model/workbench/workbench.model';
import {AppointService} from "../../../service/appoint/appoint.service";
import {ApprovService} from "../../../service/approv/approv.service";
import {AssetService} from "../../../service/asset/asset.service";
import {CheckoutService} from "../../../service/checkout/checkout.service";
import {ClientInfoService} from "../../../service/clientinfo/ClientInfoService";
import {AuthService} from '../../../service/common/auth.service';
import {BaseInfoService} from "../../../service/common/base-info.service";
import {WorkService} from '../../../service/common/work.service';
import {CompanyService} from "../../../service/company/company.service";
import {ComplaintsService} from '../../../service/complaints/complaints.service';
import {IdempotentService} from "../../../service/idempotentService/IdempotentService";
import {ImageQrcodeService} from "../../../service/image/image_qrcode.service";
import {LeaseService} from "../../../service/lease/lease.service";
import {LogService} from "../../../service/log/log.service";
import {RenterService} from "../../../service/renter/renter.service";
import {RepairService} from "../../../service/room/repair.service";
import {RptService} from "../../../service/rpt/rpt.service";
import {UserService} from "../../../service/user/user.service";
import {WorkbenchService} from "../../../service/workbench/workbench.service";
import {SeehouseDetailComponent} from "../../seehouse/seehouse-detail/seehouse-detail.component";
import {DialogComponent} from "../../shared/dialog/dialog.component";
import {ComplainModalComponent} from "../../shared/modal/complain-modal.component";
import {ApprovRecordImgComponent} from '../approv-record-img/approv-record-img.component';
import {SafeWarnDetailComponent} from "../safe-warn-detail/safe-warn-detail.component";
import {ImagePreviewModel} from "../../../model/image/image-file";
import {IMyDpOptions} from "../../third-party/mydatepicker/src/lib/interfaces";

declare var baidutongji: any;

@Component({
  selector: 'app-workbench',
  templateUrl: './workbench.component.html',
  styleUrls: ['./workbench.component.css']
})
export class WorkbenchComponent implements OnInit, OnDestroy {
  btn_privileges: any = {
    'WORKBENCH_BOOK_BTN': false,
    'WORKBENCH_SIGN_BTN': false,
    'WORKBENCH_NO_LEASE_BTN': false,
    'WORKBENCH_PREPAY_BTN': false,
    'WORKBENCH_MONITOR_BTN': false,
    'WORKBENCH_ADD_ROOM_BTN': false,
    'WORKBENCH_EXPORT_ROOM_BTN': false,
    'WORKBENCH_MONEY_REPORT_BTN': false,
    'WORKBENCH_ACCOUNT_REPORT_BTN': false,
    'WORKBENCH_BUSINESS_REPORT_BTN': false,
    'WORKBENCH_PURCHASE_LEASE_BTN': false,
    'WORKBENCH_GATHERING_BTN': false,
    'WORKBENCH_REFUND_BTN': false,
    'WORKBENCH_COUPON_BTN': false,
    'CLEAN_WORK_STATISTICS': false,
    'SECURITY_WORK_STATISTICS': false,
    'ENGERING_WORK_STATISTICS': false,
    'DEVICE_WORK_STATISTICS': false,
    'PAD_SIGN': false,
    'ROOM_LEFT_STATISTICS': false,
    'ROOM_RENT_STATISTICS': false,
    'RENTER_STATISTICS': false,
    'LEASE_GATHERING_RENT': false,
    'LEASE_OVERDUE_CHECKOUT': false,
    'COMPLIANT_STATISTICS': false,
    'DEV_ERROR_STATISTICS': false,
    'CLEAN_STATISTICS': false,
    'REPAIR_STATISTICS': false,
    'ROOM_INSPECTION_STATISTICS': false,
    'BUSINESS_BASIS_DATA': false,
    'PREPAY_CONSUME_DATA': false,
    'OVERDUE_GATHERING_DATA': false,
    'PREPAY_RECHARGE_STATISTICS': false,
    'REFUND_STATISTICS': false,
    'INVOICE_STATISTICS': false,
    'BUSINESS_GATHERING_DATA': false,
    'WORK_SCHEDULE_STATISTICS': false,
    'PLAN_MATTER_STATISTICS': false,
    /*'CLEAN_WORK_STATISTICS': false,
    'SECURITY_WORK_STATISTICS': false,
    'ENGERING_WORK_STATISTICS': false,
    'DEVICE_WORK_STATISTICS': false,*/
    'PATROL_RECORD_UPLOAD_REMINDER': false,
    'COMPLAINT_DEAL': false,
    'CLEAN_REPAIR': false,
    'SCAN_PAYMENT': false,
    'PAYBACK_UNDO_ALARM': false,
    'PREPAY_ARREARAGE_ALARM': false,
    'ROOM_LEASE_BILL_ALARM': false,
    'ROOM_DEVICE_ALARM': false,
    'LEASE_WITHIN_TEN_DAYS_A': false,
    'AUDIT_LIST': false,
    'RESERVE_ROOM': false,
    'DOOR_CHECK': false,
    'LEASE_AUDIT_AGAIN': false,
    'SUBLEASE_CHANGE_REMIND': false,
    'ASSET_CHANGE_REMIND': false,
    'COMPLAINT_DEAL_VIEW': false,
    'COMPLAINT_DEAL_HANDLE': false,
    'CLEAN_REPAIR_VIEW': false,
    'CLEAN_REPAIR_HANDLE': false,
    'PAYBACK_UNDO_ALARM_VIEW': false,
    'PAYBACK_UNDO_ALARM_HANDLE': false,
    'ROOM_LEASE_BILL_ALARM_VIEW': false,
    'ROOM_LEASE_BILL_ALARM_HANDLE': false,
    'ROOM_DEVICE_ALARM_VIEW': false,
    'ROOM_DEVICE_ALARM_HANDLE': false,
    'AUDIT_LIST_HANDLE': false,
    'RESERVE_ROOM_VIEW': false,
    'RESERVE_ROOM_HANDLE': false,
    'DOOR_CHECK_HANDLE': false,
    'LEASE_AUDIT_AGAIN_HANDLE': false,
    'SUBLEASE_CHANGE_REMIND_HANDLE': false,
    'ASSET_CHANGE_REMIND_HANDLE': false,
    'LEASE_DISCOUNT_HANDLE': false,
    'CARPORT_LEASE_DISCOUNT_AUDIT': false,
    'SHOP_LEASE_DISCOUNT_HANDLE': false,
    'PROJECT_CONFIG_AUDIT': false,
    'REPORT_DAILY': false,
    'REPORT_INCOME_REFUND': false,
    'REPORT_INCOME_REFUND_DETAIL': false,
    'REPORT_FEE': false,
    'REPORT_INVOICE': false,
    'REPORT_WATER_ELECTRIC': false,
    'REPORT_COUPON': false,
    'REPORT_ACCOUNT': false,
    'SWEEP_CODE_PAY_HANDLE': false,
    'REFUND_STATISTICS_DETAIL': false,
    'LEASE_END': false,
    'ROOM_MANAGE_LIST_BUTTON': false,
    'ROOM_EDIT_AUDIT': false,
    'ASSET_TRANS_AUDIT': false,
    'ASSET_CHECK_REMIND': false,
    'ASSET_CHECK_VIEW': false,
    'WORKSPACE_CLEANTASK_REMIND': false,
    'WORKSPACE_CLEANTASK_LIST_VIEW': false,
    SECURITY_ALARM: false,
    VIEW_SECURITY_ALARM: false,
    PROCESS_SECURITY_ALARM: false,
    SECURITY_WARN_PANEL: false,
    DELAY_REFUND: false,
    DELAY_REFUND_HANDLE: false,
    MONTH_PRICE_AVG_DATA: false,
    WORK_ORDER_CENTER: false,

    WAIT_SIGN_LEASE: false,
    CHECK_IN_LIMIT: false,
    CHECKOUT_NOT_FINISH: false,
    CHECKOUT_FINANCE_NOT_FINISH: false,

    OPERATING_THE_BRAIN: false,
    ROOM_DIAGRAM: false,
    GATHER_MONEY_INPUT: false,

    RENTER_CHECKOUT_NOT_FINISH: false,
    RENTER_CHECKOUT_FINANCE_NOT_FINISH: false,

    RENTER_CHECKOUT_REMIND: false,
    RENTER_CHECKOUT_REMIND_CANCEL_BTN: false,
    RENTER_CHECKOUT_REMIND_AGREE_BTN: false,

    RENTER_CHECKOUT_TRANSFER_ERROR_REMIND: false,
    RENTER_CHECKOUT_TRANSFER_ERROR_REMIND_DEAL_BTN: false,


    FIRE_FIGHTING_CS: false,
    FIRE_FIGHTING_CS_HANDLE: false,
  };
  approLockPasswordRes: ApprovalLockPasswordResponse;
  projectId: number = this.authService.getProjectId();
  sysUserDTO: SysUserDTO;
  modalRef: BsModalRef;
  auditRemark: string;
  beingResut: string = 'PROCESSING';
  faceFlag: boolean = true;
  isCode: string;//是否筛选
  _now: Date = new Date();
  collectionMoneyKey: string;
  @ViewChild(DialogComponent, {static: true}) dialogComponent: DialogComponent;
  // @ViewChild(NgxImageGalleryComponent, {static: true}) ngxImageGallery: NgxImageGalleryComponent;
  @ViewChild(SeehouseDetailComponent, {static: true}) seehouseDetailComponent: SeehouseDetailComponent;
  @ViewChild(ComplainModalComponent, {static: true}) complainModal: ComplainModalComponent;
  @ViewChild(SafeWarnDetailComponent) safeWarnDetailComponent: SafeWarnDetailComponent;

  // modal
  @ViewChild('template', {static: true}) template: ModalDirective;
  @ViewChild('template2') template2: ModalDirective;
  @ViewChild('staticModal27', {static: true}) staticModal27: ModalDirective;
  @ViewChild('staticModal28', {static: true}) staticModal28: ModalDirective;
  @ViewChild('staticModal29', {static: true}) staticModal29: ModalDirective;
  @ViewChild('staticModal30', {static: true}) staticModal30: ModalDirective;
  @ViewChild('staticModal31', {static: true}) staticModal31: ModalDirective;
  @ViewChild('template5', {static: true}) template5: ModalDirective;
  @ViewChild('carportModel', {static: true}) carportModel: ModalDirective;
  @ViewChild('housingapprovalModal', {static: true}) housingapprovalModal: ModalDirective;
  @ViewChild('lfcReviewModal', {static: true}) lfcReviewModal: ModalDirective;
  @ViewChild('changeRoomAuditModal', {static: true}) changeRoomAuditModal: ModalDirective;
  @ViewChild('assetChangeAuditModal', {static: true}) assetChangeAuditModal: ModalDirective;
  @ViewChild('shopLeaseModal', {static: true}) shopLeaseModal: ModalDirective;
  @ViewChild('downloadAppQrcodeModal', {static: true}) downloadAppQrcodeModal: ModalDirective;
  @ViewChild('projectFileModal', {static: true}) projectFileModal: ModalDirective;
  @ViewChild('roomEditModal', {static: true}) roomEditModal: ModalDirective;
  @ViewChild('notPayModal', {static: true}) notPayModal: ModalDirective;
  @ViewChild('orderLogModal', {static: true}) orderLogModal: ModalDirective;
  @ViewChild('showRefundModal', {static: true}) showRefundModal: ModalDirective;
  @ViewChild('shopPriceModal', {static: true}) shopPriceModal: ModalDirective;

  @ViewChild('relevanceRoomModal', {static: true}) relevanceRoomModal: ModalDirective;

  @ViewChild('showLeaseChangeAuditModal', {static: true}) showLeaseChangeAuditModal: ModalDirective;
  @ViewChild('showLeaseBillChangeAuditModal', {static: true}) showLeaseBillChangeAuditModal: ModalDirective;
  @ViewChild('showRoomAvailableDaysChangeAuditModal', {static: true}) showRoomAvailableDaysChangeAuditModal: ModalDirective;
  @ViewChild('assetTransferModal', {static: true}) assetTransferModal: ModalDirective;

  @ViewChild('projectAuditModal', {static: true}) projectAuditModal: ModalDirective;
  @ViewChild('collectionMoney', {static: true}) collectionMoney: ModalDirective;
  // 平台向导弹窗
  @ViewChild("configModal", {static: true}) configModal: ModalDirective;

  @ViewChild('paybackUndoAlarmTable', {read: ElementRef}) paybackUndoAlarmTable: ElementRef;
  @ViewChild("frozenPasswordModal", {static: true}) frozenPasswordModal: ModalDirective;
  @ViewChild("frozenPasswordAuditModal", {static: true}) frozenPasswordAuditModal: ModalDirective;
  @ViewChild("frozenPwdOperationLogModal", {static: true}) frozenPwdOperationLogModal: ModalDirective;
  @ViewChild("approvModal", {static: true}) approvModal: ModalDirective;
  @ViewChild('showFrozenPasswordAuditModal', {static: true}) showFrozenPasswordAuditModal: ModalDirective;
  @ViewChild('remarkDetailModal', {static: true}) remarkDetailModal: ModalDirective;
  @ViewChild('leaseCheckoutNotificationModal', {static: true}) leaseCheckoutNotificationModal: ModalDirective;
  @ViewChild('fullText', {static: true}) fullText: UMeditorComponent;

  @ViewChild('showLockPasswordModal', {static: true}) showLockPasswordModal: ModalDirective;
  @ViewChild('staticTabs_p') staticTabs_p: TabsetComponent;

  @ViewChild('showLimitDetailModal', {static: true}) showLimitDetailModal: ModalDirective;

  @ViewChild('renterCheckoutErrorModal', {static: true}) renterCheckoutErrorModal: ModalDirective;
  @ViewChild('renterCheckoutDepositModal', {static: true}) renterCheckoutDepositModal: ModalDirective;
  @ViewChild('projectBasePriceModal', {static: true}) projectBasePriceModal: ModalDirective;

  @ViewChild('appointmentAddModal', {static: true}) appointmentAddModal: ModalDirective;


  systemConfig: SystemConfigDTO;


  // 集中分散式房源
  roomType = 1;
  showChangeRoomType = false;
  zzErrorText: string;

  // conf: GALLERY_CONF = {
  //   imageOffset: '0px',
  //   showDeleteControl: false,
  //   showImageTitle: false
  // };
  // images: GALLERY_IMAGE[] = [];
  timer;
  timer2;
  clearTimeFace;
  status = [
    {name: '未处理', selected: true, id: 'WAITING'},
    {name: '处理中', selected: true, id: 'PROCESSING'},
    {name: '处理完成', selected: true, id: 'FIXED'}
  ];
  alertBillTypeList = [
    {name: '全部', code: 'DUE_ALL'},
    {name: '房费', code: 'DUE'},
    {name: '物业费', code: 'DUE_PROPERTYMANAGEMENTFEE'},
    {name: '宽带费', code: 'DUE_BROADBANDFEE'},
    {name: '押金', code: 'DUE_DEPOSIT_FEE'},
    {name: '服务费', code: 'DUE_SERVICE'},
  ];
  alertBillTypeCodeList = ['DUE', 'DUE_PROPERTYMANAGEMENTFEE', 'DUE_BROADBANDFEE', 'DUE_DEPOSIT_FEE', 'DUE_SERVICE'];
  repairListReqData: RepairListReqParam = {
    contact_name: null,
    contact_phone: null,
    unit_id: null,
    room_num: null,
    repair_status: null,
    repair_type: '',
    page_num: 1,
    page_size: 10
  };
  orderRealteListReq: OrderIfRelateListReqParam = {
    page_num: 1,
    page_size: 10
  };
  noAuditList: NoAuditListRequest = {
    page_num: 1,
    page_size: 10
  };
  doorLockListReq: DoorLockListReqParam = {
    page_num: 1,
    page_size: 10
  };

  resetPswReq: RestPswReqParam = {
    uuid: ''
  };

  dueListReq: AlertListReqParam = {
    alert_type: '',
    page_num: 1,
    page_size: 10,
    room_name: '',
    contract_num: ''
  };
  alert_type_due: string = ALERT_TYPE.DUE_ALL;
  noListReq: AlertListReqParam = {
    alert_type: '',
    page_num: 1,
    page_size: 10,
    room_name: '',
    contract_num: ''
  };
  faultListReq: AlertListReqParam = {
    alert_type: '',
    page_num: 1,
    page_size: 10
  };
  delayRefundListReq: AlertListReqParam = {
    alert_type: '',
    page_num: 1,
    page_size: 10
  };

  evolveReq: EvolveReqParam = {
    alert_type: '',
    evolve_remark: '',
    evolve_status: '',
    alert_id: null
  };
  discountReq: DiscountReqParam = {
    service_status: 'TOAUDIT',
    page_num: 1,
    page_size: 10
  };
  dataStatReq: DataStatReqParm = {
    data_stat: 'success'
  };

  relevanceRoomReq: RelevanceRoomParam = {
    uuid: ""
  };

  leaseAuditReq: LeaseAuditReqParam = {
    lease_audi_type: null,
    lease_audit_value: null,
    lease_id: null,
    remark: null,
    lease_type: null
  };

  roomGroupReq: AuditRoomGroupReqParam = {
    id: null,
    status: null,
    remark: null
  };

  roomPriceReq: RoomPriceReqParam = {
    lease_id: null
  };

  complainReq: ComplaintsListRequest = {
    complaints_status_list: ['WAITING', 'PROCESSING'],
    page_num: 1,
    page_size: 10
  };
  complaintsDetailRequest: ComplaintsDealRequest = {
    operate_type: null,
    complaints_id: null
  };

  // 财务复核
  lfcReviewReq: AlertListReqParam = {
    alert_type: 'LFCREVIEW',
    page_num: 1,
    page_size: 10
  };

  // 十天内即将到期的租约请求参数
  withinTenDaysReq = {
    page_num: 1,
    page_size: 10,
  };

  // 安全告警
  safeWarningReq: PersonSafeAlarmListRequest = {
    page_num: 1,
    page_size: 5,
    is_closed: false
  };

  fireAlertListReq: AlertListReqParam = {
    alert_type: 'FIRE_FIGHTING_CS',
    page_num: 1,
    page_size: 10
  };
  fireAlertListResp: FireAlertListResp;

  // 列表相关
  repairInfos: RepairInfoList;
  repairOverHours: RepairOverHoursDTOList;
  doorLockInfos: DoorLockInfoList;
  alertFault: FaultAlertInfos;
  relevanceRoomInfo: RelevanceRoomResponse;
  alertDue: DueAlertInfos;
  alertNoCheckout: NoCheckoutAlertInfos;
  discountList: DiscountInfoList;
  roomPrice: RoomPriceList1;
  person_safe_resp: PersonSafeAlarmListResponse = new PersonSafeAlarmListResponse();

  lfcReviewList: LFCReviewResponse;

  withinTenDaysResponse: NoCheckoutAlertInfos;
  withinTenDaysRequest: AlertListReqParam = new AlertListReqParam();

  delayRefundData: DelayRefundInfo;
  refundModalDetalis: any = {};
  refundStatus: string = 'handle';

  passWord: string;
  uuid: string;
  roomId: number;
  doorStatus: string;

  repairUserList: any = [];
  repairActiveUser: any;
  repair_state: string;
  repair_type: string;
  repairInfo: RepairInfo;
  repairOverHoursDto: RepairOverHoursDTO;
  processingResult: string = 'ALL';
  repair_type_type: string;
  compensationError: boolean = false;
  compensation: string;
  repair_audit = '';
  plan_fix_time: any = '';
  remark: string;
  repair_id: number;
  evolve_remark: string;
  evolve_status: string = 'IN_HANDLE';

  lease_remark: string;
  lease_audit = 'PASS';

  room_edit_remark: string;
  room_edit_audit_status = 'AGREED';

  asset_trans_remark: string;
  asset_trans_audit_status = 'AGREED';

  doorLockInfo: DoorLockInfo;
  discount: DiscountInfo;

  roomGroupInfo: AuditRoomGroupDTO;

  dataStat: DataStatInfo;
  commonalertInfo: AlertInfo;
  price: RoomPrice;
  lease_id: number;
  complain: ComplaintsListResponse;
  compla_data: ComplaintsInfoDTO;
  complain_log_list_reponse: ComplaintsLogListResponse;
  complaintsLogInfoDTO: ComplaintsLogInfoDTO;
  //车库租约合同详情
  carportAuditDetailResponse = new CarportAuditDetailResponse();
  // 换房详情 lbb 2019-01-07
  roomChangeDetailResponse = new RoomChangeAuditDetailResponse();

  roomEditAuditDetailResponse = new RoomEditAuditDetailResponse();

  assTransAuditDetailResponse?: AssTransAuditDetailResponse;
  assTransAuditDetailRequest: AssTransAuditDetailRequest = {
    page_num: 1,
    page_size: 20,
    assetEditRecordId: 0
  };
  assetEditRecordCount: number = 0;
  loading = false;

  // 财务复核
  lfcAlertId: number;
  lfcLeaseId: number;
  lfcLeaseNum: string;
  lfc_audit;

  // 下载app二维码
  downloadIosQrCodePath: string;
  downloadAndriodQrCodePath: string;


  //权限
  privileges: string;

  trWithinTenDaysList = [];
  trPrepayArrearageList = [];

  // 房间预付费账户欠费提醒
  prepayArrearageReq = {
    page_num: 1,
    page_size: 40,
  };

  prepayArrearageResponse: PrepayArrearageResponse;
  orderIfRelateResponse: OrderIfRelateResponse;


  // 资产变更提醒
  assetChangeRemindListResponse: AssetChangeRemindListResponse;
  assetChangeRemindListReq = {
    page_num: 1,
    page_size: 10,
  };

  // 资产盘点提醒
  assetCheckRemindReq: AssetCheckListRequest = {
    page_num: 1,
    page_size: 10,
    schemeName: "",
    checkPrincipal: "",
    checkStatus: 'REMIND',
  };
  assetCheckRemindResponse?: AssetCheckListRespone;

  // 保洁任务提醒
  cleanTaskListReq: CleanTaskListRequest = {
    page_num: 1,
    page_size: 20,
    project_id_list: [],
    room_name: "",
    clean_status: "WAITING",
    unit_id: 0,
  };
  cleanTaskListResponse?: CleanTaskListResponse;


  platformCode: string;

  assetChangeRemind: AssetChangeRemindDTO;
  appointListResponse: AppointListResponse = {appoint_info_dtos: [], total: 0};
  appointListRequest: AppointListRequest = {
    status: 'UNHANDLE',
    page_num: 1,
    page_size: 10
  };
  @Output() auditingChange = new EventEmitter();

  // 换房转租提醒
  subleaseChangeRoomRemindListResponse: SubleaseChangeRoomRemindListResponse;
  subleaseChange: SubleaseChangeRemind;
  subleaseChangeListReq = {
    pageNum: 1,
    pageSize: 10,
  };

  waitSignListResponse: WaitSignContractListResponse;
  waitSignListReq = {
    page_num: 1,
    page_size: 10,
  };
  noAuditListResponse: NoAuditListResponse;
  checkInLimitResponse: CheckInLimitResponse;
  checkInLimitReq = {
    page_num: 1,
    page_size: 10
  };


  checkInLimitDetailResponse: CheckInLimitDetailResponse;

  // 退宿结算
  renterCheckoutNotFinishReq: RenterCheckoutNotFinishRequest = {
    page_num: 1,
    page_size: 20,
  };
  renterCheckoutNotFinishResponse?: RenterCheckoutNotFinishResponse;

  // 退宿财务结算
  renterCheckoutFinanceNotFinishReq: RenterCheckoutFinanceNotFinishRequest = {
    page_num: 1,
    page_size: 20,
  };
  renterCheckoutFinanceNotFinishResponse?: RenterCheckoutFinanceNotFinishResponse;

  // 提醒退宿
  renterCheckoutRemindListReq: RenterCheckoutRemindListRequest = {
    page_num: 1,
    page_size: 20,
  };
  renterCheckoutRemindListResponse?: RenterCheckoutRemindListResponse;

  // 住客自动退押提醒
  renterCheckoutTransferErrorRemindListReq: RenterCheckoutTransferErrorRemindListRequest = {
    page_num: 1,
    page_size: 20,
  };
  renterCheckoutTransferErrorRemindListResponse?: RenterCheckoutTransferErrorRemindListResponse;


  //系统公告
  first_notice_list: Array<NoticeUserDTO>;
  //公告类型
  notice_types: typeof NoticeType = NoticeType;
  notice_list_req: NoticeUserRequest = new NoticeUserRequest();
  notice_list: Array<NoticeUserDTO>;
  login_id: number;
  notice_total = 0;
  notice_detail: NoticeUserDTO;
  show_type: number;

  //操作面板切换
  showTab: number;
  classFlag = false;

  template_list: RptTemplate[];

  workbenchData: WorkbenchDataResponse;

  //二维码路径
  qrCodePath: string;
  projectName: string;

  inIpad = false;
  inAndroid = false;


  new_door_lock_info: DoorLockInfo;


  project_dto: ProjectDto;
  project_file_dtos: Array<ProjectFileDTO> = [];

  projectFileAuditRequest: ProjectFileAuditRequest = new ProjectFileAuditRequest();

  project_file_audit_status: boolean = true;

  audit_project_file_dtos: Array<ProjectFileDTO> = [];


  projectAuditId: number;
  is_remark: boolean = false;
  defaultOption: Array<any> = [];

  not_pay_dto: SweepCodeNotPayRequest = new SweepCodeNotPayRequest();
  isRemark: boolean = false;
  order_info_log_dtos: Array<OrderInfoLogDTO>;
  signatory_dto: SignatoryDTO = new SignatoryDTO();

  // 余量统计
  vacant_room_num = 0;
  vacant_bed_num = 0;
  vacant_room_rate = "-";
  vacant_bed_rate = "-";
  not_checkin_num = 0;
  bed_config = false;

  nolease_room_num = 0;
  lease_room_num = 0;
  unavailable_room_num = 0;
  internal_room_num = 0;
  enclosed_lease_room_num;
  lease_room_rate = "-";
  // 整体出租率
  actual_total_percent = "-";
  // 房间出租率
  room_total_percent = "-";
  // 床位出租率
  bed_total_percent = "-";

  projectConfig: HotelProject;

  fileCount: number = 0;

  tips_company_name: string;
  workOrderAwaitNum: number;

  person_safe_alarm_dtos?: Array<PersonSafeAlarmDTO>;

  month_price_list?: WorkbenchMonthPriceResponse[];

  yearStr: string;
  monthStr: string;

  monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  yearList = [];

  shop_price_list: Array<ShopLeasePriceResponseDTO>;
  shop_price_type?: string;
  shop_lease_audit_info_dto: ShopLeaseInfoDTO;
  // 支付周期
  pay_cycle_list: BaseEnum[];

  // audit
  leaseSubjectAuditDetail: LeaseSubjectFlowAuditResponse;
  leaseBillFlowAuditResponse: LeaseBillFlowAuditResponse;
  changeRoomAvailableDaysFlowAuditResponse: ChangeRoomAvailableDaysFlowAuditResponse;
  clientNameCertcodeRsp: ClientNameCertcodeResponse = new ClientNameCertcodeResponse();
  uploadPaymentCertificateRsp: UploadPaymentCertificateResponse = new UploadPaymentCertificateResponse();
  queryAccountNameRsp: QueryAccountNameResponse = new QueryAccountNameResponse();
  clientNameIsExistRsp: ClientNameIsExistResponse = new ClientNameIsExistResponse();


  @ViewChild(ApprovRecordImgComponent) approvRecordImgComponent: ApprovRecordImgComponent;
  auditStatus = null;
  auditRejectReason: string;
  isAuditSubmit = false;
  taskId;

  // checkout
  checkoutNotFinishResponse: CheckoutFinanceUncheckListResponse;
  checkoutFinanceUncheckListRequest: CheckoutFinanceUncheckListRequest = new CheckoutFinanceUncheckListRequest();
  checkoutNotFinishReq = {
    page_num: 1,
    page_size: 10,
  };
  checkoutFinanceNotFinishResponse: CheckoutFinanceUnconfirmListResponse;
  checkoutFinanceFinanceNotFinishReq = {
    page_num: 1,
    page_size: 10,
  };

  isConfirmMoney: boolean = true;
  verifyStatement: boolean = false;
  collectionverify: boolean = true;
  cardVerify: boolean = false;
  showTimeBlock: boolean = false;
  reminderModel: number = 1;
  license: string; // 上传付款凭证
  isBtn: boolean = false;
  userNameMoney: number;
  clientUserNameMoneyId: number;
  clientUserName: string;
  certCode: string;
  closePaymentCause?: string;
  accountMoneyName?: string;
  frozenPwdOperation?: string;
  frozenPwdContractNum?: string;
  frozenPwdSignName?: string;
  frozenReason?: string;
  frozenPwdAlertId?: number;
  codeBtnStr = '点击获取';
  auditList: Array<SysUser>;
  time = 60;
  gray_btn: boolean = false;
  inter;
  frozenPwdAuditRequest: FrozenDoorPasswordAuditRequest = new FrozenDoorPasswordAuditRequest();
  frozenPwdResult?: string;
  frozenLeaseId?: number;
  frozenLeaseRoomIds?: number[];
  operationLogs?: Array<LeaseLog>;
  approvNum: string;
  frozenPasswordFlowAuditResponse: FrozenPasswordFlowAuditResponse;
  evolveDTOS: Array<EvolveInfo>;
  evolveRemark: string;
  evolveRemarkAlertId: number;
  mailAddress: string;
  mailSubject: string;
  mailText: string;
  sendMailServiceType: string;
  sendMailAlertId: number;
  sendMailLeaseId: number;
  workbenchEmailHistoryDTOS?: Array<WorkbenchEmailHistoryDTO> = new Array<WorkbenchEmailHistoryDTO>();

  payMoneyDebounceFunc;
  cardNumberDebunceFunc;
  searchLoading = false;
  @ViewChild("clientUserNameRef") clientUserNameRef: ElementRef;
  @ViewChild("certCodeRef") certCodeRef: ElementRef;

  // 审批相关
  @ViewChild('staticTabs', {static: true}) staticTabs: TabsetComponent;
  approvalResultResponse: ApprovalResultResponse;
  approvImg;


  //
  renterCheckoutErrorData: RenterCheckoutTransferErrorRemindModel;
  renterCheckoutErrorDealReq: RenterCheckoutTransferErrorDealRequest = {
    checkout_finance_type_enum: 'REFUND'
  };
  approRenterCheckoutDeposit: ApprovalRenterCheckoutDepositResponse;
  approProjectBasePrice: any;

  // 预约看房
  appointGroupList: Array<AppointmentProjectGroupDTO> = [];
  appointSubmitReq = new AppointmentHouseApplySubmitRequest;
  appointTime = generatePluginDate(new Date());
  today = new Date();
  yestoday = addDate(this.today, -1);
  appointTimeOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    showClearDateBtn: false,
    disableDateRanges: [{
      begin: {year: 1000, month: 1, day: 1},
      end: {year: this.yestoday.getFullYear(), month: (this.yestoday.getMonth() + 1), day: this.yestoday.getDate()}
    }]
  };
  appointTimes: Array<AppointmentProjectTimesDTO> = [];

  constructor(
    private workbenchService: WorkbenchService,
    private complaintsService: ComplaintsService,
    private router: ActivatedRoute,
    private workService: WorkService,
    private authService: AuthService,
    private ref: ChangeDetectorRef,
    private router1: Router,
    private leaseService: LeaseService,
    private assetService: AssetService,
    private appointService: AppointService,
    private rptService: RptService,
    private imageQrcodeService: ImageQrcodeService,
    private companyService: CompanyService,
    private logService: LogService,
    private userService: UserService,
    private baseInfoService: BaseInfoService,
    private checkoutService: CheckoutService,
    private idempotentService: IdempotentService,
    private clientInfoService: ClientInfoService,
    private repairService: RepairService,
    private approvService: ApprovService,
    private renterService: RenterService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnDestroy() {
    this.destroy();
  }

  ngOnInit() {
    this.logService.debug("WorkbenchComponent ngOnInit");

    this.timer = setInterval(() => {
      this.refresh();
    }, 1000 * 60 * 2); //2分钟刷新一次列表

    this.timer2 = setInterval(() => {
      this.refresh2();
    }, 1000 * 60 * 30); //30分钟刷新一次工作台统计面板数据*/

    // 重新计算宽度
    this.workService.event_bus.subscribe((data: WorkModel) => {
      if (data.type === this.workService.type.RESIZE) {
        this.resizeFunc();
      }
    });

    this.init();

    this.payMoneyDebounceFunc = _.debounce(this.paymentMoney, 300);
    this.cardNumberDebunceFunc = _.debounce(this.cardNumber, 300);
  }

  init() {
    let localitem = this.authService.getUserInfo();
    if (localitem) {
      this.sysUserDTO = localitem;
    }

    this.updataFaceStaus();

    // 获取项目配置参数
    this.projectConfig = this.authService.getProjectInfo();

    // auth
    this.authService.getOperatePrivilege(this.btn_privileges);

    this.initBusinessType();

    this.refresh();

    //公告相关
    if (this.authService.getCompanyId()) {
      this.login_id = this.authService.getUserInfo().id;
      this.getFirstNoticeUserList();
      this.notice_list_req.page_num = 1;
      this.notice_list_req.page_size = 20;
      this.getNoticeUserList();
    }

    this.rptService.getRptTemplateList(['NIGHT', 'ACCOUNT']).then(data => {
      this.template_list = data.rpt_template_list;
    });

    this.baseInfoService.getPayCycleList().then(data => {
      this.pay_cycle_list = data.base_enum_dto_list;
    });

    //工作台job数据
    this.getWorkbenchData();

    //初始化操作面板
    this.operationPanelInit();

    //月均价
    for (let i = 0; i <= 2; i++) {
      this.yearList.push(this._now.getFullYear() - i);
    }
    this.yearStr = this._now.getFullYear().toString();
    this.monthStr = (this._now.getMonth() + 1).toString();
    this.getMonthPriceAvg();
  }

  destroy() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
    if (this.clearTimeFace) {
      clearInterval(this.clearTimeFace);
    }
  }

  updataFaceStaus() {
    this.workbenchService.getSysFaceFlag().then(data => {
      this.faceFlag = data.flag;
      if (this.faceFlag && this.clearTimeFace) {
        clearInterval(this.clearTimeFace);
      }
    });
  }

  to_lease(lease_id: number) {
    this.template.hide();
    this.router1.navigate(["/system_manage/signed/pact/", lease_id]);
  }

  showDialog() {
    baidutongji("工作台/无合同收款");
    this.dialogComponent.openNoContractGatherDialog();
  }

  refresh(): void {

    // 新建项目必须审批
    this.getDiscountRoom();

    // 无项目不查询
    if (this.authService.getProjectId()) {

      // 空置率和入住人数
      this.getDataStat();

      this.getRepairList();
      this.getDoorList(null);
      // 告警
      this.getAlertList();

      this.getComplainList();
      this.getWithinTenDays(1);
      this.getPrepayArrearageResponse();
      this.getAssetChangeRemindList(1);
      this.getAssetCheckRemindList(1);
      this.getCleanTaskList(1);
      this.searchClientAppointList();
      this.getSubleaseChangeList();
      this.getOrderRealteList();

      this.getCheckoutNotFinishList(1);
      this.getCheckoutFinanceNotFinish(1);

      this.getRenterCheckoutNotFinishList();
      this.getRenterCheckoutFinanceNotFinishList();
      this.getRenterCheckoutRemindList();
      this.getRenterCheckoutTransferErrorRemindList();

      this.getNoAuditList();
      this.getWorkOrderNum();

      if (this.btn_privileges.SECURITY_WARN_PANEL) {
        // 工作台安全告警数据最近两条
        this.getWorkbenchSafeWarningListLastTwo();
      }

      // 工作台安全告警数据列表
      this.getWorkbenchSafeWarningList();

      if (this.btn_privileges.WAIT_SIGN_LEASE) {
        this.getWaitSignList(1);
      }

      if (this.btn_privileges.CHECK_IN_LIMIT) {
        this.getCheckInLimit(1);
      }

    }
  }

  refresh2(): void {
    this.getWorkbenchData();
  }

  showPrice(price: RoomPrice): void {
    this.price = price;
    this.staticModal27.show();
  }

  showShopPrice(priceList: Array<ShopLeasePriceResponseDTO>, shop_price_type: string) {
    this.shop_price_list = priceList;
    this.shop_price_type = shop_price_type;
    this.shopPriceModal.show();
  }

  getUserRepair(repairId: number) {
    this.userService.userSysWorker({privilegeCode: 'WOS_MMH_000004', rest_day: true, repairId: repairId}).then(res => {
      if (res.sys_user_list) {
        this.repairUserList = res.sys_user_list;
      }
    });
  }

  showRepairModal(repairOverHoursDto: RepairOverHoursDTO, state: string = '') {
    this.repairUserList = [];
    this.repairActiveUser = '';
    if (repairOverHoursDto.repair_type === 'CLEAN') {
      this.repair_type_type = 'CLEAN';
      this.repair_type = '保洁详细信息';
    }
    if (repairOverHoursDto.repair_type === 'REPAIR') {
      this.repair_type_type = 'REPAIR';
      this.repair_type = '维修订单详情';
      this.getUserRepair(repairOverHoursDto.repair_id);
    }
    this.repair_state = state;
    this.plan_fix_time = null;
    this.remark = null;
    this.repair_id = repairOverHoursDto.repair_id;
    this.repairOverHoursDto = repairOverHoursDto;
    this.template5.show();
  }

  showDoorModal(doorInfo: DoorLockInfo) {
    this.new_door_lock_info = doorInfo;
    this.passWord = doorInfo.dev_password;
    this.uuid = doorInfo.uuid;
    this.roomId = doorInfo.room_id;
    this.staticModal29.show();
  }

  showDoorModal2() {
    this.staticModal31.show();
  }

  showFaultModal(faultInfo: AlertInfo, p: string) {
    this.privileges = null;
    this.privileges = p;
    this.evolve_remark = null;
    this.evolve_status = 'IN_HANDLE';
    this.commonalertInfo = faultInfo;
    // this.modalRef = this.modalService.show(template);
    this.template.show();
  }

  showNocheckModal(
    nocheckInfo: AlertInfo,
    p: string
  ) {
    this.privileges = null;
    this.privileges = p;
    this.evolve_remark = null;
    this.evolve_status = 'IN_HANDLE';
    this.commonalertInfo = nocheckInfo;
    // this.modalRef = this.modalService.show(template);
    this.signatory_dto = new SignatoryDTO();
    if (this.alertNoCheckout.signatory_dtolist) {
      this.alertNoCheckout.signatory_dtolist.forEach(signatory => {
        if (signatory.lease_id === nocheckInfo.lease_id) {
          this.signatory_dto = signatory;
        }
      });
    }
    this.template.show();
  }

  showDueModal(dueInfo: AlertInfo, p: string) {
    this.privileges = null;
    this.privileges = p;
    this.evolve_remark = null;
    this.evolve_status = 'IN_HANDLE';
    this.commonalertInfo = dueInfo;
    this.signatory_dto = new SignatoryDTO();
    if (this.alertDue.signatory_dtolist) {
      this.alertDue.signatory_dtolist.forEach(signatory => {
        if (signatory.lease_id === dueInfo.lease_id) {
          this.signatory_dto = signatory;
        }
      });
    }

    // this.modalRef = this.modalService.show(template);
    this.template.show();
  }

  tj(title: string) {
    baidutongji("工作台/" + title + "/处理");
  }

  showLeaseModal(discount: AuditDTO) {
    this.lease_audit = 'PASS';
    this.lease_remark = null;
    let req = new AuditDetailReqParam();
    req.service_type = discount.service_type;
    req.id = discount.id;
    this.workbenchService.getAuditDetail(req).then(data => {
      this.discount = data as DiscountInfo;
    });
    this.roomPriceReq.lease_id = discount.id;
    this.getRoomPrice();
    this.staticModal28.show();
  }

  // 展示商铺合同信息
  showShopLeaseModal(discount: AuditDTO) {
    let req = new AuditDetailReqParam();
    req.service_type = discount.service_type;
    req.id = discount.id;
    this.workbenchService.getAuditDetail(req).then(data => {
      this.shop_lease_audit_info_dto = data.shop_lease_info_dto;
    });
    this.shopLeaseModal.show();
  }

  showRoomGroupModal(item: AuditDTO) {
    this.lease_audit = 'PASS';
    this.lease_remark = null;
    let req = new AuditDetailReqParam();
    req.service_type = item.service_type;
    req.id = item.id;
    this.workbenchService.getAuditDetail(req).then(data => {
      this.roomGroupInfo = data as AuditRoomGroupDTO;
    });
    this.housingapprovalModal.show();
  }

  /**
   * @Description:  获取车库租约详情
   * @Author: zjh
   * @Date: 2018-12-07  11:35
   */
  showCarportDetailModel(item: AuditDTO) {
    this.lease_remark = null;
    this.lease_audit = 'AGREED';
    let req = new AuditDetailReqParam();
    req.service_type = item.service_type;
    req.id = item.id;
    this.workbenchService.getAuditDetail(req).then(data => {
      this.carportAuditDetailResponse = data as CarportAuditDetailResponse;
      this.carportModel.show();
    });

  }

  // 获取投诉弹窗内容
  openModal(compla_data: ComplaintsInfoDTO) {
    this.compla_data = compla_data;
    this.getComplainData(compla_data.complaints_id);
    this.complainModal.auditRemark = "";
    // this.modalRef = this.modalService.show(template);
    // this.template2.show();
    this.complainModal.handleModal.show();
  }

  getDataStat(): void {
    this.workbenchService
      .getDataStat(this.dataStatReq)
      .then(data => {
        if (data) {
          this.dataStat = data;
        } else {
          this.dataStat = null;
        }
      });
  }

  getRoomPrice(): void {
    this.workbenchService
      .getRoomPrice(this.roomPriceReq)
      .then(data => {
        if (data) {
          this.roomPrice = data;
        } else {
          this.roomPrice = null;
        }
      });
  }


  //维修保洁列表
  getRepairList(): void {
    // 过滤无用参数
    for (const item in this.repairListReqData) {
      if (!this.repairListReqData[item]) {
        delete this.repairListReqData[item];
      }
    }
    this.repairListReqData.repair_status = 'WAITING';
    this.workbenchService.getRepairLit(this.repairListReqData).then(data => {

      if (data && data.repair_over_hours) {
        this.repairOverHours = data;
      } else {
        this.repairOverHours = null;
      }
    });
  }

  //付款成功未关联列表
  getOrderRealteList(): void {
    for (const item in this.orderRealteListReq) {
      if (!this.orderRealteListReq[item]) {
        delete this.orderRealteListReq[item];
      }
    }
    this.workbenchService.getOrderIfRelateList(this.orderRealteListReq).then(data => {
      if (data && data.order_info_dtolist) {
        this.orderIfRelateResponse = data;
      } else {
        this.orderIfRelateResponse = null;
      }
    });

  }

  getNoAuditList(): void {
    for (const item in this.noAuditList) {
      if (!this.noAuditList[item]) {
        delete this.noAuditList[item];
      }
    }
    this.workbenchService.noAuditList(this.noAuditList).then(data => {
      if (data && data.no_audit_transfer_list) {
        this.noAuditListResponse = data;
      } else {
        this.noAuditListResponse = null;
      }
    });
  }

  //门锁列表
  getDoorList(type: string): void {
    for (const item in this.doorLockListReq) {
      if (!this.doorLockListReq[item]) {
        delete this.doorLockListReq[item];
      }
    }

    this.workbenchService.getDoorLockList(this.doorLockListReq).then(data => {
      if (data && data.door_lock_dtos) {
        this.doorLockInfos = data;
        if (type === 'reset') {
          this.passWord = this.doorLockInfos.door_lock_dtos.filter(unit => unit.uuid === this.new_door_lock_info.uuid).pop().dev_password;
        }
      } else {
        this.doorLockInfos = null;
      }
    });
  }

  //告警列表
  getAlertList(): void {

    // 合同收租
    this.getContactRentList();

    // 设备故障
    this.getDeviceFaultList();

    // 退房提醒
    this.getCheckoutRoomWarningList();

    // 财务复核
    this.getFinaceCheckList();

    // 延迟退房退款
    this.getdelayRefundList();

    if (this.btn_privileges.FIRE_FIGHTING_CS) {
      this.getFireAlertList();
    }
  }

  getContactRentList() {
    this.dueListReq.alert_type = this.alert_type_due;
    this.workbenchService.getDueAlertList(this.dueListReq).then(data => {
      this.alertDue = data;
      if (!data.due_alert_dtos) {
        this.alertDue.due_alert_dtos = [];
        this.alertDue.total = 0;
      }
    });
  }

  getDeviceFaultList() {
    this.isCode = "";
    this.faultListReq.alert_type = ALERT_TYPE.FAULT;
    this.workbenchService.getFaultAlertList(this.faultListReq).then(data => {
      this.alertFault = data;
      if (!data.fault_alert_dtos) {
        this.alertFault.fault_alert_dtos = [];
        this.alertFault.total = 0;
      }
    });
  }

  getCheckoutRoomWarningList() {
    this.noListReq.alert_type = ALERT_TYPE.NO_CHECKOUT;
    this.workbenchService.getNoCheckoutAlertList(this.noListReq).then(data => {
      this.alertNoCheckout = data;
      if (!data.no_checkout_alert_dto) {
        this.alertNoCheckout.no_checkout_alert_dto = [];
        this.alertNoCheckout.total = 0;
      }
    });
  }

  getdelayRefundList() {
    this.delayRefundListReq.alert_type = ALERT_TYPE.DELAY_REFUND;
    this.workbenchService.getdelayRefundList(this.delayRefundListReq).then(data => {
      this.delayRefundData = data;
      if (!data.delay_refund_list) {
        this.delayRefundData.delay_refund_list = [];
        this.delayRefundData.total = 0;
      }
    });
  }

  getFinaceCheckList() {
    if (this.btn_privileges.LEASE_AUDIT_AGAIN) {
      this.workbenchService.getLFCReviewAlertList(this.lfcReviewReq).then(data => {
        this.lfcReviewList = data;
        if (!data.lease_fcreview_dtolist) {
          this.lfcReviewList.lease_fcreview_dtolist = [];
          this.lfcReviewList.total = 0;
        }
      });
    }
  }

  //获取租约待审核列表
  getDiscountRoom(): void {
    this.discountList = null;
  }

  restPassWord(): void {
    this.resetPswReq.uuid = this.uuid;
    this.workbenchService.restDoorLockPsw(this.resetPswReq).then(data => {
      if (data) {
        this.doorStatus = data.status;
        this.getDoorList('reset');
      } else {
      }
    });
    this.staticModal30.show();
  }

  // 待处理投诉列表
  getComplainList(): void {
    this.complaintsService
      .listWithLoading(this.complainReq)
      .then(data => {
        if (data) {
          this.complain = data;
          // this.complain.total = 0;
        } else {
          this.complain = null;
        }
      });
  }

  //获取日志列表
  getComplainData(id: any): void {
    this.complain_log_list_reponse = {};
    this.complaintsDetailRequest.complaints_id = id;
    this.complaintsService
      .log(this.complaintsDetailRequest)
      .then(response => {
        if (response) {
          this.complain_log_list_reponse = response;
        } else {
          this.complain_log_list_reponse = {
            complaints_log_info_dtos: []
          };
        }
      });
  }

  //验收成功
  acceptanceSuccess(): void {
    let acceptanceReqParam: AcceptanceResParam = new AcceptanceResParam();
    acceptanceReqParam.room_id = this.roomId;
    acceptanceReqParam.uuid = this.uuid;
    acceptanceReqParam.install_status = 'INSTALLED';
    this.workbenchService.acceptance(acceptanceReqParam).then(data => {
      if (data) {
        this.staticModal29.hide();
        this.staticModal31.hide();
        this.getDoorList(null);

        let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
        scheduleOperateModel.operateType = OperationType.DELETE;
        scheduleOperateModel.scheduleType = 'DOORLOCK';
        this.workService.event_bus.emit(
          new WorkModel(
            this.workService.type.SCHEDULE_TASK,
            scheduleOperateModel
          )
        );
      }
    });
  }

  repairPageChange(currentPage: number): void {
    this.repairListReqData.page_num = currentPage;
    this.getRepairList();
  }

  orderPageChange(currentPage: number): void {
    this.orderRealteListReq.page_num = currentPage;
    this.getOrderRealteList();

  }

  doorPageChange(currentPage: number): void {
    this.doorLockListReq.page_num = currentPage;
    this.getDoorList(null);
  }

  leasePageChange(currentPage: number) {
    this.discountReq.page_num = currentPage;
    this.getDiscountRoom();
  }

  faultPageChange(currentPage: number) {


    this.faultListReq.page_num = currentPage;
    if (this.isCode) {
      this.searchName(this.isCode);
    } else {
      this.getDeviceFaultList();
    }

  }

  withinTenDaysPageChange(currentPage: number) {
    this.withinTenDaysRequest.page_size = 10;
    this.getWithinTenDays(currentPage);
  }

  delayRefundPageChange(currentPage: number) {
    this.delayRefundListReq.page_num = currentPage;
    this.getdelayRefundList();
  }

  prepayArrearagePageChange(currentPage: number) {
    this.prepayArrearageReq.page_num = currentPage;
    this.getPrepayArrearageResponse();
  }

  duePageChange(currentPage: number) {
    this.dueListReq.page_num = currentPage;
    this.getContactRentList();
  }

  checkPageChange(currentPage: number) {
    this.noListReq.page_num = currentPage;
    this.getCheckoutRoomWarningList();
  }

  getComplainPage(currentPage: number) {
    this.complainReq.page_num = currentPage;
    this.getComplainList();
  }

  changeClientAppointAlert(currentPage: number) {
    this.appointListRequest.page_num = currentPage;
    this.searchClientAppointList();
  }

  lfcPageChange(currentPage: number) {
    this.lfcReviewReq.page_num = currentPage;
    this.getFinaceCheckList();
  }

  //照片凭证
  //打开图片详情
  openImages(imgArray: string[], index) {
    this.workService.event_bus.emit(new WorkModel(this.workService.type.IMGFULL_SCREEN_PREVEIEW, new ImagePreviewModel(imgArray, index)));
  }

  openImage(imgArray: RepairImage[], index) {
    this.openImages(imgArray.map(e => e.image_url), index);
  }

  repairDistr() {
    if (this.repairActiveUser == this.repairOverHoursDto.worker) {
      return;
    }
    if (!this.repairActiveUser) {
      this.workService.showErrorModal('请选择维修账号!');
      return;
    }
    this.userService.distrUserRepair({repair_id: this.repair_id, worker_id: this.repairActiveUser}).then(res => {
      this.template5.hide();
      this.getRepairList();
    });
  }

  repairOk(repairInfo: any, type?: string): void {
    let repairHandleReqParam: RepairHandleReqParam = new RepairHandleReqParam();
    repairHandleReqParam.repair_id = this.repair_id;
    if (type) {
      this.repair_audit = type;
    }
    switch (this.repair_audit) {
      case 'CONFIRM':
        repairHandleReqParam.repair_change_type = REPAIR_HANDLE_TYPE.CONFIRM;
        repairHandleReqParam.plan_fix_time = repairInfo.reservation_start_time;
        repairHandleReqParam.repair_id = this.repair_id;
        repairHandleReqParam.reject_reason = this.remark;
        this.workbenchService.handle(repairHandleReqParam).then(data => {
          this.ngOnInit();
        });
        this.template5.hide();
        break;

      case 'REJECT':
        if (this.remark !== null) {
          repairHandleReqParam.repair_change_type = REPAIR_HANDLE_TYPE.REJECT;
          repairHandleReqParam.repair_id = this.repair_id;
          repairHandleReqParam.reject_reason = this.remark;
          this.workbenchService
            .handle(repairHandleReqParam)
            .then(data => {
              this.getRepairList();
              let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
              scheduleOperateModel.operateType = OperationType.DELETE;
              scheduleOperateModel.scheduleType = 'REPAIR';
              this.workService.event_bus.emit(
                new WorkModel(
                  this.workService.type.SCHEDULE_TASK,
                  scheduleOperateModel
                )
              );
            })
            .catch(err => {
              this.workService.event_bus.emit(
                new WorkModel(this.workService.type.DIALOG_ERROR, err.message)
              );
            });
          this.template5.hide();
        }
        break;

      case 'FINISH':
        repairHandleReqParam.repair_change_type = REPAIR_HANDLE_TYPE.FINISH;
        repairHandleReqParam.repair_id = this.repair_id;
        repairHandleReqParam.reject_reason = this.remark;
        repairHandleReqParam.processing_result = this.processingResult;
        repairHandleReqParam.compensation = this.compensation;
        this.workbenchService.handle(repairHandleReqParam)
          .then(data => {
            this.ngOnInit();
          })
          .catch(err => {
            this.workService.event_bus.emit(
              new WorkModel(this.workService.type.DIALOG_ERROR, err.message)
            );
          });
        this.template5.hide();
        break;
    }
  }

  ok_evolve(info: AlertInfo): void {
    this.evolveReq.alert_id = info.alert_id;
    this.evolveReq.evolve_status = this.evolve_status;
    this.evolveReq.evolve_remark = this.evolve_remark;
    this.evolveReq.alert_type = info.alert_type;
    if (this.evolve_remark && this.evolve_remark.length >= 3) {

      this.workbenchService.handleEvolve(this.evolveReq).then(data => {
        if (data) {

          this.getAlertList();

          this.showRefundModal.hide();
          this.template.hide();

          if (this.evolve_status === 'OFF') {
            let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
            scheduleOperateModel.operateType = OperationType.DELETE;
            if (info.alert_type !== 'NO_CHECKOUT' && info.alert_type !== 'DUE') {
              scheduleOperateModel.scheduleType = 'FAULT';
            } else {
              scheduleOperateModel.scheduleType = info.alert_type;
            }
            this.workService.event_bus.emit(
              new WorkModel(
                this.workService.type.SCHEDULE_TASK,
                scheduleOperateModel
              )
            );
          }

        }
      });
    }
  }

  ok_roomGroup(room: AuditRoomGroupDTO) {
    this.roomGroupReq.remark = null;
    this.roomGroupReq.status = null;
    this.roomGroupReq.id = null;

    if (this.lease_audit === 'PASS') {
      this.roomGroupReq.remark = this.lease_remark;
      this.roomGroupReq.status = this.lease_audit;
      this.roomGroupReq.id = room.id;
      this.workbenchService.handleRoomGroupAudit(this.roomGroupReq).then(data => {
        this.getDiscountRoom();
        this.housingapprovalModal.hide();

        let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
        scheduleOperateModel.operateType = OperationType.DELETE;
        scheduleOperateModel.scheduleType = 'AUDIT';
        this.workService.event_bus.emit(
          new WorkModel(
            this.workService.type.SCHEDULE_TASK,
            scheduleOperateModel
          )
        );
      });
    }
    if (this.lease_audit === 'REJECT' && this.lease_remark !== null) {
      this.roomGroupReq.remark = this.lease_remark;
      this.roomGroupReq.status = this.lease_audit;
      this.roomGroupReq.id = room.id;
      this.workbenchService.handleRoomGroupAudit(this.roomGroupReq).then(data => {
        this.getDiscountRoom();
        this.housingapprovalModal.hide();

        let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
        scheduleOperateModel.operateType = OperationType.DELETE;
        scheduleOperateModel.scheduleType = 'AUDIT';
        this.workService.event_bus.emit(
          new WorkModel(
            this.workService.type.SCHEDULE_TASK,
            scheduleOperateModel
          )
        );
      });
    }
  }

  ok_lease(discount: DiscountInfo): void {
    this.leaseAuditReq.remark = this.lease_remark;
    this.leaseAuditReq.lease_audit_value = this.lease_audit;
    if (discount.lease_booking_list_dto.lease_type === 'ORIGIN') {
      this.leaseAuditReq.lease_audi_type = 'LEASE_AUDIT';
    } else if (discount.lease_booking_list_dto.lease_type === 'RELET') {
      this.leaseAuditReq.lease_audi_type = 'RELET_AUDIT';
    } else if (discount.lease_booking_list_dto.lease_type === 'EXCHANGE') {
      this.leaseAuditReq.lease_audi_type = 'EXCHANGE_AUDIT';
    }
    this.leaseAuditReq.lease_id = discount.lease_booking_list_dto.serial_number;

    if (this.lease_audit === 'PASS') {
      this.workbenchService.handleLeaseAudit(this.leaseAuditReq).then(data => {
        if (data) {
          this.getDiscountRoom();
          this.staticModal28.hide();

          let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
          scheduleOperateModel.operateType = OperationType.DELETE;
          scheduleOperateModel.scheduleType = 'AUDIT';
          this.workService.event_bus.emit(
            new WorkModel(
              this.workService.type.SCHEDULE_TASK,
              scheduleOperateModel
            )
          );
        }
      });
    }

    if (this.lease_audit === 'REJECT' && this.lease_remark !== null) {
      this.workbenchService.handleLeaseAudit(this.leaseAuditReq).then(data => {
        if (data) {
          this.getDiscountRoom();
          this.staticModal28.hide();

          let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
          scheduleOperateModel.operateType = OperationType.DELETE;
          scheduleOperateModel.scheduleType = 'AUDIT';
          this.workService.event_bus.emit(
            new WorkModel(
              this.workService.type.SCHEDULE_TASK,
              scheduleOperateModel
            )
          );
        }
      });
    }
  }

  // 提交审核
  ok_shop_lease(discount: DiscountInfo): void {
    this.leaseAuditReq.remark = this.lease_remark;
    this.leaseAuditReq.lease_audit_value = this.lease_audit;
    if (discount.shop_lease_info_dto.lease_type === 'ORIGIN') {
      this.leaseAuditReq.lease_audi_type = 'LEASE_AUDIT';
    }
    this.leaseAuditReq.lease_type = 'SHOP_LEASE';
    this.leaseAuditReq.lease_id = discount.shop_lease_info_dto.lease_id;


    if (this.lease_audit === 'PASS') {
      this.workbenchService.handleLeaseAudit(this.leaseAuditReq).then(data => {
        if (data) {
          // 刷新列表数据
          this.lease_audit = 'PASS';
          this.lease_remark = '';
          this.getDiscountRoom();
          this.shopLeaseModal.hide();
          let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
          scheduleOperateModel.operateType = OperationType.DELETE;
          scheduleOperateModel.scheduleType = 'AUDIT';
          this.workService.event_bus.emit(
            new WorkModel(
              this.workService.type.SCHEDULE_TASK,
              scheduleOperateModel
            )
          );
        }
      });
    }

    if (this.lease_audit === 'REJECT' && this.lease_remark && this.lease_remark.length > 0) {
      this.workbenchService.handleLeaseAudit(this.leaseAuditReq).then(data => {
        if (data) {
          // 刷新列表数据
          this.lease_audit = 'PASS';
          this.lease_remark = '';
          this.getDiscountRoom();
          this.shopLeaseModal.hide();
          let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
          scheduleOperateModel.operateType = OperationType.DELETE;
          scheduleOperateModel.scheduleType = 'AUDIT';
          this.workService.event_bus.emit(
            new WorkModel(
              this.workService.type.SCHEDULE_TASK,
              scheduleOperateModel
            )
          );
        }
      });
    }
  }

  // 提交审核
  ok_shop_lease_audit(shop_lease_id?: number): void {
    this.leaseAuditReq.remark = this.lease_remark;
    this.leaseAuditReq.lease_audit_value = this.lease_audit;
    this.leaseAuditReq.lease_type = 'SHOP_LEASE';
    this.leaseAuditReq.lease_id = shop_lease_id;

    if (this.lease_audit === 'REJECT' && (!this.lease_remark || this.lease_remark.length <= 0)) {
      this.workService.showErrorModal('请填写拒绝原因！');
      return;
    }
    this.workbenchService.handleLeaseAudit(this.leaseAuditReq).then(data => {
      if (data) {
        // 刷新列表数据
        this.lease_audit = 'PASS';
        this.lease_remark = '';
        this.getDiscountRoom();
        this.shopLeaseModal.hide();
        let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
        scheduleOperateModel.operateType = OperationType.DELETE;
        scheduleOperateModel.scheduleType = 'AUDIT';
        this.workService.event_bus.emit(
          new WorkModel(
            this.workService.type.SCHEDULE_TASK,
            scheduleOperateModel
          )
        );
      }
    });

  }

  cancel_shop_lease() {
    this.lease_audit = 'PASS';
    this.lease_remark = '';
    this.shopLeaseModal.hide();
  }

  check(str: string) {
    if (str && str === 'being') {
      this.beingResut = 'PROCESSING';
    }
    if (str && str === 'succ') {
      this.beingResut = 'FIXED';
    }
  }

  ok_carportLease(carport: CarportAuditDetailResponse) {
    this.roomGroupReq.remark = this.lease_remark;
    this.roomGroupReq.status = this.lease_audit;
    this.roomGroupReq.id = carport.carport_id;
    if (this.lease_audit === 'REFUSED' && this.lease_remark == null) {
      return;
    }

    this.workbenchService.handleCarportAudit(this.roomGroupReq).then(data => {
      this.getDiscountRoom();
      this.carportModel.hide();

      let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
      scheduleOperateModel.operateType = OperationType.DELETE;
      scheduleOperateModel.scheduleType = 'AUDIT';
      this.workService.event_bus.emit(
        new WorkModel(
          this.workService.type.SCHEDULE_TASK,
          scheduleOperateModel
        )
      );
    });
  }

  showLFCReviewModel(item: LeaseFCReviewDTO) {

    this.lfcAlertId = item.alert_id;
    this.lfcLeaseId = item.lease_id;
    this.lfcLeaseNum = item.lease_num;

    this.lfc_audit = null;
    this.lfcReviewModal.show();
  }

  lrfOk() {

    if (this.lfc_audit) {
      this.evolveReq.alert_id = this.lfcAlertId;
      this.evolveReq.evolve_status = 'OFF';
      this.evolveReq.alert_type = 'LFCREVIEW';
      this.workbenchService.handleEvolve(this.evolveReq).then(data => {
        if (data) {

          this.lfcReviewReq.page_num = 1;
          this.getAlertList();
          this.lfcReviewModal.hide();

          let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
          scheduleOperateModel.operateType = OperationType.DELETE;
          scheduleOperateModel.scheduleType = 'LFCREVIEW';
          this.workService.event_bus.emit(
            new WorkModel(
              this.workService.type.SCHEDULE_TASK,
              scheduleOperateModel
            )
          );
        }
      });
    } else {
      this.lfcReviewModal.hide();
    }

  }

  getPrepayArrearageResponse() {
    this.leaseService.roomArrearageList(this.prepayArrearageReq).then(data => {
      this.prepayArrearageResponse = data;
      this.prepayArrearageGroupLength();
    });
  }

  prepayArrearageGroupLength() {
    this.trPrepayArrearageList = [];
    if (this.prepayArrearageResponse.prepay_arrearage_list != null && this.prepayArrearageResponse.prepay_arrearage_list.length > 0) {
      //let list = [];
      let k = -1;
      for (let i = 0; i < this.prepayArrearageResponse.prepay_arrearage_list.length; i++) {
        if (i % 4 === 0) {
          k++;
          this.trPrepayArrearageList[k] = [];
        }
        this.prepayArrearageResponse.prepay_arrearage_list[i].arrearage_amount
          = Math.abs(+this.prepayArrearageResponse.prepay_arrearage_list[i].arrearage_amount) + "";
        this.trPrepayArrearageList[k].push(this.prepayArrearageResponse.prepay_arrearage_list[i]);
      }
    }
  }


  getWithinTenDays(page_num: number) {
    // this.withinTenDaysRequest.page_num = page_num;
    // this.withinTenDaysRequest.page_size = 10;
    // this.leaseService.withinTenDays(this.withinTenDaysRequest).then(data => {
    //   this.withinTenDaysResponse = data;
    //   //this.groupLenth();
    // });
    this.withinTenDaysRequest.alert_type = ALERT_TYPE.NO_CHECKOUT;
    this.withinTenDaysRequest.page_num = page_num;
    this.withinTenDaysRequest.page_size = 10;
    this.workbenchService.getNoCheckoutAlertList(this.withinTenDaysRequest).then(data => {
      this.withinTenDaysResponse = data;
      if (!data.no_checkout_alert_dto) {
        this.alertNoCheckout.no_checkout_alert_dto = [];
        this.alertNoCheckout.total = 0;
      }
    });
  }

  // groupLenth() {
  //   this.trWithinTenDaysList = [];
  //   if (this.withinTenDaysResponse.within_ten_days_list != null && this.withinTenDaysResponse.within_ten_days_list.length > 0) {
  //     let k = -1;
  //     for (let i = 0; i < this.withinTenDaysResponse.within_ten_days_list.length; i++) {
  //       if (i % 4 === 0) {
  //         k++;
  //         this.trWithinTenDaysList[k] = [];
  //       }
  //       this.trWithinTenDaysList[k].push(this.withinTenDaysResponse.within_ten_days_list[i]);
  //     }
  //   }
  // }


  showRefundfun(info) {
    this.refundModalDetalis = info;
    this.evolve_remark = '';
    this.showRefundModal.show();
  }

  goLease(leaseId: number) {
    this.router1.navigate(['../../signed/pact', leaseId], {
      relativeTo: this.router
    });
  }

  assetChangeShow(info: AssetChangeRemindDTO) {
    this.assetChangeRemind = info;
    this.assetChangeAuditModal.show();
  }

  appointChangeShow(id?: number) {
    this.seehouseDetailComponent.showModel(id);
  }

  // lbb 2019-01-07 显示换房审核弹窗
  showRoomChangeModal(item: AuditDTO) {
    this.lease_remark = null;
    this.lease_audit = 'PASS';

    let req = new AuditDetailReqParam();
    req.service_type = item.service_type;
    req.id = item.id;
    this.workbenchService.getAuditDetail(req).then(data => {
      this.roomChangeDetailResponse = data as RoomChangeAuditDetailResponse;
      this.changeRoomAuditModal.show();
    });
  }

  ok_roomEditChange(roomEditAuditDetailResponse: RoomEditAuditDetailResponse) {
    let req = new AuditRoomEditReq();
    req.room_edit_record_id = roomEditAuditDetailResponse.room_edit_record_id;
    req.remark = this.room_edit_remark;
    req.record_status = this.room_edit_audit_status;

    this.workbenchService.handleRoomEditAudit(req).then(data => {
      this.getDiscountRoom();
      this.roomEditModal.hide();

      let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
      scheduleOperateModel.operateType = OperationType.DELETE;
      scheduleOperateModel.scheduleType = 'AUDIT';
      this.workService.event_bus.emit(new WorkModel(this.workService.type.SCHEDULE_TASK, scheduleOperateModel));
    });
  }

  // lbb 2019-01-07 换房审核通过
  ok_roomChange(roomChangeDetailResponse: RoomChangeAuditDetailResponse) {
    let req = new AuditRoomChangeReq();
    req.record_id = roomChangeDetailResponse.room_change_info.record_id;
    req.remark = this.lease_remark;
    req.status = this.lease_audit;

    this.workbenchService.handleRoomChangeAudit(req).then(data => {
      this.getDiscountRoom();
      this.changeRoomAuditModal.hide();

      let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
      scheduleOperateModel.operateType = OperationType.DELETE;
      scheduleOperateModel.scheduleType = 'AUDIT';
      this.workService.event_bus.emit(
        new WorkModel(
          this.workService.type.SCHEDULE_TASK,
          scheduleOperateModel
        )
      );
    });
  }

  getAssetChangeRemindList(page_num: number) {
    this.assetChangeRemindListReq.page_num = page_num;
    if (this.assetChangeRemindListResponse) {
      delete this.assetChangeRemindListResponse.asset_change_remind_list;
    }
    this.assetService.assetChangeRemindList(this.assetChangeRemindListReq).then(data => {
      this.assetChangeRemindListResponse = data;
    });
  }

  getAssetCheckRemindList(page_num: number) {
    this.assetCheckRemindReq.page_num = page_num;
    if (this.assetCheckRemindResponse) {
      delete this.assetCheckRemindResponse.asset_check_list;
    }
    this.assetService.getAssetCheckList(this.assetCheckRemindReq).then(data => {
      this.assetCheckRemindResponse = data;
    });
  }

  getCleanTaskList(page_num: number) {
    this.platformCode = localStorage.getItem('platformCode');
    this.cleanTaskListReq.page_num = page_num;
    if (this.cleanTaskListResponse) {
      delete this.cleanTaskListResponse.clean_task_list;
    }

    let companyId = this.authService.getCompanyId();
    this.userService.userProjectList({
      company_id: companyId,
      user_id: this.authService.getUserInfo().id,
      platfromCode: this.platformCode
    }).then(data => {
      this.cleanTaskListReq.project_id_list = [];
      data.project_dtolist.forEach((pj, index) => {
        this.cleanTaskListReq.project_id_list.push(pj.id);
      });

      this.repairService.getCleanTaskList(this.cleanTaskListReq).then(data2 => {
        this.cleanTaskListResponse = data2;
      });
    });
  }

  assetChangeRemindRead(id: number) {
    this.assetService.assetChangeRemindRead({assetChangeRemindId: id}).then(data => {
      this.assetChangeAuditModal.hide();
      this.getAssetChangeRemindList(this.assetChangeRemindListReq.page_num);

      let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
      scheduleOperateModel.operateType = OperationType.DELETE;
      scheduleOperateModel.scheduleType = 'ASSETCHANGEREMIND';
      this.workService.event_bus.emit(new WorkModel(this.workService.type.SCHEDULE_TASK, scheduleOperateModel));
    });

  }

  yanzhengCompensation() {
    if ('ARTIFICIAL_DAMAGE' === this.processingResult) {
      if (!/^\d+(\.\d{0,2})?$/.test(this.compensation)) {
        this.compensationError = true;
        return;
      } else {
        this.compensationError = false;
      }
    }
  }

  searchClientAppointList() {
    this.appointService.list(this.appointListRequest).then(data => {
      if (data && data.total > 0) {
        this.appointListResponse = data;
      } else {
        this.appointListResponse.appoint_info_dtos = [];
        this.appointListResponse.total = 0;
      }
    });
  }

  getSubleaseChangeList() {
    if (this.btn_privileges.SUBLEASE_CHANGE_REMIND) {
      this.leaseService.subleaseChangeRemindList(this.subleaseChangeListReq).then(data => {
        this.subleaseChangeRoomRemindListResponse = data;
      });
    }
  }

  subleaseChangePageChange(page) {
    this.subleaseChangeListReq.pageNum = page;
    this.getSubleaseChangeList();
  }

  subleaseChangeShow(subleaseChangeModal: any, remind: SubleaseChangeRemind) {
    this.subleaseChange = remind;
    subleaseChangeModal.show();
  }

  subleaseChangeRemindRead(subleaseChangeModal: any) {
    this.leaseService.subleaseChangeRemindRead({sublease_change_remind_id: this.subleaseChange.sublease_change_remind_id})
      .then(data => {
        subleaseChangeModal.hide();
        if (this.subleaseChangeRoomRemindListResponse.remind_list && this.subleaseChangeRoomRemindListResponse.remind_list.length === 1) {
          this.subleaseChangeListReq.pageNum = this.subleaseChangeListReq.pageNum > 1
            ? this.subleaseChangeListReq.pageNum - 1 : this.subleaseChangeListReq.pageNum;
        }
        let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
        scheduleOperateModel.operateType = OperationType.DELETE;
        scheduleOperateModel.scheduleType = 'SUBLEASECHANGEREMIND';
        this.workService.event_bus.emit(new WorkModel(this.workService.type.SCHEDULE_TASK, scheduleOperateModel));
        // 刷新当前列表
        this.getSubleaseChangeList();
      });
  }

  //获取工作台前三条公告列表
  getFirstNoticeUserList() {
    const noticeUserReq = new NoticeUserRequest();
    noticeUserReq.page_num = 1;
    noticeUserReq.page_size = 3;
    noticeUserReq.userId = this.login_id;
    this.workbenchService.getNoticeUserList(noticeUserReq).then(data => {
      this.first_notice_list = data.notice_user_dtolist;
    });
  }

  //公告列表分页
  pageNoticeUserChange(page_num: number) {
    this.notice_list_req.page_num = page_num;
    this.getNoticeUserList();
  }

  //公告列表查询
  getNoticeUserList() {
    this.notice_list_req.userId = this.login_id;
    this.workbenchService.getNoticeUserList(this.notice_list_req).then(data => {
      this.notice_list = data.notice_user_dtolist;
      this.notice_total = data.total;
    });
  }

  //显示公告列表弹窗
  noticeListModelShow(noticeListModel: any) {
    noticeListModel.show();
  }

  //公告详情查询
  noticeUserDetailShow(noticeId: number, type: number, readFlag: number, noticeDetailModel: any, noticeListModel: any) {
    this.show_type = type;
    const noticeUserReq = new NoticeUserRequest();
    noticeUserReq.page_num = 1;
    noticeUserReq.page_size = 1;
    noticeUserReq.noticeId = noticeId;
    this.workbenchService.getNoticeUserDetail(noticeUserReq).then(data => {
      this.notice_detail = data.notice_user_dto;
      if (this.show_type === 1) {
        noticeListModel.hide();
      }
      noticeDetailModel.show();
      $("#noticeContent").html("");
      $("#noticeContent").append(this.notice_detail.content);
      //公告已读
      if (readFlag === 0) {
        this.noticeRead(this.notice_detail.id);
      }
    });
  }

  //隐藏公告详情弹窗
  noticeDetailHide(noticeDetailModel: any) {
    noticeDetailModel.hide();
    //刷新工作台公告及公告列表数据
    this.getFirstNoticeUserList();
    this.getNoticeUserList();
  }

  //标记公告已读
  noticeRead(noticeId: number) {
    const noticeUserReq = new NoticeUserRequest();
    noticeUserReq.page_num = 1;
    noticeUserReq.page_size = 1;
    noticeUserReq.noticeId = noticeId;
    noticeUserReq.userId = this.login_id;
    this.workbenchService.insertNoticeUserDetail(noticeUserReq).then(data => {
    });
  }

  //判断返回工作台或者广告列表
  noticeReturn(noticeDetailModel: any, noticeListModel: any) {
    this.getNoticeUserList();
    this.getFirstNoticeUserList();
    if (this.show_type === 0) {
      noticeDetailModel.hide();
    } else {
      noticeDetailModel.hide();
      noticeListModel.show();
    }
  }

  //操作面板切换
  selectTab(setTab: number, total: number) {//tab点击
    if (total !== 0) {
      this.showTab = setTab;
    }
  }

  isSelected(checkedTab: number) {/*页面的切换*/
    return this.showTab === checkedTab;
  }

  operationPanelInit() {
    //初始化操作面板显示
    this.showTab = 15;
    const that = this;

    setTimeout(function () {
      if (that.btn_privileges.SECURITY_ALARM && (that.person_safe_resp && that.person_safe_resp.total > 0)) {//安全报警
        that.showTab = 15;
        return;
      } else if (that.btn_privileges.FIRE_FIGHTING_CS && (that.fireAlertListResp && that.fireAlertListResp.total > 0)) {//投诉处理
        that.showTab = 28;
        return;
      } else if (that.btn_privileges.COMPLAINT_DEAL && (that.complain && that.complain.total > 0)) {//投诉处理
        that.showTab = 1;
        return;
      } else if (that.btn_privileges.CLEAN_REPAIR && (that.repairOverHours && that.repairOverHours.total > 0)) {//保洁维修
        that.showTab = 2;
        return;
      } else if (that.btn_privileges.SCAN_PAYMENT && (that.orderIfRelateResponse && that.orderIfRelateResponse.total > 0)) {//线上收款
        that.showTab = 3;
        return;
      } else if (that.btn_privileges.PAYBACK_UNDO_ALARM && (that.alertNoCheckout && that.alertNoCheckout.total > 0)) {//提醒退房
        that.showTab = 4;
        return;
      } else if (that.btn_privileges.PREPAY_ARREARAGE_ALARM && (that.prepayArrearageResponse && that.prepayArrearageResponse.total > 0)) {//水电欠费
        that.showTab = 5;
        return;
      } else if (that.btn_privileges.ROOM_LEASE_BILL_ALARM && (that.alertDue && that.alertDue.total > 0)) {//合同收租
        that.showTab = 6;
        return;
      } else if (that.btn_privileges.DELAY_REFUND && (that.delayRefundData && that.delayRefundData.total > 0)) {//延期退款
        that.showTab = 16;
        return;
      } else if (that.btn_privileges.ROOM_DEVICE_ALARM && (that.alertFault && that.alertFault.total > 0)) {//设备故障
        that.showTab = 7;
        return;
      } else if ((that.withinTenDaysResponse && that.withinTenDaysResponse.total > 0)) {//合同到期
        that.showTab = 8;
        return;
      } else if (that.btn_privileges.AUDIT_LIST && (that.discountList && that.discountList.data_count > 0)) {//事项审批
        that.showTab = 9;
        return;
      } else if (that.btn_privileges.RESERVE_ROOM && (that.appointListResponse && that.appointListResponse.total > 0)) {//预约看房
        that.showTab = 10;
        return;
      } else if (that.btn_privileges.DOOR_CHECK && (that.doorLockInfos && that.doorLockInfos.total > 0)) {//门锁验收
        that.showTab = 11;
        return;
      } else if (that.btn_privileges.LEASE_AUDIT_AGAIN && (that.lfcReviewList && that.lfcReviewList.total > 0)) {//合同复审
        that.showTab = 12;
        return;
      } else if (that.btn_privileges.SUBLEASE_CHANGE_REMIND &&
        (that.subleaseChangeRoomRemindListResponse && that.subleaseChangeRoomRemindListResponse.total > 0)) {//换房转租
        that.showTab = 13;
        return;
      } else if (that.btn_privileges.ASSET_CHANGE_REMIND && that.assetChangeRemindListResponse && that.assetChangeRemindListResponse.total > 0) {//资产变更
        that.showTab = 14;
        return;
      } else if (that.btn_privileges.ASSET_CHECK_REMIND && that.assetCheckRemindResponse && that.assetCheckRemindResponse.total > 0) {//资产盘点
        that.showTab = 18;
        return;
      } else if (that.btn_privileges.CHECK_IN_LIMIT && that.checkInLimitResponse && that.checkInLimitResponse.total > 0) {//入住限制
        that.showTab = 19;
        return;
      } else if (that.btn_privileges.CHECK_IN_LIMIT && that.noAuditListResponse && that.noAuditListResponse.total > 0) {//线下收款
        that.showTab = 20;
        return;
      } else if (that.btn_privileges.WORKSPACE_CLEANTASK_REMIND && that.cleanTaskListResponse && that.cleanTaskListResponse.total > 0) {//保洁任务
        that.showTab = 23;
        return;
      } else {
        that.showTab = -1;
      }


    }, 2000);
  }

  getRPTId(code: string) {
    if (this.template_list) {
      let info: RptTemplate = this.template_list.find(t => t.need_auth === code);
      if (info) {
        return info.template_code;
      } else {
        return null;
      }
    }
  }

  // 跳转到报表详情页面
  goToTemplate(code: string) {
    this.router1.navigate(['system_manage/rpt/template/detail', this.getRPTId(code)]);
  }

  goToRoomPrepayDetailTemplate() {
    this.router1.navigate(['system_manage/rpt/template/room/prepay/detail']);
  }

  getWorkbenchData() {

    this.initChart();

    this.workbenchService.getWorkbenchData().then(data => {
      this.workbenchData = data;

      if (data) {

        //是否展示 向导配置成功 弹窗
        if (data.system_config_dto) {

          this.systemConfig = data.system_config_dto;

          let guide_status = localStorage.getItem("guide_status");
          if (this.systemConfig.add_dept && this.systemConfig.add_project
            && this.systemConfig.add_user && this.systemConfig.add_room && guide_status === 'uncomplete') {

            //弹出平台向导
            this.configModal.show();

            localStorage.removeItem("guide_status");
            localStorage.removeItem("guide_data");
            localStorage.removeItem("guide_show");
            localStorage.removeItem("guide_detail");
            localStorage.setItem("guide_status", "complete");
          }
        }

        let room_free = data.room_free_statistics_model;
        if (room_free) {
          this.vacant_room_num = room_free.vacant_room_num;
          this.not_checkin_num = room_free.not_checkin_num;
          this.bed_config = room_free.bed_config;
          if (room_free.room_num == 0) {
            this.vacant_room_rate = '-';
          } else {
            this.vacant_room_rate = new Decimal(room_free.vacant_room_num).mul(100).div(room_free.room_num).toFixed(2);
          }
          this.vacant_bed_num = room_free.vacant_bed_num;
          if (room_free.bed_num == 0) {
            this.vacant_bed_rate = '-';
          } else {
            this.vacant_bed_rate = new Decimal(room_free.vacant_bed_num).mul(100).div(room_free.bed_num).toFixed(2);
          }
        }

        let room_rent = data.room_rent_statistics_dto;
        if (room_rent) {
          this.nolease_room_num = room_rent.room_num - room_rent.lease_room_num;
          this.lease_room_num = room_rent.lease_room_num;
          this.enclosed_lease_room_num = room_rent.enclosed_lease_room_num;
          if (room_rent.lease_room_num == 0) {
            this.lease_room_rate = '-';
          } else {
            this.lease_room_rate = new Decimal(room_rent.lease_room_num).mul(100).div(room_rent.room_num).toFixed(2);
          }
          this.actual_total_percent = room_rent.actual_total_percent;
          this.room_total_percent = room_rent.room_total_percent;
          this.bed_total_percent = room_rent.bed_total_percent;

          this.unavailable_room_num = room_rent.unavailable_room_num;
          this.internal_room_num = room_rent.internal_room_num;
        }
      }

      setTimeout(() => {
        this.updateChart();
      });

    }).catch(error => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, error.message));
    });


    if (this.btn_privileges.SECURITY_WARN_PANEL) {
      // 渲染后触发
      setTimeout(() => {
        // 获取安全告警chart
        this.chart4 = this.initColumn1("新增未处理", "新增已处理");

        this.workbenchService.getWorkbenchWarningData().then(data => {
          if (data) {
            let arr1 = [], arr2 = [];

            for (let i = 0; i < data.alarm_statistics_dtolist.length; i++) {
              let item = data.alarm_statistics_dtolist[i];
              arr1.push([item.month, item.un_processed_num]);
              arr2.push([item.month, item.processed_num]);
            }
            if (this.chart4 && this.chart4.ref.series) {
              this.chart4.ref.series[0].setData(arr1);
              this.chart4.ref.series[1].setData(arr2);
            }
          }
        });
      }, 300);
    }
  }


  chart1: Chart;
  chart2: Chart;
  chart3: Chart;

  chart4: Chart;

  chartData = {
    todayIncome: "0",
    monthIncome: "0",
    newLease: 0,
    reletLease: 0,
    newLeaseMonth: 0,
    reletLeaseMonth: 0,
    todayPrepay: "0",
    todayCompanyPrepay: "0",
    monthPrepay: "0",
    monthCompanyPrepay: "0",
  };

  initChart() {

    this.logService.debug(" init chart");

    if (this.btn_privileges.BUSINESS_BASIS_DATA) {
      this.chart1 = this.initLine1("今日收入");
      this.chart2 = this.initLine2("新增合同", "续签合同");
    }
    if (this.btn_privileges.PREPAY_CONSUME_DATA) {
      this.chart3 = this.initLine2("个人账户", "企业账户");
    }
  }

  updateChart() {

    this.logService.debug(" update chart");

    let today = this._now.getDate();

    if (this.workbenchData && this.workbenchData.business_basis_data_dtos && this.workbenchData.prepay_consume_data_dtos) {
      let incomeData = this.workbenchData.business_basis_data_dtos;
      let prepayData = this.workbenchData.prepay_consume_data_dtos;

      let todayIncomeData = incomeData[today - 1];
      let todayPrepayData = prepayData[today - 1];

      this.chartData.todayIncome = todayIncomeData.today_income;
      this.chartData.monthIncome = this.workbenchData.this_month_income;
      this.chartData.newLease = todayIncomeData.today_new_lease;
      this.chartData.newLeaseMonth = this.workbenchData.this_month_new_lease;
      this.chartData.reletLease = todayIncomeData.today_relet_lease;
      this.chartData.reletLeaseMonth = this.workbenchData.this_month_relet_lease;

      this.chartData.todayPrepay = todayPrepayData.today_person_consume;
      this.chartData.todayCompanyPrepay = todayPrepayData.today_company_consume;
      this.chartData.monthPrepay = this.workbenchData.this_month_person_prepay_consume_total;
      this.chartData.monthCompanyPrepay = this.workbenchData.this_month_company_prepay_consume_total;

      let chartData1 = [], chartData2 = [], chartData3 = [];
      for (let i = 0; i < incomeData.length; i++) {
        if (incomeData && incomeData.length > 0) {
          if (i + 1 > today) {
            chartData1.push([(i + 1), null]);
            chartData2.push([(i + 1), null]);
            chartData3.push([(i + 1), null]);
          } else {
            chartData1.push([(i + 1), parseFloat(incomeData[i].today_income)]);
            chartData2.push([(i + 1), parseFloat(incomeData[i].today_new_lease + '')]);
            chartData3.push([(i + 1), parseFloat(incomeData[i].today_relet_lease + '')]);
          }
        }
      }
      let chartData4 = [], chartData5 = [];
      for (let i = 0; i < prepayData.length; i++) {
        if (prepayData && prepayData.length > 0) {
          if (i + 1 > today) {
            chartData4.push([(i + 1), null]);
            chartData5.push([(i + 1), null]);
          } else {
            chartData4.push([(i + 1), parseFloat(prepayData[i].today_person_consume)]);
            chartData5.push([(i + 1), parseFloat(prepayData[i].today_company_consume)]);
          }
        }
      }

      if (this.btn_privileges.BUSINESS_BASIS_DATA) {
        if (this.chart1 && this.chart1.ref.series) {
          this.chart1.ref.series[0].setData(chartData1);
        }
        if (this.chart2 && this.chart2.ref.series) {
          this.chart2.ref.series[0].setData(chartData2);
          this.chart2.ref.series[1].setData(chartData3);
        }
      }
      if (this.btn_privileges.PREPAY_CONSUME_DATA) {
        if (this.chart3 && this.chart3.ref.series) {
          this.chart3.ref.series[0].setData(chartData4);
          this.chart3.ref.series[1].setData(chartData5);
        }
      }
    } else {
      let chartData1 = [];
      const curDate = this._now;
      const curMonth = curDate.getMonth() + 1;
      curDate.setMonth(curMonth, 0);
      let days = curDate.getDate();
      for (let i = 0; i < days; i++) {
        chartData1.push([i, 0]);
      }
      if (this.btn_privileges.BUSINESS_BASIS_DATA) {
        if (this.chart1 && this.chart1.ref.series) {
          this.chart1.ref.series[0].setData(chartData1);
        }
        if (this.chart2 && this.chart2.ref.series) {
          this.chart2.ref.series[0].setData(chartData1);
        }
      }
      if (this.btn_privileges.PREPAY_CONSUME_DATA) {
        if (this.chart3 && this.chart3.ref.series) {
          this.chart3.ref.series[0].setData(chartData1);
        }
      }
    }
  }

  initLine1(name) {
    return new Chart(this.getDefaultSettingLine(1, name, null, ["#0068bf"]));
  }

  initLine2(name1, name2) {
    return new Chart(this.getDefaultSettingLine(2, name1, name2, ["#E53935", "#FF9800"]));
  }

  initColumn1(name1, name2) {
    return new Chart(this.getDefaultSettingColumn(name1, name2, ["#21c485", "#19a975"]));
  }


  getDefaultSettingLine(type, name1, name2, color): any {
    let series;
    if (type === 1) {
      series = [{
        allowPointSelect: false,
        showSymbol: false,
        name: name1,
        marker: {
          enabled: false
        }
        // data: data
      }];
    } else {
      series = [{
        allowPointSelect: false,
        showSymbol: false,
        name: name1,
        marker: {
          enabled: false
        }
        // data: data
      }, {
        allowPointSelect: false,
        showSymbol: false,
        name: name2,
        marker: {
          enabled: false
        }
        // data: data
      }];
    }
    return {
      chart: {
        type: 'line',
        backgroundColor: '',
        borderColor: '',
        height: 200
      },
      colors: color,
      title: {
        text: ''
      },
      tooltip: {
        enabled: true
      },
      xAxis: {
        allowDecimals: false,
        tickPixelInterval: 1,
        showLastLabel: true,
        labels: {
          format: '{value}日'
        }
      },
      yAxis: {
        min: 0,
        floor: 0,
        gridLineWidth: 0,
        title: {text: ''},
        allowDecimals: false
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        floating: true,
        itemStyle: {
          "fontSize": "12px", "fontWeight": "normal"
        }
      },
      plotOptions: {
        spline: {
          marker: {enabled: false}
        }
      },
      series: series
    };
  }

  getDefaultSettingColumn(name1, name2, color): any {
    let series = [{
      allowPointSelect: false,
      showSymbol: false,
      name: name1,
      marker: {
        enabled: false
      }
      // data: data
    }, {
      allowPointSelect: false,
      showSymbol: false,
      name: name2,
      marker: {
        enabled: false
      }
      // data: data
    }];
    return {
      chart: {
        type: 'column',
        backgroundColor: '',
        borderColor: '',
        height: 240
      },
      colors: color,
      title: {
        text: ''
      },
      tooltip: {
        enabled: true,
      },
      xAxis: {
        allowDecimals: false,
        tickPixelInterval: 1,
        showLastLabel: true,
        labels: {
          format: '{value}月'
        }
      },
      yAxis: {
        visible: false,
        min: 0,
        floor: 0,
        gridLineWidth: 0,
        title: {text: ''},
        allowDecimals: false,
        stackLabels: {  // 堆叠数据标签
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: 'gray'
          }
        }
      },
      legend: {
        // layout: 'vertical',
        // align: 'right',
        // verticalAlign: 'top',
        // floating: true,
        itemStyle: {
          "fontSize": "12px", "fontWeight": "normal"
        }
      },
      plotOptions: {
        spline: {
          marker: {enabled: false}
        },
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            crop: false,
            allowOverlap: true,
            color: '#545454',
            style: {
              // 如果不需要数据标签阴影，可以将 textOutline 设置为 'none'
              textOutline: 'none'
            }
          }
        }
      },
      series: series
    };
  }

  resizeFunc() {
    if (this.btn_privileges.BUSINESS_BASIS_DATA) {
      resizeCharts([this.chart1]);
      resizeCharts([this.chart2]);
    }
    if (this.btn_privileges.PREPAY_CONSUME_DATA) {
      resizeCharts([this.chart3]);
    }
  }

  incomeQrcode(incomeQrcodeModal: any) {
    // 获取二维码的路径
    this.projectName = this.authService.getProjectName();
    this.imageQrcodeService.qrcodeIncomePath().then(data => {
      this.qrCodePath = data.qr_code_path;
    });
    incomeQrcodeModal.show();
  }

  turnToPadSign() {
    //判断当前设备是不是平板或者手机
    let u = window.navigator.userAgent.toLowerCase();
    if ((u.match(/ipad/i) && u.match(/ipad/i).length > 0) || (u.match(/Macintosh/i) && u.match(/Macintosh/i).length > 0)) {
      this.inIpad = true;
    } else if (u.match(/android/i) && u.match(/android/i).length > 0) {
      this.inAndroid = true;
    }

    if (!this.inIpad && !this.inAndroid) {
      let messageModalInfo = new MessageModalInfo();
      messageModalInfo.operationMessage = "请在pad上使用该功能！";
      messageModalInfo.returnUrl = '/';
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
    } else {
      this.router1.navigate(['/padsign']);
    }
  }

  // 下载app二维码
  downloadAppQrcode() {
    // todo更换二维码链接
    this.downloadIosQrCodePath = 'https://apps.apple.com/cn/app/openpms/id1414791349?l=zh&ls=1';
    this.downloadAndriodQrCodePath = 'http://p-img1-pms.oss-cn-shanghai.aliyuncs.com/apk/pmsapp/openpms.apk?v=' + this._now.getTime();
    this.downloadAppQrcodeModal.show();
  }

  // 展示项目配置文件信息
  showProjectFileModal(discount: AuditDTO) {
    this.project_file_dtos = [];
    let req = new AuditDetailReqParam();
    req.service_type = discount.service_type;
    req.id = discount.project_id;
    this.projectAuditId = discount.id;
    this.workbenchService.getAuditDetail(req).then(data => {
      this.project_dto = data;
      this.project_dto.project_file_dtos.filter(f => f.file_type === 'DELIVERY_LIST').forEach(e2 => {
        this.project_file_dtos.push(e2);
      });
      // 计算文件数量(除了物品交接单)
      this.fileCount = this.project_dto.project_file_dtos.filter(f => f.file_type != 'DELIVERY_LIST').length;
      this.projectFileAuditRequest.project_id = this.project_dto.project_id;
      this.projectFileAuditRequest.project_file_dtos = this.project_dto.project_file_dtos;
      this.audit_project_file_dtos = [];
      let num = 0;
      this.project_dto.project_file_dtos.forEach(e2 => {
        e2.audit_status = 'EFFECTIVE';
        if (e2.file_type === 'DELIVERY_LIST') {
          if (num === 0) {
            this.audit_project_file_dtos.push(e2);
          }
          num += 1;
        } else {
          this.audit_project_file_dtos.push(e2);
        }

      });
      this.projectFileModal.show();
    });
  }

  showRoomEditModal(item: AuditDTO) {
    this.room_edit_remark = null;
    this.room_edit_audit_status = 'AGREED';

    let req = new AuditDetailReqParam();
    req.service_type = item.service_type;
    req.id = item.id;
    this.workbenchService.getAuditDetail(req).then(data => {
      this.roomEditAuditDetailResponse = data as RoomEditAuditDetailResponse;
      this.roomEditModal.show();
    });
  }

  showAssetTransModal(item: AuditDTO) {
    this.assTransAuditDetailRequest.assetEditRecordId = item.id;
    this.getAssetEditRecord(item);
  }

  // 流程审批弹窗需要清空内容 防止多个审批框出现
  cleanModalContent() {
    this.assTransAuditDetailResponse = null;
    this.leaseSubjectAuditDetail = null;
    this.leaseBillFlowAuditResponse = null;
    this.changeRoomAvailableDaysFlowAuditResponse = null;
    this.frozenPasswordFlowAuditResponse = null;
    delete this.approLockPasswordRes;
  }

  initFlowAuditModal(item: AuditDTO, instanceId): Promise<any> {

    // 流程审批弹窗需要清空内容 防止多个审批框出现
    this.cleanModalContent();

    this.isAuditSubmit = false;
    this.auditRejectReason = null;
    this.auditStatus = null;
    this.taskId = item.task_id;

    return this.approvService.approvDoneDetail(instanceId).then(data1 => {
      this.approvalResultResponse = data1;
      this.staticTabs.tabs[0].active = true;
    });
  }

  getAssetEditRecord(item: AuditDTO) {

    filterFormData(this.assTransAuditDetailRequest);
    this.assetService.assetEditRecordDetail(this.assTransAuditDetailRequest).then(data => {
      if (data.asset_list && data.asset_list.length > 0) {
        if (this.assTransAuditDetailRequest.page_num === 1) {
          this.initFlowAuditModal(item, data.process_instance_id).then(() => {
            this.asset_trans_remark = null;
            this.asset_trans_audit_status = 'AGREED';

            this.assTransAuditDetailResponse = data;
            this.assetEditRecordCount = this.assTransAuditDetailRequest.page_size * (this.assTransAuditDetailRequest.page_num - 1);
            this.assTransAuditDetailResponse.asset_list.forEach((dl2, index2) => {
              this.assetEditRecordCount++;
              dl2.asset_index = this.assetEditRecordCount;
            });
            this.assetTransferModal.show();
          });
        } else {
          this.assTransAuditDetailResponse = data;
          this.assetEditRecordCount = this.assTransAuditDetailRequest.page_size * (this.assTransAuditDetailRequest.page_num - 1);
          this.assTransAuditDetailResponse.asset_list.forEach((dl2, index2) => {
            this.assetEditRecordCount++;
            dl2.asset_index = this.assetEditRecordCount;
          });
        }

      } else {
        if (this.assTransAuditDetailRequest.page_num === 1) {
          this.assTransAuditDetailResponse.asset_edit_record_id = 0;
          this.assTransAuditDetailResponse.asset_list = [];
        }
      }

      this.loading = false;
    });
  }

  transEditScroll() {
    let transEditTr = $("#transEditTr").height();
    let transEditTby = $("#transEditTby").height();
    let transEditTbySt = $("#transEditTby").scrollTop();
    let index = transEditTr * this.assetEditRecordCount;

    if (transEditTby + transEditTbySt >= index && this.loading === false) {
      this.loading = true;
      this.assTransAuditDetailRequest.page_num += 1;
      this.getAssetEditRecord(null);
    }
  }

  okAssetEditChange() {
    let req = new AuditAssetEditReq();
    req.asset_edit_record_id = this.assTransAuditDetailResponse.asset_edit_record_id;
    req.remark = this.asset_trans_remark;
    req.record_status = this.asset_trans_audit_status;

    this.assetService.assetEditAudit(req).then(data => {
      this.getDiscountRoom();
      this.assetTransferModal.hide();
      this.assTransAuditDetailRequest.page_num = 1;

      let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
      scheduleOperateModel.operateType = OperationType.DELETE;
      scheduleOperateModel.scheduleType = 'AUDIT';
      this.workService.event_bus.emit(new WorkModel(this.workService.type.SCHEDULE_TASK, scheduleOperateModel));
    });
  }

  hideAssetTransfer() {
    this.assTransAuditDetailRequest.page_num = 1;
    this.assetTransferModal.hide();
  }

  auditProjectFile(fileType, status) {
    let i = 0;
    this.audit_project_file_dtos.forEach(e2 => {
      if (e2.file_type === fileType) {
        e2.audit_status = status;
      }
    });
    this.audit_project_file_dtos.forEach(e2 => {
      if (e2.audit_status === 'AUDIT_FAIL') {
        i += 1;
      }
    });
    if (i > 0) {
      this.is_remark = true;
    } else {
      this.is_remark = false;
    }
  }

  projectAuditSummit() {
    if (this.is_remark && !this.projectFileAuditRequest.remark) {
      return;
    }
    this.audit_project_file_dtos.forEach(e2 => {
      this.projectFileAuditRequest.project_file_dtos.forEach(e3 => {
        if (e2.file_type === e3.file_type) {
          e3.audit_status = e2.audit_status;
        }
      });
    });
    this.projectFileAuditRequest.id = this.projectAuditId;
    this.workbenchService.projectFileAudit(this.projectFileAuditRequest).then(data => {
      if (data && data.result === 'SUCCESS') {
        this.getDiscountRoom();
        this.projectFileModal.hide();
        this.reminderModel = 1;
        this.projectAuditModal.show();
      } else {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, data.result ? data.result : "项目配置文件审核失败！"));
      }
    });
  }

  downloadProjectFile(url: string) {
    window.open(url);
  }


  notPayModalShow(info: OrderInfoDTO) {
    this.not_pay_dto.create_time = info.create_time;
    this.not_pay_dto.order_id = info.order_id;
    this.not_pay_dto.order_no = info.order_no;
    this.not_pay_dto.order_status = info.order_status;
    this.not_pay_dto.order_amount = info.order_amount;
    this.not_pay_dto.pay_channel = info.pay_channel;
    this.not_pay_dto.remark = '0';
    this.clientNameCertcodeRsp = null;
    this.clientUserName = null;
    this.certCode = null;
    this.isConfirmMoney = true;
    this.notPayModal.show();
  }

  setRemark($event: any) {
    this.not_pay_dto.remark = $event.target.value;
    this.isRemark = false;
  }

  saveNotPay() {
    if (this.not_pay_dto.remark === '0') {
      this.isRemark = true;
      return;
    }
    if (this.not_pay_dto.pay_channel === 'ALIPAY') {
      this.not_pay_dto.pay_type = '支付宝收款';
    } else if (this.not_pay_dto.pay_channel === 'WEIXIN') {
      this.not_pay_dto.pay_type = '微信收款';
    } else if (this.not_pay_dto.pay_channel === 'CCBPAY') {
      this.not_pay_dto.pay_type = '建行龙支付收款';
    }
    this.workbenchService.closeOrderInfo(this.not_pay_dto).then(data => {
      if (data && data.result === 'SUCCESS') {
        this.getOrderRealteList();
        this.notPayModal.hide();
        this.not_pay_dto.remark = '0';
      } else {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "操作失败！"));
      }
    });
  }


  getOrderInfoLogList() {
    this.workbenchService.orderInfoLogList().then(data => {
      this.order_info_log_dtos = data.order_info_log_dtos;
      this.orderLogModal.show();
    });
  }

  // 投诉模态框关闭后刷新列表
  refreshList(refresh: any) {
    if (refresh) {
      this.getComplainList();
    }
  }

  hideSystemGuide() {
    this.configModal.hide();
  }

  toAddRoom(type: string, addRoomTipsModal: any) {
    let pId = this.authService.getProjectId();
    let cId = this.authService.getCompanyId();
    this.companyService.getCompanyProjectIds(cId).then(data => {
      let next = false;
      if (data) {
        this.tips_company_name = data.company_name;
        if (data.project_ids) {
          data.project_ids.forEach(id => {
            if (+id === +pId) {
              next = true;
            }
          });
        }
        if (next) {
          if (type === 'alone') {
            this.router1.navigate(['system_manage/room/add-room']);
          } else {
            this.router1.navigate(['system_manage/room/import']);
          }
        } else {
          addRoomTipsModal.show();
        }
      }
    });
  }

  // 锚点到应退未退的表格
  jumpToData(number: any) {
    if (!number) {
      return;
    }
    this.selectTab(4, this.alertNoCheckout.total);

    document.getElementById("cardDiv").scrollIntoView();
  }

  // 文字变成链接样式
  changeToLink($event: any) {
    if ($($event.target).text().trim() != "0" && $($event.target).text().trim() != "0.00") {
      $($event.target).addClass('text-blue').addClass('pointer');
      $($event.target).css('text-decoration', 'underline');
    }
  }

  // 取消链接样式
  changeTospan($event: any) {
    $($event.target).removeClass('text-blue').removeClass('pointer');
    $($event.target).css('text-decoration', '');
  }

  // 跳转至退款列表
  jumpToRefundList($event: any) {
    if ($($event.target).text().trim() != "0" && $($event.target).text().trim() != "0.00") {
      if (this.btn_privileges['REFUND_STATISTICS_DETAIL']) {
        this.router1.navigate(['/system_manage/finance/audit/refund/list']);
      } else {
        this.workService.showErrorModal('您无查看退款数据的权限');
      }
    }
  }

  // 去数据详情页面
  goDataPage($event: any, type: string, gatheringType: string) {
    if ($($event.target).text().trim() != "0" && $($event.target).text().trim() != "0.00") {
      this.router1.navigateByUrl('/system_manage/workspace/data/detail/' + type + '/' + gatheringType);
    }
  }

  goOverdueRpt($event: any, type: string, gatheringType: string) {
    if ($($event.target).text().trim() !== "0" && $($event.target).text().trim() !== "0.00") {
      this.router1.navigateByUrl('/system_manage/rpt/template/overdue/bill/detail/' + gatheringType);
    }
  }

  goCheckoutRefund(id, type, router) {
    this.template.hide();
    this.router1.navigateByUrl(`system_manage/signed/${router}?id=${id}&type=${type}`);
  }

  getWorkbenchSafeWarningListLastTwo() {

    let req = new PersonSafeAlarmListRequest();
    req.is_closed = true;
    req.page_num = 1;
    req.page_size = 2;
    this.workbenchService.getWorkbenchWarningList(req).then(data => {
      if (data && data.person_safe_alarm_dtos) {
        this.person_safe_alarm_dtos = data.person_safe_alarm_dtos;
      }
    });
  }

  goFace() {
    if (isInApp()) {
      if (this.clearTimeFace) {
        clearInterval(this.clearTimeFace);
      }
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //安卓端
      let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //IOS端
      if (isAndroid) {
        window['androidAPP'].sysuserFaceAuth();
      }
      if (isIos) {
        window["webkit"].messageHandlers.goFaceVCUserId.postMessage({userid: null, userType: '员工'});
      }
      this.clearTimeFace = setInterval(() => {
        this.updataFaceStaus();
      }, 3000);
    } else {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "请登录云盾宿管APP使用该功能！"));
    }
  }

  getWorkbenchSafeWarningList(page_num?: number) {
    if (page_num) {
      this.safeWarningReq.page_num = page_num;
    }

    this.workbenchService.getWorkbenchWarningList(this.safeWarningReq).then(data => {
      if (data && data.person_safe_alarm_dtos) {
        this.person_safe_resp = data;
      } else {
        this.person_safe_resp.total = 0;
      }
    }).catch(() => {
      this.person_safe_resp.total = 0;
    });
  }


  safeWarnDetail(item: PersonSafeAlarmDTO) {

    this.safeWarnDetailComponent.detailShow(item);
  }

  safeWarnHandle(item: PersonSafeAlarmDTO) {
    this.safeWarnDetailComponent.handleShow(item);
  }

  safeListHandle() {
    this.getWorkbenchSafeWarningList(1);
  }


  searchMonthPriceAvg() {
    this.getMonthPriceAvg();
  }

  //月均价
  getMonthPriceAvg() {
    let month_price_req = new WorkbenchMonthPriceRequest();
    month_price_req.year = this.yearStr;
    month_price_req.month = this.monthStr;
    this.workbenchService.getMonthPriceAvgList(month_price_req).then(data => {
      this.month_price_list = data;
    }).catch(() => {
      this.month_price_list = null;
    });
  }

  getWorkOrderNum() {
    this.workbenchService.workOrderAwaitNum().then(res => {
      this.workOrderAwaitNum = res.wait_confirm_work_order_num;
    });
  }

  getWaitSignList($event: number) {
    this.waitSignListReq.page_num = $event;
    this.leaseService.waitSignList(this.waitSignListReq).then(data => {
      this.waitSignListResponse = data;
      if (!data.wait_sign_contract_dtolist) {
        this.waitSignListResponse = new WaitSignContractListResponse();
        this.waitSignListResponse.wait_sign_contract_dtolist = [];
        this.waitSignListResponse.total = 0;
      }
    });
  }

  getCheckInLimit($event: number) {
    this.checkInLimitReq.page_num = $event;
    this.leaseService.getCheckInLimitLeaseList(this.checkInLimitReq).then(data => {
      this.checkInLimitResponse = data;
      if (!data.checkin_limit_lease_dtolist) {
        this.checkInLimitResponse = new CheckInLimitResponse();
        this.checkInLimitResponse.checkin_limit_lease_dtolist = [];
        this.checkInLimitResponse.total = 0;
      }
    });
  }

  getCheckInOffline($event: number) {
    this.noAuditList.page_num = $event;
    this.workbenchService.noAuditList(this.noAuditList).then(data => {
      this.noAuditListResponse = data;
      if (!data.no_audit_transfer_list) {
        this.noAuditListResponse = new NoAuditListResponse();
        this.noAuditListResponse.no_audit_transfer_list = [];
        this.noAuditListResponse.total = 0;
      }
    });
  }

  // 合同信息变更
  showLeaseChangeLSEditModal(item: AuditDTO) {

    this.leaseService.getLeaseSubjectAuditDetail(item.id).then(data => {
      this.initFlowAuditModal(item, data.process_instance_id).then(() => {

        this.leaseSubjectAuditDetail = data;
        this.showLeaseChangeAuditModal.show();
      });
    });
  }

// 合同费用变更审批
  showLeaseChangeLBEditModal(item: AuditDTO) {

    this.leaseService.getLeaseBillChangeDetail(item.id).then(data => {
      this.initFlowAuditModal(item, data.process_instance_id).then(() => {

        this.leaseBillFlowAuditResponse = data;
        this.showLeaseBillChangeAuditModal.show();
      });
    });
  }

// 房间使用期限调整审批
  showRoomAvailableDaysChangeEditModal(item: AuditDTO) {

    this.leaseService.getRoomAvailableTimeChangeAuditDetail(item.id).then(data => {
      this.initFlowAuditModal(item, data.process_instance_id).then(() => {

        this.changeRoomAvailableDaysFlowAuditResponse = data;
        this.showRoomAvailableDaysChangeAuditModal.show();
      });
    });
  }

  auditCheck(status: boolean = null) {
    this.auditStatus = status;
  }

  auditSubmit(id: number) {
    this.isAuditSubmit = true;
    if (this.auditStatus !== null) {
      if (this.auditStatus === false && !this.auditRejectReason) {
        return false;
      }

      let req: ApprovEditRequest = new ApprovEditRequest();
      req.agree = this.auditStatus;
      req.comment = this.auditRejectReason;
      req.appro_record_info_id = id;
      req.task_id = this.taskId;

      this.leaseService.postApprovAudit(req).then(data => {
        this.getDiscountRoom();
        this.showLeaseChangeAuditModal.hide();
        this.showLeaseBillChangeAuditModal.hide();
        this.hideAssetTransfer();
        this.showRoomAvailableDaysChangeAuditModal.hide();
        this.showFrozenPasswordAuditModal.hide();
        this.showLockPasswordModal.hide();
        this.renterCheckoutDepositModal.hide();
        this.projectBasePriceModal.hide();
      });
    }
  }

  isChangeEmergencyContacts(leaseSubjectAuditDetail: LeaseSubjectFlowAuditResponse): boolean {
    let tag = false;
    if (leaseSubjectAuditDetail) {
      let changeBeforeDtoE = leaseSubjectAuditDetail.change_before_dto.emergency_contacts;
      let changeAfterDtoE = leaseSubjectAuditDetail.change_after_dto.emergency_contacts;

      let a = [], b = [];

      if (changeBeforeDtoE) {
        changeBeforeDtoE.forEach((item) => {
          a.push({
            contact_name: item.contact_name,
            contact_phone: item.contact_phone,
          });
        });
      }
      if (changeAfterDtoE) {
        changeAfterDtoE.forEach((item) => {
          b.push({
            contact_name: item.contact_name,
            contact_phone: item.contact_phone,
          });
        });
      }

      tag = JSON.stringify(a) !== JSON.stringify(b);
    }

    return tag;
  }

  getRelevanceRoom(gate_way_uuid: string) {
    this.relevanceRoomReq.uuid = gate_way_uuid;
    this.workbenchService.getRelevanceRoom(this.relevanceRoomReq).then(data => {
      this.relevanceRoomInfo = data;
      this.relevanceRoomModal.show();
    });
    return true;
  }

  searchName(code) {//筛选下拉列表
    if (code !== this.isCode) {
      this.faultListReq.page_num = 1;
    }
    this.isCode = code;
    this.faultListReq.alert_type = ALERT_TYPE.FAULT;
    this.faultListReq.junior_alert_type = code;

    this.workbenchService.getdelayRefundList(this.faultListReq).then(data => {
      this.alertFault = data;
      if (!data.fault_alert_dtos) {
        this.alertFault.fault_alert_dtos = [];
        this.alertFault.total = 0;
      }
    });
  }


  initBusinessType() {

    this.showChangeRoomType = this.authService.getProjectInfo().room_type === 3;

    let businessType = LocalStorage.getItem(this.authService.getUserInfo().id + "_room_type");
    this.roomType = businessType ? +businessType : (this.authService.getProjectInfo().room_type === 2 ? 2 : 1);
  }

  switchRoomType() {
    if (this.roomType === 1) {
      this.roomType = 2;
    } else {
      this.roomType = 1;
    }
    LocalStorage.setItem(this.authService.getUserInfo().id + "_room_type", this.roomType + "");
    // 刷新房态图
    this.workService.event_bus.emit(new WorkModel(this.workService.type.PANEL_NEED_REFRESH));
  }


  getCheckoutNotFinishList($event: number) {
    this.checkoutFinanceUncheckListRequest.page_num = $event;
    this.checkoutFinanceUncheckListRequest.page_size = 10;
    this.checkoutService.checkoutNotFinishList(this.checkoutFinanceUncheckListRequest).then(data => {
      this.checkoutNotFinishResponse = data;
    });
  }

  getCheckoutFinanceNotFinish($event: number) {
    this.checkoutFinanceFinanceNotFinishReq.page_num = $event;
    this.checkoutService.checkoutFinanceNotFinishList(this.checkoutFinanceFinanceNotFinishReq).then(data => {
      data.checkout_finance_not_finish_dtos.forEach(item => {
        if (item.checkout_confirm_end_time) {
          let isTime = countDownTime(item.checkout_confirm_end_time);

          item.checkout_confirm_end_time_new = isTime;
        }
      });
      this.checkoutFinanceNotFinishResponse = data;
    });
  }


  // openDialog(id){
  //   this.onlineModal.show();
  // }


  paymentMoneyDebounce() {
    this.payMoneyDebounceFunc();
  }

  paymentMoney() {
    let str = trim(this.clientUserName);
    if (str) {
      this.verifyStatement = false;
      this.cardVerify = false;
      this.searchLoading = true;
      let request = new QueryClientNameRequest();
      request.client_name = str;
      this.clientUserName = str;
      this.certCode = null;
      this.userNameMoney = null;
      this.workbenchService.queryClientName(request).then(data => {
        this.clientNameIsExistRsp = data;
        this.verifyStatement = !data.exist;
        let clientInfoList = this.clientNameIsExistRsp.client_info_list;
        if (data.exist) {
          if (clientInfoList.length === 1) {
            let request1 = new ClientNameCertcodeRequest();
            request1.client_name = this.clientUserName;
            request1.cert_code = clientInfoList[0].cert_code;
            this.certCode = clientInfoList[0].cert_code;
            this.workbenchService.clientNameCertcode(request1).then(data1 => {
              this.clientNameCertcodeRsp = data1;
              this.cardVerify = !this.clientNameCertcodeRsp.exist;
              if (!this.clientNameCertcodeRsp.exist_multiple_accounts) {
                let accountMoneyList = this.clientNameCertcodeRsp.account_money_list;
                this.userNameMoney = accountMoneyList[0].account_money_info_id;
              }
              this.searchLoading = false;
              setTimeout(() => {
                this.clientUserNameRef.nativeElement.focus();
              });
            });
          } else {
            this.searchLoading = false;
            setTimeout(() => {
              this.clientUserNameRef.nativeElement.focus();
            });
          }
        } else {
          this.searchLoading = false;
          setTimeout(() => {
            this.clientUserNameRef.nativeElement.focus();
          });
        }
      });
    }
  }

  cardNumberDebunce() {
    this.cardNumberDebunceFunc();
  }

  cardNumber() {
    let name = trim(this.clientUserName);
    let code = trim(this.certCode);
    if (code) {
      this.verifyStatement = false;
      this.cardVerify = false;
      this.searchLoading = true;
      let request = new ClientNameCertcodeRequest();
      request.client_name = name;
      request.cert_code = code;
      this.userNameMoney = null;
      this.workbenchService.clientNameCertcode(request).then(data => {
        this.clientNameCertcodeRsp = data;
        this.clientUserName = this.clientNameCertcodeRsp.client_name;
        this.cardVerify = !this.clientNameCertcodeRsp.exist;
        if (this.clientNameCertcodeRsp.exist && !this.clientNameCertcodeRsp.exist_multiple_accounts) {
          let accountMoneyList = this.clientNameCertcodeRsp.account_money_list;
          this.userNameMoney = accountMoneyList[0].account_money_info_id;
        }

        this.searchLoading = false;
        setTimeout(() => {
          this.certCodeRef.nativeElement.focus();
        });
      });
    }
  }


  over(i) {
    if (i === 1) {
      this.showTimeBlock = true;
    } else {
      this.showTimeBlock = false;
    }
  }

  submitMoney() {
    if (this.userNameMoney) {
      let request = new ThirdpayPaymentConfirmRequest();
      request.account_money_info_id = this.userNameMoney;
      request.order_no = this.not_pay_dto.order_no;
      request.pay_name = this.clientUserName;

      this.idempotentService.getIdempotentKey().then(data => {
        request.idempotent_key = data.idempotent_key;
        this.workbenchService.thirdpayPaymentConfirm(request).then(data1 => {
          this.reminderModel = 2;
          this.notPayModal.hide();
          this.getOrderRealteList();
          this.projectAuditModal.show();
        });
      });

    }
  }

  openMoneyModel() {
    // init
    this.license = null;
    this.accountMoneyName = null;
    this.clientUserNameMoneyId = null;
    this.uploadPaymentCertificateRsp = new UploadPaymentCertificateResponse();
    this.collectionMoney.show();
    this.idempotentService.getIdempotentKey().then(data => {
      this.collectionMoneyKey = data.idempotent_key;
    });
  }

  submitUser() {
    if (this.checkRequiredClick()) {
      delete this.zzErrorText;
      let detailReq = new MoneyPaymentConfirmRequest();
      detailReq.account_money_info_id = this.clientUserNameMoneyId;
      detailReq.payment_amount = this.uploadPaymentCertificateRsp.payment_amount;
      detailReq.receipt_num = this.uploadPaymentCertificateRsp.receipt_num;
      detailReq.payment_name = this.accountMoneyName;
      detailReq.img_url = this.license;
      detailReq.idempotent_key = this.collectionMoneyKey;
      this.clientInfoService.moneyPaymentConfirm(detailReq).then(data => {
        this.collectionMoney.hide();
        this.reminderModel = 3;
        this.projectAuditModal.show();
      });
    } else {
      this.zzErrorText = "有必填信息尚未填写";
    }
  }

  // 上传图片变化
  fileChange($event, type: string) {

    let detailReq = new UploadPaymentCertificateRequest();
    if ($event && $event.length > 0) {
      let fileUrl = $event[0];
      detailReq.file_url = fileUrl;
      this.clientInfoService.uploadPaymentCertificate(detailReq).then(data => {
        this.uploadPaymentCertificateRsp = data;
        this.checkRequiredClick();
      });
    }

    if ("license" === type) {
      this.license = $event && $event.length > 0 ? $event[0] : null;
    }
    let list = [];

    if (this.license != null) {
      list.push(this.license);
    }
    //this.sign_form_group.get('lease_subject_info').get('image_url_list').patchValue(list);
  }

  collectionmentMoney() {

    this.clientUserNameMoneyId = null;
    let request = new QueryAccountNameRequest();
    request.client_name = this.accountMoneyName;
    this.workbenchService.queryAccountName(request).then(data => {
      this.queryAccountNameRsp = data;
      this.collectionverify = this.queryAccountNameRsp.exist;
      if (this.queryAccountNameRsp.account_money_list.length === 1) {
        this.clientUserNameMoneyId = this.queryAccountNameRsp.account_money_list[0].account_money_info_id;
      }
      /* if(data){
         this.defaultOption=data.account_money_list;
         this.collectionverify=false;
       }else{
         this.collectionverify=true;
       }*/
      this.checkRequiredClick();
    });
  }

  checkRequiredClick() {
    if (this.clientUserNameMoneyId && this.uploadPaymentCertificateRsp.payment_amount && this.accountMoneyName && this.collectionverify
      && this.uploadPaymentCertificateRsp.receipt_num) {
      // this.isBtn = true;
      return true;
    } else {
      // this.isBtn = false;
      return false;
    }
  }

  shiftFocus(e) {
  }

  clientshiftFocus(e) {
    this.checkRequiredClick();
  }

  showFrozenPasswordModal(contactName: string, contractNum: string, frozenPwdOperation: string, frozenReason: string,
                          lease_id: number, lease_room_ids: number[], alert_id: number) {
    this.frozenPwdOperation = frozenPwdOperation;
    this.frozenPwdContractNum = contractNum;
    this.frozenPwdSignName = contactName;
    this.frozenReason = frozenReason;
    this.frozenLeaseId = lease_id;
    this.frozenLeaseRoomIds = lease_room_ids;
    this.frozenPwdAlertId = alert_id;
    this.frozenPwdAuditRequest.imgs = null;
    this.frozenPasswordModal.show();
  }

  applyFrozenPwd() {
    this.frozenPasswordModal.hide();
    this.frozenPwdAuditRequest.code = '';
    this.frozenPwdAuditRequest.phone = '';
    this.frozenPasswordAuditModal.show();
  }

  unFrozenPwd() {
    let request = new UnFrozenDoorPasswordRequest();
    request.frozen_lease_id = this.frozenLeaseId;
    request.frozen_lease_room_ids = this.frozenLeaseRoomIds;
    request.frozen_type = this.frozenReason;
    request.alert_id = this.frozenPwdAlertId;
    this.leaseService.unFreezePasswordConfirm(request).then(data => {
      this.frozenPasswordModal.hide();
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "操作成功"));
      // 刷新列表
      if (this.frozenReason === 'RENT_OVERDUE') {
        this.getContactRentList();
      } else {
        this.getWithinTenDays(1);
      }
    });
  }

  getFrozenPwdAuditCode() {
    if (this.frozenPwdAuditRequest.phone) {
      if (this.time === 60) {
        this.time = 0;
        let detailReq = new FrozenDoorPasswordSmsCodeRequest();
        detailReq.phone = this.frozenPwdAuditRequest.phone;
        detailReq.contract_num = this.frozenPwdContractNum;
        detailReq.frozen_type = this.frozenReason;
        this.leaseService.frozenAuditSendSmsCode(detailReq).then(data => {

          this.time = 60;
          this.gray_btn = true;
          this.inter = setInterval(() => {

            if (--this.time > 0) {
              this.codeBtnStr = this.time + 's后重新获取';
            } else {
              this.gray_btn = false;
              this.time = 60;
              this.codeBtnStr = '点击获取';
              clearInterval(this.inter);
            }

          }, 1000);
        }).catch(() => {
          this.time = 60;
        });
      }
    }
  }

  confirmFrozen() {
    this.frozenPwdAuditRequest.frozen_lease_room_ids = this.frozenLeaseRoomIds;
    this.frozenPwdAuditRequest.frozen_lease_id = this.frozenLeaseId;
    this.frozenPwdAuditRequest.frozen_type = this.frozenReason;
    this.frozenPwdAuditRequest.alert_id = this.frozenPwdAlertId;
    this.leaseService.freezePasswordConfirm(this.frozenPwdAuditRequest).then(data => {
      this.approvNum = data.approv_num;
      this.frozenPasswordModal.hide();
      if (this.approvNum) {
        this.approvModal.show();
      } else {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "操作成功"));
      }
      // 刷新列表
      if (this.frozenReason === 'RENT_OVERDUE') {
        this.getContactRentList();
      } else if (this.frozenReason === 'LEASE_OVERDUE') {
        this.getWithinTenDays(1);
      }
    });
  }

  openOperationLogModal() {
    let request = new LogListReq();
    request.lease_id = this.frozenLeaseId;
    request.page_num = 1;
    request.page_size = 20;
    this.operationLogs = [];
    this.queryOperationLogModalMore(request);
    this.frozenPwdOperationLogModal.show();
  }

  queryOperationLogModalMore(request: LogListReq) {
    this.leaseService.getFrozenPwdOperationLogList(request).then(data => {
      if (data && data.lease_log_dtolists) {
        data.lease_log_dtolists.forEach(item => {
          this.operationLogs.push(item);
        });
        if (request.page_size * request.page_num < data.total) {
          request.page_num++;
          this.queryOperationLogModalMore(request);
        }
      } else {
        this.operationLogs = null;
      }
    });
  }

  stopP($event) {
    $event.stopPropagation();
  }

  showFrozenPasswordFlowModal(item: AuditDTO) {

    this.leaseService.getFrozenPasswordAuditDetail(item.id).then(data => {
      this.initFlowAuditModal(item, data.process_instance_id).then(() => {

        this.frozenPasswordFlowAuditResponse = data;
        this.showFrozenPasswordAuditModal.show();
      });
    });
  }

  showLockPasswordModalInfo(item: AuditDTO) {
    this.approvService.getLockPasswordDetail(item.id).then(data => {
      this.initFlowAuditModal(item, data.process_instance_id).then(() => {
        this.approLockPasswordRes = data;
        this.staticTabs_p.tabs[0].active = true;
        this.showLockPasswordModal.show();
      });
    });
  }

  showRenterCheckoutModealInfo(item: AuditDTO) {
    this.approvService.renterCheckoutDepositInfo(item.id).then(data => {
      this.initFlowAuditModal(item, data.process_instance_id).then(() => {
        this.approRenterCheckoutDeposit = data;
        this.staticTabs_p.tabs[0].active = true;
        this.renterCheckoutDepositModal.show();
      });
    });
  }

  flowImgTabOnSelect(instanceId) {
    this.approvRecordImgComponent.show(instanceId);
  }

  setFiles1($event: Array<string>) {
    this.frozenPwdAuditRequest.imgs = $event;
  }

  searchCheckoutFinanceUncheckList() {
    this.checkoutFinanceUncheckListRequest.page_num = 1;
    this.checkoutFinanceUncheckListRequest.page_size = 10;
    this.checkoutService.checkoutNotFinishList(this.checkoutFinanceUncheckListRequest).then(data => {
      this.checkoutNotFinishResponse = data;
    });
  }

  downloadCheckoutFinanceUncheckList() {
    this.checkoutFinanceUncheckListRequest.page_num = 1;
    this.checkoutService.checkoutNotFinishListDownload(this.checkoutFinanceUncheckListRequest);
  }

  searchWithinTenDaysList() {
    this.getWithinTenDays(1);
  }

  downloadWithinTenDaysList() {
    this.withinTenDaysRequest.page_num = 1;
    this.withinTenDaysRequest.alert_type = ALERT_TYPE.NO_CHECKOUT;
    this.leaseService.withinTenDaysDownload(this.withinTenDaysRequest);
  }

  searchNoCheckoutAlertList() {
    this.noListReq.alert_type = ALERT_TYPE.NO_CHECKOUT;
    this.noListReq.page_num = 1;
    this.workbenchService.getNoCheckoutAlertList(this.noListReq).then(data => {
      this.alertNoCheckout = data;
      if (!data.no_checkout_alert_dto) {
        this.alertNoCheckout.no_checkout_alert_dto = [];
        this.alertNoCheckout.total = 0;
      }
    });
  }

  downloadNoCheckoutAlertList() {
    this.noListReq.alert_type = ALERT_TYPE.NO_CHECKOUT;
    this.noListReq.page_num = 1;
    this.workbenchService.noCheckoutAlertListDownload(this.noListReq);
  }

  searchDueList() {
    this.dueListReq.alert_type = this.alert_type_due;
    this.dueListReq.page_num = 1;
    this.workbenchService.getDueAlertList(this.dueListReq).then(data => {
      this.alertDue = data;
      if (!data.due_alert_dtos) {
        this.alertDue.due_alert_dtos = [];
        this.alertDue.total = 0;
      }
    });
  }

  downloadDueList() {
    this.dueListReq.alert_type = this.alert_type_due;
    this.dueListReq.page_num = 1;
    this.workbenchService.dueAlertListDownload(this.dueListReq);
  }

  showRemarkDetail(alertId: number) {
    let request = new QueryEvolveRequest();
    this.evolveRemarkAlertId = alertId;
    request.alert_id = alertId;
    this.workbenchService.queryEvolveDetail(request).then(data => {
      this.evolveDTOS = data.evolve_dtos;
    });
    this.remarkDetailModal.show();
  }

  hideRemarkDetailModal() {
    this.remarkDetailModal.hide();
  }

  confirmRemarkDetailModal() {
    let request = new AddEvolveRemarkRequest();
    request.alert_id = this.evolveRemarkAlertId;
    request.evolve_remark = this.evolveRemark;
    this.workbenchService.addEvolveRemark(request);
    delete this.evolveRemark;
    this.remarkDetailModal.hide();
  }

  showLeaseCheckoutEmailDetail(lease_id: number, contract_num: string, room_count: number,
                               overdue_day: number, project_name: string, alert_id: number) {
    let request = new WorkbenchEmailHistoryRequest();
    request.lease_id = lease_id;
    request.service_type = 'CONTRACT_EXPIRATION';
    this.workbenchService.workbenchEmailHistory(request).then(data => {
      this.workbenchEmailHistoryDTOS = data.workbench_email_history_dtos;
    });
    this.mailAddress = null;
    this.sendMailServiceType = 'CONTRACT_EXPIRATION';
    this.sendMailLeaseId = lease_id;
    this.sendMailAlertId = alert_id;
    this.mailSubject = "【" + contract_num + "】" + "合同到期提醒";
    this.mailText = "<p>尊敬的乐璟客户，您好！</p><p>&nbsp; &nbsp; &nbsp; &nbsp;您/贵公司承租的<span style=\"color:#ff0000\">" + contract_num + "</span>合同，共计<span style=\"color:#ff0000\">" + room_count + "</span>间房，于<span style=\"color:#ff0000\">" + YYYYmmdd2(new Date(overdue_day)) + "</span>到期，请问您/贵公司是否续租呢？如有需要请与前台联系，祝您生活愉快！</p>" +
      "<p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;" + project_name + "</p><p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; " +
      "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;" + YYYYmmdd2(this._now) + "</p>";
    this.leaseCheckoutNotificationModal.show();
  }

  hideLeaseCheckoutNotificationModal() {
    this.leaseCheckoutNotificationModal.hide();
  }

  confirmSendEmail() {
    if (!this.mailAddress) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写邮箱地址"));
      return;
    }
    let emails = this.mailAddress.split(";");
    let EMAIL_REG = /^([a-zA-Z0-9]+[_|\_|-|\-|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|-|\-|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
    for (let i = 0; i < emails.length; i++) {
      if (!EMAIL_REG.test(emails[i])) {
        this.workService.showErrorModal('邮箱格式不正确');
        return;
      }
    }
    if (!this.mailSubject) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写邮箱主题"));
      return;
    }
    if (!this.mailText) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写邮箱正文"));
      return;
    }
    let request = new WorkbenchSendEmailRequest();
    request.alert_id = this.sendMailAlertId;
    request.lease_id = this.sendMailLeaseId;
    request.mail_address = this.mailAddress;
    request.mail_subject = this.mailSubject;
    request.mail_text = this.mailText;
    request.service_type = this.sendMailServiceType;
    this.workbenchService.workbenchSendEmail(request).then(data => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "发送成功"));
    });
    this.leaseCheckoutNotificationModal.hide();
  }

  fullInit() {
    $(".edui-editor-body").css({"width": "35.5rem", "height": "15rem", "overflow": "auto"});
    $(".edui-icon-fullscreen").hide();
    $(".edui-icon-image").on("click", function (e) {
      $("#imgUploadDiv app-new-file-upload .ant-upload-text").click();
      e.stopPropagation();
    });
    let th = this;
    this.fullText.addListener('selectionchange', function (type, causeByUi, uiReady) {
      th.setContent();
    });
  }

  setContent() {
    if (this.mailText !== this.fullText.Instance.getContent()) {
      this.mailText = this.fullText.Instance.getContent();
    }
  }

  showGatheringModal(lease_id: number) {
    this.dialogComponent.openGatherDialog(lease_id);
  }

  showLeaseGatheringEmailDetail(lease_id: number, contract_num: string, room_count: number, bill_type: string,
                                bill_amount: string, bill_time: string, plan_time: number, project_name: string,
                                alert_id: number, waiting_pay_amount: string) {
    let request = new WorkbenchEmailHistoryRequest();
    request.lease_id = lease_id;
    request.service_type = 'PRESS_FOR_MONEY';
    this.workbenchService.workbenchEmailHistory(request).then(data => {
      this.workbenchEmailHistoryDTOS = data.workbench_email_history_dtos;
    });
    let planPayTime = new Date(plan_time);
    this.mailAddress = null;
    this.sendMailServiceType = 'PRESS_FOR_MONEY';
    this.sendMailLeaseId = lease_id;
    this.sendMailAlertId = alert_id;
    this.mailSubject = "【" + contract_num + "】" + "账单支付提醒";
    this.mailText = "<p>尊敬的乐璟客户，您好！</p><p>&nbsp; &nbsp; &nbsp; &nbsp;您/贵公司承租的<span style=\"color:#ff0000\">" + contract_num + "</span>合同，" +
      "共计<span style=\"color:#ff0000\">" + room_count + "</span>间房，<span style=\"color:#ff0000\">" + bill_time + "</span>周期账单已经生成，共计<span style=\"color:#ff0000\">" + bill_amount + "</span>元，" + "还需缴纳<span style=\"color:#ff0000\">" + waiting_pay_amount + "</span>元，" +
      "为避免逾期，请您于<span style=\"color:#ff0000\">" + YYYYmmdd2(planPayTime) + "</span>前完成支付，如有需要请与前台联系，祝您生活愉快！</p><p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; " +
      "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; " + project_name + "</p><p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; " +
      "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; " + YYYYmmdd2(this._now) + "</p>";
    this.leaseCheckoutNotificationModal.show();
  }

  searchBillType(code: string) {
    this.alert_type_due = code;
    this.dueListReq.alert_type = this.alert_type_due;
    this.dueListReq.page_num = 1;
    this.workbenchService.getDueAlertList(this.dueListReq).then(data => {
      this.alertDue = data;
      if (!data.due_alert_dtos) {
        this.alertDue.due_alert_dtos = [];
        this.alertDue.total = 0;
      }
    });
  }


  showLimitDetail(leaseRoomId: number) {
    this.leaseService.getCheckInLimitLeaseDetail(leaseRoomId).then(data => {
      this.checkInLimitDetailResponse = data;
      this.showLimitDetailModal.show();
    });
  }

  getRenterCheckoutNotFinishList($event?: number) {
    if ($event) {
      this.renterCheckoutNotFinishReq.page_num = $event;
    }
    this.renterCheckoutNotFinishReq.page_size = 10;
    this.renterService.getRenterCheckoutNotFinishList(this.renterCheckoutNotFinishReq).then(data => {
      this.renterCheckoutNotFinishResponse = data;
    });
  }

  getRenterCheckoutFinanceNotFinishList($event?: number) {
    if ($event) {
      this.renterCheckoutFinanceNotFinishReq.page_num = $event;
    }
    this.renterCheckoutFinanceNotFinishReq.page_size = 10;
    this.renterService.getRenterCheckoutFinanceNotFinishList(this.renterCheckoutFinanceNotFinishReq).then(data => {
      this.renterCheckoutFinanceNotFinishResponse = data;
    });
  }

  calCountDown(time: number) {
    return dateTimeCountDown(time);
  }

  cancelFinanceCheckout(record_id: number) {

    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = "取消后需重新退宿结算，是否确定取消？";
    messageModalInfo.cancel = true;
    messageModalInfo.callback.subscribe(() => {
      this.renterService.postRenterCheckoutFinanceNotFinishCancel(record_id).then(data => {
        this.getRenterCheckoutFinanceNotFinishList();
      });
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  getRenterCheckoutRemindList($event?: number) {
    if ($event) {
      this.renterCheckoutRemindListReq.page_num = $event;
    }
    this.renterCheckoutRemindListReq.page_size = 10;
    this.renterService.getRenterCheckoutRemindList(this.renterCheckoutRemindListReq).then(data => {
      this.renterCheckoutRemindListResponse = data;
    });
  }

  cancelRenterCheckoutRequest(record_id: number) {
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = "取消后需重新申请或办理退宿，是否确定取消？";
    messageModalInfo.cancel = true;
    messageModalInfo.callback.subscribe(() => {
      this.renterService.postRenterCheckoutRequest(record_id, false).then(data => {
        this.getRenterCheckoutRemindList();
      });
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  agreeRenterCheckoutRequest(record_id: number) {
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = "确定同意退宿吗？" +
      "确定之后将会立即退宿，释放房间，作废门锁密码。";
    messageModalInfo.cancel = true;
    messageModalInfo.callback.subscribe(() => {
      this.renterService.postRenterCheckoutRequest(record_id, true).then(data => {
        this.getRenterCheckoutRemindList();
      });
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }


  getRenterCheckoutTransferErrorRemindList($event?: number) {
    if ($event) {
      this.renterCheckoutTransferErrorRemindListReq.page_num = $event;
    }
    this.renterCheckoutTransferErrorRemindListReq.page_size = 10;
    this.renterService.getRenterCheckoutTransferErrorRemindList(this.renterCheckoutTransferErrorRemindListReq).then(data => {
      this.renterCheckoutTransferErrorRemindListResponse = data;
    });
  }

  dealRenterCheckoutError(item: RenterCheckoutTransferErrorRemindModel) {
    this.renterCheckoutErrorData = item;
    this.renterCheckoutErrorDealReq = new RenterCheckoutTransferErrorDealRequest();
    this.renterCheckoutErrorDealReq.checkout_finance_type_enum = 'REFUND';
    this.renterCheckoutErrorDealReq.record_id = item.record_id;
    this.renterCheckoutErrorModal.show();
  }

  confirmRenterCheckoutError() {
    if (this.renterCheckoutErrorDealReq.checkout_finance_type_enum == 'REFUND') {
      if (
        !trim(this.renterCheckoutErrorDealReq.bank_no)
        || !trim(this.renterCheckoutErrorDealReq.open_bank)
        || !trim(this.renterCheckoutErrorDealReq.refund_name)
      ) {
        return;
      }
    }

    this.renterService.postRenterCheckoutTransferErrorDeal(this.renterCheckoutErrorDealReq).then(data => {

      this.renterCheckoutErrorModal.hide();

      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "提交成功！"));

      this.getRenterCheckoutTransferErrorRemindList();
    });


  }

  goRenterCheckout(id) {
    this.router1.navigate(['/system_manage/signed/renter/checkout/record/detail/' + id]);
  }

  getProjectBasePriceInfo(item: AuditDTO) {
    this.approvService.projectBasePriceInfo(item.id).then(data => {
      this.initFlowAuditModal(item, data.process_instance_id).then(d => {
        this.approProjectBasePrice = data;
        this.staticTabs_p.tabs[0].active = true;
        this.projectBasePriceModal.show();
      });
    });
  }

  searchAssertList(pageNum: number) {
    this.assTransAuditDetailRequest.page_num = pageNum;
    this.assetService.assetEditRecordDetail(this.assTransAuditDetailRequest).then(data => {
      if (data.asset_list && data.asset_list.length > 0) {
        this.assTransAuditDetailResponse = data;
        this.assetEditRecordCount = this.assTransAuditDetailRequest.page_size * (this.assTransAuditDetailRequest.page_num - 1);
        this.assTransAuditDetailResponse.asset_list.forEach((dl2, index2) => {
          this.assetEditRecordCount++;
          dl2.asset_index = this.assetEditRecordCount;
        });
      } else {
        if (this.assTransAuditDetailRequest.page_num === 1) {
          this.assTransAuditDetailResponse.asset_edit_record_id = 0;
          this.assTransAuditDetailResponse.asset_list = [];
        }
      }
    });
  }


  getFireAlertList() {
    this.workbenchService.getFireAlertList(this.fireAlertListReq).then(data => {
      this.fireAlertListResp = data;
      if (!data.dto_list) {
        this.fireAlertListResp.dto_list = [];
        this.fireAlertListResp.total = 0;
      }
    });
  }

  changeFireAlertPage(currentPage: number) {
    this.fireAlertListReq.page_num = currentPage;
    this.getFireAlertList();
  }

  appointmentNew() {

    this.appointSubmitReq = new AppointmentHouseApplySubmitRequest;

    this.appointService.appointmentTimes(this.appointTime.formatted).then(data => {
      this.appointTimes = data.times;
    });
    this.appointService.appointmentGroupList().then(data => {
      this.appointGroupList = data.group_list;
      this.appointmentAddModal.show();
    });
  }

  searchAppoint() {
    this.appointTimes = [];
    this.appointService.appointmentTimes(this.appointTime.formatted).then(data => {
      this.appointTimes = data.times;
    });
  }

  formatAppointTime(str: string) {
    return str ? str.substring(0, str.length - 3) : '';
  }

  saveAppointment() {
    this.appointSubmitReq.appointment_time = this.appointTime.formatted;
    let t = this.appointTimes.find(e => e.start === this.appointSubmitReq.t);
    this.appointSubmitReq.start_time = t.start;
    this.appointSubmitReq.end_time = t.end;
    if (this.appointSubmitReq.contacts_name && this.appointSubmitReq.contacts_phone
      && this.appointSubmitReq.start_time && this.appointSubmitReq.room_group_id) {
      this.appointService.appointmentSubmit(this.appointSubmitReq).then(data => {
        this.appointmentAddModal.hide();
      });
    }
  }

}
