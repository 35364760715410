<div class="col-12" (resize)="resizeFunc()">
  <div class="row">
    <div class="faceStatus col-12" *ngIf="!faceFlag">
      <p> 亲爱的{{sysUserDTO?.user_name}}，系统账号安全提醒：为了保障您的账号安全能正常使用系统，请在云盾宿管App中完成人脸认证，点击<span
        (click)="goFace()">立即认证</span></p>
    </div>
    <div class="workfont col-12">工作台</div>
    <div class="col-12 mb-3 text-right">


      <div class="d-block show1">

        <button type="button mr-1" *ngIf="btn_privileges.OPERATING_THE_BRAIN"
                class="btn btn-info mr-1"
                [routerLink]="['/ob/dv']">
          <i class="fa fa-graduation-cap" aria-hidden="true"></i>运营大脑
        </button>

        <div class="btn-group mr-1" role="group" *ngIf="btn_privileges.ROOM_DIAGRAM">
          <button type="button" class="btn btn-info" [routerLink]="['/system_manage/room-configuration/state-diagram']">
            <i class="fa fa-pie-chart" aria-hidden="true"></i>
            <ng-container *ngIf="roomType===1">集中式房态图</ng-container>
            <ng-container *ngIf="roomType===2">分散式房态图</ng-container>
          </button>
          <button type="button" class="btn btn-secondary"
                  *ngIf="showChangeRoomType"
                  (click)="switchRoomType()"
          >[切换]
          </button>
        </div>

        <hr>
      </div>


      <button type="button" *ngIf="projectConfig?.need_lease_contract == 1"
              class="btn btn-info mb10 mr-1" (click)="appointmentNew()">
        预约看房
      </button>

      <button type="button" *ngIf="btn_privileges.WORKBENCH_BOOK_BTN && projectConfig?.need_lease_contract == 1"
              class="btn btn-info mb10 mr-1" [routerLink]="['/system_manage/lease/booking/add']">
        新预定
      </button>
      <button type="button" *ngIf="btn_privileges.WORKBENCH_SIGN_BTN && projectConfig?.need_lease_contract == 1"
              class="btn btn-info mb10 mr-1" [routerLink]="['/system_manage/lease/sign/add']">
        新签约
      </button>
      <button type="button" *ngIf="btn_privileges.WORKBENCH_NO_LEASE_BTN" class="btn btn-info mb10 mr-1"
              (click)="showDialog()">
        无合同收款
      </button>

      <button type="button"
              *ngIf="btn_privileges.WORKBENCH_MONITOR_BTN && (projectConfig?.ele_type == 'SMART' || projectConfig?.cold_water_type == 'SMART' || projectConfig?.hot_water_type == 'SMART')"
              class="btn btn-warning mb10 mr-1" [routerLink]="['/system_manage/monitor/ssm']">
        服务与安全监控
      </button>
      <button type="button" *ngIf="btn_privileges.ROOM_MANAGE_LIST_BUTTON" class="btn btn-success mb10 mr-1"
              [routerLink]="['/system_manage/room/room-list']">
        房间管理
      </button>
      <button type="button" *ngIf="projectId" (click)="incomeQrcode(incomeQrcodeModal)"
              class="btn btn-success mb10 mr-1">
        收款二维码
      </button>
      <button type="button" *ngIf="btn_privileges.WORKBENCH_MONEY_REPORT_BTN" class="btn btn-primary mb10 mr-1"
              (click)="goToTemplate('REPORT_FEE')">
        查看资金流水表
      </button>

      <button type="button"
              *ngIf="btn_privileges.WORKBENCH_ACCOUNT_REPORT_BTN && projectConfig?.need_lease_contract == 1"
              class="btn btn-primary mb10 mr-1" (click)="goToTemplate('REPORT_ACCOUNT_NEW')">
        查看台账报表
      </button>
      <button type="button"
              *ngIf="btn_privileges.WORKBENCH_BUSINESS_REPORT_BTN && projectConfig?.need_lease_contract == 1"
              class="btn btn-primary mb10 mr-1" (click)="goToTemplate('REPORT_DAILY_NEW')">
        查看营业报表
      </button>

      <button type="button"
              *ngIf="btn_privileges.WORKBENCH_PREPAY_BTN && (projectConfig?.ele_fee_type == 'PREPAY' || projectConfig?.cold_water_fee_type == 'PREPAY' || projectConfig?.hot_water_fee_type == 'PREPAY')"
              class="btn btn-primary mb10 mr-1" (click)="goToRoomPrepayDetailTemplate()">
        预付费扣费查询
      </button>


      <button type="button" *ngIf="btn_privileges.WORKBENCH_PURCHASE_LEASE_BTN" class="btn btn-info mb10 mr-1"
              [routerLink]="['/system_manage/asset/contract/add']">
        新增采购合同
      </button>
      <button type="button" *ngIf="btn_privileges.WORKBENCH_GATHERING_BTN && projectConfig?.need_lease_contract == 1"
              class="btn btn-danger mb10 mr-1" [routerLink]="['/system_manage/finance/payloglist']">
        收款核销
      </button>
      <button type="button" *ngIf="btn_privileges.WORKBENCH_REFUND_BTN && projectConfig?.need_lease_contract == 1"
              class="btn btn-danger mb10 mr-1" [routerLink]="['/system_manage/finance/audit/refund/list']">
        退款处理
      </button>
      <button type="button" *ngIf="btn_privileges.WORKBENCH_COUPON_BTN && projectConfig?.need_lease_contract == 1"
              class="btn btn-success mb10 mr-1" [routerLink]="['/system_manage/coupon/manage']">
        优惠券管理
      </button>
      <button type="button" *ngIf="btn_privileges.CLEAN_WORK_STATISTICS" class="btn btn-info mb10 mr-1"
              [routerLink]="['/system_manage/property/clean/check']">
        保洁计划检查
      </button>
      <button type="button" *ngIf="btn_privileges.SECURITY_WORK_STATISTICS" class="btn btn-info mb10 mr-1"
              [routerLink]="['/system_manage/property/security/check']">
        保安计划检查
      </button>
      <button type="button" *ngIf="btn_privileges.ENGERING_WORK_STATISTICS" class="btn btn-info mb10 mr-1"
              [routerLink]="['/system_manage/property/engineering/check']">
        工程计划检查
      </button>
      <button type="button" *ngIf="btn_privileges.DEVICE_WORK_STATISTICS" class="btn btn-info mb10 mr-1"
              [routerLink]="['/system_manage/property/base_equipment_plan/check']">
        设备维保检查
      </button>
      <button type="button" *ngIf="btn_privileges.PAD_SIGN" class="btn btn-info mb10 mr-1" (click)="turnToPadSign()">
        pad签字板
      </button>
      <button type="button" class="btn btn-success mb10 mr-1" (click)="downloadAppQrcode()">
        下载PMS-APP二维码
      </button>


      <button class="btn btn-warning mb10 mr-1" [routerLink]="['/system_manage/workspace/approv/done']">
        查看审批记录
      </button>
      <button class="btn btn-warning mb10 mr-1" [routerLink]="['/system_manage/workspace/approv/lock/password/list']">
        房间密码申请记录
      </button>
      <button *ngIf="btn_privileges.GATHER_MONEY_INPUT" class="btn btn-warning mb10 mr-1" (click)="openMoneyModel()">
        收款录入
      </button>

    </div>
  </div>
<!--  <ng-template #yuliangtongji let-message="message">-->
<!--    当前空房：当前项目房间入住人为0的房间总数量<br/>当前空床：当前项目按床位出租的空床数<br/>已租未入住人数：已签约房间及床位可入住人数总和-已入住人数-->
<!--  </ng-template>-->
  <!-- 统计面板区-->
  <div class="">
    <div class="row">


      <div *ngIf="btn_privileges.ROOM_RENT_STATISTICS" class="col-12 col-md-6 col-lg-4 col-xl-3 mb20"
           style="color: #333;">
        <div class="setbox">
          <p class="p1">在租统计</p>
          <div class="row mt10">
            <div class="wp33 text-center">
              <div class="w-100  mb10"><span
                class="font1 span1">{{actual_total_percent}}</span>%
              </div>
              <div class="w-100  mb10"><img class="img1" src="../../../../assets/img/workbench/u1498.png"></div>
              <div class="w-100 ">整体出租率
              </div>
            </div>
            <div class="wp33 text-center">
              <div class="w-100  mb10"><span
                class="font1 span1">{{room_total_percent}}</span>%
              </div>
              <div class="w-100  mb10"><img class="img1" src="../../../../assets/img/workbench/u1498.png"></div>
              <div class="w-100 ">
                房间出租率
              </div>
            </div>
            <div class="wp33 text-center">
              <div class="wp100  mb10"><span
                class="font1 span1">{{bed_total_percent}}</span>%
              </div>
              <div class="wp100  mb10"><img class="img1" src="../../../../assets/img/workbench/u1498.png"></div>
              <div class="wp100 ">床位出租率
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 余量统计 -->
      <div *ngIf="btn_privileges.ROOM_RENT_STATISTICS" class="col-12 col-md-6 col-lg-4 col-xl-3 mb20"
           style="color: #333;">
        <div class="setbox">
          <p class="p1">房量统计
<!--            <i class="fa fa-question-circle-o cursor-pointer" placement="top" triggers="mouseenter:mouseleave"-->
<!--               popoverTitle="房量统计" [popover]="yuliangtongji"></i>-->
          </p>
          <div class="row mt10">

            <div class="wp25 text-center" [ngClass]="{'wp25':internal_room_num,'wp33':!internal_room_num}"
                 title="已签约房间">
              <div class="w-100  mb10"><span class="font1 span1">{{lease_room_num}}</span>间
              </div>
              <div class="w-100  mb10"><i class="fa fa-hotel img1 c-rent-green"></i></div>
              <div class="w-100 ">已租房间
              </div>
            </div>
            <div class="wp25 text-center" [ngClass]="{'wp25':internal_room_num,'wp33':!internal_room_num}"
                 title="总房量-已签约-保留房-维修房-待验收">
              <div class="w-100  mb10"><span class="font1 span1">{{nolease_room_num}}</span>间
              </div>
              <div class="w-100  mb10"><img class="img1" src="../../../../assets/img/workbench/u1492.png"></div>
              <div class="w-100 ">可租空房
              </div>
            </div>
            <div class="wp25 text-center" [ngClass]="{'wp25':internal_room_num,'wp33':!internal_room_num}"
                 title="保留房+维修房+待验收">
              <div class="w-100  mb10"><span class="font1 span1">{{unavailable_room_num}}</span>间
              </div>
              <div class="w-100  mb10"><i class="fa fa-ban img1"></i></div>
              <div class="w-100 ">不可用房间
              </div>
            </div>
            <div class="text-center wp25" *ngIf="internal_room_num"
                 title="内部免租房间">
              <div class="w-100  mb10"><span class="font1 span1">{{internal_room_num}}</span>间
              </div>
              <div class="w-100  mb10"><i class="fa fa-inbox img1 c-rent-purple"></i></div>
              <div class="w-100 ">内部房间数
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 住户统计 -->
      <div *ngIf="btn_privileges.RENTER_STATISTICS" class="col-12 col-md-6 col-lg-4 col-xl-3 mb20" style="color: #333;">
        <div class="setbox">
          <p class="p1">住户统计</p>
          <div class="row mt10">
            <div class="wp33 text-center">
              <div class="wp100  mb10"><span class="font1 span1">{{workbenchData ? workbenchData.renter_statistics_dto ?
                (workbenchData?.renter_statistics_dto?.checkin_num) : '-' : '-'}}</span>人
              </div>
              <div class="wp100  mb10"><img class="img1" src="../../../../assets/img/workbench/checkin2.png"></div>
              <div class="wp100 ">今日入住</div>
            </div>
            <div class="wp33 text-center">
              <div class="w-100  mb10"><span class="font1 span1">{{workbenchData ? workbenchData.renter_statistics_dto ?
                (workbenchData?.renter_statistics_dto?.checkout_num) : '-' : '-'}}</span>人
              </div>
              <div class="w-100  mb10"><img class="img1" src="../../../../assets/img/workbench/checkout2.png"></div>
              <div class="w-100 ">今日退宿</div>
            </div>
            <div class="wp33 text-center">
              <div class="w-100  mb10"><span class="font1 span1">{{workbenchData ? workbenchData.renter_statistics_dto ?
                (workbenchData?.renter_statistics_dto?.effective_num) : '-' : '-'}}</span>人
              </div>
              <div class="w-100  mb10"><img class="img1" src="../../../../assets/img/workbench/effetive.png"></div>
              <div class="w-100 ">在住用户</div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb20" style="color: #333;">
        <div class="setbox">
          <p class="p1" style="margin-bottom:20px">系统公告
            <span class="float-right notice-head-right" (click)="noticeListModelShow(noticeListModel)">查看更多>></span>
          </p>
          <div class="col-12">
            <div class="row" *ngFor="let notice of first_notice_list;let i = index"
                 (click)="noticeUserDetailShow(notice.id,0,notice.read_flag,noticeDetailModel,noticeListModel)"
            >
              <span class="d-inline-block p-0 col-3 col-md-3 col-lg-3 col-3xl-2">
                <button *ngIf="notice.type == 'SYSTEM_UPDATE'" type="button"
                        class="notice-type sysupdate">{{notice_types[notice.type]}}</button>
                <button *ngIf="notice.type == 'BUSINESS_REMIND'" type="button"
                        class="notice-type business">{{notice_types[notice.type]}}</button>
              </span>
              <span class="d-inline-block p-0 col-7 col-md-6 col-lg-7 col-3xl-8 notice-nowrap noticeCon">
                <span *ngIf="notice.type == 'SYSTEM_UPDATE'">【{{notice.version}}版本】</span>{{notice.title}}
              </span>
              <span class="d-inline-block p-0 col-2 col-md-3 col-lg-2 col-3xl-2 text-right">
                <span *ngIf="notice.read_flag == 0" class="noticeNoRead px-1">未读</span>
              </span>
              <div *ngIf="i != 2" class="notic_dashed d-inline"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb20">
      <!-- 操作面板 -->
      <div class="setbox">


        <div class="d-flex justify-content-between align-items-center">
          <p class="p1 mb-0"><img class="mx-1" src="../../../../assets/img/v3/u4129.png"/>快速操作</p>
          <!-- <div class="openwork" [routerLink]="['../work-order/list']">
            <img class="mx-1" src="../../../../assets/img/u2737.png" alt="">
            <span>工单中心</span>
            <span>1</span>
          </div> -->
          <div class="position-relative" *ngIf="btn_privileges.WORK_ORDER_CENTER">
            <div class="new_msg" *ngIf="workOrderAwaitNum">
              <span>您有 {{workOrderAwaitNum}} 条待确认工单！</span>
              <i></i>
            </div>
            <div class="service openwork" [routerLink]="['../work-order/list']">
              <img class="mx-1" src="../../../../assets/img/u2737.png" alt="">
              <span>工单中心</span>
            </div>
          </div>
        </div>
        <hr class="w-100">

        <div class="row">

          <!-- 安全报警 15 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton "
               *ngIf="btn_privileges.SECURITY_ALARM" (click)="selectTab(15, person_safe_resp?.total)">

            <div class="d-inline-block rounded px-1" [ngClass]="{'safeBtn':person_safe_resp.total != 0}">
              <span class="buttonTitle">安全报警</span>

              <div *ngIf="person_safe_resp?.total == 0" class="btn btn-secondary disabled buttonIcon"
                   style="background-color:#c9c9c9 !important;">
                0
              </div>
              <div *ngIf="person_safe_resp.total && person_safe_resp?.total != 0" class="btn btn-primary buttonIcon"
                   style="background-color:#f25861 !important;">
                <ng-container *ngIf="person_safe_resp?.total >0 && person_safe_resp?.total < 99">
                  {{person_safe_resp?.total}}
                </ng-container>
                <ng-container *ngIf=" person_safe_resp?.total >= 99">
                  99+
                </ng-container>
              </div>
            </div>

          </div>

          <!--消防组件状态预警 28 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.FIRE_FIGHTING_CS"
               (click)="selectTab(28,fireAlertListResp?.total)">
            <span class="buttonTitle">消防报警</span>
            <div *ngIf="fireAlertListResp?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="fireAlertListResp?.total >0 && fireAlertListResp?.total < 99" class="btn btn-primary buttonIcon"
                 style="background-color:#48abf7 !important;">
              {{fireAlertListResp?.total}}
            </div>
            <div *ngIf="fireAlertListResp?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--投诉处理 1 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.COMPLAINT_DEAL" (click)="selectTab(1,complain?.total)">
            <span class="buttonTitle">投诉处理</span>
            <div *ngIf="complain?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="complain?.total >0 && complain?.total < 99" class="btn btn-primary buttonIcon"
                 style="background-color:#48abf7 !important;">
              {{complain?.total}}
            </div>
            <div *ngIf="complain?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>
          <!--保洁维修 2-->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.CLEAN_REPAIR" (click)="selectTab(2,repairOverHours?.total)">
            <span class="buttonTitle">保洁维修</span>
            <div *ngIf="repairOverHours?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="repairOverHours?.total >0 && repairOverHours?.total < 99" class="btn btn-primary buttonIcon"
                 style="background-color:#48abf7 !important;">
              {{repairOverHours?.total}}
            </div>
            <div *ngIf="repairOverHours?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>
          <!-- 线上收款 3-->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.SCAN_PAYMENT && projectConfig?.need_lease_contract == 1"
               (click)="selectTab(3,orderIfRelateResponse?.total)">
            <span class="buttonTitle">线上收款</span>
            <div *ngIf="orderIfRelateResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="orderIfRelateResponse?.total >0 && orderIfRelateResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{orderIfRelateResponse?.total}}
            </div>
            <div *ngIf="orderIfRelateResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>
          <!-- 提醒退房 4-->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.PAYBACK_UNDO_ALARM && projectConfig?.need_lease_contract == 1"
               (click)="selectTab(4,alertNoCheckout?.total)">
            <span class="buttonTitle">提醒退房</span>
            <div *ngIf="alertNoCheckout?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="alertNoCheckout?.total >0 && alertNoCheckout?.total < 99" class="btn btn-primary buttonIcon"
                 style="background-color:#48abf7 !important;">
              {{alertNoCheckout?.total}}
            </div>
            <div *ngIf="alertNoCheckout?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>
          <!--水电欠费 5 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.PREPAY_ARREARAGE_ALARM && (projectConfig?.ele_fee_type == 'PREPAY' || projectConfig?.cold_water_fee_type == 'PREPAY' || projectConfig?.hot_water_fee_type == 'PREPAY')"
               (click)="selectTab(5,prepayArrearageResponse?.total)">
            <span class="buttonTitle">水电欠费</span>
            <div *ngIf="prepayArrearageResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="prepayArrearageResponse?.total >0 && prepayArrearageResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{prepayArrearageResponse?.total}}
            </div>
            <div *ngIf="prepayArrearageResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>
          <!-- 合同收租 6 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM && projectConfig?.need_lease_contract == 1"
               (click)="selectTab(6,alertDue?.total)">
            <span class="buttonTitle">合同收租</span>
            <div *ngIf="alertDue?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="alertDue?.total >0 && alertDue?.total < 99" class="btn btn-primary buttonIcon"
                 style="background-color:#48abf7 !important;">
              {{alertDue?.total}}
            </div>
            <div *ngIf="alertDue?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!-- 延期退款 16 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.DELAY_REFUND" (click)="selectTab(16,delayRefundData?.total)">
            <span class="buttonTitle">延期退款</span>
            <div *ngIf="delayRefundData?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="delayRefundData?.total >0 && delayRefundData?.total < 99" class="btn btn-primary buttonIcon"
                 style="background-color:#48abf7 !important;">
              {{delayRefundData?.total}}
            </div>
            <div *ngIf="delayRefundData?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--设备故障 7 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.ROOM_DEVICE_ALARM"
               (click)="selectTab(7,alertFault?.total)">
            <span class="buttonTitle">设备故障</span>
            <div *ngIf="alertFault?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="alertFault?.total >0 && alertFault?.total < 99" class="btn btn-primary buttonIcon"
                 style="background-color:#48abf7 !important;">
              {{alertFault?.total}}
            </div>
            <div *ngIf="alertFault?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>
          <!--合同到期 8 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               (click)="selectTab(8,withinTenDaysResponse?.total)"
               *ngIf="btn_privileges.LEASE_END && projectConfig?.need_lease_contract == 1">
            <span class="buttonTitle">合同到期</span>
            <div *ngIf="withinTenDaysResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="withinTenDaysResponse?.total >0 && withinTenDaysResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{withinTenDaysResponse?.total}}
            </div>
            <div *ngIf="withinTenDaysResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>
          <!--事项审批 9 -->
          <!--<div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.AUDIT_LIST" (click)="selectTab(9,discountList?.data_count)">
            <span class="buttonTitle">事项审批</span>
            <div *ngIf="discountList?.data_count == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="discountList?.data_count >0 && discountList?.data_count < 99" class="btn btn-primary buttonIcon"
                 style="background-color:#48abf7 !important;">
              {{discountList?.data_count}}
            </div>
            <div *ngIf="discountList?.data_count >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>-->
          <!--预约看房 10 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.RESERVE_ROOM && projectConfig?.need_lease_contract == 1"
               (click)="selectTab(10,appointListResponse?.total)">
            <span class="buttonTitle">预约看房</span>
            <div *ngIf="appointListResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="appointListResponse?.total >0 && appointListResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{appointListResponse?.total}}
            </div>
            <div *ngIf="appointListResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>
          <!--门锁验收 11 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.DOOR_CHECK && projectConfig?.door_lock_type == 'SMART'"
               (click)="selectTab(11,doorLockInfos?.total)">
            <span class="buttonTitle">门锁验收</span>
            <div *ngIf="doorLockInfos?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="doorLockInfos?.total >0 && doorLockInfos?.total < 99" class="btn btn-primary buttonIcon"
                 style="background-color:#48abf7 !important;">
              {{doorLockInfos?.total}}
            </div>
            <div *ngIf="doorLockInfos?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>
          <!--合同复审 12 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.LEASE_AUDIT_AGAIN && projectConfig?.need_lease_contract == 1"
               (click)="selectTab(12,lfcReviewList?.total)">
            <span class="buttonTitle">合同复审</span>
            <div *ngIf="lfcReviewList?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="lfcReviewList?.total >0 && lfcReviewList?.total < 99" class="btn btn-primary buttonIcon"
                 style="background-color:#48abf7 !important;">
              {{lfcReviewList?.total}}
            </div>
            <div *ngIf="lfcReviewList?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>
          <!--换房转租 13 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.SUBLEASE_CHANGE_REMIND && projectConfig?.need_lease_contract == 1"
               (click)="selectTab(13,subleaseChangeRoomRemindListResponse?.total)">
            <span class="buttonTitle">换房转租</span>
            <div *ngIf="subleaseChangeRoomRemindListResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div
              *ngIf="subleaseChangeRoomRemindListResponse?.total >0 && subleaseChangeRoomRemindListResponse?.total < 99"
              class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{subleaseChangeRoomRemindListResponse?.total}}
            </div>
            <div *ngIf="subleaseChangeRoomRemindListResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>
          <!--资产变更 14 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.ASSET_CHANGE_REMIND" (click)="selectTab(14,assetChangeRemindListResponse?.total)">
            <span class="buttonTitle">资产变更</span>
            <div *ngIf="assetChangeRemindListResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="assetChangeRemindListResponse?.total >0 && assetChangeRemindListResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{assetChangeRemindListResponse?.total}}
            </div>
            <div *ngIf="assetChangeRemindListResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--待签合同 17 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.WAIT_SIGN_LEASE" (click)="selectTab(17,waitSignListResponse?.total)">
            <span class="buttonTitle">待签合同</span>
            <div *ngIf="waitSignListResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="waitSignListResponse?.total >0 && waitSignListResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{waitSignListResponse?.total}}
            </div>
            <div *ngIf="waitSignListResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--资产盘点 18 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.ASSET_CHECK_REMIND" (click)="selectTab(18, assetCheckRemindResponse?.total)">
            <span class="buttonTitle">资产盘点</span>
            <div *ngIf="assetCheckRemindResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="assetCheckRemindResponse?.total > 0 && assetCheckRemindResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{assetCheckRemindResponse?.total}}
            </div>
            <div *ngIf="assetCheckRemindResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--入住限制 19 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.CHECK_IN_LIMIT" (click)="selectTab(19,checkInLimitResponse?.total)">
            <span class="buttonTitle">入住限制</span>
            <div *ngIf="checkInLimitResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="checkInLimitResponse?.total >0 && checkInLimitResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{checkInLimitResponse?.total}}
            </div>
            <div *ngIf="checkInLimitResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--线下收款 22 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.CHECK_IN_LIMIT" (click)="selectTab(22,noAuditListResponse?.total)">
            <span class="buttonTitle">线下收款</span>
            <div *ngIf="noAuditListResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="noAuditListResponse?.total >0 && noAuditListResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{noAuditListResponse?.total}}
            </div>
            <div *ngIf="noAuditListResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--退房结算 20 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.CHECKOUT_NOT_FINISH"
               (click)="selectTab(20, checkoutNotFinishResponse?.total)">
            <span class="buttonTitle">退房结算</span>
            <div *ngIf="checkoutNotFinishResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="checkoutNotFinishResponse?.total > 0 && checkoutNotFinishResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{checkoutNotFinishResponse?.total}}
            </div>
            <div *ngIf="checkoutNotFinishResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--退房签字 21 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.CHECKOUT_FINANCE_NOT_FINISH"
               (click)="selectTab(21, checkoutFinanceNotFinishResponse?.total)">
            <span class="buttonTitle">退房签字</span>
            <div *ngIf="checkoutFinanceNotFinishResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="checkoutFinanceNotFinishResponse?.total > 0 && checkoutFinanceNotFinishResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{checkoutFinanceNotFinishResponse?.total}}
            </div>
            <div *ngIf="checkoutFinanceNotFinishResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--保洁任务提醒 23 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.WORKSPACE_CLEANTASK_REMIND" (click)="selectTab(23, cleanTaskListResponse?.total)">
            <span class="buttonTitle">保洁任务</span>
            <div *ngIf="cleanTaskListResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="cleanTaskListResponse?.total > 0"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              1
            </div>
          </div>

          <!--退宿结算 24 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.RENTER_CHECKOUT_NOT_FINISH"
               (click)="selectTab(24, renterCheckoutNotFinishResponse?.total)">
            <span class="buttonTitle">退宿结算</span>
            <div *ngIf="renterCheckoutNotFinishResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="renterCheckoutNotFinishResponse?.total > 0 && renterCheckoutNotFinishResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{renterCheckoutNotFinishResponse?.total}}
            </div>
            <div *ngIf="renterCheckoutNotFinishResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--退宿财务结算 25 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.RENTER_CHECKOUT_FINANCE_NOT_FINISH"
               (click)="selectTab(25, renterCheckoutFinanceNotFinishResponse?.total)">
            <span class="buttonTitle">退宿签字</span>
            <div *ngIf="renterCheckoutFinanceNotFinishResponse?.total == 0"
                 class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div
              *ngIf="renterCheckoutFinanceNotFinishResponse?.total > 0 && renterCheckoutFinanceNotFinishResponse?.total < 99"
              class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{renterCheckoutFinanceNotFinishResponse?.total}}
            </div>
            <div *ngIf="renterCheckoutFinanceNotFinishResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--提醒退宿 26 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.RENTER_CHECKOUT_REMIND"
               (click)="selectTab(26, renterCheckoutRemindListResponse?.total)">
            <span class="buttonTitle">提醒退宿</span>
            <div *ngIf="renterCheckoutRemindListResponse?.total == 0" class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div *ngIf="renterCheckoutRemindListResponse?.total > 0 && renterCheckoutRemindListResponse?.total < 99"
                 class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{renterCheckoutRemindListResponse?.total}}
            </div>
            <div *ngIf="renterCheckoutRemindListResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

          <!--住客自动退押提醒 27 -->
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 col-2xl-1 px-0 text-center workbenchButton"
               *ngIf="btn_privileges.RENTER_CHECKOUT_TRANSFER_ERROR_REMIND"
               (click)="selectTab(27, renterCheckoutTransferErrorRemindListResponse?.total)">
            <span class="buttonTitle">住客退押提醒</span>
            <div *ngIf="renterCheckoutTransferErrorRemindListResponse?.total == 0"
                 class="btn btn-secondary disabled buttonIcon"
                 style="background-color:#c9c9c9 !important;">
              0
            </div>
            <div
              *ngIf="renterCheckoutTransferErrorRemindListResponse?.total > 0 && renterCheckoutTransferErrorRemindListResponse?.total < 99"
              class="btn btn-primary buttonIcon" style="background-color:#48abf7 !important;">
              {{renterCheckoutTransferErrorRemindListResponse?.total}}
            </div>
            <div *ngIf="renterCheckoutTransferErrorRemindListResponse?.total >= 99" class="btn btn-danger buttonIcon"
                 style="background-color:#f25861 !important;">
              99+
            </div>
          </div>

        </div>

        <hr class="w-100">

        <!-- card -->
        <div class="cardbox" id="cardDiv">
          <div class="row">
            <div class="col-12 col-md-12 col-xl-12">
              <div class="card">
                <div class="card-block border-2x" style="background-color: #ffffff;">
                  <div class="text-center" *ngIf="showTab === -1">暂无数据</div>

                  <!--安全报警 15 -->
                  <div class="card card-inverse card-warning" *ngIf="btn_privileges.SECURITY_ALARM && isSelected(15)">
                    <div class="operationHeader">
                      安全报警
                      <span class="float-right">共{{person_safe_resp?.total}}条</span>
                    </div>
                    <div class="card-block" style="padding-top: 10px;">

                      <div class="row">
                        <div class="col-12 col-xl-51 mt-2"
                             *ngFor="let item of person_safe_resp.person_safe_alarm_dtos | paginate: {itemsPerPage: safeWarningReq?.page_size,
                                currentPage: safeWarningReq?.page_num, totalItems:person_safe_resp?.total,id:'safeWarnPage'}">

                          <div class="border rounded border-green p-2 mx-1 mh-22">

                            <div class="clearfix">
                              <span class="warn-tip bg-success" *ngIf="item.status=='UNPROCESSED'">
                                未处理
                              </span>
                              <span class="warn-tip bg-warning" *ngIf="item.status=='PROCESSED'">
                                处理中
                              </span>
                            </div>

                            <div class="col-12 mt-4">
                              <div class="col-12 text-center">
                                <img class="figure-img img-fluid " style="width: 6em;height: 8em"
                                     src="{{item.head_image}}" alt="">
                              </div>

                              <div class="col-12" *ngIf="item.safe_type=='THID_NOT_IN'">

                                <div class="text-center font-weight-bold">3日未归人员</div>

                                <div class=" mt-2">{{item.room_name}} {{item.person_name}}</div>
                                <div class="">{{item.person_remark}}</div>
                                <div class="">离宿时间：{{item.get_out_time|date:'MM-dd HH:mm:ss'}}</div>

                              </div>
                              <div class="col-12" *ngIf="item.safe_type=='THID_NOT_OUT'">

                                <div class="text-center font-weight-bold">3日未出人员</div>

                                <div class=" mt-2">{{item.room_name}} {{item.person_name}}</div>
                                <div class="">{{item.person_remark}}</div>
                                <div class="">进入时间：{{item.come_in_time|date:'MM-dd HH:mm:ss'}}</div>

                              </div>
                              <div class="col-12" *ngIf="item.safe_type=='UN_REGISTERED'">

                                <div class="text-center font-weight-bold">未登记在住人员</div>

                                <div *ngIf="item.not_registered_chickin_dto">
                                  <div class="font-weight-bold mt-2">{{item.not_registered_chickin_dto.total_num}}
                                    次进出{{item.not_registered_chickin_dto.unit_name}}</div>
                                  <div class="">进出时段：</div>
                                  <div class="" *ngIf="item.not_registered_chickin_dto.come_in_time">
                                    进：{{item.not_registered_chickin_dto.come_in_time}}
                                    {{item.not_registered_chickin_dto.come_in_num}}
                                    次
                                  </div>
                                  <div class="" *ngIf="!item.not_registered_chickin_dto.come_in_time">
                                    进：未能获取
                                  </div>
                                  <div class="" *ngIf="item.not_registered_chickin_dto.get_out_time">
                                    出：{{item.not_registered_chickin_dto.get_out_time}}
                                    {{item.not_registered_chickin_dto.get_out_num}}
                                    次
                                  </div>
                                  <div class="" *ngIf="!item.not_registered_chickin_dto.get_out_time">
                                    出：未能获取
                                  </div>
                                  <div class="">
                                    首次进入日期：{{item.not_registered_chickin_dto.first_come_in_time|date:'yyyy.MM.dd'}}
                                  </div>
                                </div>

                              </div>
                              <div class="col-12" *ngIf="item.safe_type=='UNKNOW_SUSPICIOUS'">

                                <div class="text-center font-weight-bold">陌生可疑人员</div>

                                <div class=" font-weight-bold mt-2">频繁多次进出</div>

                                <div class="scroll v2 scroll-min">
                                  <ng-container *ngFor="let item1 of item.strange_suspect_dtos">
                                    <ng-container *ngFor="let item2 of item1.access_dtos">
                                      <div class="">
                                        {{item2.access_time|date:'MM-dd HH:mm:ss'}}
                                        ：{{item2.position_detail}}{{item2.type}}
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                </div>

                              </div>

                            </div>
                          </div>

                          <div class="row mt-1 mx-1">
                            <div class="col-6">
                              <div class="btn btn-outline-primary text-center w-100"
                                   *ngIf="btn_privileges.VIEW_SECURITY_ALARM" (click)="safeWarnDetail(item)">
                                详情
                              </div>
                            </div>
                            <div class="col-6 text-center">
                              <div class="btn btn-outline-primary text-center w-100"
                                   *ngIf="btn_privileges.PROCESS_SECURITY_ALARM" (click)="safeWarnHandle(item)">
                                处理
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="text-center pages mt-2">
                        <pagination-controls id="safeWarnPage" *ngIf="person_safe_resp?.total !== 0"
                                             (pageChange)="getWorkbenchSafeWarningList($event)" directionLinks="true"
                                             previousLabel="上一页"
                                             nextLabel="下一页" screenReaderPaginationLabel="Pagination"
                                             screenReaderPageLabel="page"
                                             screenReaderCurrentLabel="You're on page"></pagination-controls>

                        <span class="float-right"><button class="btn btn-link"
                                                          [routerLink]="['../safety/dispose/list']">查看记录</button></span>
                      </div>
                    </div>
                  </div>

                  <app-safe-warn-detail *ngIf="btn_privileges.SECURITY_ALARM && person_safe_resp"
                                        (handled)="safeListHandle()"></app-safe-warn-detail>

                  <!-- 投诉处理 1 -->
                  <div class="card card-inverse" *ngIf="btn_privileges.COMPLAINT_DEAL && isSelected(1)">
                    <div class="operationHeader">
                      待处理的投诉与建议
                      <span class="float-right">共{{complain?.total}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">

                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#1572e8;color:white;">
                          <th scope="col" class="text-center">提交用户</th>
                          <th scope="col" class="text-center">投诉时间</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="complain?.total > 0">
                          <tr *ngFor="let it of complain?.complaints_info_dtos  | paginate: {itemsPerPage: complainReq?.page_size,
            currentPage: complainReq?.page_num, totalItems:complain?.total, id : 'complaints' }">
                            <td class="text-center">{{it?.complaints_user_phone}}</td>
                            <td class="text-center">{{it?.create_time | date:'yyyy-MM-dd HH:mm'}}</td>
                            <td class="text-center">
                              <span class="btnBgBlue" *ngIf="it?.complaints_status == 'WAITING'">未处理</span>
                              <span class="btnBgGreen" *ngIf="it?.complaints_status == 'PROCESSING'">处理中</span>
                              <span class="btnBgGreen" *ngIf="it?.complaints_status == 'FIXED'">处理完成</span>
                            </td>
                            <td class="text-center">
                              <button
                                *ngIf="btn_privileges.COMPLAINT_DEAL_VIEW && !btn_privileges.COMPLAINT_DEAL_HANDLE"
                                class="text-primary" style="background-color: #ffffff;color:#1368e3 !important;"
                                (click)="openModal(it);tj('待处理的投诉')">
                                <i class="fa fa-search"></i>
                                查看
                              </button>
                              <button class="text-primary" style="background-color: #ffffff;color:#1368e3 !important;"
                                      *ngIf="btn_privileges.COMPLAINT_DEAL_HANDLE"
                                      (click)="openModal(it);tj('待处理的投诉')">
                                <img class="icon_img" src="../../../../assets/img/u935.png">
                                处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="complain?.complaints_info_dtos?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="complain?.complaints_info_dtos?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="complain?.complaints_info_dtos?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="complain?.complaints_info_dtos?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="complain?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无待处理的投诉信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="complaints" *ngIf="complain?.total !== 0"
                                           (pageChange)="getComplainPage($event)" directionLinks="true"
                                           previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>
                  <!--保洁维修 2 -->
                  <div class="card card-inverse card-warning" *ngIf="btn_privileges.CLEAN_REPAIR && isSelected(2)">
                    <div class="operationHeader">
                      保洁维修超时告警
                      <span class="float-right">共{{repairOverHours?.total}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#f25861;color:white;">
                          <th scope="col" class="text-center">房间号</th>
                          <th scope="col" class="text-center">类型</th>
                          <th scope="col" class="text-center">超时类别</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="repairOverHours?.total > 0">
                          <tr *ngFor="let item of repairOverHours?.repair_over_hours | paginate: {itemsPerPage: repairListReqData.page_size,
              currentPage: repairListReqData.page_num, totalItems:repairOverHours?.total, id:'repair' }">
                            <td class="text-center">{{item?.unit_name + ">" + item?.room_num}}</td>
                            <td class="text-center">
                              <span *ngIf="item?.repair_type === 'REPAIR'">维修</span>
                              <span *ngIf="item?.repair_type === 'CLEAN'">保洁</span>
                            <td class="text-center">
                              <span *ngIf="item?.over_type === 'MANUAL'">超时未分配</span>
                              <span *ngIf="item?.over_type === 'UNDONE'">超时未完成</span>
                              <span *ngIf="item?.over_type === 'NOTACCEPTED'">超时未受理</span>
                            </td>
                            <td class="text-center">
                              <button class="text-primary" style="background-color: #ffffff;color:#1368e3 !important;"
                                      *ngIf="(btn_privileges.CLEAN_REPAIR_VIEW && !btn_privileges.CLEAN_REPAIR_HANDLE) || (btn_privileges.CLEAN_REPAIR_HANDLE && item?.repair_type === 'CLEAN')"
                                      (click)="showRepairModal(item);tj('维修/保洁待处理');">
                                <!--<img class="icon_img" src="../../../../assets/img/u935.png">
                              处理-->
                                <i class="fa fa-search"></i>
                                查看
                              </button>
                              <button *ngIf="btn_privileges.CLEAN_REPAIR_HANDLE && item?.repair_type === 'REPAIR'"
                                      class="text-primary" style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="showRepairModal(item, 'handle');tj('维修/保洁待处理');">
                                <img class="icon_img" src="../../../../assets/img/u935.png">
                                处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="repairOverHours?.repair_over_hours?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="repairOverHours?.repair_over_hours?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="repairOverHours?.repair_over_hours?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="repairOverHours?.repair_over_hours?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="repairOverHours?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无维修保洁房间</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls *ngIf="repairInfos?.total !== 0" id="repair"
                                           (pageChange)="repairPageChange($event)" directionLinks="true"
                                           previousLabel="上一页"
                                           nextLabel="下一页" screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--线上收款 3 -->
                  <div class="card card-inverse card-warning" *ngIf="btn_privileges.SCAN_PAYMENT && isSelected(3)">
                    <div class="operationHeader">
                      线上收款
                      <span class="float-right">共{{orderIfRelateResponse?.total}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#f25861;color:white;">
                          <th scope="col" class="text-center">支付时间</th>
                          <th scope="col" class="text-center">支付方式</th>
                          <th scope="col" class="text-center">交易金额</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="orderIfRelateResponse?.total > 0">
                          <tr *ngFor="let info of orderIfRelateResponse?.order_info_dtolist | paginate: {itemsPerPage: orderRealteListReq.page_size,
            currentPage: orderRealteListReq.page_num, totalItems:orderIfRelateResponse?.total, id:'order'}">
                            <td class="text-center">{{info?.create_time | date:'yyyy.MM.dd HH:mm:ss' }}</td>
                            <td class="text-center">
                              <span *ngIf="info?.pay_channel == null">支付类型为空</span>
                              <span *ngIf="info?.pay_channel == 'ALIPAY'">支付宝收款</span>
                              <span *ngIf="info?.pay_channel == 'WEIXIN'">微信收款</span>
                              <span *ngIf="info?.pay_channel == 'CCBPAY'">建行龙支付收款</span>
                            </td>
                            <td class="text-center" style="color:#8d68dc;">{{info?.order_amount}}元</td>
                            <td class="text-center">
                              <button class="text-primary" style="background-color: #ffffff;color:#1368e3 !important;"
                                      *ngIf="!btn_privileges.SWEEP_CODE_PAY_HANDLE" (click)="notPayModalShow(info)">
                                <i class="fa fa-search"></i>
                                查看
                              </button>
                              <button class="text-primary" style="background-color: #ffffff;color:#1368e3 !important;"
                                      *ngIf="btn_privileges.SWEEP_CODE_PAY_HANDLE" (click)="notPayModalShow(info)">
                                <img class="icon_img" src="../../../../assets/img/u935.png">
                                处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="orderIfRelateResponse?.order_info_dtolist?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="orderIfRelateResponse?.order_info_dtolist?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="orderIfRelateResponse?.order_info_dtolist?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="orderIfRelateResponse?.order_info_dtolist?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="orderIfRelateResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3">暂无提醒信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages" style="position: relative"
                         *ngIf="orderIfRelateResponse?.total !== 0">
                      <pagination-controls id="order" (pageChange)="orderPageChange($event)" directionLinks="true"
                                           previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                      <span style="color:#1672E9;cursor: pointer;position: absolute;right: 10px;top: 0px;"
                            (click)="getOrderInfoLogList()">
                        查看记录
                      </span>
                    </div>
                  </div>

                  <!--提醒退房 4 -->
                  <div class="card card-inverse card-danger" *ngIf="btn_privileges.PAYBACK_UNDO_ALARM && isSelected(4)"
                       #paybackUndoAlarmTable>
                    <div class="operationHeader">
                      应退未退房间告警
                      <span class="float-right">共{{alertNoCheckout?.total}}条</span>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-7 text-right pr-0">
                        <label>房间号</label>
                        <div class="d-inline-block ml-2">
                          <input [(ngModel)]="noListReq.room_name" placeholder="请输入房间号">
                        </div>
                      </div>
                      <div class="col-12 col-md-3 text-right pl-0">
                        <label>合同号</label>
                        <div class="d-inline-block ml-2">
                          <input [(ngModel)]="noListReq.contract_num" placeholder="请输入合同号">
                        </div>
                      </div>
                      <div class="col-12 col-md-2">
                        <button (click)="searchNoCheckoutAlertList()" class="btn btn-info " type="button"
                                style="margin: 0rem">
                          <i class="fa fa-search"></i>搜索
                        </button>
                        <button class="btn btn-warning" (click)="downloadNoCheckoutAlertList()"
                                style="margin: 0rem 0rem 0rem 0.4rem;">
                          <i class="fa fa-download"></i>下&nbsp;载
                        </button>
                      </div>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#f25861;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">签约人</th>
                          <th scope="col" class="text-center">房间（数）</th>
                          <th scope="col" class="text-center">应退日期</th>
                          <th scope="col" class="text-center">逾期天数</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="alertNoCheckout?.total > 0">
                          <tr *ngFor="let item of alertNoCheckout?.no_checkout_alert_dto  | paginate: {itemsPerPage: noListReq?.page_size,
              currentPage: noListReq?.page_num, totalItems:alertNoCheckout?.total, id : 'check' }">
                            <td class="text-center">{{item?.contract_num}}</td>
                            <td class="text-center">
                              <ng-container *ngFor="let signatory of alertNoCheckout?.signatory_dtolist">
                                  <span *ngIf="item?.lease_id === signatory?.lease_id">
                                    {{signatory?.signatory}}
                                  </span>
                              </ng-container>
                            </td>
                            <td class="text-center">
                              <ng-container *ngFor="let signatory of alertNoCheckout?.signatory_dtolist">
                                  <span *ngIf="item?.lease_id === signatory?.lease_id">
                                    {{signatory?.room_name_or_numnber}}
                                  </span>
                              </ng-container>
                            </td>
                            <td class="text-center">{{item?.plan_time | date:'yyyy-MM-dd'}}</td>
                            <td class="text-center">{{item?.overdue_day}}</td>
                            <td class="text-center">
                              <span class="btnBgBlue" *ngIf="item?.alert_status == 'WAITING'">未处理</span>
                              <span class="btnBgGreen" *ngIf="item?.alert_status == 'IN_HANDLE'">处理中</span>
                            </td>
                            <td class="text-center">
                              <button
                                *ngIf="btn_privileges.PAYBACK_UNDO_ALARM_VIEW && !btn_privileges.PAYBACK_UNDO_ALARM_HANDLE"
                                class="text-primary" style="background-color: #ffffff;color:#1368e3 !important;"
                                (click)="showNocheckModal(item,null);tj('应退未退房间');">
                                <i class="fa fa-search"></i>
                                查看
                              </button>
                              <button *ngIf="btn_privileges.PAYBACK_UNDO_ALARM_HANDLE" class="text-primary"
                                      style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="showNocheckModal(item,btn_privileges.PAYBACK_UNDO_ALARM_HANDLE);tj('应退未退房间');">
                                <img class="icon_img" src="../../../../assets/img/u935.png">
                                处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="alertNoCheckout?.no_checkout_alert_dto?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="alertNoCheckout?.no_checkout_alert_dto?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="alertNoCheckout?.no_checkout_alert_dto?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="alertNoCheckout?.no_checkout_alert_dto?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="alertNoCheckout?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无租约到期房间</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="check" *ngIf="alertNoCheckout?.total!== 0"
                                           (pageChange)="checkPageChange($event)" directionLinks="true"
                                           previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--水电欠费 5 -->
                  <div class="card card-inverse card-warning"
                       *ngIf="btn_privileges.PREPAY_ARREARAGE_ALARM && isSelected(5)">
                    <div class="operationHeader">
                      预付费账户欠费提醒
                      <span class="float-right">共{{prepayArrearageResponse?.total}}条</span>
                    </div>
                    <div class="card-block">
                      <div class="row">
                        <ng-container *ngIf="prepayArrearageResponse?.total > 0">
                          <ng-container
                            *ngFor="let infoList of trPrepayArrearageList  | paginate: {itemsPerPage: prepayArrearageReq?.page_size,
                       currentPage: prepayArrearageReq?.page_num, totalItems:prepayArrearageResponse?.total,id:'prepayArravange'}">
                            <ng-container *ngIf="infoList?.length === 4">
                              <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                   style="margin:10px 0;padding-bottom:15px;border-bottom:1px solid #e4e4e4;"
                                   *ngFor="let info of infoList">
                                <span>
                                  {{info?.room_name}}
                                  <span class="btn "
                                        style="background-color:#f25861 !important;line-height:16px !important;color:white;margin-top: -3px;cursor: default">
                                    欠{{info?.arrearage_amount}}
                                  </span>
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="infoList?.length === 1">
                              <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                   style="margin:10px 0;padding-bottom:10px;border-bottom:1px solid #e4e4e4;"
                                   *ngFor="let info of infoList">
                                <span>
                                  {{info?.room_name}}
                                  <span class="btn"
                                        style="background-color:#f25861 !important;line-height:16px !important;color:white;margin-top: -3px;cursor: default">
                                    欠{{info?.arrearage_amount}}
                                  </span>
                                </span>
                              </div>
                              <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                   style="margin:10px 0;padding-bottom:15px;border-bottom:1px solid #e4e4e4;"></div>
                              <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                   style="margin:10px 0;padding-bottom:15px;border-bottom:1px solid #e4e4e4;"></div>
                              <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                   style="margin:10px 0;padding-bottom:15px;border-bottom:1px solid #e4e4e4;"></div>
                            </ng-container>
                            <ng-container *ngIf="infoList?.length === 2">
                              <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                   style="margin:10px 0;padding-bottom:15px;border-bottom:1px solid #e4e4e4;"
                                   *ngFor="let info of infoList">
                                <span>
                                  {{info?.room_name}}
                                  <span class="btn "
                                        style="background-color:#f25861 !important;line-height:16px !important;color:white;margin-top: -3px;cursor: default">
                                    欠{{info?.arrearage_amount}}
                                  </span>
                                </span>
                              </div>
                              <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                   style="margin:10px 0;padding-bottom:15px;border-bottom:1px solid #e4e4e4;"></div>
                              <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                   style="margin:10px 0;padding-bottom:15px;border-bottom:1px solid #e4e4e4;"></div>
                            </ng-container>
                            <ng-container *ngIf="infoList?.length === 3">
                              <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                   style="margin:10px 0;padding-bottom:15px;border-bottom:1px solid #e4e4e4;"
                                   *ngFor="let info of infoList">
                                <span>
                                  {{info?.room_name}}
                                  <span class="btn "
                                        style="background-color:#f25861 !important;line-height:16px !important;color:white;margin-top: -3px;cursor: default">
                                    欠{{info?.arrearage_amount}}
                                  </span>
                                </span>
                              </div>
                              <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                   style="margin:10px 0;padding-bottom:15px;border-bottom:1px solid #e4e4e4;"></div>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="trPrepayArrearageList?.length === 1">
                            <div style="height:40px;" class="col-12 text-center"></div>
                            <div style="height:40px;" class="col-12 text-center"></div>
                            <div style="height:40px;" class="col-12 text-center"></div>
                            <div style="height:40px;" class="col-12 text-center"></div>
                          </ng-container>
                          <ng-container *ngIf="trPrepayArrearageList?.length === 2">
                            <div style="height:40px;" class="col-12 text-center"></div>
                            <div style="height:40px;" class="col-12 text-center"></div>
                            <div style="height:40px;" class="col-12 text-center"></div>
                          </ng-container>
                          <ng-container *ngIf="trPrepayArrearageList?.length === 3">
                            <div style="height:40px;" class="col-12 text-center"></div>
                            <div style="height:40px;" class="col-12 text-center"></div>
                          </ng-container>
                          <ng-container *ngIf="trPrepayArrearageList?.length === 4">
                            <div style="height:40px;" class="col-12 text-center"></div>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="prepayArrearageResponse?.total === 0">
                          <div style="height:40px;" class="col-12 text-center">暂无房间预付费账户欠费</div>
                          <div style="height:40px;" class="col-12 text-center"></div>
                          <div style="height:40px;" class="col-12 text-center"></div>
                          <div style="height:40px;" class="col-12 text-center"></div>
                          <div style="height:40px;" class="col-12 text-center"></div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="prepayArravange" *ngIf="prepayArrearageResponse?.total !== 0"
                                           (pageChange)="prepayArrearagePageChange($event)" directionLinks="true"
                                           previousLabel="上一页"
                                           nextLabel="下一页" screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--合同收租 6 -->
                  <div class="card card-inverse card-danger"
                       *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM && isSelected(6)">
                    <div class="operationHeader">
                      合同收租提醒
                      <span class="float-right">共{{alertDue?.total}}条</span>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-7 text-right pr-0">
                        <label>房间号</label>
                        <div class="d-inline-block ml-2">
                          <input [(ngModel)]="dueListReq.room_name" placeholder="请输入房间号">
                        </div>
                      </div>
                      <div class="col-12 col-md-3 text-right pl-0">
                        <label>合同号</label>
                        <div class="d-inline-block ml-2">
                          <input [(ngModel)]="dueListReq.contract_num" placeholder="请输入合同号">
                        </div>
                      </div>
                      <div class="col-12 col-md-2">
                        <button (click)="searchDueList()" class="btn btn-info " type="button" style="margin: 0rem">
                          <i class="fa fa-search"></i>搜索
                        </button>
                        <button class="btn btn-warning" (click)="downloadDueList()"
                                style="margin: 0rem 0rem 0rem 0.4rem;">
                          <i class="fa fa-download"></i>下&nbsp;载
                        </button>
                      </div>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#1572e8;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">签约人</th>
                          <th scope="col" class="text-center">房间（数）</th>
                          <th scope="col" class="text-center">
                            <a nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]>
                              账单类型
                              <i nz-icon nzType="menu" nzTheme="outline"></i>
                            </a>
                            <nz-dropdown-menu #menu="nzDropdownMenu">
                              <ul nz-menu nzSelectable *ngFor="let data of alertBillTypeList">
                                <li nz-menu-item (click)="searchBillType(data.code)">{{data.name}}</li>
                              </ul>
                            </nz-dropdown-menu>
                          </th>
                          <th scope="col" class="text-center">应付日期</th>
                          <th scope="col" class="text-center">金额</th>
                          <th scope="col" class="text-center">逾期天数</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="alertDue?.total > 0">
                          <tr *ngFor="let item of alertDue?.due_alert_dtos  | paginate: {itemsPerPage: dueListReq?.page_size,
              currentPage: dueListReq?.page_num, totalItems:alertDue?.total, id: 'due' }">
                            <td class="text-center">{{item?.contract_num}}</td>
                            <td class="text-center">
                              <ng-container *ngFor="let signatory of alertDue?.signatory_dtolist">
                                  <span *ngIf="item?.lease_id === signatory?.lease_id">
                                    {{signatory?.signatory}}
                                  </span>
                              </ng-container>
                            </td>
                            <td class="text-center">
                              <ng-container *ngFor="let signatory of alertDue?.signatory_dtolist">
                                  <span *ngIf="item?.lease_id === signatory?.lease_id">
                                    {{signatory?.room_name_or_numnber}}
                                  </span>
                              </ng-container>
                            </td>
                            <td class="text-center">{{item?.bill_type}}</td>
                            <td class="text-center">{{item?.plan_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                            <td class="text-center">{{item?.bill_amount}}<span
                              class="text-warning">&nbsp;(待付{{item?.waiting_pay_amount}})</span></td>
                            <td class="text-center">{{item?.overdue_day}}</td>
                            <td class="text-center mw60">
                              <span class="btnBgBlue" *ngIf="item?.alert_status == 'WAITING'">未处理</span>
                              <span class="btnBgGreen" *ngIf="item?.alert_status == 'IN_HANDLE'">处理中</span>
                            </td>
                            <td class="text-center">
                              <button *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE" class="text-primary mw60"
                                      style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="showLeaseGatheringEmailDetail(item.lease_id,item.contract_num,item.room_count,item.bill_type,
                                       item.bill_amount,item.bill_time,item.plan_time, item.project_name, item.alert_id, item.waiting_pay_amount)"
                              >
                                催款
                              </button>
                              <button *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE" class="text-primary mw60"
                                      style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="showGatheringModal(item.lease_id)">
                                收款
                              </button>
                              <button
                                *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE && item.frozen_pwd_completed == 'ALLOWED'"
                                class="mw60"
                                (click)="showFrozenPasswordModal(item.contact_name,item.contract_num,'FROZEN_PWD','RENT_OVERDUE',item.lease_id,item.lease_room_ids, item.alert_id)"
                                [disabled]="!item.rent_overdue"
                                [ngClass]="{'text-secondary': !item.rent_overdue,'btn-primary-wb':item.rent_overdue }">
                                冻结密码
                              </button>
                              <button
                                *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE && item.frozen_pwd_completed == 'FROZEN_PASSWORD'"
                                class="mw60"
                                style="background-color: #ffffff"
                                [disabled]="!item.rent_overdue"
                                [ngClass]="{'text-secondary': !item.rent_overdue,'text-danger':item.rent_overdue }"
                                (click)="showFrozenPasswordModal(item.contact_name,item.contract_num,'UNFROZEN_PWD','RENT_OVERDUE',item.lease_id,item.lease_room_ids,item.alert_id)"
                              >
                                解冻密码
                              </button>
                              <button
                                *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE && item.frozen_pwd_completed == 'WAITING_AUDIT'"
                                class="mw60 text-secondary"
                                style="background-color: #ffffff"
                              >
                                冻结审核中
                              </button>
                              <button
                                *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_VIEW && !btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE"
                                class="text-primary mw60" style="background-color: #ffffff;color:#1368e3 !important;"
                                (click)="showDueModal(item, null);tj('房间催租提醒')">
                                查看
                              </button>
                              <button *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE" class="text-primary mw60"
                                      style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="showDueModal(item,btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE);tj('房间催租提醒')">
                                备注
                              </button>
                            </td>
                          </tr>

                          <ng-container *ngIf="alertDue?.due_alert_dtos?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="alertDue?.due_alert_dtos?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="alertDue?.due_alert_dtos?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="alertDue?.due_alert_dtos?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="alertDue?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无房间催租提醒</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls *ngIf="alertDue?.total !== 0" id="due" (pageChange)="duePageChange($event)"
                                           directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--设备故障 7 -->
                  <div class="card card-inverse card-warning" *ngIf="btn_privileges.ROOM_DEVICE_ALARM && isSelected(7)">
                    <div class="operationHeader">
                      设备故障告警
                      <span class="float-right">共{{alertFault?.total}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#f25861;color:white;">
                          <th scope="col" class="text-center">
                            <a nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]>
                              故障类型
                              <i nz-icon nzType="menu" nzTheme="outline"></i>
                            </a>
                            <nz-dropdown-menu #menu="nzDropdownMenu">
                              <ul nz-menu nzSelectable *ngFor="let data of alertFault?.fault_select_list">
                                <li nz-menu-item (click)="searchName(data.code)">{{data.name}}</li>
                              </ul>
                            </nz-dropdown-menu>

                          </th>
                          <th scope="col" class="text-center">网关标识</th>
                          <th scope="col" class="text-center">故障详细信息</th>
                          <th scope="col" class="text-center">报警时间</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="alertFault?.total > 0">
                          <tr
                            *ngFor="let it of alertFault?.fault_alert_dtos  | paginate: {itemsPerPage: faultListReq?.page_size,
                                     currentPage: faultListReq?.page_num, totalItems:alertFault?.total, id : 'fault' }">
                            <td class="text-center">{{it?.alert_type_name}}</td>
                            <td class="text-center">{{it?.gate_way_name}}</td>

                            <ng-container *ngIf="it?.alert_type == 'GATE_WAY_ERR'">
                              <td class="text-center"><a class="btn btn-link" style="color:#0068bf;"
                                                         (click)="getRelevanceRoom(it.gate_way_uuid);">关联房间</a></td>
                            </ng-container>

                            <ng-container *ngIf="it?.alert_type != 'GATE_WAY_ERR'">
                              <td class="text-center">{{it?.title}}</td>
                            </ng-container>

                            <td class="text-center">{{it?.first_alert_time | date:'yyyy-MM-dd HH:mm'}}</td>
                            <td class="text-center mw60">
                              <span class="btnBgBlue" *ngIf="it?.alert_status == 'WAITING'">未处理</span>
                              <span class="btnBgGreen" *ngIf="it?.alert_status == 'IN_HANDLE'">处理中</span>
                            </td>
                            <td class="text-center">
                              <button
                                *ngIf="btn_privileges.ROOM_DEVICE_ALARM_VIEW && !btn_privileges.ROOM_DEVICE_ALARM_HANDLE"
                                class="text-primary mw60" style="background-color: #ffffff;color:#1368e3 !important;"
                                (click)="showFaultModal(it,null);tj('设备故障');">
                                <i class="fa fa-search"></i>
                                查看
                              </button>
                              <button *ngIf="btn_privileges.ROOM_DEVICE_ALARM_HANDLE" class="text-primary mw60"
                                      style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="showFaultModal(it,btn_privileges.ROOM_DEVICE_ALARM_HANDLE);tj('设备故障');">
                                <img class="icon_img" src="../../../../assets/img/u935.png">
                                处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="alertFault?.fault_alert_dtos?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="alertFault?.fault_alert_dtos?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="alertFault?.fault_alert_dtos?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="alertFault?.fault_alert_dtos?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="alertFault?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="6">暂无设备故障信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="6"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="6"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="6"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="6"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="fault" *ngIf="alertFault?.total !== 0"
                                           (pageChange)="faultPageChange($event)" directionLinks="true"
                                           previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--合同到期 8 -->
                  <div class="card card-inverse card-warning" *ngIf="btn_privileges.LEASE_END && isSelected(8)">
                    <div class="operationHeader">
                      十五天内即将到期合同提醒
                      <span class="float-right">共{{withinTenDaysResponse?.total}}条</span>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-7 text-right pr-0">
                        <label>房间号</label>
                        <div class="d-inline-block ml-2">
                          <input [(ngModel)]="withinTenDaysRequest.room_name" placeholder="请输入房间号">
                        </div>
                      </div>
                      <div class="col-12 col-md-3 text-right pl-0">
                        <label>合同号</label>
                        <div class="d-inline-block ml-2">
                          <input [(ngModel)]="withinTenDaysRequest.contract_num" placeholder="请输入合同号">
                        </div>
                      </div>
                      <div class="col-12 col-md-2">
                        <button (click)="searchWithinTenDaysList()" class="btn btn-info " type="button"
                                style="margin: 0rem">
                          <i class="fa fa-search"></i>搜索
                        </button>
                        <button class="btn btn-warning" (click)="downloadWithinTenDaysList()"
                                style="margin: 0rem 0rem 0rem 0.4rem;">
                          <i class="fa fa-download"></i>下&nbsp;载
                        </button>
                      </div>
                    </div>
                    <div class="card-block workbenchhead pt-1">
                      <!--                      <div class="row ">-->
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">签约人</th>
                          <th scope="col" class="text-center">房间（数）</th>
                          <th scope="col" class="text-center">到期日期</th>
                          <th scope="col" class="text-center">合同剩余有效天数</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="withinTenDaysResponse?.total > 0">
                          <tr
                            *ngFor="let withininfo of withinTenDaysResponse?.no_checkout_alert_dto  | paginate: {itemsPerPage: withinTenDaysRequest?.page_size,
                              currentPage: withinTenDaysRequest?.page_num, totalItems:withinTenDaysResponse?.total,id:'withinTen'}">
                            <td class="text-center">
                              {{withininfo?.contract_num}}
                            </td>
                            <td class="text-center">
                              <ng-container *ngFor="let signatory of withinTenDaysResponse?.signatory_dtolist">
                                  <span *ngIf="withininfo?.lease_id === signatory?.lease_id">
                                    {{signatory?.signatory}}
                                  </span>
                              </ng-container>
                            </td>
                            <td class="text-center">
                              <ng-container *ngFor="let signatory of withinTenDaysResponse?.signatory_dtolist">
                                  <span *ngIf="withininfo?.lease_id === signatory?.lease_id">
                                    {{signatory?.room_name_or_numnber}}
                                  </span>
                              </ng-container>
                            </td>
                            <td class="text-center">
                              {{withininfo?.contract_end | date:'yyyy-MM-dd'}}
                            </td>
                            <td class="text-center">
                                <span class="btn"
                                      style="background-color:#f25861 !important;width:53px !important;line-height:16px !important;color:white;margin-top: -3px;cursor: default;">
                                  {{withininfo?.overdue_day}}
                                  天
                                </span>
                            </td>
                            <td class="text-center">
                              <button *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE"
                                      class="mw60 border-green btn-primary-wb"
                                      style="background-color: #ffffff"
                                      (click)="showLeaseCheckoutEmailDetail(withininfo.lease_id,withininfo.contract_num,withininfo.room_count,withininfo.plan_time, withininfo.project_name, withininfo.alert_id)">
                                发送到期通知函
                              </button>
                              <button *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE"
                                      class="mw60 border-green btn-primary-wb"
                                      style="background-color: #ffffff"
                                      [routerLink]="['/system_manage/signed/pact',withininfo.lease_id]">
                                合同详情
                              </button>
                              <button
                                *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE && withininfo.frozen_pwd_completed_lease == 'ALLOWED'"
                                class="mw60 border-green"
                                style="background-color: #ffffff"
                                (click)="showFrozenPasswordModal(withininfo.contact_name,withininfo.contract_num,'FROZEN_PWD','LEASE_OVERDUE',withininfo.lease_id,null,null)"
                                [disabled]="!withininfo.lease_overdue"
                                [ngClass]="{'text-secondary': !withininfo.lease_overdue,'btn-primary-wb':withininfo.lease_overdue }">
                                冻结密码
                              </button>
                              <button
                                *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE && withininfo.frozen_pwd_completed_lease == 'FROZEN_PASSWORD'"
                                class="mw60 text-danger"
                                style="background-color: #ffffff"
                                (click)="showFrozenPasswordModal(withininfo.contact_name,withininfo.contract_num,'UNFROZEN_PWD','LEASE_OVERDUE',withininfo.lease_id,null,null)"
                              >
                                解冻密码
                              </button>
                              <button
                                *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE && withininfo.frozen_pwd_completed_lease == 'WAITING_AUDIT'"
                                class="mw60 border-green text-secondary"
                                style="background-color: #ffffff" disabled
                              >
                                冻结审核中
                              </button>
                              <button *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE"
                                      class="mw60 border-green btn-primary-wb"
                                      style="background-color: #ffffff"
                                      (click)="showRemarkDetail(withininfo.alert_id)">
                                备注
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="withinTenDaysResponse?.no_checkout_alert_dto?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="withinTenDaysResponse?.no_checkout_alert_dto?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="withinTenDaysResponse?.no_checkout_alert_dto?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="withinTenDaysResponse?.no_checkout_alert_dto?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="withinTenDaysResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无十天内即将到期合同</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                      <!--                      </div>-->
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="withinTen" *ngIf="withinTenDaysResponse?.total !== 0"
                                           (pageChange)="withinTenDaysPageChange($event)" directionLinks="true"
                                           previousLabel="上一页"
                                           nextLabel="下一页" screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--事项审批 9 -->
                  <div class="card card-inverse card-primary" *ngIf="btn_privileges.AUDIT_LIST && isSelected(9)">
                    <div class="operationHeader">
                      待审批事项
                      <span class="float-right">共{{discountList?.data_count}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">

                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">类型</th>
                          <th scope="col" class="text-center">提交时间</th>
                          <th scope="col" class="text-center">提交人</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="discountList?.data_count > 0">
                          <ng-container *ngIf="discountList?.audit_list_dtos.length>0">
                            <tr *ngFor="let item of discountList?.audit_list_dtos  | paginate: {itemsPerPage: discountReq?.page_size,
            currentPage: discountReq.page_num, totalItems:discountList?.data_count, id : 'lease' }">
                              <td class="text-center"
                                  *ngIf="item?.service_type == 'LEASE' || item?.service_type ==='CARPORT'">
                                租约审批
                              </td>
                              <td class="text-center" *ngIf="item?.service_type == 'BOOKING'">预定审批</td>
                              <td class="text-center" *ngIf="item?.service_type == 'ROOMGROUP'">房源审批</td>
                              <td class="text-center" *ngIf="item?.service_type == 'CHANGEROOM'">换房审批</td>
                              <td class="text-center" *ngIf="item?.service_type == 'SHOP_LEASE'">商铺审批</td>
                              <td class="text-center" *ngIf="item?.service_type == 'PROJECT_FILE_AUDIT'">项目配置审核
                              </td>
                              <td class="text-center" *ngIf="item?.service_type == 'INTERNAL_LEASE'">内部免租合同审批
                              </td>
                              <td class="text-center" *ngIf="item?.service_type == 'ROOM_EDIT_AUDIT'">房间信息变更审批
                              </td>

                              <!-- 流程审批 -->
                              <td class="text-center" *ngIf="item?.service_type == 'LEASE_CHANGE'">合同信息变更申请</td>
                              <td class="text-center" *ngIf="item?.service_type == 'LEASE_BILL_CHANGE'">费用调整申请
                              </td>
                              <td class="text-center" *ngIf="item?.service_type == 'ROOM_AVAILABLE_TIME_CHANGE'">
                                房间使用期限调整审批
                              </td>
                              <td class="text-center" *ngIf="item?.service_type == 'ASSET_TRANSFER'">资产转移审批</td>
                              <td class="text-center" *ngIf="item?.service_type == 'FROZEN_PASSWORD'">冻结密码申请</td>
                              <td class="text-center" *ngIf="item?.service_type == 'LOCK_PASSWORD'">密码申请审批</td>
                              <td class="text-center" *ngIf="item?.service_type == 'RENTER_CHECKOUT_DEPOSIT'">
                                住客退押审批
                              </td>
                              <td class="text-center" *ngIf="item?.service_type == 'PROJECT_BASE_PRICE'">
                                项目基础价格变更
                              </td>

                              <td class="text-center">{{item?.create_time | date:'yyyy-MM-dd HH:mm'}}</td>
                              <td class="text-center">{{item?.create_name}}</td>
                              <td class="text-center">
                                <button class="text-primary"
                                        *ngIf="btn_privileges.LEASE_DISCOUNT_HANDLE && item?.service_type == 'LEASE' || item?.service_type == 'BOOKING' || item?.service_type == 'INTERNAL_LEASE'"
                                        style="background-color: #ffffff;color:#1368e3 !important;"
                                        (click)="showLeaseModal(item);tj('租约审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="text-primary"
                                        *ngIf="btn_privileges.LEASE_DISCOUNT_HANDLE && item?.service_type == 'ROOMGROUP'"
                                        style="background-color: #ffffff;color:#1368e3 !important;"
                                        (click)="showRoomGroupModal(item);tj('预定审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="text-primary"
                                        *ngIf="btn_privileges.LEASE_DISCOUNT_HANDLE &&  item?.service_type == 'CARPORT'"
                                        style="background-color: #ffffff;color:#1368e3 !important;"
                                        (click)="showCarportDetailModel(item);tj('房源审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="text-primary"
                                        *ngIf="btn_privileges.LEASE_DISCOUNT_HANDLE &&  item?.service_type == 'CHANGEROOM'"
                                        style="background-color: #ffffff;color:#1368e3 !important;"
                                        (click)="showRoomChangeModal(item);tj('换房审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="text-primary"
                                        *ngIf="btn_privileges.SHOP_LEASE_DISCOUNT_HANDLE &&  item?.service_type == 'SHOP_LEASE'"
                                        style="background-color: #ffffff;color:#1368e3 !important;"
                                        (click)="showShopLeaseModal(item);tj('商铺审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="text-primary"
                                        *ngIf="btn_privileges.PROJECT_CONFIG_AUDIT &&  item?.service_type == 'PROJECT_FILE_AUDIT'"
                                        style="background-color: #ffffff;color:#1368e3 !important;"
                                        (click)="showProjectFileModal(item);tj('项目配置审核')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="text-primary"
                                        *ngIf="btn_privileges.ROOM_EDIT_AUDIT &&  item?.service_type == 'ROOM_EDIT_AUDIT'"
                                        style="background-color: #ffffff;color:#1368e3 !important;"
                                        (click)="showRoomEditModal(item);tj('房间信息变更审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>

                                <!-- 流程相关 -->
                                <button class="btn btn-link"
                                        *ngIf="item?.service_type == 'LEASE_CHANGE'"
                                        (click)="showLeaseChangeLSEditModal(item);tj('合同信息变更审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>

                                <button class="btn btn-link" *ngIf="item?.service_type == 'LEASE_BILL_CHANGE'"
                                        (click)="showLeaseChangeLBEditModal(item);tj('合同费用变更审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="text-primary"
                                        *ngIf="btn_privileges.ASSET_TRANS_AUDIT &&  item?.service_type == 'ASSET_TRANSFER'"
                                        style="background-color: #ffffff;color:#1368e3 !important;"
                                        (click)="showAssetTransModal(item);tj('资产转移审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="btn btn-link"
                                        *ngIf="item?.service_type == 'ROOM_AVAILABLE_TIME_CHANGE'"
                                        (click)="showRoomAvailableDaysChangeEditModal(item);tj('房间使用期限调整审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="btn btn-link"
                                        *ngIf="item?.service_type == 'FROZEN_PASSWORD'"
                                        (click)="showFrozenPasswordFlowModal(item);tj('冻结密码申请')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="btn btn-link"
                                        *ngIf="item?.service_type == 'LOCK_PASSWORD'"
                                        (click)="showLockPasswordModalInfo(item);tj('密码申请审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="btn btn-link"
                                        *ngIf="item?.service_type == 'RENTER_CHECKOUT_DEPOSIT'"
                                        (click)="showRenterCheckoutModealInfo(item);tj('住客退押申请审批')">
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                                <button class="btn btn-link"
                                        *ngIf="item?.service_type == 'PROJECT_BASE_PRICE'"
                                        (click)="getProjectBasePriceInfo(item);tj('项目基础价格变更申请审批')"
                                >
                                  <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                                </button>
                              </td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="discountList?.audit_list_dtos.length==0">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="discountList?.audit_list_dtos?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="discountList?.audit_list_dtos?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="discountList?.audit_list_dtos?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="discountList?.audit_list_dtos?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="discountList?.data_count === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无待审批事项</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls *ngIf="discountList?.data_count !== 0" id="lease"
                                           (pageChange)="leasePageChange($event)" directionLinks="true"
                                           previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--预约看房 10 -->
                  <div class="card card-inverse card-primary" *ngIf="btn_privileges.RESERVE_ROOM && isSelected(10)">
                    <div class="operationHeader">
                      待处理预约看房
                      <span class="float-right">共{{appointListResponse?.total}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#1572e8;color:white;">
                          <th scope="col" class="text-center">提交时间</th>
                          <th scope="col" class="text-center">预约房型</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="appointListResponse?.total > 0">
                          <tr
                            *ngFor="let item of appointListResponse?.appoint_info_dtos  | paginate: {itemsPerPage: appointListRequest?.page_size,
            currentPage: appointListRequest.page_num, totalItems:appointListResponse?.total, id : 'client_appoint_alert' }">
                            <td class="text-center">{{item?.create_time | date:'yyyy-MM-dd HH:mm'}}</td>
                            <td class="text-center">{{item?.room_type_name}}</td>
                            <td class="text-center">
                              <button *ngIf="btn_privileges.RESERVE_ROOM_VIEW && !btn_privileges.RESERVE_ROOM_HANDLE"
                                      class="text-primary" style="background-color: #ffffff;color:#1368e3 !important"
                                      (click)="appointChangeShow(item.id);tj('待处理预约看房');">
                                <i class="fa fa-search"></i>查看
                              </button>
                              <button *ngIf="btn_privileges.RESERVE_ROOM_HANDLE" class="text-primary"
                                      style="background-color: #ffffff;color:#1368e3 !important"
                                      (click)="appointChangeShow(item.id);tj('待处理预约看房');">
                                <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="appointListResponse?.appoint_info_dtos?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="appointListResponse?.appoint_info_dtos?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="appointListResponse?.appoint_info_dtos?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="appointListResponse?.appoint_info_dtos?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="appointListResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3">暂无待处理预约看房</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls *ngIf="appointListResponse?.total !== 0" id="client_appoint_alert"
                                           (pageChange)="changeClientAppointAlert($event)" directionLinks="true"
                                           previousLabel="上一页"
                                           nextLabel="下一页" screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--门锁验收 11 -->
                  <div class="card card-inverse card-primary " *ngIf="btn_privileges.DOOR_CHECK && isSelected(11)">
                    <div class="operationHeader">
                      门锁待验收
                      <span class="float-right">共{{doorLockInfos?.total}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">

                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">房间号</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="doorLockInfos?.total > 0">
                          <tr *ngFor="let item of doorLockInfos?.door_lock_dtos  | paginate: {itemsPerPage: doorLockListReq?.page_size,
            currentPage: doorLockListReq?.page_num, totalItems:doorLockInfos?.total, id:'door' }">
                            <td class="text-center">{{item?.room_name}}</td>

                            <td class="text-center">
                              <button *ngIf="btn_privileges.DOOR_CHECK_HANDLE" class="text-primary"
                                      style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="showDoorModal(item);tj('门锁待验收');">
                                <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="doorLockInfos?.door_lock_dtos?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="doorLockInfos?.door_lock_dtos?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="doorLockInfos?.door_lock_dtos?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="doorLockInfos?.door_lock_dtos?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="doorLockInfos?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="2">暂无待验收门锁</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="2"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="2"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="2"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="2"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls *ngIf="doorLockInfos?.total !== 0" id="door"
                                           (pageChange)="doorPageChange($event)" directionLinks="true"
                                           previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--合同复审 12 -->
                  <div class="card card-inverse card-primary"
                       *ngIf="btn_privileges.LEASE_AUDIT_AGAIN && isSelected(12)">
                    <div class="operationHeader">
                      待财务复审合同
                      <span class="float-right">共{{lfcReviewList?.total}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">

                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">生效日期</th>
                          <th scope="col" class="text-center">提交人</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="lfcReviewList?.total > 0">
                          <tr *ngFor="let item of lfcReviewList?.lease_fcreview_dtolist  | paginate: {itemsPerPage: lfcReviewReq?.page_size,
            currentPage: lfcReviewReq.page_num, totalItems:lfcReviewList?.total, id:'auditAgain'}">
                            <td class="text-center">{{item?.lease_num}}</td>
                            <td class="text-center">{{item?.first_alert_time | date:'yyyy-MM-dd'}}</td>
                            <td class="text-center">{{item?.create_user_name}}</td>
                            <td class="text-center">
                              <button *ngIf="btn_privileges.LEASE_AUDIT_AGAIN_HANDLE" class="text-primary"
                                      style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="showLFCReviewModel(item);tj('待财务复审合同')">
                                <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="lfcReviewList?.lease_fcreview_dtolist?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="lfcReviewList?.lease_fcreview_dtolist?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="lfcReviewList?.lease_fcreview_dtolist?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="lfcReviewList?.lease_fcreview_dtolist?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="lfcReviewList?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无待财务复审合同</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls *ngIf="lfcReviewList?.total !== 0" id="auditAgain"
                                           (pageChange)="lfcPageChange($event)" directionLinks="true"
                                           previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--换房转租 13 -->
                  <div *ngIf="btn_privileges.SUBLEASE_CHANGE_REMIND && isSelected(13)"
                       class="card card-inverse card-warning">
                    <div class="operationHeader">
                      换房转租财务处理
                      <span class="float-right">共{{subleaseChangeRoomRemindListResponse?.total}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">操作日期</th>
                          <th scope="col" class="text-center">类型</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="subleaseChangeRoomRemindListResponse?.total > 0">
                          <tr
                            *ngFor="let sc_remind of subleaseChangeRoomRemindListResponse?.remind_list | paginate: {itemsPerPage: subleaseChangeListReq.pageSize,
          currentPage: subleaseChangeListReq.pageNum, totalItems:subleaseChangeRoomRemindListResponse?.total, id:'subleaseChangeList' }">
                            <td class="text-center">{{sc_remind?.lease_number}}</td>
                            <td class="text-center">{{sc_remind?.create_time | date:'yyyy-MM-dd'}}</td>
                            <td class="text-center">{{sc_remind?.remind_type_name}}</td>
                            <td class="text-center">
                              <button *ngIf="btn_privileges.SUBLEASE_CHANGE_REMIND_HANDLE" class="text-primary"
                                      style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="subleaseChangeShow(subleaseChangeModal,sc_remind)">
                                <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="subleaseChangeRoomRemindListResponse?.remind_list?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="subleaseChangeRoomRemindListResponse?.remind_list?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="subleaseChangeRoomRemindListResponse?.remind_list?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="subleaseChangeRoomRemindListResponse?.remind_list?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="subleaseChangeRoomRemindListResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无提醒信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages" *ngIf="subleaseChangeRoomRemindListResponse?.total > 0">
                      <pagination-controls id="subleaseChangeList" (pageChange)="subleaseChangePageChange($event)"
                                           directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--资产变更 14 -->
                  <div class="card card-inverse card-warning"
                       *ngIf="btn_privileges.ASSET_CHANGE_REMIND && isSelected(14)">
                    <div class="operationHeader">
                      资产变更处理
                      <span class="float-right">共{{assetChangeRemindListResponse?.total}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">资产编号</th>
                          <th scope="col" class="text-center">变更日期</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="assetChangeRemindListResponse?.total > 0">
                          <tr
                            *ngFor="let assetChange of assetChangeRemindListResponse?.asset_change_remind_list  | paginate: {itemsPerPage: assetChangeRemindListReq?.page_size,
                              currentPage: assetChangeRemindListReq?.page_num, totalItems:assetChangeRemindListResponse?.total,id:'assetChange'}">
                            <td class="text-center">{{assetChange?.asset_number}}</td>
                            <td class="text-center">{{assetChange?.change_time | date:"yyyy-MM-dd"}}</td>
                            <td class="text-center">
                              <button *ngIf="btn_privileges.ASSET_CHANGE_REMIND_HANDLE" class="text-primary"
                                      style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="assetChangeShow(assetChange);tj('资产变更提醒');">
                                <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="assetChangeRemindListResponse?.asset_change_remind_list?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="assetChangeRemindListResponse?.asset_change_remind_list?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="assetChangeRemindListResponse?.asset_change_remind_list?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="assetChangeRemindListResponse?.asset_change_remind_list?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="assetChangeRemindListResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3">暂无资产变更提醒信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="assetChange" *ngIf="assetChangeRemindListResponse?.total !== 0"
                                           (pageChange)="getAssetChangeRemindList($event)" directionLinks="true"
                                           previousLabel="上一页"
                                           nextLabel="下一页" screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>


                  <!--延期退款 16 -->
                  <div class="card card-inverse card-primary" *ngIf="btn_privileges.DELAY_REFUND && isSelected(16)">
                    <div class="operationHeader">
                      延期退款
                      <span class="float-right">共{{delayRefundData?.total}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">

                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#21C485;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">签约人</th>
                          <th scope="col" class="text-center">房间(数)</th>
                          <th scope="col" class="text-center">需退款金额</th>
                          <th scope="col" class="text-center">退房日期</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="delayRefundData?.delay_refund_list?.length > 0">
                          <tr
                            *ngFor="let item of delayRefundData?.delay_refund_list  | paginate: {itemsPerPage: delayRefundListReq?.page_size,currentPage: delayRefundListReq?.page_num, totalItems:delayRefundData?.total,id:'delayRefund'}">
                            <td class="text-center">{{item?.lease_num}}</td>
                            <td class="text-center">{{item?.sign_name}}</td>
                            <td class="text-center">{{item?.room}}</td>
                            <td class="text-center">{{item?.refund}}</td>
                            <td class="text-center">{{item?.create_time | date:'yyyy-MM-dd'}}</td>
                            <td class="text-center">
                              <span class="no" *ngIf="item.status === 'WAITING'">未处理</span>
                              <span class="ongoing" *ngIf="item.status !== 'WAITING'">处理中</span>
                            </td>
                            <td class="text-center">
                              <button class="text-primary" style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="showRefundfun(item)" *ngIf="btn_privileges.DELAY_REFUND_HANDLE">
                                <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="delayRefundData?.delay_refund_list?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="delayRefundData?.delay_refund_list?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="delayRefundData?.delay_refund_list?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="delayRefundData?.delay_refund_list?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="2"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="delayRefundData?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无延期退款信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="delayRefund" *ngIf="delayRefundData?.total !== 0"
                                           (pageChange)="delayRefundPageChange($event)" directionLinks="true"
                                           previousLabel="上一页"
                                           nextLabel="下一页" screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page">
                      </pagination-controls>
                    </div>
                  </div>

                  <!--待签合同 17 -->
                  <div class="card card-inverse card-primary" *ngIf="btn_privileges.WAIT_SIGN_LEASE && isSelected(17)">
                    <div class="operationHeader">
                      待签合同
                      <span class="float-right">共{{waitSignListResponse?.total}}条</span>
                    </div>
                    <div class="card-block" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">合同租期</th>
                          <th scope="col" class="text-center">签约人</th>
                          <th scope="col" class="text-center">联系人</th>
                          <th scope="col" class="text-center">联系电话</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">签约倒计时</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="waitSignListResponse?.total > 0">
                          <tr
                            *ngFor="let item of waitSignListResponse?.wait_sign_contract_dtolist  | paginate: {itemsPerPage: waitSignListReq?.page_size,
                              currentPage: waitSignListReq?.page_num, totalItems:waitSignListResponse?.total,id:'waitSign'}">
                            <td class="text-center">{{item?.contract_no}}</td>
                            <td class="text-center">{{item?.start_time | date:"yyyy-MM-dd"}}
                              -{{item?.end_time |
                                date:"yyyy-MM-dd"}}</td>
                            <td class="text-center">{{item?.sign_name}}</td>
                            <td class="text-center">{{item?.contract_name}}</td>
                            <td class="text-center">{{item?.contract_tel}}</td>
                            <td class="text-center"><span class="btnBgBlue">待签署</span></td>
                            <td class="text-center" [ngClass]="{'text-danger':item.count_down_day<=3}">
                              {{item?.count_down_day}}天
                            </td>
                            <td class="text-center">
                              <button class="text-primary" style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="goLease(item.lease_id);">
                                <i class="fa fa-search"></i> 查看
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="waitSignListResponse?.wait_sign_contract_dtolist?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="waitSignListResponse?.wait_sign_contract_dtolist?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="waitSignListResponse?.wait_sign_contract_dtolist?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="waitSignListResponse?.wait_sign_contract_dtolist?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="waitSignListResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8">暂无待签合同信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="waitSign" *ngIf="waitSignListResponse?.total !== 0"
                                           (pageChange)="getWaitSignList($event)" directionLinks="true"
                                           previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--资产盘点 18 -->
                  <div class="card card-inverse card-warning"
                       *ngIf="btn_privileges.ASSET_CHECK_REMIND && isSelected(18)">
                    <div class="operationHeader">
                      资产盘点
                      <span class="float-right">共{{assetCheckRemindResponse?.total}}条</span>
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">盘点编号</th>
                          <th scope="col" class="text-center">盘点方案名称</th>
                          <th scope="col" class="text-center">盘点资产项数</th>
                          <th scope="col" class="text-center">盘点负责人</th>
                          <th scope="col" class="text-center">盘点任务周期</th>
                          <th scope="col" class="text-center">距离任务到期天数</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody style="color:#f25861;">
                        <ng-container *ngIf="assetCheckRemindResponse?.total > 0">
                          <tr
                            *ngFor="let assetCheck of assetCheckRemindResponse?.asset_check_list  | paginate: {itemsPerPage: assetCheckRemindReq?.page_size,
                              currentPage: assetCheckRemindReq?.page_num, totalItems:assetCheckRemindResponse?.total,id:'assetCheck'}">
                            <td class="text-center">{{assetCheck?.check_number}}</td>
                            <td class="text-center">{{assetCheck?.scheme_name}}</td>
                            <td class="text-center">{{assetCheck?.asset_num}}</td>
                            <td class="text-center">{{assetCheck?.check_principal}}</td>
                            <td class="text-center">{{assetCheck?.start_time |
                              date:'yyyy.MM.dd'}}-{{assetCheck?.end_time | date:'yyyy.MM.dd'}}</td>
                            <td class="text-center">
                              <label style="color: red;"
                                     *ngIf="assetCheck.effect_days < 10">{{assetCheck?.effect_days}}</label>
                              <label style="color: black;"
                                     *ngIf="assetCheck.effect_days >= 10">{{assetCheck?.effect_days}}</label>
                            </td>
                            <td class="text-center">
                                <span *ngIf="'WAIT_START' == assetCheck.check_status"
                                      class="badge badge-pill badge-blue">待启动</span>
                              <span *ngIf="'CHECKING' == assetCheck.check_status"
                                    class="badge badge-pill badge-orange">盘点中</span>
                            </td>
                            <td class="text-center">
                              <button *ngIf="btn_privileges.ASSET_CHECK_VIEW" class="text-primary"
                                      style="background-color: #ffffff;color:#1368e3 !important;"
                                      [routerLink]="['/system_manage/asset/check/detail',assetCheck.id]">
                                <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;查看
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="assetCheckRemindResponse?.asset_check_list?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="assetCheckRemindResponse?.asset_check_list?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="assetCheckRemindResponse?.asset_check_list?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="assetCheckRemindResponse?.asset_check_list?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="3"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="assetCheckRemindResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3">暂无资产盘点提醒信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="3"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="assetCheck" *ngIf="assetCheckRemindResponse?.total !== 0"
                                           (pageChange)="getAssetCheckRemindList($event)" directionLinks="true"
                                           previousLabel="上一页"
                                           nextLabel="下一页" screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>


                  <!--退房结算 20 -->
                  <div class="card card-inverse card-primary"
                       *ngIf="btn_privileges.CHECKOUT_NOT_FINISH && isSelected(20)">
                    <div class="operationHeader">
                      退房结算
                      <span class="float-right">共{{checkoutNotFinishResponse?.total}}条</span>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-7 text-right pr-0">
                        <label>房间号</label>
                        <div class="d-inline-block ml-2">
                          <input [(ngModel)]="checkoutFinanceUncheckListRequest.room_name" placeholder="请输入房间号">
                        </div>
                      </div>
                      <div class="col-12 col-md-3 text-right pl-0">
                        <label>合同号</label>
                        <div class="d-inline-block ml-2">
                          <input [(ngModel)]="checkoutFinanceUncheckListRequest.contract_num"
                                 placeholder="请输入合同号">
                        </div>
                      </div>
                      <div class="col-12 col-md-2 align-top">
                        <button (click)="searchCheckoutFinanceUncheckList()" class="btn btn-info" type="button">
                          <i class="fa fa-search"></i>搜索
                        </button>
                        <button class="btn btn-warning" (click)="downloadCheckoutFinanceUncheckList()">
                          <i class="fa fa-download"></i>下&nbsp;载
                        </button>
                      </div>
                    </div>
                    <div class="card-block" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">签约人</th>
                          <th scope="col" class="text-center">退房日期</th>
                          <th scope="col" class="text-center">未结算房间（数）</th>
                          <th scope="col" class="text-center">挂账时长（天）</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="checkoutNotFinishResponse?.total > 0">
                          <tr *ngFor="let item of checkoutNotFinishResponse?.checkout_not_finish_dtolist  | paginate:
                          {itemsPerPage: checkoutFinanceUncheckListRequest?.page_size,
                              currentPage: checkoutFinanceUncheckListRequest?.page_num,
                              totalItems:checkoutNotFinishResponse?.total,
                              id:'checkoutNotFinish'}">
                            <td class="text-center">{{item?.contract_num}}</td>
                            <td class="text-center">{{item?.subject_name}}</td>
                            <td class="text-center">{{item?.checkout_time | date:'yyyy.MM.dd'}}</td>
                            <td class="text-center">
                              <ng-container *ngIf="item.room_num>1">{{item.room_num}}间</ng-container>
                              <ng-container *ngIf="item.room_num==1">{{item.room_name}}</ng-container>
                            </td>
                            <td class="text-center">{{item?.checkout_between_day}}</td>
                            <td class="text-center">
                              <span *ngIf="'WAITING' == item.status"
                                    class="badge badge-pill badge-success">未处理</span>
                              <span *ngIf="'PROCESSING' == item.status"
                                    class="badge badge-pill badge-warning">处理中</span>
                            </td>
                            <td class="text-center">
                              <button class="btn btn-link"
                                      [routerLink]="['/system_manage/signed/checkout/finance/roomselect', item.lease_id]">
                                <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="checkoutNotFinishResponse?.checkout_not_finish_dtolist?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="checkoutNotFinishResponse?.checkout_not_finish_dtolist?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="checkoutNotFinishResponse?.checkout_not_finish_dtolist?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="checkoutNotFinishResponse?.checkout_not_finish_dtolist?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="checkoutNotFinishResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="checkoutNotFinish" *ngIf="checkoutNotFinishResponse?.total !== 0"
                                           (pageChange)="getCheckoutNotFinishList($event)"
                                           directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--退房签字 21 -->
                  <div class="card card-inverse card-primary"
                       *ngIf="btn_privileges.CHECKOUT_FINANCE_NOT_FINISH && isSelected(21)">
                    <div class="operationHeader">
                      退房签字
                      <span class="float-right">共{{checkoutFinanceNotFinishResponse?.total}}条</span>
                    </div>
                    <div class="card-block" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">签约人</th>
                          <th scope="col" class="text-center">退房单号</th>
                          <th scope="col" class="text-center">房间数</th>
                          <th scope="col" class="text-center">
                            <p>费用结算合计</p>
                            <p class="text-sm">（含预估滞纳金）</p>
                          </th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">剩余天数</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="checkoutFinanceNotFinishResponse?.total > 0">
                          <tr *ngFor="let item of checkoutFinanceNotFinishResponse?.checkout_finance_not_finish_dtos  | paginate:
                          {itemsPerPage: checkoutFinanceFinanceNotFinishReq?.page_size,
                              currentPage: checkoutFinanceFinanceNotFinishReq?.page_num,
                              totalItems:checkoutFinanceNotFinishResponse?.total,
                              id:'checkoutFinanceNotFinish'}">
                            <td class="text-center">{{item?.contract_num}}</td>
                            <td class="text-center">{{item?.subject_name}}</td>
                            <td class="text-center">{{item?.checkout_record_num}}</td>
                            <td class="text-center">
                              <ng-container *ngIf="item.room_num>1">{{item.room_num}}间</ng-container>
                              <ng-container *ngIf="item.room_num==1">{{item.room_name}}</ng-container>
                            </td>
                            <td class="text-center text-danger">{{item?.checkout_record_total}}</td>
                            <td class="text-center">
                              <span class="text-warning" *ngIf="item.status=='1'">待客户签字确认</span>
                              <span class="text-success" *ngIf="item.status=='2'">已签字，请跟进财务退款</span>
                              <span class="text-success" *ngIf="item.status=='3'">已签字，请处理收款</span>
                            </td>
                            <td class="text-center">
                              <span class="text-warning"
                                    *ngIf="item.status=='1'">{{item.checkout_confirm_end_time_new}}</span>
                              <span class="text-warning" *ngIf="item.status=='2'|| item.status=='3'">-</span>
                            </td>

                            <td class="text-center">
                              <button class="btn btn-link"
                                      [routerLink]="['/system_manage/signed/pact',item.lease_id]">
                                <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                              </button>
                            </td>
                          </tr>
                          <ng-container
                            *ngIf="checkoutFinanceNotFinishResponse?.checkout_finance_not_finish_dtos?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container
                            *ngIf="checkoutFinanceNotFinishResponse?.checkout_finance_not_finish_dtos?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container
                            *ngIf="checkoutFinanceNotFinishResponse?.checkout_finance_not_finish_dtos?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container
                            *ngIf="checkoutFinanceNotFinishResponse?.checkout_finance_not_finish_dtos?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="checkoutFinanceNotFinishResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8">暂无信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="checkoutFinanceNotFinish"
                                           *ngIf="checkoutFinanceNotFinishResponse?.total !== 0"
                                           (pageChange)="getCheckoutFinanceNotFinish($event)"
                                           directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--入住限制 19 -->
                  <div class="card card-inverse card-primary" *ngIf="btn_privileges.CHECK_IN_LIMIT && isSelected(19)">
                    <div class="operationHeader">
                      入住限制
                      <span class="float-right">共{{checkInLimitResponse?.total}}条</span>
                    </div>
                    <div class="card-block" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">签约人</th>
                          <th scope="col" class="text-center">欠费方</th>
                          <th scope="col" class="text-center">账单类型</th>
                          <th scope="col" class="text-center">账单周期</th>
                          <th scope="col" class="text-center">应付日期</th>
                          <th scope="col" class="text-center">宽限天数</th>
                          <th scope="col" class="text-center">限制事件内容</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">距离执行天数</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="checkInLimitResponse?.total > 0">
                          <tr
                            *ngFor="let item of checkInLimitResponse?.checkin_limit_lease_dtolist  | paginate: {itemsPerPage: checkInLimitReq?.page_size,
                              currentPage: checkInLimitReq?.page_num, totalItems:checkInLimitResponse?.total,id:'checkinLimit'}">
                            <td class="text-center">{{item?.contract_num}}</td>
                            <td class="text-center">{{item?.name}}</td>
                            <td class="text-center">{{item?.owe_name}}</td>
                            <td class="text-center">{{item?.bill_type}}</td>
                            <td class="text-center">{{item?.bill_start_time |
                              date:'yyyy.MM.dd'}}-{{item?.bill_end_time | date:'yyyy.MM.dd'}}</td>
                            <td class="text-center">{{item?.plan_pay_time | date:'yyyy.MM.dd'}}</td>
                            <td class="text-center">{{item?.room_available_days}}</td>
                            <td class="text-center">{{item?.limit_content}}</td>
                            <td class="text-center">
                                <span *ngIf="'WAIT_PERFORM' == item?.limit_status"
                                      class="badge badge-pill badge-blue">待执行</span>
                              <span *ngIf="'HAS_PERFORM' == item?.limit_status"
                                    class="badge badge-pill badge-orange">已执行</span>
                            </td>
                            <td class="text-center">{{item?.limit_days}}</td>
                            <td class="text-center">

                              <button class="text-success" *ngIf="item?.limit_content=='断水电作废门锁密码'"
                                      style="background-color: #ffffff;"
                                      (click)="showLimitDetail(item.lease_room_id);">
                                <i class="fa fa-search"></i>
                                查看
                              </button>

                              <button class="text-primary" style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="goLease(item.lease_id);">
                                <img class="icon_img" src="../../../../assets/img/u935.png">
                                处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="checkInLimitResponse?.checkin_limit_lease_dtolist?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="checkInLimitResponse?.checkin_limit_lease_dtolist?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="checkInLimitResponse?.checkin_limit_lease_dtolist?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="checkInLimitResponse?.checkin_limit_lease_dtolist?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="checkInLimitResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8">暂无待签合同信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="checkinLimit" *ngIf="checkInLimitResponse?.total !== 0"
                                           (pageChange)="getCheckInLimit($event)" directionLinks="true"
                                           previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>
                  <!--线下收款 22  -->
                  <div class="card card-inverse card-primary" *ngIf="btn_privileges.CHECK_IN_LIMIT && isSelected(22)">
                    <div class="operationHeader">
                      线下收款
                      <span class="float-right">共{{noAuditListResponse?.total}}条</span>
                    </div>
                    <div class="card-block" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">提交时间</th>
                          <th scope="col" class="text-center">提交人</th>
                          <th scope="col" class="text-center">付款人名称</th>
                          <th scope="col" class="text-center">收款方式</th>
                          <th scope="col" class="text-center">收款金额</th>
                          <th scope="col" class="text-center">状态</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="noAuditListResponse?.total > 0">
                          <tr
                            *ngFor="let item of noAuditListResponse?.no_audit_transfer_list  | paginate: {itemsPerPage: noAuditList?.page_size,
                          currentPage: noAuditList?.page_num, totalItems:noAuditListResponse?.total,id:'checkinOffline'}">
                            <td class="text-center">{{item?.create_time}}</td>
                            <td class="text-center">{{item?.create_user}}</td>
                            <td class="text-center">{{item?.pay_user}}</td>
                            <td class="text-center">{{item?.pay_type}}</td>
                            <td class="text-center">{{item?.pay_amount}}</td>
                            <td class="text-center"><span class="text-warning">待财务人员核销</span></td>

                          </tr>
                          <ng-container *ngIf="noAuditListResponse?.no_audit_transfer_list?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="noAuditListResponse?.no_audit_transfer_list?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="noAuditListResponse?.no_audit_transfer_list?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="noAuditListResponse?.no_audit_transfer_list?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="8"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="noAuditListResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8">暂无待签合同信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="8"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="checkinOffline" *ngIf="noAuditListResponse?.total !== 0"
                                           (pageChange)="getCheckInOffline($event)" directionLinks="true"
                                           previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--保洁任务 23 -->
                  <div class="card card-inverse card-warning"
                       *ngIf="btn_privileges.WORKSPACE_CLEANTASK_REMIND && isSelected(23)">
                    <div class="operationHeader">
                      保洁任务
                    </div>
                    <div class="card-block workbenchhead" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">提醒日期</th>
                          <th scope="col" class="text-center">保洁房间数</th>
                          <th scope="col" class="text-center">进度</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-center">{{_now|date:'yyyy.MM.dd'}}</td>
                          <td class="text-center">{{cleanTaskListResponse?.total}}</td>
                          <td class="text-center text-danger">待上传完成结果</td>
                          <td class="text-center">
                            <button *ngIf="btn_privileges.WORKSPACE_CLEANTASK_LIST_VIEW" class="text-primary"
                                    style="background-color: #ffffff;color:#1368e3 !important;"
                                    [routerLink]="['/system_manage/workspace/cleantask']">
                              <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;查看
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!--退宿结算 24 -->
                  <div class="card card-inverse card-primary"
                       *ngIf="btn_privileges.RENTER_CHECKOUT_NOT_FINISH && isSelected(24)">
                    <div class="operationHeader">
                      退宿结算
                      <span class="float-right">共{{renterCheckoutNotFinishResponse?.total}}条</span>
                    </div>
                    <div class="card-block" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">房间号</th>
                          <th scope="col" class="text-center">住客姓名</th>
                          <th scope="col" class="text-center">退宿日期</th>
                          <th scope="col" class="text-center">挂账时长（天）</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="renterCheckoutNotFinishResponse?.total > 0">
                          <tr *ngFor="let item of renterCheckoutNotFinishResponse?.renter_checkout_wait_finance_confirm_dtos  | paginate:
                          {itemsPerPage: renterCheckoutNotFinishReq?.page_size,
                              currentPage: renterCheckoutNotFinishReq?.page_num,
                              totalItems:renterCheckoutNotFinishResponse?.total,
                              id:'renterCheckoutNotFinish'}">
                            <td class="text-center">{{item?.contract_no}}</td>
                            <td class="text-center">{{item?.room_name}}</td>
                            <td class="text-center">{{item?.name}}</td>
                            <td class="text-center">{{item?.checkout_time | date:'yyyy.MM.dd'}}</td>
                            <td class="text-center">{{item?.time}}</td>
                            <td class="text-center">
                              <span class="badge badge-pill badge-success">未处理</span>
                            </td>
                            <td class="text-center">
                              <button class="btn btn-link"
                                      [routerLink]="['/system_manage/checkoutrenter/checkout/view']"
                                      [queryParams]="{checkout_date: item?.checkout_time, info_ids: item?.renter_id, renter_id: item?.renter_id}">
                                <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                              </button>
                            </td>
                          </tr>
                          <ng-container
                            *ngIf="renterCheckoutNotFinishResponse?.renter_checkout_wait_finance_confirm_dtos?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container
                            *ngIf="renterCheckoutNotFinishResponse?.renter_checkout_wait_finance_confirm_dtos?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container
                            *ngIf="renterCheckoutNotFinishResponse?.renter_checkout_wait_finance_confirm_dtos?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container
                            *ngIf="renterCheckoutNotFinishResponse?.renter_checkout_wait_finance_confirm_dtos?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="renterCheckoutNotFinishResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="renterCheckoutNotFinish"
                                           *ngIf="renterCheckoutNotFinishResponse?.total !== 0"
                                           (pageChange)="getRenterCheckoutNotFinishList($event)"
                                           directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--退宿结算完成 25 -->
                  <div class="card card-inverse card-primary"
                       *ngIf="btn_privileges.RENTER_CHECKOUT_FINANCE_NOT_FINISH && isSelected(25)">
                    <div class="operationHeader">
                      退宿签字
                      <span class="float-right">共{{renterCheckoutFinanceNotFinishResponse?.total}}条</span>
                    </div>
                    <div class="card-block" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">房间号</th>
                          <th scope="col" class="text-center">住客姓名</th>
                          <th scope="col" class="text-center">退宿日期</th>
                          <th scope="col" class="text-center">退宿单号</th>
                          <th scope="col" class="text-center">应收/应退总计</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">剩余天数</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="renterCheckoutFinanceNotFinishResponse?.total > 0">
                          <tr *ngFor="let item of renterCheckoutFinanceNotFinishResponse?.list  | paginate:
                          {itemsPerPage: renterCheckoutFinanceNotFinishReq?.page_size,
                              currentPage: renterCheckoutFinanceNotFinishReq?.page_num,
                              totalItems:renterCheckoutFinanceNotFinishResponse?.total,
                              id:'renterCheckoutFinanceNotFinish'}">
                            <td class="text-center">{{item?.contract_no}}</td>
                            <td class="text-center">{{item?.room_name}}</td>
                            <td class="text-center">{{item?.name}}</td>
                            <td class="text-center">{{item?.checkout_time | date:'yyyy.MM.dd'}}</td>
                            <td class="text-center">{{item?.record_no}}</td>
                            <td class="text-center">{{item?.total}}</td>
                            <td class="text-center">
                              <span *ngIf="item?.status=='WAIT_SIGN'"
                                    class="badge badge-pill badge-warning">待客户签字确认</span>
                              <span *ngIf="item?.status=='WAIT_PAY'"
                                    class="badge badge-pill badge-success">已签字，待收款</span>
                              <span *ngIf="item?.status=='WAIT_REFUND'"
                                    class="badge badge-pill badge-success">已签字，待退款</span>
                            </td>
                            <td class="text-center" *ngIf="item?.status=='WAIT_SIGN'">{{calCountDown(item?.time)}}</td>
                            <td class="text-center" *ngIf="item?.status!='WAIT_SIGN'">-</td>
                            <td class="text-center">

                              <button class="btn btn-link" *ngIf="item?.status=='WAIT_SIGN'"
                                      (click)="cancelFinanceCheckout(item?.record_id)"
                              >
                                取消
                              </button>
                              <button class="btn btn-link"
                                      [routerLink]="['/system_manage/signed/pact', item.lease_id]">
                                <img class="icon_img" src="../../../../assets/img/u935.png">&nbsp;处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="renterCheckoutFinanceNotFinishResponse?.list?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="renterCheckoutFinanceNotFinishResponse?.list?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="renterCheckoutFinanceNotFinishResponse?.list?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="renterCheckoutFinanceNotFinishResponse?.list?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="renterCheckoutFinanceNotFinishResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="renterCheckoutFinanceNotFinish"
                                           *ngIf="renterCheckoutFinanceNotFinishResponse?.total !== 0"
                                           (pageChange)="getRenterCheckoutFinanceNotFinishList($event)"
                                           directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--提醒退宿 26 -->
                  <div class="card card-inverse card-primary"
                       *ngIf="btn_privileges.RENTER_CHECKOUT_REMIND && isSelected(26)">
                    <div class="operationHeader">
                      提醒退宿
                      <span class="float-right">共{{renterCheckoutRemindListResponse?.total}}条</span>
                    </div>
                    <div class="card-block" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">房间号</th>
                          <th scope="col" class="text-center">住客姓名</th>
                          <th scope="col" class="text-center">退宿日期</th>
                          <th scope="col" class="text-center">申请日期</th>
                          <th scope="col" class="text-center">距离退宿日期（天）</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="renterCheckoutRemindListResponse?.total > 0">
                          <tr *ngFor="let item of renterCheckoutRemindListResponse?.list  | paginate:
                          {itemsPerPage: renterCheckoutRemindListReq?.page_size,
                              currentPage: renterCheckoutRemindListReq?.page_num,
                              totalItems:renterCheckoutRemindListResponse?.total,
                              id:'renterCheckoutRemind'}">
                            <td class="text-center">{{item?.room_name}}</td>
                            <td class="text-center">{{item?.name}}</td>
                            <td class="text-center">{{item?.checkout_time | date:'yyyy.MM.dd'}}</td>
                            <td class="text-center">{{item?.create_time | date:'yyyy.MM.dd'}}</td>
                            <td class="text-center">{{item?.time}}</td>
                            <td class="text-center">
                              <button class="btn btn-link"
                                      *ngIf="btn_privileges?.RENTER_CHECKOUT_REMIND_CANCEL_BTN && item?.time>=0"
                                      (click)="cancelRenterCheckoutRequest(item?.record_id)"
                              >
                                &nbsp;取消
                              </button>
                              <button class="btn btn-link"
                                      *ngIf="btn_privileges?.RENTER_CHECKOUT_REMIND_AGREE_BTN && item?.time == 0"
                                      (click)="agreeRenterCheckoutRequest(item?.record_id)"
                              >
                                &nbsp;同意
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="renterCheckoutRemindListResponse?.list?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="renterCheckoutRemindListResponse?.list?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="renterCheckoutRemindListResponse?.list?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="renterCheckoutRemindListResponse?.list?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="renterCheckoutRemindListResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="renterCheckoutRemind"
                                           *ngIf="renterCheckoutRemindListResponse?.total !== 0"
                                           (pageChange)="getRenterCheckoutRemindList($event)"
                                           directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>

                  <!--住客自动退押提醒 27 -->
                  <div class="card card-inverse card-primary"
                       *ngIf="btn_privileges.RENTER_CHECKOUT_TRANSFER_ERROR_REMIND && isSelected(27)">
                    <div class="operationHeader">
                      住客自动退押提醒
                      <span class="float-right">
                        <span
                          class="mr-5">当天预计退押总计{{renterCheckoutTransferErrorRemindListResponse?.total_amount}}
                          元</span>
                        共{{renterCheckoutTransferErrorRemindListResponse?.total}}条</span>
                    </div>
                    <div class="card-block" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">合同号</th>
                          <th scope="col" class="text-center">房间号</th>
                          <th scope="col" class="text-center">住客姓名</th>
                          <th scope="col" class="text-center">退宿单号</th>
                          <th scope="col" class="text-center">退押金额（元）</th>
                          <th scope="col" class="text-center">收款方式</th>
                          <th scope="col" class="text-center">应退日期</th>
                          <th scope="col" class="text-center">逾期天数</th>
                          <th scope="col" class="text-center">失败原因</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="renterCheckoutTransferErrorRemindListResponse?.total > 0">
                          <tr *ngFor="let item of renterCheckoutTransferErrorRemindListResponse?.list  | paginate:
                          {itemsPerPage: renterCheckoutTransferErrorRemindListReq?.page_size,
                              currentPage: renterCheckoutTransferErrorRemindListReq?.page_num,
                              totalItems:renterCheckoutTransferErrorRemindListResponse?.total,
                              id:'renterCheckoutTransferErrorRemind'}">
                            <td class="text-center">{{item?.contact_num}}</td>
                            <td class="text-center">{{item?.room_name}}</td>
                            <td class="text-center">{{item?.name}}</td>
                            <td class="text-center">{{item?.checkout_num}}</td>
                            <td class="text-center">{{item?.amount}}</td>
                            <td class="text-center">{{item?.transfer_type}}</td>
                            <td class="text-center">{{item?.transfer_time | date:'yyyy.MM.dd'}}</td>
                            <td class="text-center">{{item?.time}}</td>
                            <td class="text-center">{{item?.msg}}</td>
                            <td class="text-center">
                              <button class="btn btn-link"
                                      *ngIf="btn_privileges.RENTER_CHECKOUT_TRANSFER_ERROR_REMIND_DEAL_BTN"
                                      (click)="dealRenterCheckoutError(item)"
                              >
                                &nbsp;处理
                              </button>
                              <button class="btn btn-link"
                                      [routerLink]="'/system_manage/signed/renter/checkout/record/detail/'+item?.renter_checkout_id"
                              >
                                &nbsp;查看
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="renterCheckoutTransferErrorRemindListResponse?.list?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="renterCheckoutTransferErrorRemindListResponse?.list?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="renterCheckoutTransferErrorRemindListResponse?.list?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="renterCheckoutTransferErrorRemindListResponse?.list?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="renterCheckoutTransferErrorRemindListResponse?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="renterCheckoutTransferErrorRemind"
                                           *ngIf="renterCheckoutTransferErrorRemindListResponse?.total !== 0"
                                           (pageChange)="getRenterCheckoutTransferErrorRemindList($event)"
                                           directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>


                  <!--消防组件状态预警 28 -->
                  <div class="card card-inverse card-primary"
                       *ngIf="btn_privileges.FIRE_FIGHTING_CS && isSelected(28)">
                    <div class="operationHeader">
                      消防报警
                      <span class="float-right">共{{fireAlertListResp?.total}}条</span>
                    </div>
                    <div class="card-block" style="padding-top: 10px;">
                      <table class="table workbenchbody">
                        <thead>
                        <tr style="background-color:#31ce36;color:white;">
                          <th scope="col" class="text-center">系统</th>
                          <th scope="col" class="text-center">部件类型</th>
                          <th scope="col" class="text-center">描述</th>
                          <th scope="col" class="text-center">事件</th>
                          <th scope="col" class="text-center">时间</th>
                          <th scope="col" class="text-center">状态</th>
                          <th scope="col" class="text-center">操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="fireAlertListResp?.total > 0">
                          <tr *ngFor="let item of fireAlertListResp?.dto_list  | paginate:
                          {itemsPerPage: fireAlertListReq?.page_size,
                              currentPage: fireAlertListReq?.page_num,
                              totalItems:fireAlertListResp?.total,
                              id:'fireAlert'}">
                            <td class="text-center">{{item?.system_type_name}}</td>
                            <td class="text-center">{{item?.component_type_str}}</td>
                            <td class="text-center">{{item?.desc_str}}</td>
                            <td class="text-center font-weight-bold">
                              <span *ngIf="item.b0" class="text-green ">正常</span>
                              <span *ngIf="item.b1" class="text-danger">火警</span>
                              <span *ngIf="item.b2" class="text-warning">故障</span>
                              <span *ngIf="item.b3" class="text-green">屏蔽</span>
                              <span *ngIf="item.b4" class="text-danger">监管</span>
                              <span *ngIf="item.b5" class="text-danger">启动</span>
                              <span *ngIf="item.b6" class="text-danger">反馈</span>
                              <span *ngIf="item.b7" class="text-warning">延时</span>
                              <span *ngIf="item.b8" class="text-warning">电源故障</span>
                            </td>
                            <td class="text-center">{{item?.time | date:'yyyy.MM.dd HH:mm:ss'}}</td>
                            <td class="text-center mw60">
                              <span class="btnBgBlue" *ngIf="item?.alert_status == 'WAITING'">未处理</span>
                              <span class="btnBgGreen" *ngIf="item?.alert_status == 'IN_HANDLE'">处理中</span>
                            </td>
                            <td class="text-center">
                              <button
                                *ngIf="btn_privileges.FIRE_FIGHTING_CS && !btn_privileges.FIRE_FIGHTING_CS_HANDLE"
                                class="text-primary mw60" style="background-color: #ffffff;color:#1368e3 !important;"
                                (click)="showFaultModal(item,null);tj('消防报警');">
                                <i class="fa fa-search"></i>
                                查看
                              </button>
                              <button *ngIf="btn_privileges.FIRE_FIGHTING_CS_HANDLE" class="text-primary mw60"
                                      style="background-color: #ffffff;color:#1368e3 !important;"
                                      (click)="showFaultModal(item,btn_privileges.FIRE_FIGHTING_CS_HANDLE);tj('消防报警');">
                                <img class="icon_img" src="../../../../assets/img/u935.png">
                                处理
                              </button>
                            </td>
                          </tr>
                          <ng-container *ngIf="fireAlertListResp?.dto_list?.length === 1">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="fireAlertListResp?.dto_list?.length === 2">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="fireAlertListResp?.dto_list?.length === 3">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="fireAlertListResp?.dto_list?.length === 4">
                            <tr>
                              <td style="border-bottom: 0px !important;" colspan="4"></td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="fireAlertListResp?.total === 0">
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4">暂无信息</td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                          <tr>
                            <td style="border-bottom: 0px !important;" colspan="4"></td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center pages">
                      <pagination-controls id="fireAlert" *ngIf="fireAlertListResp?.total !== 0"
                                           (pageChange)="changeFireAlertPage($event)"
                                           directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                                           screenReaderPaginationLabel="Pagination"
                                           screenReaderPageLabel="page"
                                           screenReaderCurrentLabel="You're on page"></pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #fjcztj let-message="message">
      本月出租率：当前项目本月已出租房间数/当前项目本月全部房间数*100%<br/>今日在租率：当前项目当天已出租房间数/当前项目当天全部房间数*100%<br/>说明：预定房态不纳入计算
    </ng-template>
    <div class="row">

      <!--房间出租统计-->
      <!--      <div *ngIf="btn_privileges.ROOM_RENT_STATISTICS && projectConfig?.need_lease_contract == 1"-->
      <!--           class="col-12 col-md-6 col-lg-3 mb20" style="color: #333;">-->
      <!--        <div class="setbox" style="padding-bottom: 5px;">-->
      <!--          <p class="p1" style="margin-bottom: 13px;">房间出租统计-->
      <!--            &lt;!&ndash;            <img src="../../../../assets/img/rpt_icon.png" placement="top" triggers="mouseenter:mouseleave"&ndash;&gt;-->
      <!--            &lt;!&ndash;                 style="width: 20px; height: 20px; cursor:pointer"popoverTitle = "房间出租统计" [popover] = "fjcztj">&ndash;&gt;-->

      <!--            <i class="fa fa-question-circle-o cursor-pointer" placement="top" triggers="mouseenter:mouseleave"-->
      <!--               popoverTitle="房间出租统计" [popover]="fjcztj">-->
      <!--            </i>-->
      <!--          </p>-->
      <!--          <div class="row">-->
      <!--            <div class="col-6 text-center">-->
      <!--              <div class="wp100">-->
      <!--                <circle-progress [percent]="workbenchData?.room_rent_statistics_dto?.month_rent_percent"-->
      <!--                                 [title]="workbenchData?.room_rent_statistics_dto?.month_rent_percent == null ? 0 : workbenchData?.room_rent_statistics_dto?.month_rent_percent"-->
      <!--                                 [outerStrokeColor]="'#53a9ff'" [outerStrokeGradientStopColor]="'#4882c2'"-->
      <!--                                 [titleFontSize]="24">-->
      <!--                </circle-progress>-->
      <!--              </div>-->
      <!--              <div class="wp100 text-center">本月出租率-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="col-6 text-center">-->
      <!--              <div class="wp100">-->
      <!--                <circle-progress [percent]="workbenchData?.room_rent_statistics_dto?.today_rent_percent"-->
      <!--                                 [title]="workbenchData?.room_rent_statistics_dto?.today_rent_percent == null ? 0 : workbenchData?.room_rent_statistics_dto?.today_rent_percent"-->
      <!--                                 [outerStrokeColor]="'#3dcd52'"-->
      <!--                                 [outerStrokeGradientStopColor]="'#488d3f'"></circle-progress>-->
      <!--              </div>-->
      <!--              <div class="wp100 text-center">今日在租率-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--设备故障统计-->
      <div
        *ngIf="btn_privileges.DEV_ERROR_STATISTICS && (projectConfig?.ele_type == 'SMART' || projectConfig?.cold_water_type == 'SMART' || projectConfig?.hot_water_type == 'SMART')"
        class="col-12  col-md-6 col-lg-3 mb20">
        <div class="setbox box4">
          <p class="p1" style="margin-bottom: 20px;">设备故障事件统计</p>
          <div class="row">
            <div class="wp33 text-center">
              <div class="" style="margin-bottom: 15px;">
                <span class="font1">{{workbenchData && workbenchData?.dev_error_statistics_dto &&
                workbenchData?.dev_error_statistics_dto?.door_count ?
                  workbenchData?.dev_error_statistics_dto?.door_count : 0}}</span>
              </div>
              <div class="">
                本月故障门锁
              </div>
            </div>
            <div class="wp33 text-center">
              <div class="" style="margin-bottom: 15px;">
                <span class="font1">{{workbenchData && workbenchData?.dev_error_statistics_dto &&
                workbenchData?.dev_error_statistics_dto?.ele_count ?
                  workbenchData?.dev_error_statistics_dto?.ele_count : 0}}</span>
              </div>
              <div class="">
                本月故障水表
              </div>
            </div>
            <div class="wp33 text-center">
              <div class="" style="margin-bottom: 15px;">
                <span class="font1">{{workbenchData && workbenchData?.dev_error_statistics_dto &&
                workbenchData?.dev_error_statistics_dto?.water_count ?
                  workbenchData?.dev_error_statistics_dto?.water_count : 0}}</span>
              </div>
              <div class="">
                本月故障电表
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--投诉建议-->
      <ng-template #tousujianyititle let-message="message">
        <h4>投诉建议事件统计</h4>
      </ng-template>

      <ng-template #tousujianyi let-message="message">
        今日新增：当天提交的投诉建议单总数量<br/>本周新增：本周提交的投诉建议单总数量<br/>本周处理时效：本周所有订单的（处理完成时间-提交时间）总和/投诉建议单总数量
      </ng-template>
      <div *ngIf="btn_privileges.COMPLIANT_STATISTICS" class="col-12 col-md-6 col-lg-3 mb20">
        <div class="setbox box4">
          <p class="p1" style="margin-bottom: 20px;">投诉建议事件统计
            <!--            <img src="../../../../assets/img/rpt_icon.png" placement="top" triggers="mouseenter:mouseleave"-->
            <!--                 style="width: 20px; height: 20px; cursor:pointer" popoverTitle = "投诉建议事件统计" [popover] = "tousujianyi">-->
            <i class="fa fa-question-circle-o cursor-pointer" placement="top" triggers="mouseenter:mouseleave"
               popoverTitle="投诉建议事件统计" [popover]="tousujianyi">
            </i>
          </p>
          <div class="row">
            <div class="wp33 text-center">
              <div class="" style="margin-bottom: 15px;">
                <span class="font1">{{workbenchData && workbenchData?.complaint_statistics_dto &&
                workbenchData?.complaint_statistics_dto?.today_num ?
                  workbenchData?.complaint_statistics_dto?.today_num : 0}}</span>
              </div>
              <div class="">
                今日新增
              </div>
            </div>
            <div class="wp33 text-center">
              <div class="" style="margin-bottom: 15px;">
                <span class="font1">{{workbenchData && workbenchData?.complaint_statistics_dto &&
                workbenchData?.complaint_statistics_dto?.this_week_num ?
                  workbenchData?.complaint_statistics_dto?.this_week_num : 0}}</span>
              </div>
              <div class="">
                本周新增
              </div>
            </div>
            <div class="wp33 text-center">
              <div class="" style="margin-bottom: 15px;">
                <span class="font1">{{workbenchData && workbenchData?.complaint_statistics_dto &&
                workbenchData?.complaint_statistics_dto?.deal_hour ?
                  workbenchData?.complaint_statistics_dto?.deal_hour : '00'}}</span>时
                <span class="font1">{{workbenchData && workbenchData?.complaint_statistics_dto &&
                workbenchData?.complaint_statistics_dto?.deal_minute ?
                  workbenchData?.complaint_statistics_dto?.deal_minute : '00'}}</span>分
              </div>
              <div class="">
                本周处理时效
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--房间巡查统计-->
      <ng-template #fjxctj let-message="message">
        今日巡查：当天巡查记录列表新增的巡查房间数量<br/>本月巡查：本月巡查记录列表新增的巡查房间数量<br/>待复查：巡查记录列表进度为”整改中“的房间数量
      </ng-template>
      <div *ngIf="btn_privileges.ROOM_INSPECTION_STATISTICS" class="col-12 col-md-6 col-lg-3 mb20">
        <div class="setbox box9">
          <p class="p1" style="margin-bottom: 20px;">房间巡查统计
            <!--            <img src="../../../../assets/img/rpt_icon.png" placement="top" triggers="mouseenter:mouseleave"-->
            <!--                 style="width: 20px; height: 20px; cursor:pointer;"popoverTitle = "房间巡查统计" [popover] = "fjxctj">-->

            <i class="fa fa-question-circle-o cursor-pointer" placement="top" triggers="mouseenter:mouseleave"
               popoverTitle="房间巡查统计" [popover]="fjxctj">
            </i>
          </p>
          <div class="row">
            <div class="wp33 text-center">
              <div class="" style="margin-bottom: 15px;">
                <span class="font1">{{workbenchData && workbenchData?.room_inspect_statistic_dto &&
                workbenchData?.room_inspect_statistic_dto?.today_num ?
                  workbenchData?.room_inspect_statistic_dto?.today_num : 0}}</span>
              </div>
              <div class="">
                今日巡查
              </div>
            </div>
            <div class="wp33 text-center">
              <div class="" style="margin-bottom: 15px;">
                <span class="font1">{{workbenchData && workbenchData?.room_inspect_statistic_dto &&
                workbenchData?.room_inspect_statistic_dto?.this_month_num ?
                  workbenchData?.room_inspect_statistic_dto?.this_month_num : 0}}</span>
              </div>
              <div class="">
                本月巡查
              </div>
            </div>
            <div class="wp33 text-center">
              <div class="" style="margin-bottom: 15px;">
                <span class="font1">{{workbenchData && workbenchData?.room_inspect_statistic_dto &&
                workbenchData?.room_inspect_statistic_dto?.need_check_num ?
                  workbenchData?.room_inspect_statistic_dto?.need_check_num : 0}}</span>
              </div>
              <div class="">
                待复查
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!--合同收租统计-->
      <div *ngIf="btn_privileges.LEASE_GATHERING_RENT && projectConfig?.need_lease_contract == 1"
           class="col-12 col-md-12 col-lg-9 mb20">
        <div class="setbox box1 ">
          <div class="col-12">
            <div class="row">
              <div class="col-12 " style="padding-bottom: 0px;padding-left: 0px;padding-top: 0px">
                <p class="p1">公寓合同收租统计</p>
                <div class="bg-white text-red table-bordered table-stripe">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6 col-lg-3">今日剩余房费应收</div>
                      <div class="col-6 col-lg-3">
              <span class="p1 span1 wrap" (mouseover)="changeToLink($event)" (mouseleave)="changeTospan($event)"
                    (click)="goDataPage($event, 'LEFT_GATHERING', 'RENT')">{{workbenchData &&
              workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.left_gathering ?
                workbenchData?.lease_gathering_rent_dto?.left_gathering : 0 | number}}</span>元
                      </div>
                      <div class="col-6 col-lg-3">房费逾期未收</div>
                      <div class="col-6 col-lg-3">
              <span class="p1 span1 wrap" (mouseover)="changeToLink($event)"
                    (mouseleave)="changeTospan($event)"
                    (click)="goOverdueRpt($event,'OVERDUE_GATHERING','RENT')">{{workbenchData &&
              workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.overdue_gathering ?
                workbenchData?.lease_gathering_rent_dto?.overdue_gathering : 0 | number}}
                </span>元
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-lg-3">今日剩余押金应收</div>
                      <div class="col-6 col-lg-3">
                <span class="p1 span1 wrap" (mouseover)="changeToLink($event)" (mouseleave)="changeTospan($event)"
                      (click)="goDataPage($event,'LEFT_GATHERING','DEPOSIT')">{{workbenchData &&
                workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.deposit_left_gathering ?
                  workbenchData?.lease_gathering_rent_dto?.deposit_left_gathering : 0 | number}}</span>元
                      </div>
                      <div class="col-6 col-lg-3">押金逾期应收</div>
                      <div class="col-6 col-lg-3">
                <span class="p1 span1 wrap" (mouseover)="changeToLink($event)"
                      (mouseleave)="changeTospan($event)"
                      (click)="goOverdueRpt($event,'OVERDUE_GATHERING','DEPOSIT')">{{workbenchData &&
                workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.deposit_overdue_gathering ?
                  workbenchData?.lease_gathering_rent_dto?.deposit_overdue_gathering : 0 | number}}
                </span>元
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-lg-3">今日其余费项应收</div>
                      <div class="col-6 col-lg-3">
                <span class="p1 span1 wrap" (mouseover)="changeToLink($event)" (mouseleave)="changeTospan($event)"
                      (click)="goDataPage($event,'LEFT_GATHERING','OTHER')">{{workbenchData &&
                workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.other_left_gathering ?
                  workbenchData?.lease_gathering_rent_dto?.other_left_gathering : 0 | number}}</span>元
                      </div>
                      <div class="col-6 col-lg-3">其余逾期应收</div>
                      <div class="col-6 col-lg-3">
                <span class="p1 span1 wrap" (mouseover)="changeToLink($event)"
                      (mouseleave)="changeTospan($event)"
                      (click)="goOverdueRpt($event,'OVERDUE_GATHERING','OTHER')">{{workbenchData &&
                workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.other_overdue_gathering ?
                  workbenchData?.lease_gathering_rent_dto?.other_overdue_gathering : 0 | number}}
                </span>元
                      </div>
                    </div>
                  </div>
                </div>
              </div>
<!--              <div class="col-12 col-lg-6" style="padding: 0px">-->
<!--                <p class="p1">商业合同收租统计</p>-->
<!--                <div class="bg-white text-red table-bordered table-stripe">-->
<!--                  <div class="col-12">-->
<!--                    <div class="row">-->
<!--                      <div class="col-6 col-lg-3">今日剩余房费应收</div>-->
<!--                      <div class="col-6 col-lg-3">-->
<!--              <span class="p1 span1 wrap" (mouseover)="changeToLink($event)" (mouseleave)="changeTospan($event)"-->
<!--                    (click)="goDataPage($event,'SHOP_LEFT_GATHERING','RENT')">{{workbenchData &&-->
<!--              workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.shop_left_gathering ?-->
<!--                workbenchData?.lease_gathering_rent_dto?.shop_left_gathering : 0 | number}}</span>元-->
<!--                      </div>-->
<!--                      <div class="col-6 col-lg-3">房费逾期未收</div>-->
<!--                      <div class="col-6 col-lg-3">-->
<!--              <span class="p1 span1 wrap" (mouseover)="changeToLink($event)"-->
<!--                    (mouseleave)="changeTospan($event)"-->
<!--                    (click)="goDataPage($event,'SHOP_OVERDUE_GATHERING','RENT')">{{workbenchData &&-->
<!--              workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.shop_overdue_gathering ?-->
<!--                workbenchData?.lease_gathering_rent_dto?.shop_overdue_gathering : 0 | number}}-->
<!--                </span>元-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                      <div class="col-6 col-lg-3">今日剩余押金应收</div>-->
<!--                      <div class="col-6 col-lg-3">-->
<!--                <span class="p1 span1 wrap" (mouseover)="changeToLink($event)" (mouseleave)="changeTospan($event)"-->
<!--                      (click)="goDataPage($event,'SHOP_LEFT_GATHERING','DEPOSIT')">{{workbenchData &&-->
<!--                workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.shop_deposit_left_gathering ?-->
<!--                  workbenchData?.lease_gathering_rent_dto?.shop_deposit_left_gathering : 0 | number}}</span>元-->
<!--                      </div>-->
<!--                      <div class="col-6 col-lg-3">押金逾期应收</div>-->
<!--                      <div class="col-6 col-lg-3">-->
<!--                <span class="p1 span1 wrap" (mouseover)="changeToLink($event)"-->
<!--                      (mouseleave)="changeTospan($event)"-->
<!--                      (click)="goDataPage($event,'SHOP_OVERDUE_GATHERING','DEPOSIT')">{{workbenchData &&-->
<!--                workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.shop_deposit_overdue_gathering ?-->
<!--                  workbenchData?.lease_gathering_rent_dto?.shop_deposit_overdue_gathering : 0 | number}}-->
<!--                </span>元-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                      <div class="col-6 col-lg-3">今日其余费项应收</div>-->
<!--                      <div class="col-6 col-lg-3">-->
<!--                <span class="p1 span1 wrap" (mouseover)="changeToLink($event)" (mouseleave)="changeTospan($event)"-->
<!--                      (click)="goDataPage($event,'SHOP_LEFT_GATHERING','OTHER')">{{workbenchData &&-->
<!--                workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.shop_other_left_gathering ?-->
<!--                  workbenchData?.lease_gathering_rent_dto?.shop_other_left_gathering : 0 | number}}</span>元-->
<!--                      </div>-->
<!--                      <div class="col-6 col-lg-3">其余逾期应收</div>-->
<!--                      <div class="col-6 col-lg-3">-->
<!--                <span class="p1 span1 wrap" (mouseover)="changeToLink($event)"-->
<!--                      (mouseleave)="changeTospan($event)"-->
<!--                      (click)="goDataPage($event,'SHOP_OVERDUE_GATHERING','OTHER')">{{workbenchData &&-->
<!--                workbenchData?.lease_gathering_rent_dto && workbenchData?.lease_gathering_rent_dto?.shop_other_overdue_gathering ?-->
<!--                  workbenchData?.lease_gathering_rent_dto?.shop_other_overdue_gathering : 0 | number}}-->
<!--                </span>元-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <!--逾期退房统计-->
      <ng-template #yqtftj let-message="message">今日剩余应退：合同截止日期为当日的所有合同中应退房的总间数<br/>
        逾期未退：超过合同截止日期未退房的所有合同中未退房的总间数
      </ng-template>
      <div *ngIf="btn_privileges.LEASE_OVERDUE_CHECKOUT && projectConfig?.need_lease_contract == 1"
           class="col-12 col-md-6 col-lg-3 mb20">
        <div class="setbox box1">
          <p class="p1">逾期退房统计
            <!--            <img src="../../../../assets/img/rpt_icon.png" placement="top" triggers="mouseenter:mouseleave"-->
            <!--                 style="width: 20px; height: 20px; cursor:pointer"popoverTitle = "逾期退房统计" [popover] = "yqtftj">-->
            <i class="fa fa-question-circle-o cursor-pointer" placement="top" triggers="mouseenter:mouseleave"
               popoverTitle="逾期退房统计" [popover]="yqtftj">
            </i>
          </p>
          <div class="row">
            <div class="box2">今日剩余应退</div>
            <div class="box3">
              <span class="font1 span1 text-d" (click)="jumpToData(workbenchData?.lease_overdue_checkout_dto?.plan_num)"
                    (mouseover)="changeToLink($event)" (mouseleave)="changeTospan($event)">
                {{workbenchData && workbenchData?.lease_overdue_checkout_dto &&
              workbenchData?.lease_overdue_checkout_dto?.plan_num ?
                workbenchData?.lease_overdue_checkout_dto?.plan_num : 0}}
              </span>间
            </div>
          </div>
          <div class="row">
            <div class="box2">逾期未退</div>
            <div class="box3">
              <span class="font1 span1" (click)="jumpToData(workbenchData?.lease_overdue_checkout_dto?.overdue_num)"
                    (mouseover)="changeToLink($event)" (mouseleave)="changeTospan($event)">
                {{workbenchData && workbenchData?.lease_overdue_checkout_dto &&
              workbenchData?.lease_overdue_checkout_dto?.overdue_num ?
                workbenchData?.lease_overdue_checkout_dto?.overdue_num : 0}}
              </span>间
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!--保洁事件-->
      <div *ngIf="btn_privileges.CLEAN_STATISTICS" class="col-12 col-md-6 mb20">
        <div class="setbox box19">
          <p class="p1" style="margin-bottom: 20px;">保洁事件统计</p>
          <div class="row" style="margin-bottom: 15px;">
            <div class="wp20 text-center">
              <span class="font1 span1">{{workbenchData && workbenchData?.clean_statistics_dto &&
              workbenchData.clean_statistics_dto?.today_num ? workbenchData?.clean_statistics_dto?.today_num :
                0}}</span>单
            </div>
            <div class="wp20 text-center">
              <span class="font1 span1">{{workbenchData && workbenchData?.clean_statistics_dto &&
              workbenchData.clean_statistics_dto?.this_week_num ? workbenchData?.clean_statistics_dto?.this_week_num :
                0}}</span>单
            </div>
            <div class="wp20 text-center">
              <span class="font1 span1">{{workbenchData && workbenchData?.clean_statistics_dto &&
              workbenchData.clean_statistics_dto?.deal_hour ? workbenchData?.clean_statistics_dto?.deal_hour :
                '00'}}</span>时
              <span class="font1 span1">{{workbenchData && workbenchData?.clean_statistics_dto &&
              workbenchData.clean_statistics_dto?.deal_minute ? workbenchData?.clean_statistics_dto?.deal_minute :
                '00'}}</span>分
            </div>
            <div class="wp20 text-center">
              <span class="font1 span1">{{workbenchData && workbenchData?.clean_statistics_dto &&
              workbenchData.clean_statistics_dto?.timeout_num ? workbenchData?.clean_statistics_dto?.timeout_num :
                0}}</span>单
            </div>
            <div class="wp20 text-center">
              <span class="font1 span1">{{workbenchData && workbenchData?.clean_statistics_dto &&
              workbenchData.clean_statistics_dto?.avg_timeout_mins ?
                workbenchData?.clean_statistics_dto?.avg_timeout_mins : 0}}</span>分钟
            </div>
          </div>
          <div class="row">
            <div class="wp20 text-center">
              今日新增
            </div>
            <div class="wp20 text-center">
              本周新增
            </div>
            <div class="wp20 text-center">
              本周响应时效
            </div>
            <div class="wp20 text-center">
              本周响应超时
            </div>
            <div class="wp20 text-center">
              平均超时
            </div>
          </div>
        </div>
      </div>
      <!--维修事件-->
      <div *ngIf="btn_privileges.REPAIR_STATISTICS" class="col-12 col-md-6 mb20">
        <div class="setbox box19">
          <p class="p1" style="margin-bottom: 20px;">维修事件统计</p>
          <div class="row" style="margin-bottom: 15px;">
            <div class="wp20 text-center">
              <span class="font1 span1">{{workbenchData && workbenchData?.repair_statistics_dto &&
              workbenchData?.repair_statistics_dto?.today_num ? workbenchData?.repair_statistics_dto?.today_num :
                0}}</span>单
            </div>
            <div class="wp20 text-center">
              <span class="font1 span1">{{workbenchData && workbenchData?.repair_statistics_dto &&
              workbenchData?.repair_statistics_dto?.this_week_num ?
                workbenchData?.repair_statistics_dto?.this_week_num : 0}}</span>单
            </div>
            <div class="wp20 text-center">
              <span class="font1 span1">{{workbenchData && workbenchData?.repair_statistics_dto &&
              workbenchData?.repair_statistics_dto?.deal_hour ? workbenchData?.repair_statistics_dto?.deal_hour :
                '00'}}</span>时
              <span class="font1 span1">{{workbenchData && workbenchData?.repair_statistics_dto &&
              workbenchData?.repair_statistics_dto?.deal_minute ? workbenchData?.repair_statistics_dto?.deal_minute :
                '00'}}</span>分
            </div>
            <div class="wp20 text-center">
              <span class="font1 span1">{{workbenchData && workbenchData?.repair_statistics_dto &&
              workbenchData?.repair_statistics_dto?.timeout_num ? workbenchData?.repair_statistics_dto?.timeout_num :
                0}}</span>单
            </div>
            <div class="wp20 text-center">
              <span class="font1 span1">{{workbenchData && workbenchData?.repair_statistics_dto &&
              workbenchData?.repair_statistics_dto?.avg_timeout_mins ?
                workbenchData?.repair_statistics_dto?.avg_timeout_mins : 0}}</span>分钟
            </div>
          </div>
          <div class="row">
            <div class="wp20 text-center">
              今日新增
            </div>
            <div class="wp20 text-center">
              本周新增
            </div>
            <div class="wp20 text-center">
              本周响应时效
            </div>
            <div class="wp20 text-center">
              本周响应超时
            </div>
            <div class="wp20 text-center">
              平均超时
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="btn_privileges.MONTH_PRICE_AVG_DATA" class="row">
      <div class="col-12 col-md-12 mb20">
        <div class="setbox">
          <div class="month-data-class">
            月均价统计数据
            <div class="text-left text-sm-right">
              查询月份
              <select class="width66" [(ngModel)]="yearStr">
                <ng-container *ngFor="let item of yearList">
                  <option [value]="item">{{item}}</option>
                </ng-container>
              </select>
              <select class="width66" [(ngModel)]="monthStr">
                <ng-container *ngFor="let item of monthList">
                  <option [value]="item">{{item}}月</option>
                </ng-container>
              </select>
              <button class="btn btn-primary my-0 valign-top" type="button" (click)="searchMonthPriceAvg()">
                <i class="fa fa-search"></i>搜索
              </button>
            </div>
          </div>

          <div class="workbenchhead">
            <table class="table table-bordered workbenchbody">
              <tbody>
              <tr>
                <td>房型</td>
                <ng-container *ngIf="month_price_list && month_price_list.length > 0">
                  <td *ngFor="let item of month_price_list;let i=index;">
                    {{item?.room_type_name}}
                  </td>
                </ng-container>
                <ng-container *ngIf="!month_price_list || month_price_list.length == 0">
                  <td>
                    --
                  </td>
                </ng-container>
              </tr>
              <tr>
                <td>月均价（元）</td>
                <ng-container *ngIf="month_price_list && month_price_list.length > 0">
                  <td *ngFor="let item of month_price_list;let i=index;" class="font2">
                    {{item?.month_price_avg}}
                  </td>
                </ng-container>
                <ng-container *ngIf="!month_price_list || month_price_list.length == 0">
                  <td class="font2">
                    0
                  </td>
                </ng-container>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!--营业基础数据 图表-->
    <div
      [style.display]="btn_privileges.BUSINESS_BASIS_DATA && projectConfig?.need_lease_contract == 1 ? 'block' : 'none'"
      class="setbox wp100 mb20">
      <p class="p1 w-100">营业基础数据</p>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="text-center">
                  <div class="chart-t1 text-info">
                    {{chartData && chartData.todayIncome ? chartData.todayIncome : 0 | number}}
                    <span class="text-black-50">元</span>
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    今日总收入
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="text-center">
                  <div class="chart-t1 text-danger">
                    {{chartData && chartData.monthIncome ? chartData.monthIncome : 0 | number}}
                    <span class="text-black-50">元</span>
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    本月总收入
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="col-12">
            <div id="chart-line-1" [chart]="chart1">

            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="col-12">
            <div class="row">
              <div class="col-6 col-sm-6">
                <div class="row">
                  <div class="col-6 text-center">
                    <div class="chart-t1 text-info">{{chartData && chartData.newLease ? chartData.newLease : 0 |
                      number}}</div>
                    <div class="text-gray-600">新签</div>
                  </div>
                  <div class="col-6 text-center">
                    <div class="chart-t1 text-info">{{chartData && chartData.reletLease ? chartData.reletLease : 0 |
                      number}}</div>
                    <div class="text-gray-600">续签</div>
                  </div>
                </div>
                <div class="col-12 text-center chart-t2">
                  今日新增合同
                </div>
              </div>
              <div class="col-6 col-sm-6">
                <div class="row">
                  <div class="col-6 text-center">
                    <div class="chart-t1 text-danger">{{chartData && chartData.newLeaseMonth ? chartData.newLeaseMonth :
                      0 | number}}</div>
                    <div class="text-gray-600">新签</div>
                  </div>
                  <div class="col-6 text-center">
                    <div class="chart-t1 text-danger">{{chartData && chartData.reletLeaseMonth ?
                      chartData.reletLeaseMonth : 0 | number}}</div>
                    <div class="text-gray-600">续签</div>
                  </div>
                  <div class="col-12 text-center chart-t2">
                    本月新增合同
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="col-12">
            <div id="chart-line-2" [chart]="chart2">

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--预付账户消费数据 图表-->
    <div
      [style.display]="btn_privileges.PREPAY_CONSUME_DATA && (projectConfig?.ele_fee_type == 'PREPAY' || projectConfig?.cold_water_fee_type == 'PREPAY' || projectConfig?.hot_water_fee_type == 'PREPAY') ? 'block' : 'none'"
      class="setbox wp100 mb20" style="padding-left: 15px;">
      <p class="p1 w-100">预付账户消费数据</p>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-6 col-md-3 text-center">
              <div class="chart-t1 text-info">
                {{chartData && chartData.todayPrepay ? chartData.todayPrepay : 0 | number}}
                <span>元</span>
              </div>
              <div>
                今日个人账户消费
              </div>
            </div>
            <div class="col-6 col-md-3 text-center">
              <div class="chart-t1 text-info">
                {{chartData && chartData.todayCompanyPrepay ? chartData.todayCompanyPrepay : 0 | number}}
                <span>元</span>
              </div>
              <div>
                今日企业账户消费
              </div>
            </div>
            <div class="col-6 col-md-3 text-center">
              <div class="chart-t1 text-danger">
                {{chartData && chartData.monthPrepay ? chartData.monthPrepay : 0 | number}}
                <span>元</span>
              </div>
              <div>
                本月个人账户消费
              </div>
            </div>
            <div class="col-6 col-md-3 text-center">
              <div class="chart-t1 text-danger">
                {{chartData && chartData.monthCompanyPrepay ? chartData.monthCompanyPrepay : 0 | number}}
                <span>元</span>
              </div>
              <div>
                本月企业账户消费
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <hr>
        </div>
        <div class="col-12">
          <div id="chart-line-3" [chart]="chart3">

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!--逾期金收款数据-->
      <div *ngIf="btn_privileges.OVERDUE_GATHERING_DATA && projectConfig?.need_lease_contract == 1"
           class="col-12 col-sm-6 col-md-3 mb20">
        <div class="setbox box6">
          <p class="p1" style="margin-bottom: 40px;">滞纳金收款数据</p>
          <div class="row">
            <div class="col-6 ">本月剩余应收</div>
            <div class="col-6 "><span class="p1 span1" (mouseover)="changeToLink($event)"
                                      (mouseleave)="changeTospan($event)"
                                      (click)="goDataPage($event,'OVERDUE_LEFT_GATHERING','RENT')">{{workbenchData &&
            workbenchData?.overdue_gathering_data_dto &&
            workbenchData?.overdue_gathering_data_dto?.left_gathering_amount ?
              workbenchData?.overdue_gathering_data_dto?.left_gathering_amount : 0 | number}}</span>元
            </div>
          </div>
          <div class="row">
            <div class="col-6 ">本月实收</div>
            <div class="col-6 "><span class="p1 span1">{{workbenchData && workbenchData?.overdue_gathering_data_dto &&
            workbenchData?.overdue_gathering_data_dto?.recieved_amount ?
              workbenchData?.overdue_gathering_data_dto?.recieved_amount : 0 | number}}</span>元
            </div>
          </div>
          <div class="row">
            <div class="col-6 ">本月设为无需付</div>
            <div class="col-6 "><span class="p1 span1" (mouseover)="changeToLink($event)"
                                      (mouseleave)="changeTospan($event)"
                                      (click)="goDataPage($event,'OVERDUE_NEEDLESS','RENT')">{{workbenchData &&
            workbenchData?.overdue_gathering_data_dto && workbenchData?.overdue_gathering_data_dto?.needless_amount
              ? workbenchData?.overdue_gathering_data_dto?.needless_amount : 0 | number}}</span>元
            </div>
          </div>
        </div>
      </div>
      <!--预付费充值统计-->
      <div
        *ngIf="btn_privileges.PREPAY_RECHARGE_STATISTICS && (projectConfig?.ele_fee_type == 'PREPAY' || projectConfig?.cold_water_fee_type == 'PREPAY' || projectConfig?.hot_water_fee_type == 'PREPAY')"
        class="col-12 col-sm-6 col-md-3 mb20">
        <div class="setbox box7">
          <p class="p1" style="margin-bottom: 40px;">预付费充值统计</p>
          <div class="row">
            <div class="col-6 ">今日个人充值</div>
            <div class="col-6 "><span
              class="p1 span1">{{workbenchData && workbenchData?.prepay_recharge_statistics_dto &&
            workbenchData?.prepay_recharge_statistics_dto?.person_amount ?
              workbenchData?.prepay_recharge_statistics_dto?.person_amount : 0 | number}}</span>元
            </div>
          </div>
          <div class="row">
            <div class="col-6 ">今日企业充值</div>
            <div class="col-6 "><span
              class="p1 span1">{{workbenchData && workbenchData?.prepay_recharge_statistics_dto &&
            workbenchData?.prepay_recharge_statistics_dto?.company_amount ?
              workbenchData?.prepay_recharge_statistics_dto?.company_amount : 0 | number}}</span>元
            </div>
          </div>
          <div class="row">
            <div class="col-6 ">本月充值总额</div>
            <div class="col-6 "><span
              class="p1 span1">{{workbenchData && workbenchData?.this_month_prepay_recharge_total
              ? workbenchData?.this_month_prepay_recharge_total : 0 | number}}</span>元
            </div>
          </div>
        </div>
      </div>
      <!--退款数据统计-->
      <div *ngIf="btn_privileges.REFUND_STATISTICS && projectConfig?.need_lease_contract == 1"
           class="col-12 col-sm-6 col-md-3 mb20">
        <div class="setbox box8">
          <p class="p1" style="margin-bottom: 40px;">退款数据统计</p>
          <div class="row">
            <div class="col-6 ">今日剩余应退</div>
            <div class="col-6 "><span class="p1 span1" (mouseover)="changeToLink($event)"
                                      (mouseleave)="changeTospan($event)" (click)="jumpToRefundList($event)">
                {{workbenchData && workbenchData?.refund_statistics_dto &&
            workbenchData?.refund_statistics_dto?.today_not_refund ?
              workbenchData?.refund_statistics_dto?.today_not_refund : 0 | number}}</span>元
            </div>
          </div>
          <div class="row">
            <div class="col-6 ">本周剩余应退</div>
            <div class="col-6 "><span class="p1 span1" (mouseover)="changeToLink($event)"
                                      (mouseleave)="changeTospan($event)" (click)="jumpToRefundList($event)">
                {{workbenchData && workbenchData?.refund_statistics_dto &&
            workbenchData?.refund_statistics_dto?.this_week_not_refund ?
              workbenchData?.refund_statistics_dto?.this_week_not_refund : 0 | number}}</span>元
            </div>
          </div>
          <div class="row">
            <div class="col-6 ">本月实际已退</div>
            <div class="col-6 "><span class="p1 span1">{{workbenchData && workbenchData?.refund_statistics_dto &&
            workbenchData?.refund_statistics_dto?.this_month_refunded ?
              workbenchData?.refund_statistics_dto?.this_month_refunded : 0 | number}}</span>元
            </div>
          </div>
        </div>
      </div>
      <!--发票数据统计-->
      <div *ngIf="btn_privileges.INVOICE_STATISTICS && projectConfig?.need_lease_contract == 1"
           class="col-12 col-sm-6 col-md-3 mb20">
        <div class="setbox box9">
          <p class="p1" style="margin-bottom: 40px;">发票数据统计</p>
          <div class="row">
            <div class="col-6 ">本月申请发票</div>
            <div class="col-6 "><span class="p1 span1">{{workbenchData && workbenchData?.invoice_statistics_dto &&
            workbenchData?.invoice_statistics_dto?.apply_num ? workbenchData?.invoice_statistics_dto?.apply_num :
              0}}</span>张
            </div>
          </div>
          <div class="row">
            <div class="col-6 ">本月已开发票</div>
            <div class="col-6 "><span class="p1 span1">{{workbenchData && workbenchData?.invoice_statistics_dto &&
            workbenchData?.invoice_statistics_dto?.invoiced_num ?
              workbenchData?.invoice_statistics_dto?.invoiced_num : 0}}</span>张
            </div>
          </div>
          <div class="row">
            <div class="col-6 ">本月开票总额</div>
            <div class="col-6 "><span class="p1 span1">{{workbenchData && workbenchData?.invoice_statistics_dto &&
            workbenchData?.invoice_statistics_dto?.invoiced_amount ?
              workbenchData?.invoice_statistics_dto?.invoiced_amount : 0 | number}}</span>元
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--运营收款数据统计-->
    <div *ngIf="btn_privileges.BUSINESS_GATHERING_DATA && projectConfig?.need_lease_contract == 1" class="row">
      <div class="col-12 col-md-12 mb20">
        <div class="setbox">
          <p class="p2">运营收款数据统计</p>
          <div class="row">
            <div class="wp10 font3">今日(元)</div>
            <div class="wp14 font2">{{workbenchData && workbenchData?.business_gathering_data_dto &&
            workbenchData?.business_gathering_data_dto?.transfer_amount ?
              workbenchData?.business_gathering_data_dto?.transfer_amount : 0 | number}}</div>
            <div class="wp14 font2">{{workbenchData && workbenchData?.business_gathering_data_dto &&
            workbenchData?.business_gathering_data_dto?.weixin_amount ?
              workbenchData?.business_gathering_data_dto?.weixin_amount : 0 | number}}</div>
            <div class="wp14 font2">{{workbenchData && workbenchData?.business_gathering_data_dto &&
            workbenchData?.business_gathering_data_dto?.alipay_amount ?
              workbenchData?.business_gathering_data_dto?.alipay_amount : 0 | number}}</div>
            <div class="wp14 font2">{{workbenchData && workbenchData?.business_gathering_data_dto &&
            workbenchData?.business_gathering_data_dto?.cash_amount ?
              workbenchData?.business_gathering_data_dto?.cash_amount : 0 | number}}</div>
            <div class="wp14 font2">{{workbenchData && workbenchData?.business_gathering_data_dto &&
            workbenchData?.business_gathering_data_dto?.long_amount ?
              workbenchData?.business_gathering_data_dto?.long_amount : 0 | number}}</div>
            <div class="wp14 font2">{{workbenchData && workbenchData?.business_gathering_data_dto &&
            workbenchData?.business_gathering_data_dto?.other_amount ?
              workbenchData?.business_gathering_data_dto?.other_amount : 0 | number}}</div>
            <div class="wp4"></div>
          </div>
          <div class="row">
            <div class="wp10 font3 ">本月(元)</div>
            <div class="wp14 font2 ">{{workbenchData && workbenchData?.this_month_transfer_total ?
              workbenchData?.this_month_transfer_total : 0 | number}}</div>
            <div class="wp14 font2 ">{{workbenchData && workbenchData?.this_month_weixin_total ?
              workbenchData?.this_month_weixin_total : 0 | number}}</div>
            <div class="wp14 font2 ">{{workbenchData && workbenchData?.this_month_ali_total ?
              workbenchData?.this_month_ali_total : 0 | number}}</div>
            <div class="wp14 font2 ">{{workbenchData && workbenchData?.this_month_cash_total ?
              workbenchData?.this_month_cash_total : 0 | number}}</div>
            <div class="wp14 font2 ">{{workbenchData && workbenchData?.this_month_long_total ?
              workbenchData?.this_month_long_total : 0 | number}}</div>
            <div class="wp14 font2 ">{{workbenchData && workbenchData?.this_month_other_total ?
              workbenchData?.this_month_other_total : 0 | number}}</div>
            <div class="wp4 "></div>
          </div>
          <div class="row">
            <div class="wp10 "></div>
            <div class="wp14 text-center ">转账</div>
            <div class="wp14 text-center ">微信</div>
            <div class="wp14 text-center ">支付宝</div>
            <div class="wp14 text-center ">现金</div>
            <div class="wp14 text-center ">龙支付</div>
            <div class="wp14 text-center ">其他</div>
            <div class="wp4 "></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!--工作计划统计-->
      <div *ngIf="btn_privileges.WORK_SCHEDULE_STATISTICS" class="col-12 col-md-4 mb20" style="color: #333;">
        <div class="setbox" style="min-height: 176px;">
          <p class="p2" style="margin-bottom: 13px;">工作计划统计</p>
          <div class="col-12">
            <div class="row">
              <div class="col-6 text-center">
                <div class="wp100">
                  <circle-progress [radius]="30"
                                   [percent]="workbenchData?.work_schedule_sttistics_dto?.this_month_finished_percent"
                                   [title]="workbenchData?.work_schedule_sttistics_dto?.this_month_finished_percent == null ? 0 : workbenchData?.work_schedule_sttistics_dto?.this_month_finished_percent"
                                   [outerStrokeColor]="'#53a9ff'" [outerStrokeGradientStopColor]="'#4882c2'"
                                   [titleFontSize]="24">
                  </circle-progress>
                </div>
                <div class="wp100 text-center">本月计划完成率</div>
              </div>
              <div class="col-6 text-center">
                <div class="wp100">
                  <circle-progress [radius]="30"
                                   [percent]="workbenchData?.work_schedule_sttistics_dto?.today_finished_percent"
                                   [title]="workbenchData?.work_schedule_sttistics_dto?.today_finished_percent == null ? 0 : workbenchData?.work_schedule_sttistics_dto?.today_finished_percent"
                                   [outerStrokeColor]="'#3dcd52'"
                                   [outerStrokeGradientStopColor]="'#488d3f'"></circle-progress>
                </div>
                <div class="wp100 text-center">今日计划完成率</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--计划事项统计-->
      <div *ngIf="btn_privileges.PLAN_MATTER_STATISTICS" class="col-12 col-md-4 mb20" style="color: #333;">
        <div class="setbox">
          <p class="p2">计划事项统计</p>
          <div class="row mt10">
            <div class="wp33 text-center">
              <div class="wp100 text-center mb10"><span class="font1 span1">{{workbenchData &&
              workbenchData?.work_schedule_sttistics_dto && workbenchData?.work_schedule_sttistics_dto?.finished_num
                ? workbenchData?.work_schedule_sttistics_dto?.finished_num : 0}}</span>项
              </div>
              <div class="wp100 text-center mb10"><img class="img1" src="../../../../assets/img/workbench/finish.png">
              </div>
              <div class="wp100 text-center">本月已完成</div>
            </div>
            <div class="wp33 text-center">
              <div class="wp100 text-center mb10"><span class="font1 span1">{{workbenchData &&
              workbenchData?.work_schedule_sttistics_dto &&
              workbenchData?.work_schedule_sttistics_dto?.un_finished_num ?
                workbenchData?.work_schedule_sttistics_dto?.un_finished_num : 0}}</span>项
              </div>
              <div class="wp100 text-center mb10"><img class="img1" src="../../../../assets/img/workbench/unfinish.png">
              </div>
              <div class="wp100 text-center">本月未完成</div>
            </div>
            <div class="wp33 text-center">
              <div class="wp100 text-center mb10"><span class="font1 span1">{{workbenchData &&
              workbenchData?.work_schedule_sttistics_dto &&
              workbenchData?.work_schedule_sttistics_dto?.wait_check_num ?
                workbenchData?.work_schedule_sttistics_dto?.wait_check_num : 0}}</span>项
              </div>
              <div class="wp100 text-center mb10"><img class="img1" src="../../../../assets/img/workbench/check.png">
              </div>
              <div class="wp100 text-center">本月待检查</div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<!-- 处理告警信息 -->
<div class="modal fade" bsModal #template="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>处理告警信息</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="template.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-lg-6 mb10">告警时间：{{commonalertInfo?.first_alert_time | date:'yyyy-MM-dd HH:mm:ss'}}
          </div>

          <div class="col-12 col-lg-6 mb10" *ngIf="alertBillTypeCodeList.includes(commonalertInfo?.alert_type)">
            报警类型：房间欠费
          </div>
          <div class="col-12 col-lg-6 mb10" *ngIf="commonalertInfo?.alert_type == 'NO_CHECKOUT'">报警类型：应退未退</div>
          <div class="col-12 col-lg-6 mb10"
               *ngIf="!alertBillTypeCodeList.includes(commonalertInfo?.alert_type) && commonalertInfo?.alert_type != 'NO_CHECKOUT' && commonalertInfo?.alert_type != 'FIRE_FIGHTING_CS'">
            报警类型：设备故障
          </div>
          <div class="col-12 col-lg-6 mb10" *ngIf="commonalertInfo?.alert_type == 'SHOP_DUE'">报警类型：商铺欠费</div>
          <div class="col-12 col-lg-6 mb10" *ngIf="commonalertInfo?.alert_type == 'FIRE_FIGHTING_CS'">
            报警类型：消防报警
          </div>


          <div class="col-12 col-lg-6 mb10">最后处理人：{{commonalertInfo?.last_handler_name}}</div>
          <div class="col-12 col-lg-6 mb10">
            最后处理时间：{{commonalertInfo?.last_handle_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
        </div>
        <div class="con">
          <div class="row"
               *ngIf="alertBillTypeCodeList.includes(commonalertInfo?.alert_type) || commonalertInfo?.alert_type == 'SHOP_DUE'">
            <div class="col-12 mb10">报警内容：</div>
            <div class="col-12 col-lg-6 mb10">合同号：
              <span class="text-primary pointer" (click)="to_lease(commonalertInfo?.lease_id)"
                    *ngIf="commonalertInfo && commonalertInfo?.lease_id">{{commonalertInfo?.contract_num}}</span>
              <span *ngIf="!commonalertInfo || !commonalertInfo?.lease_id">{{commonalertInfo?.contract_num}}</span>
            </div>
            <div class="col-12 col-lg-6 mb10">签约人：{{signatory_dto?.signatory}}</div>
            <div class="col-12 col-lg-6 mb10">欠费类型：{{commonalertInfo?.bill_type}}</div>
            <div class="col-12 col-lg-6 mb10">总金额：{{commonalertInfo?.bill_amount}}</div>

            <div class="col-12 col-lg-6 mb10">合同联系人：
              <ng-container *ngIf="signatory_dto?.contact_type === 'COMPANY'">
                {{signatory_dto?.contact_name}}
              </ng-container>
              <ng-container *ngIf="signatory_dto?.contact_type === 'PERSON'">
                --
              </ng-container>
            </div>
            <div class="col-12 col-lg-6 mb10">联系电话：{{signatory_dto?.contact_phone}}</div>
            <div class="col-12 col-lg-6 mb10">应付日期：{{commonalertInfo?.plan_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
          </div>
          <div class="row" *ngIf="commonalertInfo?.alert_type == 'NO_CHECKOUT'">
            <div class="col-12 mb10">报警内容：</div>
            <div class="col-12 col-lg-6 mb10">合同号：
              <span class="text-primary pointer" (click)="to_lease(commonalertInfo?.lease_id)"
                    *ngIf="commonalertInfo && commonalertInfo?.lease_id">{{commonalertInfo?.contract_num}}</span>
              <span *ngIf="!commonalertInfo || !commonalertInfo?.lease_id">{{commonalertInfo?.contract_num}}</span>
            </div>
            <div class="col-12 col-lg-6 mb10">签约人：{{signatory_dto?.signatory}}</div>
            <div class="col-12 col-lg-6 mb10">合同联系人：
              <ng-container *ngIf="signatory_dto?.contact_type === 'COMPANY'">
                {{signatory_dto?.contact_name}}
              </ng-container>
              <ng-container *ngIf="signatory_dto?.contact_type === 'PERSON'">
                --
              </ng-container>
            </div>
            <div class="col-12 col-lg-6 mb10">联系电话：{{commonalertInfo?.contact_phone}}</div>
            <div class="col-12 col-lg-6 mb10">应退日期：{{commonalertInfo?.plan_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
          </div>
          <div class="row"
               *ngIf="commonalertInfo?.alert_type != 'NO_CHECKOUT'
               && !alertBillTypeCodeList.includes(commonalertInfo?.alert_type)
               && commonalertInfo?.alert_type != 'SHOP_DUE'
                && commonalertInfo?.alert_type != 'FIRE_FIGHTING_CS'">
            <div class="col-12 mb10">报警内容：</div>
            <p class="col-12 new-lines mb10">{{commonalertInfo?.title}}</p>
            <p class="col-12 new-lines mb10">{{commonalertInfo?.jump_remark}}</p>
          </div>
          <div class="row"
               *ngIf="commonalertInfo?.alert_type == 'FIRE_FIGHTING_CS'">
            <div class="col-12 mb10">报警内容：</div>
            <div class="col-12 col-md-6 mb10">系统类型： {{commonalertInfo?.system_type_name}}
              ({{commonalertInfo?.type_num}})
            </div>
            <div class="col-12 col-md-6 mb10">系统地址： {{commonalertInfo?.addr_num}}</div>
            <div class="col-12 col-md-6 mb10">部件类型： {{commonalertInfo?.component_type_str}}
              ({{commonalertInfo?.component_type_num}})
            </div>
            <div class="col-12 col-md-6 mb10">部件地址： {{commonalertInfo?.component_addr_str}}
              ({{commonalertInfo?.component_addr_num}})
            </div>

            <div class="col-12 col-md-6 mb10">描述： {{commonalertInfo?.desc_str}}</div>
            <div class="col-12 col-md-6 mb10">时间： {{commonalertInfo?.time|date:'yyyy-MM-dd HH:mm:ss'}}</div>
            <div class="col-12 mb10">状态:
              <span *ngIf="commonalertInfo?.b0" class="text-green ">正常</span>
              <span *ngIf="commonalertInfo?.b1" class="text-danger">火警</span>
              <span *ngIf="commonalertInfo?.b2" class="text-warning">故障</span>
              <span *ngIf="commonalertInfo?.b3" class="text-green">屏蔽</span>
              <span *ngIf="commonalertInfo?.b4" class="text-danger">监管</span>
              <span *ngIf="commonalertInfo?.b5" class="text-danger">启动</span>
              <span *ngIf="commonalertInfo?.b6" class="text-danger">反馈</span>
              <span *ngIf="commonalertInfo?.b7" class="text-warning">延时</span>
              <span *ngIf="commonalertInfo?.b8" class="text-warning">电源故障</span>
            </div>
            <div class="col-12">原始内容：</div>
            <p class="col-12 new-lines mb10">{{commonalertInfo?.hex_log_data}}</p>
          </div>
        </div>
        <div class="con">
          <div class="row">
            <div class="col-12 mb10">处理进展：</div>
            <div class="col-12 mb10">
              <div class="height260" *ngIf="commonalertInfo?.evolve_dtos.length != 0 ">
                <div class="row">
                  <div class="col-12 col-md-9 col-xl-7"
                       *ngFor="let item of commonalertInfo?.evolve_dtos; let i = index; ">
                    <div class="card car-bor">
                      <div class="card-header">
                        <span>{{item?.create_time | date: 'yyyy-MM-dd'}}</span>
                        <span>{{item?.create_time | date: 'HH:mm:ss'}}</span>
                        <span>{{item?.handler}}</span>
                        <span *ngIf="item?.status == 'IN_HANDLE'  && i === 0 ">改变状态为处理中</span>
                        <span *ngIf="item?.status == 'IN_HANDLE' && i !== 0">增加进展</span>
                        <span *ngIf="item?.status == 'OFF' && i !== 0">改变状态为关闭</span>
                        <span *ngIf="item?.handler == '系统'">改变状态为未处理</span>

                      </div>
                      <div class="card-body">
                        <blockquote class="blockquote mb-0">
                          <p class="new-lines">{{item?.remark}}</p>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="con1 " *ngIf="privileges">
          <div class="row mb10">
            <div class="col-12 mb10">增加进展：</div>
            <div class="col-12">
              <textarea class="w-100" rows="3" [(ngModel)]="evolve_remark"></textarea>
              <p class="text-danger">请填写进展内容,不少于3个字符</p>
            </div>
          </div>
        </div>
        <div class="row mb10" *ngIf="privileges">
          <div class="col-12">
            <div class="form-check form-check-inline">
              <input class="form-check-input" style="cursor: pointer;" type="radio" value="IN_HANDLE"
                     [(ngModel)]="evolve_status">
              <label class="form-check-label">处理中</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" style="cursor: pointer;" type="radio" value="OFF"
                     [(ngModel)]="evolve_status">
              <label class="form-check-label">已关闭</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="privileges" type="button" class="btn btn-primary" (click)="ok_evolve(commonalertInfo)">保存
        </button>
        <button *ngIf="privileges" type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="template.hide()">
          取消
        </button>
        <button *ngIf="!privileges" type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="template.hide()">
          关闭
        </button>
      </div>
    </div>
  </div>
</div>


<!-- 门锁验收 -->
<div class="modal fade" bsModal #staticModal29="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>门锁验收</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal29.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <p class="mb10 text-center" style="color:#868686;font-size: 16px;padding-top: 20px;padding-bottom: 30px;">
          验收开门密码：<span style="color:#ff3301;font-size: 18px;font-weight: bold;">{{passWord}}</span></p>
        <p class="mb10 text-center" style="color:#868686;font-size: 16px;">如能正常打开门锁，请点击下方【验收通过】按钮</p>
        <p class="mb10 text-center" style="color:#868686;font-size: 16px;">如果未能打开门锁，请点击
          <a class="blue-c" style="font-size: 16px;color:#1672e8;text-decoration:underline;"
             (click)="restPassWord()">重置密码</a>
        </p>
        <p class="mb10 text-center" style="color:#868686;font-size: 16px;">如一直不能打开门锁，请联系云丁保修门锁</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="showDoorModal2()">验收通过</button>
        <button type="button" class="btn btn-secondary" style="color:white;" data-dismiss="modal"
                (click)="staticModal29.hide()">取&nbsp;&nbsp;消
        </button>
      </div>
    </div>
  </div>
</div>

<!-- 重置密码等待界面 -->
<div class="modal fade" bsModal #staticModal30="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>门锁验收</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal30.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <p class="blue-c text-center mb10" *ngIf="doorStatus == 'ONLINE'">此房间密码正在重置中，请稍候再试</p>
        <p class="blue-c text-center mb10" *ngIf="doorStatus == 'OFFLINE' || doorStatus == 'DAMAGE'">
          此门锁故障，无法重置，请确认门锁是否在线</p>
        <p class="mb10 text-center">如您多次重置后还不能开门，请联系云丁报修</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="staticModal30.hide()">关闭
        </button>
      </div>
    </div>
  </div>
</div>

<!-- 验收确认 -->
<div class="modal fade" bsModal #staticModal31="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>门锁验收确认</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal31.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <p class="mb10 text-center">验收成功代表门锁将视为正常状态，不可撤消，是否确认？</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="acceptanceSuccess()">确认
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="staticModal31.hide()">取消
        </button>
      </div>
    </div>
  </div>
</div>


<!--  处理维修/保洁信息  -->
<div class="modal fade" bsModal #template5="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>{{repair_type}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="template5.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <p class="_repairtit">预约报修信息</p>
        <div class="row">
          <div class="col-12 col-md-4 col-lg-4 mb10">房间：{{repairOverHoursDto?.unit_name}}
            >{{repairOverHoursDto?.room_num}}</div>
          <div *ngIf="repair_type_type === 'REPAIR' && repairOverHoursDto?.coming_time_str"
               class="col-12 col-md-4 col-lg-4 mb10">上门时间：{{repairOverHoursDto?.coming_time_str}}</div>
          <div *ngIf="repair_type_type === 'REPAIR' && repairOverHoursDto?.repair_place"
               class="col-12 col-md-4 col-lg-4 mb10">
            报修性质：{{repairOverHoursDto?.repair_place === "ROOM" ? "房间报修" : "公区报修"}}</div>
          <div class="col-12 col-md-4 col-lg-4 mb10">提交人：{{repairOverHoursDto?.create_name}}</div>
          <div class="col-12 col-md-4 col-lg-4 mb10">
            申请时间：{{repairOverHoursDto?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
          <div class="col-12 col-md-4 col-lg-4 mb10">联系方式：{{repairOverHoursDto?.contact_phone}}</div>
          <!--保洁-->
          <div *ngIf="repair_type_type === 'CLEAN'" class="col-12 col-md-4 col-lg-4 mb10">
            预约项目：
            <ng-container *ngIf="repairOverHoursDto?.repair_species_type">
              {{repairOverHoursDto?.repair_species_type == 'DAILY_CLEAN' ? '日常保洁' : '深度保洁'}}
            </ng-container>
            <ng-container *ngIf="!repairOverHoursDto?.repair_species_type">
              {{repairOverHoursDto?.type_name}}
            </ng-container>
          </div>
          <div *ngIf="repair_type_type === 'CLEAN'" class="col-12 col-md-4 col-lg-4 mb10">
            支付金额：
            <ng-container *ngIf="repairOverHoursDto?.clean_type_id!=-1">
              {{repairOverHoursDto?.repair_fee}}
            </ng-container>
            <ng-container *ngIf="repairOverHoursDto?.clean_type_id==-1">
              无需付
            </ng-container>
          </div>

          <div *ngIf="repair_type_type === 'CLEAN'" class="col-12 col-md-12 ">
            预约时间：{{repairOverHoursDto?.reservation_start_time | date: 'yyyy-MM-dd '}}
            {{repairOverHoursDto?.reservation_start_time | date:'HH:mm'}}
            ~{{repairOverHoursDto?.reservation_end_time | date:'HH:mm'}}
          </div>
          <div class="col-12 col-md-12 " *ngIf="repair_type_type === 'CLEAN'">
            备注信息：{{repairOverHoursDto?.remark}}
          </div>
          <!--维修-->
          <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-4 col-lg-4 mb10">
            紧急程度：
            <span *ngIf="repairOverHoursDto.urgency_degree === 'COMMON'">一般</span>
            <span *ngIf="repairOverHoursDto.urgency_degree === 'URGENCY'">紧急</span>
          </div>
          <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-4 col-lg-4 mb10">报修类型：
            <ng-container *ngIf="repairOverHoursDto?.repair_species_type && !repairOverHoursDto?.type_name">
              <span *ngIf="repairOverHoursDto?.repair_species_type === 'WATER'">水</span>
              <span *ngIf="repairOverHoursDto?.repair_species_type === 'ELECTRIC'">电</span>
              <span *ngIf="repairOverHoursDto?.repair_species_type === 'OTHER'">其他</span>
              <span *ngIf="repairOverHoursDto?.repair_species_type === 'FURNITURE'">家具</span>
              <span *ngIf="repairOverHoursDto?.repair_species_type === 'BATHITEMS'">浴室物品</span>
              <span *ngIf="repairOverHoursDto?.repair_species_type === 'SUNDRIES'">墙体/地面/天花板</span>
              <span *ngIf="repairOverHoursDto?.repair_species_type === 'TUBEDEMAGE'">灯管损坏</span>
            </ng-container>
            <ng-container *ngIf="repairOverHoursDto?.type_name">
              {{repairOverHoursDto?.type_name}}
            </ng-container>
          </div>
          <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-12 ">
            预约时间：{{repairOverHoursDto?.reservation_start_time | date: 'yyyy-MM-dd '}}
            {{repairOverHoursDto?.reservation_start_time | date:'HH:mm'}}
            ~{{repairOverHoursDto?.reservation_end_time | date:'HH:mm'}}
          </div>
          <div class="col-12 col-md-12 " *ngIf="repair_type_type === 'REPAIR'">
            备注信息：{{repairOverHoursDto?.remark}}
            <!--            备注信息：-->
            <!--            <div class="col-12 col-md-12 col-lg-12 mb10 i"-->
            <!--                 *ngFor="let info of repairOverHoursDto?.repair_remark_dolist">-->
            <!--              <div-->
            <!--                class="col-12 col-md-12 col-lg-12 mb10 my"> {{info?.create_time|date:'yyyy-MM-dd HH:mm:ss'}}</div>-->
            <!--              <div class="col-12 col-md-12 col-lg-12 mb10 "> {{info?.remark}}</div>-->
            <!--              <ng-container *ngFor="let item of info?.remark_imgs">-->
            <!--                <img src="{{item}}" (click)="openRemarkImage(item)"-->
            <!--                     style="height: 100px;width: 100px">-->
            <!--              </ng-container>-->
            <!--              <hr size="1" style="border:1px #cccccc dashed;">-->
            <!--            </div>-->
          </div>
          <div class="col-12 mb10"
               *ngIf="repairOverHoursDto?.repair_images && repairOverHoursDto?.repair_images.length > 0 ">
            <div class="col-12 col-md-12 col-lg-4 mb10">照片附件：</div>
            <ng-container *ngFor="let item of repairOverHoursDto?.repair_images;let i=index">
              <img src="{{item}}" (click)="openImage(repairOverHoursDto?.repair_images, i)"
                   style="height: 80px;width: 80px">
            </ng-container>
          </div>
          <!-- <ng-container>
             <div class="col-12 mb10" *ngIf="repairOverHoursDto?.repair_status !== 'INVALID' &&
             repairOverHoursDto?.repair_status !== 'EFFECTIVE' &&  repairOverHoursDto?.repair_status !== 'FIXED'
             && repair_type_type === 'CLEAN'
     ">处理：</div>
             <div class="col-12 mb10" *ngIf="repairOverHoursDto?.repair_status !== 'INVALID'
             &&  repairOverHoursDto?.repair_status !== 'FIXED'
             && repair_type_type === 'REPAIR'">处理：</div>
             <div class="col-12 mb10"
                  *ngIf="repairOverHoursDto?.repair_status !== 'INVALID' && repairOverHoursDto?.repair_status !== 'EFFECTIVE'
               &&  repairOverHoursDto?.repair_status !== 'FIXED'">
               <div class="form-check form-check-inline">
                 <input *ngIf="repair_type_type === 'REPAIR'" class="form-check-input" style="cursor: pointer;"
                        type="radio" value="CONFIRM" [checked]=""
                        [(ngModel)]=repair_audit>
                 <input *ngIf="repair_type_type === 'CLEAN'" class="form-check-input" style="cursor: pointer;"
                        type="radio" value="FINISH" [checked]=""
                        [(ngModel)]=repair_audit>
                 <label class="form-check-label">已处理</label>
               </div>
             </div>

             <div class="col-12" *ngIf="repairOverHoursDto?.repair_status !== 'INVALID' && repairOverHoursDto?.repair_status === 'EFFECTIVE'
                  && repair_type_type === 'REPAIR'">
               <span>结果：</span>
               <div class="coldiv">
                 <select class="w-100" [(ngModel)]="processingResult">
                   <option value="ALL">请选择</option>
                   <option value="NATURAL_DAMAGE">自然损坏</option>
                   <option value="ARTIFICIAL_DAMAGE">人为损坏</option>
                   <option value="NO_DAMAGE_NO_MAINTENANCE">非损坏无需维修</option>
                 </select>
               </div>

               <span *ngIf="'ARTIFICIAL_DAMAGE'=== processingResult">赔偿金额：</span>
               <div class="coldiv" *ngIf="'ARTIFICIAL_DAMAGE'=== processingResult">
                 <input type="text" placeholder="请填写赔偿金" [(ngModel)]="compensation" (input)="yanzhengCompensation();"
                        maxlength="8">
               </div>
               <div class="coldiv"><span class="text-danger"
                                         *ngIf="'ARTIFICIAL_DAMAGE'=== processingResult && compensationError">
                   赔偿金额格式错误
              </span></div>
             </div>
             <div class="col-12 mb10" *ngIf="!(repair_type_type === 'REPAIR' && repairOverHoursDto?.repair_status === 'WAITING')">
               备注：
             </div>
             <div class="col-12 mb10" *ngIf="!(repair_type_type === 'REPAIR' && repairOverHoursDto?.repair_status === 'WAITING')">
               <textarea class="w-100" rows="3" [(ngModel)]="remark"></textarea>
             </div>
           </ng-container>-->

          <!-- 超时未完成-->
          <div class="row" style="padding: 10px;"
               *ngIf="repairOverHoursDto?.over_type=== 'UNDONE' && repair_type_type === 'CLEAN'">

            <div class="col-12">
              <hr>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb10">
              保洁员：{{repairOverHoursDto?.over_name}}
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb10">
              受理时间：{{repairOverHoursDto?.receipt_time |date:'yyyy-MM-dd HH:mm:ss' }}
            </div>
            <!-- <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-6 col-lg-4 mb10">
              费用承担方：{{repairOverHoursDto?.repair_scheme_dto?.payer == 'LESSEE' ? '租户' : '公寓' }}
            </div>
            <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-6 col-lg-4 mb10">
              材料费：{{repairOverHoursDto?.repair_scheme_dto?.fee_material}}
            </div>
            <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-6 col-lg-4 mb10">
              人工费：{{repairOverHoursDto?.repair_scheme_dto?.fee_labor}}
            </div> -->
            <div class="col-12">
              <hr>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb10">
              保洁员备注 :
              <div class="col-12 col-md-12 col-lg-12 mb10 i"
                   *ngFor="let info of repairOverHoursDto?.repair_remark_dolist">
                <div class="col-12 col-md-12 col-lg-12 mb10 my"> {{info?.create_time|date:'yyyy-MM-dd HH:mm:ss'}}</div>
                <div class="col-12 col-md-12 col-lg-12 mb10 "> {{info?.remark}}</div>
                <ng-container *ngFor="let item of info?.remark_imgs;let i=index">
                  <img src="{{item}}" (click)="openImages(info?.remark_imgs, i)" style="height: 100px;width: 100px">
                </ng-container>
                <hr size="1" style="border:1px #cccccc dashed;">
              </div>
            </div>
          </div>
          <div class="row" style="padding: 10px;" *ngIf="repair_type_type === 'REPAIR'">
            <div class="col-12">
              <hr>
            </div>
            <p class="col-12 _repairtit">维修员信息</p>
            <div class="col-12 col-md-6 col-lg-6 mb10">
              维修员：<span
              *ngIf="repairOverHoursDto?.over_type !== 'MANUAL'">{{repairOverHoursDto?.over_name}}</span><span
              *ngIf="repairOverHoursDto?.over_type === 'MANUAL'">--</span>
            </div>
            <div class="col-12 col-md-6 col-lg-6 mb10">
              分配时间：<span *ngIf="repairOverHoursDto?.over_type !== 'MANUAL'">{{repairOverHoursDto?.effective_time
              |date:'yyyy-MM-dd HH:mm:ss' }}</span><span *ngIf="repairOverHoursDto?.over_type === 'MANUAL'">--</span>
            </div>
            <div class="col-12 col-md-6 col-lg-6 mb10">
              上门时间：<span *ngIf="repairOverHoursDto?.over_type === 'UNDONE'">{{repairOverHoursDto?.coming_time_str
              |date:'yyyy-MM-dd' }}</span><span *ngIf="repairOverHoursDto?.over_type !== 'UNDONE'">--</span>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb10">
              预计维修完成时间：<span
              *ngIf="repairOverHoursDto?.over_type === 'UNDONE' && repairOverHoursDto?.plan_fix_time">{{repairOverHoursDto?.plan_fix_time
              | date:'yyyy-MM-dd'}}</span><span
              *ngIf="repairOverHoursDto?.over_type !== 'UNDONE' || !repairOverHoursDto?.plan_fix_time">--</span>
            </div>
            <div class="col-12" *ngIf="repair_state">
              <hr>
            </div>
            <p class="col-12 _repairtit">维修员备注</p>
            <div class="col-12 col-md-12 col-lg-12 mb10 i"
                 *ngFor="let info of repairOverHoursDto?.repair_remark_dolist">
              <div class="col-12 col-md-12 col-lg-12 mb10 my"> {{info?.create_time|date:'yyyy-MM-dd HH:mm:ss'}}</div>
              <div class="col-12 col-md-12 col-lg-12 mb10 "> {{info?.remark}}</div>
              <ng-container *ngFor="let item of info?.remark_imgs;let i=index">
                <img src="{{item}}" (click)="openImages(info.remark_imgs, i)" style="height: 100px;width: 100px">
              </ng-container>
              <hr size="1" style="border:1px #cccccc dashed;">
            </div>
            <div class="col-12" *ngIf="repair_state">
              <hr>
            </div>
            <div class="form-group col-12 col-md-12 col-lg-6 repairuser" *ngIf="repair_state">
              <label for="repairuser" class="w70">分配给:</label>
              <select id="repairuser" class="form-control" [(ngModel)]="repairActiveUser">
                <option value="">请选择</option>
                <option *ngFor="let item of repairUserList" [value]="item.id">{{item.user_name}}</option>
              </select>
            </div>
          </div>
          <ng-container>
            <div class=" col-12 mb10 modal-footer">
              <!--<button type="button" class="btn btn-primary" [attr.disabled]="repair_audit === 'CONFIRM' ||
               repair_audit === 'FINISH' ? null : true"
                      (click)="repairOk(repairInfo)">保存
              </button>-->
              <button type="button"
                      [ngClass]="{'btn': true,'btn-secondary': repairActiveUser == repairOverHoursDto?.worker, 'btn-primary': repairActiveUser != repairOverHoursDto?.worker}"
                      *ngIf="repair_state" (click)="repairDistr()">提交
              </button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal"
                      (click)="template5.hide();repair_audit = ''">关闭
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 额外退款审批 -->
<div class="modal fade" bsModal #template6="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>额外退款审批</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-3 mb10">提交人：李伟</div>
          <div class="col-12 col-md-6 col-lg-4 mb10">联系方式： 13000000000</div>
          <div class="col-12 col-md-6 col-lg-5 mb10">提交时间： 2019-09-09 12:02:21</div>
          <div class="col-12 mb10">类型：保洁</div>
          <div class="col-12 mb10">内容：保洁</div>
          <div class="col-12 mb10">相关图片：</div>
          <div class="col-12 mb10">
            <ul class="img-box">
              <li></li>
              <li></li>
            </ul>
          </div>
          <div class="col-12 mb10">
            <span>处理：</span>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="option3" checked>
              <label class="form-check-label">接受</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="option4">
              <label class="form-check-label">拒绝</label>
            </div>
          </div>
          <div class="col-12">
            <span>预计服务时间：</span>
            <div class="coldiv">
              <my-date-picker></my-date-picker>
            </div>
          </div>
          <div class="col-12 mb10">备注：</div>
          <div class="col-12 mb10">
            <textarea class="w-100" rows="3"></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary">保存</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="template6.hide()">取消</button>
      </div>
    </div>
  </div>
</div>


<!-- 每月价格明细 -->
<div class="modal fade" bsModal #staticModal27="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>每月价格明细</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal27.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>月份</th>
            <th>价格</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of price?.month_price_info_dtos ">
            <td>{{item?.start_time | date: 'yyyy-MM-dd'}} - {{item?.end_time | date: 'yyyy-MM-dd'}}</td>
            <td>{{item?.month_rent}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- 租约优惠审批 -->
<div class="modal fade" bsModal #staticModal28="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>房间租约优惠审批</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal28.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="row">
          <div class="col-12 col-md-3 mb10 hei32">提交人：{{discount?.lease_booking_list_dto?.creater_name}}</div>
          <div class="col-12 col-md-5 mb10 hei32">合同时间段:{{discount?.lease_booking_list_dto?.start_time |
            date:'yyyy-MM-dd'}} -
            {{discount?.lease_booking_list_dto?.end_time | date:'yyyy-MM-dd'}}
          </div>
          <div class="col-12 col-md-4 mb10 hei32"
               *ngIf="discount?.lease_booking_list_dto?.lease_type == 'ORIGIN'&& discount?.lease_booking_list_dto?.service_type == 'LEASE'">
            租约类型：
            新租
          </div>
          <div class="col-12 col-md-4 mb10 hei32"
               *ngIf="discount?.lease_booking_list_dto?.lease_type == 'RELET'&& discount?.lease_booking_list_dto?.service_type == 'LEASE'">
            租约类型：
            续租
          </div>
          <div class="col-12 col-md-4 mb10 hei32" *ngIf="discount?.lease_booking_list_dto?.service_type == 'BOOKING'">
            租约类型： 预定
          </div>
          <div class="col-12 col-md-4 mb10 hei32"
               *ngIf="discount?.lease_booking_list_dto?.service_type == 'INTERNAL_LEASE'">
            租约类型： 内部免租合同
          </div>
          <div class="col-12 col-md-6  mb10 hei32">提交时间：{{discount?.lease_booking_list_dto?.create_time |
            date:'yyyy-MM-dd HH:mm:ss'}}
          </div>
          <div class="col-12  col-md-6 mb10 hei32" *ngIf="discount?.lease_booking_list_dto?.service_type == 'BOOKING'">
            预定单号：<span class="b-blues" style="cursor: pointer;"
                           [routerLink]="'/system_manage/booking-management/detail/'+discount?.lease_booking_list_dto?.serial_number">{{discount?.lease_booking_list_dto?.serial_number}}</span>
          </div>
          <div class="col-12  col-md-6 mb10 hei32" *ngIf="discount?.lease_booking_list_dto?.service_type == 'LEASE'">
            租约单号：<span class="b-blues" style="cursor: pointer;"
                           [routerLink]="'/system_manage/signed/pact/'+discount?.lease_booking_list_dto?.serial_number">{{discount?.lease_booking_list_dto?.serial_number}}</span>
          </div>
        </div>
        <p *ngIf="discount?.lease_booking_list_dto?.service_type == 'LEASE'">此租约中优惠的房间：</p>
        <p *ngIf="discount?.lease_booking_list_dto?.service_type == 'BOOKING'">此预定中优惠的房间：</p>
        <div class="table-box mb10">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th class="border">房间</th>
              <th class="border">基准月均价</th>
              <th class="border">签约月均价</th>
              <th class="border">预定金</th>
              <th class="border">优惠幅度</th>
              <ng-container
                *ngIf="discount?.lease_booking_list_dto?.free_start_time && discount?.lease_booking_list_dto?.free_end_time">
                <th class="border">免租周期</th>
              </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of roomPrice?.lease_room_price_dtos">
              <td>{{item?.room_name}}</td>
              <td>{{item?.month_rent}}</td>
              <td>{{item?.reall_rent}}
                <span class="b-blues" style="cursor: pointer;" (click)="showPrice(item)">查看每月详细</span>
              </td>
              <td>{{item?.deposit}}</td>
              <td>{{+item?.discount == 0 ? '100' : +item?.discount == 100 ? '0' : item?.discount}}%</td>
              <ng-container
                *ngIf="discount?.lease_booking_list_dto?.free_start_time && discount?.lease_booking_list_dto?.free_end_time">
                <td>{{discount?.lease_booking_list_dto?.free_start_time | date:'yyyy.MM.dd'}}
                  - {{discount?.lease_booking_list_dto?.free_end_time | date:'yyyy.MM.dd'}}</td>
              </ng-container>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-12 mb40 hei32">备注：{{discount?.lease_booking_list_dto?.remark}}</div>
        </div>
        <div class="mb10">
          <p class="w90px">处理：</p>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" style="cursor: pointer;" value="PASS" [(ngModel)]="lease_audit">
          <label class="form-check-label">同意</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" style="cursor: pointer;" value="REJECT"
                 [(ngModel)]="lease_audit">
          <label class="form-check-label">拒绝</label>
        </div>
        <div class="row">
          <p></p>
        </div>
        <p class="hei32">备注：</p>
        <textarea class="w-100" rows="3" [(ngModel)]="lease_remark"></textarea>
        <span class="text-danger" *ngIf="lease_audit == 'REJECT' && (!lease_remark)">拒绝原因不能为空！</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="ok_lease(discount)">保存</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="staticModal28.hide()">取消
        </button>
      </div>
    </div>
  </div>
</div>

<!-- 商铺租约审批 -->
<div class="modal fade" bsModal #shopLeaseModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>商铺租约审批</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="shopLeaseModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="row">
          <div class="col-12  col-md-6 col-lg-6 hei32">
            租约单号：<span class="b-blues" style="cursor: pointer;"
                           [routerLink]="'/system_manage/shop/detail/'+shop_lease_audit_info_dto?.lease_id">{{shop_lease_audit_info_dto?.contract_num}}</span>
          </div>
          <div class="col-12 col-md-6 col-lg-6 hei32">
            租约类型：
            商铺新租约
          </div>
          <div class="col-12 col-md-6  col-lg-6 hei32">提交时间：{{shop_lease_audit_info_dto?.create_time |
            date:'yyyy-MM-dd HH:mm:ss'}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-12 hei32">
            <h5>签约主体</h5>
          </div>
          <ng-container *ngIf="shop_lease_audit_info_dto?.subject_info_dto
          && shop_lease_audit_info_dto?.subject_info_dto.subject_type == 'COMPANY'">
            <div class="col-12 col-md-4 hei32">
              签约人：{{shop_lease_audit_info_dto?.subject_info_dto?.company_info?.company_name}}</div>
            <div class="col-12 col-md-4 hei32">
              联系方式：{{shop_lease_audit_info_dto?.subject_info_dto?.company_info?.tel}}</div>
            <div class="col-12 col-md-4 hei32">
              业态：{{shop_lease_audit_info_dto?.subject_info_dto?.company_info?.trade}}</div>
            <div class="col-12 col-md-4 hei32">
              品牌名称：{{shop_lease_audit_info_dto?.subject_info_dto?.company_info?.brand}}</div>
          </ng-container>
          <ng-container
            *ngIf="shop_lease_audit_info_dto?.subject_info_dto && shop_lease_audit_info_dto?.subject_info_dto.subject_type == 'PERSON'">
            <div class="col-12 col-md-4 hei32">
              签约人：{{shop_lease_audit_info_dto?.subject_info_dto?.person_info?.name}}</div>
            <div class="col-12 col-md-4 hei32">
              联系方式：{{shop_lease_audit_info_dto?.subject_info_dto?.person_info?.tel}}</div>
            <div class="col-12 col-md-4 hei32">
              业态：{{shop_lease_audit_info_dto?.subject_info_dto?.person_info?.trade}}</div>
            <div class="col-12 col-md-4 hei32">
              品牌名称：{{shop_lease_audit_info_dto?.subject_info_dto?.person_info?.brand}}</div>
          </ng-container>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-12 hei32">
            <h5>签约商铺信息</h5>
          </div>
          <div class="col-12 col-md-5 hei32">合同租期:{{shop_lease_audit_info_dto?.start_time |
            date:'yyyy-MM-dd'}} -{{shop_lease_audit_info_dto?.end_time | date:'yyyy-MM-dd'}}
          </div>
          <div class="col-12 col-md-3 hei32">
            付款周期：{{shop_lease_audit_info_dto?.pay_cycle | enumTransfer:pay_cycle_list}}</div>
          <div class="col-12 col-md-4 hei32">账单生成方式:
            <ng-container *ngIf="shop_lease_audit_info_dto?.area_type =='BUILD'">按建筑面积生成</ng-container>
            <ng-container *ngIf="shop_lease_audit_info_dto?.area_type =='USE'">按租赁面积生成</ng-container>
          </div>
        </div>

        <div class="table-box mb10">
          <table class="table table-bordered table-striped" style="width: 100%;">
            <thead>
            <tr>
              <th class="border">商铺号</th>
              <th class="border">租金单价（元/M²/月）</th>
              <th class="border">押金</th>
              <th class="border">物业管理费单价（元/M²/月）</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let shop of shop_lease_audit_info_dto?.shop_lease_relation_list">
              <td>{{shop.shop_name}}
              </td>
              <td>
                <ng-container *ngIf="shop.rent && shop.rent >= 0">
                  {{shop.rent}}
                </ng-container>
                <ng-container *ngIf="shop.rent_price_list && shop.rent_price_list.length > 0">
                    <span class="b-blues" style="cursor: pointer;"
                          (click)="showShopPrice(shop.rent_price_list,'RENT')">查看每月详细</span>
                </ng-container>
              </td>
              <td>{{shop.deposit}}</td>
              <td>
                <ng-container *ngIf="shop.manage_fee && shop.manage_fee >= 0">
                  {{shop.manage_fee}}
                </ng-container>
                <ng-container *ngIf="shop.manage_fee_list && shop.manage_fee_list.length > 0">
                    <span class="b-blues" style="cursor: pointer;"
                          (click)="showShopPrice(shop.manage_fee_list,'MANAGER_RENT')">查看每月详细</span>
                </ng-container>

              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <hr/>
        <!--<div class="row">-->
        <!--<div style="width: 500px;">-->
        <!--<div class="col-12 col-md-12 hei32"><h5>优惠及逾期信息</h5></div>-->
        <!--<ng-container *ngFor="let freeTime of shop_lease_audit_info_dto?.free_time_list; let h=index;"-->
        <!--[formGroupName]="h">-->
        <!--<ng-container *ngIf="h == 0">-->
        <!--<div class="col-12 col-md-3 hei32">租金免租周期 :</div>-->
        <!--<div class="col-12 col-md-6 hei32">-->
        <!--{{freeTime?.free_start_time |date:'yyyy.MM.dd'}}-{{freeTime?.free_end_time | date:'yyyy.MM.dd'}}-->
        <!--</div>-->
        <!--</ng-container>-->
        <!--<ng-container *ngIf="h != 0">-->
        <!--<div class="col-12 col-md-6 hei32">-->
        <!--{{freeTime?.free_start_time |date:'yyyy.MM.dd'}}-{{freeTime?.free_end_time | date:'yyyy.MM.dd'}}-->
        <!--</div>-->
        <!--</ng-container>-->
        <!--</ng-container>-->
        <!--</div>-->
        <!--<div class="col-12 col-md-12 hei32" *ngIf="shop_lease_audit_info_dto?.outstanding_amount_percent > 0">-->
        <!--允许账单延期<span class="red">{{shop_lease_audit_info_dto?.grace_period_days}}</span>天收款，延期日后每逾期一日，收取产生的逾期金，逾期金比率为账单未支付费用的：<span class="red">{{shop_lease_audit_info_dto?.outstanding_amount_percent}}</span>%-->
        <!--</div>-->
        <!--</div>-->

        <div class="col-12 col-md-12 hei32">
          <h5>优惠及逾期信息</h5>
        </div>
        <div class="padd15">
          <div class="row">
            <div class="col-12 col-md-6 mb10">
              <span class="font-weight-bold">免租期:</span>
              <ng-container *ngIf="shop_lease_audit_info_dto?.is_free">
                <ng-container *ngFor="let freeTime of shop_lease_audit_info_dto?.free_time_list; let h=index;">
                  <div class="hei32">
                    {{freeTime?.free_start_time |date:'yyyy.MM.dd'}}-{{freeTime?.free_end_time | date:'yyyy.MM.dd'}}
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!shop_lease_audit_info_dto?.is_free">
                无
              </ng-container>
            </div>
            <div class="col-12 col-md-6 mb10 ">
              <span class="font-weight-bold">逾期金设置</span>
              <div class="w-100 mb10">
                <ng-container *ngIf="!shop_lease_audit_info_dto?.outstanding_amount_percent">
                  无
                </ng-container>
                <ng-container *ngIf="shop_lease_audit_info_dto?.outstanding_amount_percent">
                  允许账单延期&nbsp;<span class="red">{{shop_lease_audit_info_dto?.grace_period_days}}</span>&nbsp;天收款，
                  延期日后每逾期一日，收取产生的逾期金，逾期金比率为账单未支付费用的：&nbsp;<span
                  class="red">{{shop_lease_audit_info_dto?.outstanding_amount_percent}}</span>%
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <hr/>
        <div class="row">
          <div class="col-12 mb40 hei32">合同备注：{{shop_lease_audit_info_dto?.remark}}</div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-12 hei32">
            <p class="w90px">处理：</p>
          </div>
          <div class="col-12 col-md-12 hei32">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" style="cursor: pointer;" value="PASS"
                     [(ngModel)]="lease_audit">
              <label class="form-check-label">同意</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" style="cursor: pointer;" value="REJECT"
                     [(ngModel)]="lease_audit">
              <label class="form-check-label">拒绝</label>
            </div>
          </div>
          <div *ngIf="lease_audit == 'REJECT'" class="col-12 col-md-12 hei32">
            <p class="hei32">拒绝原因：</p>
          </div>
          <div class="col-12 col-md-12 mb20 hei32" *ngIf="lease_audit == 'REJECT'">
            <textarea class="w-90" rows="2" [(ngModel)]="lease_remark"></textarea>
          </div>
          <div style="margin-top: 20px;margin-left: 10px;">
            <p class="text-danger" *ngIf="lease_audit == 'REJECT' && (!lease_remark)">
              拒绝原因不能为空！</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"
                (click)="ok_shop_lease_audit(shop_lease_audit_info_dto?.lease_id)">保存
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel_shop_lease()">取消
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #shopPriceModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>每月价格明细</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="shopPriceModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="ml-5 text-left">
          <div class="mt-3">
            <ng-container *ngFor="let item of shop_price_list ">
              <div class="mt-3">第 <input disabled [(ngModel)]="item.start_month" class="w80px mr-1 ml-1">
                月至第<input disabled type="number" class="w80px  mr-1 ml-1" [(ngModel)]="item.end_month">月,
                单价<input class="width140  mr-1 ml-1" disabled [(ngModel)]="item.fee"></div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 车库租约审批 -->
<div class="modal fade" bsModal #carportModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>车库租约优惠审批</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="carportModel.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="row">
          <div class="col-12 col-md-3 mb10 hei32">提交人：{{carportAuditDetailResponse?.submit_user_name}}</div>
          <div class="col-12 col-md-5 mb10 hei32">
            合同时间段:{{carportAuditDetailResponse?.start_time | date:'yyyy-MM-dd'}}
            -
            {{carportAuditDetailResponse?.end_time | date:'yyyy-MM-dd'}}
          </div>
          <div class="col-12 col-md-4 mb10 hei32">
            租约类型：{{carportAuditDetailResponse?.lease_type === 'ORIGIN' ? '新租' : '续租'}}
          </div>
          <div class="col-12 col-md-6  mb10 hei32">
            提交时间：{{carportAuditDetailResponse?.submit_time | date:'yyyy-MM-dd HH:mm:ss'}}
          </div>
          <div class="col-12  col-md-6 mb10 hei32">
            租约单号：<span class="b-blues" style="cursor: pointer;"
                           [routerLink]="'/system_manage/carport/details/'+carportAuditDetailResponse?.carport_id">{{carportAuditDetailResponse?.contract_num}}</span>
          </div>
        </div>
        <p *ngIf="discount?.lease_booking_list_dto?.service_type == 'LEASE'">此租约中优惠的车库/位：</p>
        <div class="table-box mb40">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th class="border">车库/位</th>
              <th class="border">基准价</th>
              <th class="border">签约价</th>
              <th class="border">优惠幅度</th>
              <ng-container
                *ngIf="carportAuditDetailResponse?.free_start_time && carportAuditDetailResponse?.free_end_time">
                <th class="border">免租周期</th>
              </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of carportAuditDetailResponse?.carport_lease_relation_dtos">
              <td>{{item?.carport_number}}</td>
              <td>{{item?.base_price}}</td>
              <td>{{item?.month_price}}</td>
              <td>{{item?.discount}}%</td>
              <ng-container
                *ngIf="carportAuditDetailResponse?.free_start_time && carportAuditDetailResponse?.free_end_time">
                <td>{{carportAuditDetailResponse?.free_start_time | date:'yyyy.MM.dd'}}
                  - {{carportAuditDetailResponse?.free_end_time | date:'yyyy.MM.dd'}}</td>
              </ng-container>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-12 mb10 hei32">备注：{{carportAuditDetailResponse?.remark}}</div>
        </div>
        <div class="mb10">
          <p class="w90px">处理：</p>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" style="cursor: pointer;" value="AGREED"
                 [(ngModel)]="lease_audit">
          <label class="form-check-label">同意</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" style="cursor: pointer;" value="REFUSED"
                 [(ngModel)]="lease_audit">
          <label class="form-check-label">拒绝</label>
        </div>
        <div class="row">
          <p></p>
        </div>
        <p class="hei32">备注：</p>
        <textarea class="w-100" rows="3" [(ngModel)]="lease_remark"></textarea>
        <span class="text-danger" *ngIf="lease_audit == 'REFUSED' && (!lease_remark)">拒绝原因不能为空！</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="ok_carportLease(carportAuditDetailResponse)">保存
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="carportModel.hide()">取消
        </button>
      </div>
    </div>
  </div>
</div>


<!-- 图片查看 -->
<!-- <ngx-image-gallery [images]="images" [conf]="conf"></ngx-image-gallery> -->


<!--房源审批-->
<div class="modal fade" bsModal #housingapprovalModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>新增房源审批</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="housingapprovalModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="over10" style="border-bottom: 1px solid #CCCCCC;overflow-x: hidden; padding-bottom: 30px;">
          <div class="row">
            <!--基本信息-->
            <div class="col-12 col-md-12">
              <p class="housetitle">基本信息</p>
              <div class="row">
                <div class="col-6 col-md-4 mb10">
                  社区名称：{{roomGroupInfo?.project_name}}
                </div>
                <div class="col-6 col-md-4 mb10">
                  房型：{{roomGroupInfo?.roon_type_name}}
                </div>
                <div class="col-6 col-md-4 mb10">
                  床位：{{roomGroupInfo?.bed_num}}张
                </div>
                <div class="col-6 col-md-4 mb10">
                  最多可住人数：{{roomGroupInfo?.livein_num}}
                </div>
                <div class="col-6 col-md-4 mb10">
                  朝向：{{roomGroupInfo?.orientation}}
                </div>
                <div class="col-6 col-md-4 mb10">
                  装修风格：{{roomGroupInfo?.group_style_name}}
                </div>
                <div class="col-6 col-md-4 mb10">
                  建筑面积：{{roomGroupInfo?.area}} ㎡
                </div>
              </div>
            </div>
            <!--交通信息-->
            <div class="col-12 col-md-12">
              <p class="housetitle">交通信息</p>
              <div class="row">
                <div class="col-12 col-md-12 mb10">
                  <div class="overflow">
                    <p class="float-left">粗略地址:</p>
                    <div class="float-left" style="margin-left: 10px;">
                      <p>{{roomGroupInfo?.simple_location}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 mb10">
                  <div class="overflow">
                    <p class="float-left">具体地址:</p>
                    <div class="float-left" style="margin-left: 10px;">
                      <p>{{roomGroupInfo?.location}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 mb10">
                  <div class="overflow">
                    <p class="float-left">交通出行:</p>
                    <div class="float-left" style="margin-left: 10px;">
                      <p>{{roomGroupInfo?.traffic_desc}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--配套设备-->
            <div class="col-12 col-md-12">
              <p class="housetitle">配套设备</p>
              <div class="row">
                <ng-container *ngFor="let info of roomGroupInfo?.facility_info_dtos ">
                  <div class="col-3 col-md-2 mb10">
                    <p>{{info?.facility_name}}</p>
                  </div>
                </ng-container>
              </div>
            </div>
            <!--费用信息-->
            <div class="col-12 col-md-12">
              <p class="housetitle">费用信息</p>
              <div class="row">
                <div class="col-12 mb10">
                  租金基数：{{roomGroupInfo?.month_price}}元/月；以
                  <span *ngIf="roomGroupInfo?.increase_type == 'PER_YEAR' ">每年</span>
                  <span *ngIf="roomGroupInfo?.increase_type == 'PER_HALF_YEAR'">每半年</span>
                  <span *ngIf="roomGroupInfo?.increase_type == 'PER_SEASON' ">每季度</span>
                  <span *ngIf="roomGroupInfo?.increase_type == 'PER_MONTH' ">每月</span>
                  {{roomGroupInfo?.increase_amount}}元递增调价，调价后租金于调价当月1日生效。
                </div>
                <div class="col-12 mb10">
                  管理费：{{roomGroupInfo?.manage_fee}}元/月
                </div>
              </div>
            </div>
            <!--房源信息-->
            <div class="col-12 col-md-12">
              <p class="housetitle">房源信息</p>
              <div class="row">
                <div class="col-12 mb10">
                  已关联{{roomGroupInfo?.room_num}}间房间
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt20">
          <div class="col-12 col-md-12 mb10">
            处理:
          </div>
          <div class="col-12 col-md-12 mb10">
            <input type="radio" name="rcn" [(ngModel)]="lease_audit" value="PASS"/>
            <label>同意</label>
            <input type="radio" name="rcn" style="margin-left: 10px;" [(ngModel)]="lease_audit" value="REJECT"/>
            <label>拒绝</label>
          </div>
          <div class="col-12 col-md-12 mb10">
            <div class="row" *ngIf="lease_audit == 'REJECT'">
              <div class="col-12 col-md-2">
                拒绝原因:
              </div>
              <div class="col-12 col-md-10">
                <textarea class="w-100" [(ngModel)]="lease_remark">

              </textarea>
                <span class="text-danger" *ngIf="lease_audit == 'REJECT' && (!lease_remark)">拒绝原因不能为空！</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="ok_roomGroup(roomGroupInfo)">保存</button>
        <button class="btn" (click)="housingapprovalModal.hide()">取消</button>
      </div>
    </div>
  </div>
</div>

<!--  财务复核  -->
<div class="modal fade" bsModal #lfcReviewModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>财务合同复审处理</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="lfcReviewModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="row">
          <div class="col-12 row mb10">
            <label class="col-4 text-right">合同号：</label>
            <div class="col-8">
              <button class="btn btn-link px-0" [routerLink]="['../../signed/pact', lfcLeaseId]">{{lfcLeaseNum}}
              </button>
            </div>
          </div>

          <div class="col-12 row mb10">
            <div class="col-4 text-right">
              处理：
            </div>
            <div class="col-8">
              <div class="form-check form-check-inline">
                <input class="form-check-input" style="cursor: pointer;" type="radio" value="CONFIRM"
                       [(ngModel)]="lfc_audit">
                <label class="form-check-label">已阅</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="lrfOk()" [disabled]="lfc_audit!=='CONFIRM'">保存
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="lfcReviewModal.hide()">取消
        </button>
      </div>
    </div>
  </div>
</div>


<!--  换房审批  -->
<div class="modal fade" bsModal #changeRoomAuditModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>换房申请审批</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="changeRoomAuditModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="row">
          <div class="col-12 row mb10">
            <div class="col-3 mt-2">
              提交人：{{roomChangeDetailResponse?.room_change_info?.create_user_name}}
            </div>
            <div class="col-4 mt-2">
              合同号：
              <a class="px-0"
                 [routerLink]="'/system_manage/signed/pact/' + roomChangeDetailResponse?.room_change_info?.lease_id">
                {{roomChangeDetailResponse?.contract_num}}
              </a>
            </div>
            <div class="col-5 mt-2">
              合同周期：{{roomChangeDetailResponse?.start_time | date: 'yyyy.MM.dd'}}
              -{{roomChangeDetailResponse?.end_time |
              date: 'yyyy.MM.dd'}}
            </div>

            <div class="col-3 mt-2">
              申请类型：{{roomChangeDetailResponse?.room_change_info?.apply_type === 'RENTER_APPLY' ? '房客申请' : '公寓申请'}}
            </div>
            <div class="col-9 mt-2">
              申请时间：{{roomChangeDetailResponse?.room_change_info?.create_time | date: 'yyyy.MM.dd HH:mm:ss'}}
            </div>

            <div class="col-12 mt-2">
              申请原因：{{roomChangeDetailResponse?.room_change_info?.apply_reason}}
            </div>

            <div class="col-12 mt-2">
              换房内容：
            </div>
            <div class="col-12">
              <div class="over10 mt-1">
                <table class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th class="border">原房间</th>
                    <th class="border">原租金月均价</th>
                    <th class="border">新房间</th>
                    <th class="border">新租金月均价</th>
                    <th class="border">新房间租金基准价</th>
                    <th class="border">服务费</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      {{roomChangeDetailResponse?.room_change_info?.origin_room_num}}
                    </td>
                    <td>
                      {{roomChangeDetailResponse?.room_change_info?.origin_room_avg_price}}
                    </td>
                    <td>
                      {{roomChangeDetailResponse?.room_change_info?.new_room_num}}
                    </td>
                    <td>
                      {{roomChangeDetailResponse?.room_change_info?.new_room_avg_price}}
                    </td>
                    <td>
                      {{roomChangeDetailResponse?.new_room_base_price}}
                    </td>
                    <td>
                      {{roomChangeDetailResponse?.charge_service_fee}}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12">
            处理:
          </div>
          <div class="col-12 col-md-12">
            <input type="radio" name="n" id="a1" value="PASS" [(ngModel)]="lease_audit"/>
            <label for="a1" class="ml-1">同意</label>
            <input class="ml-2" type="radio" name="n" id="b2" value="REJECT" [(ngModel)]="lease_audit"/>
            <label for="b2" class="ml-1">拒绝</label>
          </div>
          <div class="col-12 col-md-12" *ngIf="lease_audit == 'REJECT'">
            <div class="row">
              <div class="col-12">
                拒绝原因:
              </div>
              <div class="col-12">
                <textarea class="w-100" [(ngModel)]="lease_remark"></textarea>
                <span class="text-danger"
                      *ngIf="lease_audit == 'REJECT' && (!lease_remark || lease_remark.length == 0)">拒绝原因不能为空</span>
                <span class="text-danger"
                      *ngIf="lease_audit == 'REJECT' && lease_remark && lease_remark.length > 60">拒绝原因不能超过60字符</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"
                [disabled]="lease_audit === 'REJECT' && (!lease_remark || lease_remark.length === 0 || lease_remark.length > 60)"
                (click)="ok_roomChange(roomChangeDetailResponse)">保存
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="changeRoomAuditModal.hide()">
          取消
        </button>
      </div>
    </div>
  </div>
</div>


<!--  房间信息变更审批  -->
<div class="modal fade" bsModal #roomEditModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>房间信息变更审批</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="roomEditModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="row">
          <div class="col-12 row mb10">
            <div class="col-5 mt-2">
              项目名称：{{roomEditAuditDetailResponse?.project_name}}
            </div>
            <div class="col-5 mt-2">
              项目地址：{{roomEditAuditDetailResponse?.project_address}}
            </div>
            <div class="col-5 mt-2">
              提交时间：{{roomEditAuditDetailResponse?.create_time | date: 'yyyy.MM.dd HH:mm:ss'}}
            </div>

            <div class="col-12">
              <div class="over10 mt-1">
                <table class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th class="border">房间号</th>
                    <th class="border">可变更信息</th>
                    <th class="border">变更前</th>
                    <th class="border">变更后</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let item1 of roomEditAuditDetailResponse.snapshot_info">
                    <tr>
                      <td rowspan="3">
                        {{item1.room_name}}
                      </td>
                      <td>
                        房型
                      </td>
                      <ng-container *ngIf="item1.old_room_type_name != item1.new_room_type_name">
                        <td>
                          <font color="red">{{item1.old_room_type_name}}</font>
                        </td>
                        <td>
                          <font color="red">{{item1.new_room_type_name}}</font>
                        </td>
                      </ng-container>
                      <ng-container *ngIf="item1.old_room_type_name == item1.new_room_type_name">
                        <td>
                          {{item1.old_room_type_name}}
                        </td>
                        <td>
                          {{item1.new_room_type_name}}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td>
                        建议销售价
                      </td>
                      <ng-container *ngIf="item1.old_base_price == item1.new_base_price">
                        <td>
                          {{item1.old_base_price}}元
                        </td>
                        <td>
                          {{item1.new_base_price}}元
                        </td>
                      </ng-container>
                      <ng-container *ngIf="item1.old_base_price != item1.new_base_price">
                        <td>
                          <font color="red">{{item1.old_base_price}}元</font>
                        </td>
                        <td>
                          <font color="red">{{item1.new_base_price}}元</font>
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <td>
                        面积
                      </td>
                      <ng-container *ngIf="item1.old_room_area == item1.new_room_area">
                        <td>
                          {{item1.old_room_area}}㎡
                        </td>
                        <td>
                          {{item1.new_room_area}}㎡
                        </td>
                      </ng-container>
                      <ng-container *ngIf="item1.old_room_area != item1.new_room_area">
                        <td>
                          <font color="red">{{item1.old_room_area}}㎡</font>
                        </td>
                        <td>
                          <font color="red">{{item1.new_room_area}}㎡</font>
                        </td>
                      </ng-container>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12">
            审批意见:
          </div>
          <div class="col-12 col-md-12">
            <input type="radio" name="n" id="a1" value="AGREED" [(ngModel)]="room_edit_audit_status"/>
            <label for="a1" class="ml-1">同意</label>
            <input class="ml-2" type="radio" name="n" id="b2" value="REFUSED" [(ngModel)]="room_edit_audit_status"/>
            <label for="b2" class="ml-1">拒绝</label>
          </div>
          <div class="col-12 col-md-12" *ngIf="room_edit_audit_status == 'REFUSED'">
            <div class="row">
              <div class="col-12">
                拒绝原因:
              </div>
              <div class="col-12">
                <textarea class="w-100" [(ngModel)]="room_edit_remark"></textarea>
                <span class="text-danger"
                      *ngIf="room_edit_audit_status == 'REFUSED' && (!room_edit_remark || room_edit_remark.length == 0)">拒绝原因不能为空</span>
                <span class="text-danger"
                      *ngIf="room_edit_audit_status == 'REFUSED' && room_edit_remark && room_edit_remark.length > 60">拒绝原因不能超过60字符</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"
                [disabled]="room_edit_audit_status == 'REFUSED' && (!room_edit_remark || room_edit_remark.length === 0 || room_edit_remark.length > 60)"
                (click)="ok_roomEditChange(roomEditAuditDetailResponse)">保存
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="roomEditModal.hide()">
          取消
        </button>
      </div>
    </div>
  </div>
</div>

<!--  资产转移审批  -->
<div class="modal fade" bsModal #assetTransferModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle"
                                             aria-hidden="true"></i>资产转移审批（审批单号：{{assTransAuditDetailResponse?.approv_num}}
          ）</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideAssetTransfer()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="">
          <tabset #staticTabs [justified]="true">
            <tab heading="申请信息">
              <div class="col-12 mb10">
                <ng-container *ngIf="assTransAuditDetailResponse?.asset_edit_record_id > 0">
                  <div class="row">
                    <div class="col-12 mt-2 font-xl font-weight-bold">
                      <label
                        style="color: red;font-size: 1.25rem !important;">【{{assTransAuditDetailResponse.old_project_name}}
                        】</label>
                      申请转移如下资产至
                      <label
                        style="color: red;font-size: 1.25rem !important;">【{{assTransAuditDetailResponse.new_project_name}}
                        】</label>
                    </div>
                    <div class="col-12 mt-2">
                      共计{{assTransAuditDetailResponse.asset_edit_num}}
                      项，总金额{{assTransAuditDetailResponse.asset_edit_total}}元
                    </div>
                    <div class="col-12">
                        <div class="over101 mt-1">
                        <table class="table table-bordered table-striped table-fix-scroll">
                          <thead>
                          <tr>
                            <th class="border">序列</th>
                            <th class="border">资产类别</th>
                            <th class="border">资产编号</th>
                            <th class="border">资产名称</th>
                            <th class="border">资产型号</th>
                            <th class="border">单价</th>
                          </tr>
                          </thead>
                            <tbody>
                          <ng-container *ngIf="assTransAuditDetailResponse?.asset_list">
                              <ng-container *ngFor="let info of assTransAuditDetailResponse?.asset_list | paginate: {itemsPerPage: assTransAuditDetailRequest?.page_size,
          currentPage: assTransAuditDetailRequest?.page_num,totalItems: assTransAuditDetailResponse?.total,id:'assTransAuditDetailListPage'};let i = index">
                                <tr>
                                <td>{{info?.asset_index}}</td>
                                <td>{{info?.batch_category}}</td>
                                <td>{{info?.asset_serial}}</td>
                                <td>{{info?.batch_model}}</td>
                                <td>{{info?.batch_type}}</td>
                                <td>{{info?.asset_price}}</td>
                              </tr>
                            </ng-container>
                          </ng-container>
                          </tbody>
                        </table>
                      </div>
                        <div class="text-right mt-3 pages" *ngIf="assTransAuditDetailResponse?.total > 0">
                          <pagination-controls id="assTransAuditDetailListPage" (pageChange)="searchAssertList($event)" directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                                               screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                               screenReaderCurrentLabel="You're on page"></pagination-controls>
                        </div>
                      </div>

                    <div class="col-12 mt-2" *ngIf="assTransAuditDetailResponse?.attachment_list">
                      <label>附件：</label>
                      <div>
                        <app-new-file-upload [type]="2"
                                             [accept]="'IMG'"
                                             [imgList]="assTransAuditDetailResponse?.attachment_list"
                                             [showDelBtn]="false"
                                             [showUploadBtn]="false"
                        >
                        </app-new-file-upload>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </tab>
            <tab heading="审批记录信息">
              <app-approv-record-log-list
                [approvalResultResponse]="approvalResultResponse"></app-approv-record-log-list>
            </tab>
            <tab heading="审批流程图"
                 (selectTab)="flowImgTabOnSelect(assTransAuditDetailResponse?.process_instance_id)">
              <app-approv-record-img *ngIf="assTransAuditDetailResponse"></app-approv-record-img>
            </tab>
          </tabset>
        </div>
        <hr>
        <div class="row ">
          <div class="col-2">
            审批意见
          </div>
          <div class="col-10">
            <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==true" (click)="auditCheck(true)"><i
              class="fa fa-check-circle-o"></i> 同意
            </button>
            <button class="btn btn-info mx-2" *ngIf="auditStatus===true" (click)="auditCheck()"><i
              class="fa fa-check-circle-o"></i> 同意
            </button>

            <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==false" (click)="auditCheck(false)"><i
              class="fa fa-times-circle-o"></i> 拒绝
            </button>
            <button class="btn btn-danger mx-2" *ngIf="auditStatus===false" (click)="auditCheck()"><i
              class="fa fa-times-circle-o"></i> 拒绝
            </button>
          </div>

          <ng-container *ngIf="auditStatus===false">
            <div class="col-2 mt-2">
              拒绝原因
            </div>
            <div class="col-10 mt-2">
              <textarea class="w-100" [(ngModel)]="auditRejectReason">
              </textarea>
              <p class="text-danger" *ngIf="auditStatus===false && !auditRejectReason">请输入拒绝原因</p>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="auditSubmit(assTransAuditDetailResponse.appro_record_info_id)"
                class="btn btn-primary">确定
        </button>
        <button type="button" class="btn btn-secondary ml-4" data-dismiss="modal" (click)="hideAssetTransfer();">取消
        </button>
      </div>
    </div>
  </div>
</div>


<!--  资产变更提醒处理  -->
<div class="modal fade" bsModal #assetChangeAuditModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>资产变更提醒处理</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="assetChangeAuditModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="col-12 row m-0 mb10">

          <div class="col-4">
            提交人：{{assetChangeRemind?.commit_user_name}}
          </div>
          <div class="col-4">
            资产编号：{{assetChangeRemind?.asset_number}}
          </div>
          <div class="col-4">
            变更时间：{{assetChangeRemind?.change_time |date:"yyyy-MM-dd HH:mm:ss"}}
          </div>

          <div class="col-12">
            资产信息：
          </div>
          <div class="col-12">
            <div class="over10 mt-1">
              <table class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>资产类别</th>
                  <th>资产名称</th>
                  <th>资产型号</th>
                  <th>品牌</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    {{assetChangeRemind?.batch_type}}
                  </td>
                  <td>
                    {{assetChangeRemind?.batch_model}}
                  </td>
                  <td>
                    {{assetChangeRemind?.batch_category}}
                  </td>
                  <td>
                    {{assetChangeRemind?.batch_brand}}
                  </td>
                </tr>
                </tbody>
                <thead>
                <tr>
                  <th>购入单价（元）</th>
                  <th>入库时间</th>
                  <th>分配时间</th>
                  <th>保管部门</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    {{assetChangeRemind?.batch_price}}
                  </td>
                  <td>
                    {{assetChangeRemind?.asset_create_time | date:"yyy-MM-dd HH:mm:ss"}}
                  </td>
                  <td>
                    {{assetChangeRemind?.allocate_time | date:"yyy-MM-dd HH:mm:ss"}}
                  </td>
                  <td>
                    {{assetChangeRemind?.preserver}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-12">
            变更内容：
          </div>
          <div class="col-12">
            <div class="over10 mt-1">
              <table class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>状态变更</th>
                  <th>房间变更</th>
                  <th>OA审批单号</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    {{assetChangeRemind?.before_status}} -> {{assetChangeRemind?.later_status}}
                    <br>
                    （{{assetChangeRemind?.change_time |date:"yyyy-MM-dd HH:mm:ss"}}）
                  </td>
                  <td>
                    {{assetChangeRemind?.before_room_name}} -> {{assetChangeRemind?.later_room_name}}
                  </td>
                  <td>
                    {{assetChangeRemind?.approval_number}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 mb10">
            处理:
          </div>
          <div class="col-12 text-center">
            <button type="button" (click)="assetChangeRemindRead(assetChangeRemind?.asset_change_remind_id)"
                    class="btn btn-primary">已阅
            </button>
            <button type="button" class="btn btn-secondary ml-4" data-dismiss="modal"
                    (click)="assetChangeAuditModal.hide()">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--  换房转租提醒处理 -->
<div class="modal fade" bsModal #subleaseChangeModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>换房转租财务提醒</h4>
        <button (click)="subleaseChangeModal.hide();" type="button" class="close pull-right" aria-label="Close">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="row">
          <div style="width: 50%;padding-left: 50px;" class="show2">类型：{{subleaseChange?.remind_type_name}}</div>
          <div style="width: 50%" class="show2">合同号：<a class="btn btn-link"
                                                          [routerLink]="['/system_manage/signed/pact/'+subleaseChange?.lease_id]">{{subleaseChange?.lease_number}}</a>
          </div>
          <div style="width: 768px;margin-left: 50px;" class="show1">类型：{{subleaseChange?.remind_type_name}}</div>
          <div style="width: 768px;margin-left: 50px;" class="show1">合同号：<a class="btn btn-link"
                                                                               [routerLink]="['/system_manage/signed/pact/'+subleaseChange?.lease_id]">{{subleaseChange?.lease_number}}</a>
          </div>
        </div>
        <div class="row">
          <div style="width: 50%;padding-left: 50px;" class="show2">
            操作时间：{{subleaseChange?.create_time | date:"yyyy-MM-dd HH:mm ss"}}</div>
          <div style="width: 50%" class="show2">操作人：{{subleaseChange?.user_name}}</div>
          <div style="width: 768px;margin-left: 50px;" class="show1">
            操作时间：{{subleaseChange?.create_time | date:"yyyy-MM-dd HH:mm ss"}}</div>
          <div style="width: 768px;margin-left: 50px;" class="show1">操作人：{{subleaseChange?.user_name}}</div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="" style="margin-left: 20px;">
            处理：
          </div>
          <div class="col-12 text-center">
            <button type="button" (click)="subleaseChangeRemindRead(subleaseChangeModal)" class="btn btn-primary">已阅
            </button>
            <button (click)="subleaseChangeModal.hide();" type="button" class="btn btn-secondary ml-4"
                    data-dismiss="modal">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-dialog [type]="1"></app-dialog>
<app-seehouse-detail #detailComponent (auditingChange)="searchClientAppointList()"></app-seehouse-detail>

<!-- 公告列表 -->
<div class="modal fade" bsModal #noticeListModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-volume-up"></i>公告列表</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="noticeListModel.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body" style="overflow: auto;max-height: 520px;">
        <div class="row wp100 notic-pl" *ngFor="let notice1 of notice_list | paginate: {itemsPerPage: notice_list_req.page_size,
                      currentPage: notice_list_req.page_num,totalItems: notice_total }"
             (click)="noticeUserDetailShow(notice1?.id,0,notice1?.read_flag,noticeDetailModel,noticeListModel)">
          <span class="float-left notice-percentage20">
            <button *ngIf="notice1?.type == 'SYSTEM_UPDATE'" type="button"
                    class="notice-type sysupdate btn-xs">{{notice_types[notice1?.type]}}</button>
            <button *ngIf="notice1?.type == 'BUSINESS_REMIND'" type="button"
                    class="notice-type business btn-xs">{{notice_types[notice1?.type]}}</button>
          </span>
          <span class="float-left notice-percentage70 notice-nowrap noticeCon">
            <span *ngIf="notice1?.type == 'SYSTEM_UPDATE'">【{{notice1?.version}}版本】</span>{{notice1?.title}}
          </span>
          <span class="float-left notice-percentage10">
            <span class="float-right noticeNoRead px-1" *ngIf="notice1?.read_flag == 0">未读</span>
            <span class="float-right noticeRead px-1" *ngIf="notice1?.read_flag == 1">已读</span>
          </span>
          <span class="float-left notic_dashed">
          </span>
        </div>
      </div>
      <div class="text-center pages">
        <pagination-controls (pageChange)="pageNoticeUserChange($event)" directionLinks="true" previousLabel="上一页"
                             nextLabel="下一页" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                             screenReaderCurrentLabel="You're on page"></pagination-controls>
        <div class="text-right" style="padding-right: 10px;padding-bottom: 5px;">共有{{notice_total}}条记录</div>
      </div>
    </div>
  </div>
</div>
<!-- 公告详情 -->
<div class="modal fade" bsModal #noticeDetailModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-volume-up"></i>公告详情</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="noticeDetailHide(noticeDetailModel)">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row notice-margin">
          <div class="col-12 col-md-12 col-xl-4"></div>
          <div class="col-12 col-md-12 col-xl-4 notice-title">
            <span *ngIf="notice_detail?.type == 'SYSTEM_UPDATE'">【{{notice_detail?.version}}
              版本】</span>{{notice_detail?.title}}
          </div>
          <div class="col-12 col-md-12 col-xl-4"></div>
        </div>
        <div class="row notice-margin">
          <div class="col-12 col-md-12 col-xl-4"></div>
          <div class="col-12 col-md-12 col-xl-4" style="text-align:center;">
            <span class="notice-type sysupdate"
                  *ngIf="notice_detail?.type == 'SYSTEM_UPDATE'">{{notice_types[notice_detail?.type]}}</span>
            <span class="notice-type business"
                  *ngIf="notice_detail?.type == 'BUSINESS_REMIND'">{{notice_types[notice_detail?.type]}}</span>
            <span class="notice-time">{{notice_detail?.create_time|date:'yyyy.MM.dd HH:mm:ss'}}</span>
          </div>
          <div class="col-12 col-md-12 col-xl-4"></div>
        </div>
        <div class="row notice-margin" style="padding-left: 18px;padding-right: 18px;">
          <div class="col-12 col-md-12 col-xl-12 noticeDashed"></div>
        </div>
        <div class="row notice-margin" style="padding-left: 50px;padding-right: 50px;">
          <div class="col-12 col-md-12 col-xl-12 notice-content">
            <p id="noticeContent"></p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" (click)="noticeReturn(noticeDetailModel,noticeListModel)">返回
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #incomeQrcodeModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>收款二维码详情</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="incomeQrcodeModal.hide();">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            【<span class="text-danger">{{ projectName }}</span>】收款二维码：
          </div>
          <div class="col-12 d-flex justify-content-center">
            <qr-code [value]="qrCodePath" class="qrcode" size="200">
            </qr-code>
          </div>
          <div class="col-12 text-center text-gray-600">
            如需下载，可鼠标右击图片保存
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-info" type="button" (click)="incomeQrcodeModal.hide();">关&nbsp;闭
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #downloadAppQrcodeModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>下载PMS-APP二维码
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="downloadAppQrcodeModal.hide();">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12" style="font-size: 20px">
            二维码：<span style="color: red;font-size: 16px;">打开支付宝（非微信）扫一扫下载pms手机APP</span>
          </div>
          <div class="" style="margin-left: 27%">
            <div class="mr-3 text-center d-inline-block">
              <div>
                <!--                <ngx-qrcode [qrc-value]="downloadIosQrCodePath" [qrc-class]="'qrcode'"-->
                <!--                            qrc-errorCorrectionLevel="L">-->
                <!--                </ngx-qrcode>-->

                <qr-code [value]="downloadIosQrCodePath" class="qrcode" size="104">
                </qr-code>

              </div>
              <div>IOS</div>
            </div>
            <div class="text-center d-inline-block">
              <div>
                <!--                <ngx-qrcode [qrc-value]="downloadAndriodQrCodePath" [qrc-class]="'qrcode'"-->
                <!--                            qrc-errorCorrectionLevel="L">-->
                <!--                </ngx-qrcode>-->

                <qr-code [value]="downloadAndriodQrCodePath" class="qrcode" size="104">
                </qr-code>

              </div>
              <div>安卓</div>
            </div>
          </div>
          <div class="col-12 mt-2" style="color: #868686;text-align: center;">
            如需下载，可鼠标右击图片保存
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-info" type="button" (click)="downloadAppQrcodeModal.hide();">关&nbsp;闭
        </button>
      </div>
    </div>
  </div>
</div>

<!-- 项目配置文件审批 -->
<div class="modal fade" bsModal #projectFileModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>项目配置审核</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="projectFileModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body pr-5 pl-5" style="color: #333333;font-size: 14px;">
        <div class="row" style="padding-left: 40px;">
          <div class="col-3 mb10">
            项目编号：{{project_dto?.project_id}}
          </div>
          <div class="col-9 mb10">
            项目名称：{{project_dto?.project_name}}
          </div>
          <ng-container *ngIf="fileCount > 0">
            <div class="col-12 mb20">
              需审核配置文件.
            </div>
            <div class="col-12" *ngFor="let pfile of project_dto?.project_file_dtos">
              <div class="row col-12 mb20 pl-5" *ngIf="pfile.file_type !== 'DELIVERY_LIST'">
                <span *ngIf="pfile.file_type === 'SIGNATURE'">电子合同签章：</span>
                <span *ngIf="pfile.file_type === 'CONTRACT'">个人电子合同：</span>
                <span style="margin-left: 15px;" *ngIf="pfile.file_type === 'CHANGE_ROOM_AGREEMENT'">换房协议书：</span>
                <span style="margin-left: 30px;" *ngIf="pfile.file_type === 'HOUSEHOLD_CONVENTION'">住户公约：</span>
                {{pfile.file_name}}&nbsp;&nbsp;&nbsp;&nbsp;<span (click)="downloadProjectFile(pfile.file_url)"
                                                                 style="color: #199ED8;cursor: pointer;">下载</span>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row" style="padding-left: 40px;" *ngIf="project_file_dtos.length > 0">
          <div class="col-2 text-right">
            <span>物品交接单：</span>
          </div>
          <div class="col-10" *ngIf="project_file_dtos.length > 0">
            <table class="table table-bordered table-striped w80">
              <thead>
              <tr>
                <th>房型</th>
                <th>交接单</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let r of project_file_dtos;">
                <td>{{r.room_type_name}}</td>
                <td>{{r.file_name}}&nbsp;&nbsp;&nbsp;&nbsp;<span (click)="downloadProjectFile(r.file_url)"
                                                                 style="color: #199ED8;cursor: pointer;">下载</span>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
        <hr style="border-top: 2px solid rgb(229, 229, 229);">
        <div class="row" style="padding-left: 40px;">
          <div class="col-12 col-md-12 mb20 pl-3">处理：</div>
          <div class="col-12 pl-5" *ngFor="let pfile of audit_project_file_dtos;">
            <div class="row col-12">
              <p class="mb20" *ngIf="pfile.file_type === 'SIGNATURE'">电子合同签章：
                <input class="cursor" type="radio" [checked]="pfile.audit_status === 'EFFECTIVE'"
                       (click)="auditProjectFile(pfile.file_type,'EFFECTIVE')" name="SIGNATURE">通过&nbsp;&nbsp;&nbsp;&nbsp;
                <input class="cursor" type="radio" [checked]="pfile.audit_status === 'AUDIT_FAIL'"
                       (click)="auditProjectFile(pfile.file_type,'AUDIT_FAIL')" name="SIGNATURE">拒绝
              </p>
              <p class="mb20" *ngIf="pfile.file_type === 'CONTRACT'">个人电子合同：
                <input class="cursor" type="radio" [checked]="pfile.audit_status === 'EFFECTIVE'"
                       (click)="auditProjectFile(pfile.file_type,'EFFECTIVE')" name="CONTRACT">通过&nbsp;&nbsp;&nbsp;&nbsp;
                <input class="cursor" type="radio" [checked]="pfile.audit_status === 'AUDIT_FAIL'"
                       (click)="auditProjectFile(pfile.file_type,'AUDIT_FAIL')" name="CONTRACT">拒绝
              </p>
              <p class="mb20" style="padding-left: 15px;" *ngIf="pfile.file_type === 'CHANGE_ROOM_AGREEMENT'">换房协议书：
                <input class="cursor" type="radio" [checked]="pfile.audit_status === 'EFFECTIVE'"
                       (click)="auditProjectFile(pfile.file_type,'EFFECTIVE')"
                       name="CHANGE_ROOM_AGREEMENT">通过&nbsp;&nbsp;&nbsp;&nbsp;
                <input class="cursor" type="radio" [checked]="pfile.audit_status === 'AUDIT_FAIL'"
                       (click)="auditProjectFile(pfile.file_type,'AUDIT_FAIL')" name="CHANGE_ROOM_AGREEMENT">拒绝
              </p>
              <p class="mb20" style="padding-left: 29px;" *ngIf="pfile.file_type === 'HOUSEHOLD_CONVENTION'">住户公约：
                <input class="cursor" type="radio" [checked]="pfile.audit_status === 'EFFECTIVE'"
                       (click)="auditProjectFile(pfile.file_type,'EFFECTIVE')"
                       name="HOUSEHOLD_CONVENTION">通过&nbsp;&nbsp;&nbsp;&nbsp;
                <input class="cursor" type="radio" [checked]="pfile.audit_status === 'AUDIT_FAIL'"
                       (click)="auditProjectFile(pfile.file_type,'AUDIT_FAIL')" name="HOUSEHOLD_CONVENTION">拒绝
              </p>
              <p class="mb20" style="padding-left: 15px;" *ngIf="pfile.file_type === 'DELIVERY_LIST'">物品交接单：
                <input class="cursor" type="radio" [checked]="pfile.audit_status === 'EFFECTIVE'"
                       (click)="auditProjectFile(pfile.file_type,'EFFECTIVE')"
                       name="DELIVERY_LIST">通过&nbsp;&nbsp;&nbsp;&nbsp;
                <input class="cursor" type="radio" [checked]="pfile.audit_status === 'AUDIT_FAIL'"
                       (click)="auditProjectFile(pfile.file_type,'AUDIT_FAIL')" name="DELIVERY_LIST">拒绝
              </p>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="is_remark">
          <div class="col-3" style="padding-left:30px;">拒绝原因：</div>
          <div class="col-9" style="margin-left:-90px;">
            <textarea class="w80" placeholder="请输入拒绝原因" [(ngModel)]="projectFileAuditRequest.remark"
                      rows="3"></textarea>
          </div>
          <span style="margin-left: 110px;" class="text-danger"
                *ngIf="!projectFileAuditRequest.remark">拒绝原因不能为空</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="projectAuditSummit()">保存</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="projectFileModal.hide()">取消
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #projectAuditModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" keyboard="false" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" [ngClass]="{'reminderModel': reminderModel === 3}">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="projectAuditModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row text-center" *ngIf="reminderModel===1">
          <div style="margin: 50px 0px;" class="col-lg-12 col-md-12 pt-10 ">
            保存成功！
          </div>
        </div>
        <div class="row text-center font-weight-bold" *ngIf="reminderModel===2">
          <div style="margin: 50px 0px;" class="col-lg-12 col-md-12 pt-10 ">
            收款处理成功！
          </div>
        </div>
        <div class="row text-center font-weight-bold" *ngIf="reminderModel===3">
          <div style="margin: 50px 0px;" class="col-lg-12 col-md-12 pt-10 ">
            收款提交成功
            <p>待财务核销后即入账至客商账户</p>
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <button (click)="projectAuditModal.hide()" [ngClass]="{'reminderModel': reminderModel === 3}"
                    class="btn btn-primary">
              关闭
            </button>&nbsp;
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #orderLogModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>手动关闭提醒记录</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="orderLogModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body text-left" style="max-height: 365px;overflow-y: auto;">
        <div style="color: black;">
          <table class="table table-bordered table-striped" style="border: none;">
            <thead>
            <tr style="border: none;">
              <th style="border: none;">支付时间</th>
              <th style="border: none;">支付方式</th>
              <th style="border: none;">支付金额</th>
              <th style="border: none;">关闭原因</th>
              <th style="border: none;">操作时间</th>
              <th style="border: none;">操作人</th>
            </tr>
            </thead>
            <tbody>
            <tr style="border: none !important;" *ngFor="let oLog of order_info_log_dtos;">
              <td style="border: none !important;">{{oLog?.pay_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
              <td style="border: none !important;">{{oLog?.pay_type}}</td>
              <td style="border: none !important;">{{oLog?.pay_amount}}</td>
              <td style="border: none !important;">{{oLog?.remark}}</td>
              <td style="border: none !important;">{{oLog?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
              <td style="border: none !important;">{{oLog?.user_name}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="orderLogModal.hide();" style="color:white;" class="btn btn-info" type="button">关&nbsp;闭
        </button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #configModal="bs-modal" [config]="{backdrop: 'static',keyboard: false}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content content">
      <div class="modal-body" style="padding: 100px;padding-top: 60px;">

        <h1 class="text-white" style="font-weight: 700;">恭喜您，已完成全部初始化数据配置，</h1>
        <h1 *ngIf="!workbenchData?.smart_dev_flag" class="text-white"
            style="font-weight: 700;margin-top: -10px;line-height: 40px;">您可以正常使用系统啦！</h1>
        <h1 *ngIf="workbenchData?.smart_dev_flag" class="text-white"
            style="font-weight: 700;margin-top: -10px;line-height: 40px;">
          系统检测到您创建的项目/导入的房源有智能设备，需联系我们完成设备对接！</h1>

        <div class="mt-2" style="margin-top: 32px !important;">
          <div class=" row2">
            <div class="float-left width210">
              <img *ngIf="!systemConfig?.add_dept" class="img1" src="./././assets/img/workbench/chya.png"/>
              <img *ngIf="systemConfig?.add_dept" class="img1" src="./././assets/img/workbench/gou.png"/>
              <span class="font-18">添加公司部门信息</span>
            </div>
            <div class="mar-35 float-left width210">
              <img *ngIf="!systemConfig?.add_project" class="img1" src="./././assets/img/workbench/chya.png"/>
              <img *ngIf="systemConfig?.add_project" class="img1" src="./././assets/img/workbench/gou.png"/>
              <span class="font-18">创建项目信息</span>
            </div>
          </div>
          <div class=" row2">
            <div class="float-left width210">
              <img *ngIf="!systemConfig?.add_user" class="img1" src="./././assets/img/workbench/chya.png"/>
              <img *ngIf="systemConfig?.add_user" class="img1" src="./././assets/img/workbench/gou.png"/>
              <span class="font-18">添加员工信息</span>
            </div>
            <div class="mar-35 float-left width210">
              <img *ngIf="!systemConfig?.add_user" class="img1" src="./././assets/img/workbench/chya.png"/>
              <img *ngIf="systemConfig?.add_user" class="img1" src="./././assets/img/workbench/gou.png"/>
              <span class="font-18">完成房源导入</span>
            </div>
          </div>
          <div class=" row2" *ngIf="workbenchData?.smart_dev_flag">
            <div class="float-left width210">
              <img class="img1" src="./././assets/img/workbench/chya.png"/>
              <span class="font-18">智能设备接入</span>
            </div>
          </div>
          <div class="dashed"></div>
          <div class=" row1" *ngIf="!workbenchData?.smart_dev_flag">
            <img class="img1" src="./././assets/img/workbench/gant.png"/>
            <span class="font-18">如有智能设备（门锁、水电表 ）可联系我们评估后接入系统。</span>
          </div>
          <div class=" row1">
            <img class="img1" src="./././assets/img/workbench/gant.png"/>
            <span class="font-18">如需接入消防预防、人脸识别设备可联系我们</span>
            <p class="font-18 color18">消防预防和人脸识别设备有助于提升宿舍公寓的安全等级，保障入住人的人身安全。</p>
          </div>
        </div>

      </div>
      <div class="div4">
        <button type="button" class="btn font-18 btn1" (click)="hideSystemGuide()">我知道了</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #addRoomTipsModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     keyboard="false" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="addRoomTipsModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row text-center">
          <div style="margin: 50px 0px;" class="col-lg-12 col-md-12 pt-10 ">
            当前项目仅支持直属公司【{{tips_company_name}}】账号操作添加房间！
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <button (click)="addRoomTipsModal.hide()" class="btn btn-primary">
              关闭
            </button>&nbsp;
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  延期退款处理  -->
<div class="modal fade showRefundModal" bsModal #showRefundModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>延期退款处理</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="showRefundModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="col-12 row m-0 mb10 line">
          <p class="col-12">类型: 延期退款</p>
          <div class="col-12 flexjubet mt10">
            <p class="col-6">提交人：{{refundModalDetalis?.create_user_name}}</p>
            <p class="col-6">提交时间：{{refundModalDetalis?.create_time | date:"yyyy-MM-dd"}}</p>
          </div>
        </div>
        <div class="col-12 row m-0 mb10">
          <p class="col-12" style="font-weight: 600;">合同内容: </p>
          <div class="col-12 flexjubet mt10">
            <p class="col-6">合同号：<span class="hover"
                                          (click)="to_lease(refundModalDetalis?.lease_id)">{{refundModalDetalis?.lease_num}}</span>
            </p>
            <p class="col-6">签约人：{{refundModalDetalis?.sign_name}}</p>
          </div>
          <div class="col-12 flexjubet mt10">
            <p class="col-6">合同联系人：{{refundModalDetalis?.contact_name}}</p>
            <p class="col-6">联系电话：{{refundModalDetalis?.contact_phone}}</p>
          </div>
          <div class="col-12 flexjubet mt10">
            <p class="col-6">退房日期：{{refundModalDetalis?.checkout_time | date:"yyyy-MM-dd"}}</p>
            <p class="col-6">退款金额：{{refundModalDetalis?.refund}}</p>
          </div>
        </div>

        <div class="con">
          <div class="row">
            <div class="col-12 mb10">处理进展：</div>
            <div class="col-12 mb10">
              <div class="height260" *ngIf="refundModalDetalis?.evolve_dtos?.length != 0 ">
                <div class="row">
                  <div class="col-12 col-md-9 col-xl-7"
                       *ngFor="let item of refundModalDetalis?.evolve_dtos; let i = index; ">
                    <div class="card car-bor">
                      <div class="card-header">
                        <span>{{item?.create_time | date: 'yyyy-MM-dd'}}</span>
                        <span>{{item?.create_time | date: 'HH:mm:ss'}}</span>
                        <span>{{item?.handler}}</span>
                        <span *ngIf="item?.status == 'IN_HANDLE'  && i === 0 ">改变状态为处理中</span>
                        <span *ngIf="item?.status == 'IN_HANDLE' && i !== 0">增加进展</span>
                        <span *ngIf="item?.status == 'OFF' && i !== 0">改变状态为关闭</span>
                        <span *ngIf="item?.handler == '系统'">改变状态为未处理</span>
                      </div>
                      <div class="card-body">
                        <blockquote class="blockquote mb-0">
                          <p class="new-lines">{{item?.remark}}</p>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="con1 ">
          <div class="row mb10">
            <div class="col-12 mb10">增加进展：</div>
            <div class="col-12">
              <textarea class="w-100" rows="3" [(ngModel)]="evolve_remark"></textarea>
              <p class="text-danger" *ngIf="evolve_remark?.length < 3">请填写进展内容,不少于3个字符</p>
            </div>
          </div>
        </div>
        <div class="col-12 row m-0 mb10">
          <div class="col-12 flexjubet mt10">
            <p class="col-6">如需继续退款，请点击<span class="hover"
                                                      (click)="goCheckoutRefund(refundModalDetalis?.refund_id, 'REFUND', 'checkoutRefund')">去退款</span>
            </p>
            <p class="col-6">如确认不退款了，请点击<span class="hover"
                                                        (click)="goCheckoutRefund(refundModalDetalis?.refund_id, 'TRANSFER_IN', 'checkoutRefundIncome')">退款转收入</span>
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" (click)="ok_evolve(refundModalDetalis)" class="btn btn-primary">确定
            </button>
            <button type="button" class="btn btn-secondary ml-4" data-dismiss="modal"
                    (click)="showRefundModal.hide()">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--  合同信息变更审批  -->
<div class="modal fade " bsModal #showLeaseChangeAuditModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle"
                                             aria-hidden="true"></i>合同信息变更申请（审批单号：{{leaseSubjectAuditDetail?.approv_num}}
          ）</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="showLeaseChangeAuditModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body" *ngIf="leaseSubjectAuditDetail">

        <div class="">
          <tabset #staticTabs [justified]="true">
            <tab heading="申请信息">
              <div class="mt-2 mx-1 mx-md-5">
                <div class="row ">
                  <div class="col-4"><span
                    class="font-weight-bold">项目名称</span>：{{leaseSubjectAuditDetail?.project_name}}
                  </div>
                  <div class="col-4"><span class="font-weight-bold">合同号</span>：
                    <button class="btn btn-link py-0"
                            (click)="to_lease(leaseSubjectAuditDetail?.lease_id)">{{leaseSubjectAuditDetail?.contract_num}}</button>
                  </div>
                  <div class="col-4"><span class="font-weight-bold">签约人</span>：{{leaseSubjectAuditDetail?.name}}
                  </div>
                  <div class="col-4"><span
                    class="font-weight-bold">合同周期</span>：{{leaseSubjectAuditDetail?.start_time|date:'yyyy.MM.dd'}}
                    -{{leaseSubjectAuditDetail?.end_time|date:'yyyy.MM.dd'}}
                  </div>

                  <div class="col-4"><span class="font-weight-bold">变更内容</span>：<span
                    class="text-danger">合同主体信息</span></div>
                  <div class="col-4"><span
                    class="font-weight-bold">提交人</span>：{{leaseSubjectAuditDetail?.submit_name}}</div>
                  <div class="col-4"><span
                    class="font-weight-bold">提交时间</span>：{{leaseSubjectAuditDetail?.submit_time|date:'yyyy.MM.dd HH:mm:ss'}}
                  </div>

                  <div class="col-12 mt-2">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <td class="bg-gray-100">变更信息</td>
                        <td class="bg-gray-100">变更前</td>
                        <td class="bg-gray-100">变更后</td>
                      </tr>
                      </thead>
                      <tbody>
                      <ng-container *ngIf="leaseSubjectAuditDetail?.change_before_dto.subject_type==='PERSON'">
                        <tr
                          *ngIf="leaseSubjectAuditDetail?.change_before_dto.name!=leaseSubjectAuditDetail?.change_after_dto.name">
                          <td class="bg-gray-100">签约</td>
                          <td>{{leaseSubjectAuditDetail?.change_before_dto.name}}</td>
                          <td>{{leaseSubjectAuditDetail?.change_after_dto.name}}</td>
                        </tr>
                        <tr
                          *ngIf="leaseSubjectAuditDetail?.change_before_dto.cret_num!=leaseSubjectAuditDetail?.change_after_dto.cret_num">
                          <td class="bg-gray-100">证件号</td>
                          <td>{{leaseSubjectAuditDetail?.change_before_dto.cret_num}}</td>
                          <td>{{leaseSubjectAuditDetail?.change_after_dto.cret_num}}</td>
                        </tr>
                        <tr
                          *ngIf="leaseSubjectAuditDetail?.change_before_dto.tel!=leaseSubjectAuditDetail?.change_after_dto.tel">
                          <td class="bg-gray-100">联系电话</td>
                          <td>{{leaseSubjectAuditDetail?.change_before_dto.tel}}</td>
                          <td>{{leaseSubjectAuditDetail?.change_after_dto.tel}}</td>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="leaseSubjectAuditDetail?.change_before_dto.subject_type==='COMPANY'">
                        <tr
                          *ngIf="leaseSubjectAuditDetail?.change_before_dto.company_name!=leaseSubjectAuditDetail?.change_after_dto.company_name">
                          <td class="bg-gray-100">企业名称</td>
                          <td>{{leaseSubjectAuditDetail?.change_before_dto.company_name}}</td>
                          <td>{{leaseSubjectAuditDetail?.change_after_dto.company_name}}</td>
                        </tr>
                        <tr
                          *ngIf="leaseSubjectAuditDetail?.change_before_dto.name!=leaseSubjectAuditDetail?.change_after_dto.name">
                          <td class="bg-gray-100">联系人</td>
                          <td>{{leaseSubjectAuditDetail?.change_before_dto.name}}</td>
                          <td>{{leaseSubjectAuditDetail?.change_after_dto.name}}</td>
                        </tr>
                        <tr
                          *ngIf="leaseSubjectAuditDetail?.change_before_dto.tel!=leaseSubjectAuditDetail?.change_after_dto.tel">
                          <td class="bg-gray-100">联系电话</td>
                          <td>{{leaseSubjectAuditDetail?.change_before_dto.tel}}</td>
                          <td>{{leaseSubjectAuditDetail?.change_after_dto.tel}}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="isChangeEmergencyContacts(leaseSubjectAuditDetail)">
                        <td class="bg-gray-100">紧急联系人</td>
                        <td>
                          <p *ngFor="let item of leaseSubjectAuditDetail?.change_before_dto.emergency_contacts">
                            {{item.contact_name}} {{item.contact_phone}}
                          </p>
                        </td>
                        <td>
                          <p *ngFor="let item of leaseSubjectAuditDetail?.change_after_dto.emergency_contacts">
                            {{item.contact_name}} {{item.contact_phone}}
                          </p>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 mt-2">
                    <label>变更原因</label>
                    <div>
                      {{leaseSubjectAuditDetail?.change_reason}}
                    </div>
                  </div>
                  <div class="col-12 mt-2" *ngIf="leaseSubjectAuditDetail?.image_url_list">
                    <label>附件证明：</label>
                    <div>
                      <app-new-file-upload [type]="2" [accept]="'IMG'"
                                           [imgList]="leaseSubjectAuditDetail?.image_url_list"
                                           [showDelBtn]="false" [showUploadBtn]="false">
                      </app-new-file-upload>
                    </div>
                  </div>
                  <div class="col-12 mt-2" *ngIf="leaseSubjectAuditDetail?.attachment_list">
                    <label>附件：</label>
                    <div>
                      <app-new-file-upload [type]="2"
                                           [accept]="'IMG'"
                                           [imgList]="leaseSubjectAuditDetail?.attachment_list"
                                           [showDelBtn]="false"
                                           [showUploadBtn]="false"
                      >
                      </app-new-file-upload>
                    </div>
                  </div>

                </div>
              </div>
            </tab>
            <tab heading="审批记录信息">
              <app-approv-record-log-list
                [approvalResultResponse]="approvalResultResponse"></app-approv-record-log-list>
            </tab>
            <tab heading="审批流程图" (selectTab)="flowImgTabOnSelect(leaseSubjectAuditDetail?.process_instance_id)">
              <app-approv-record-img *ngIf="leaseSubjectAuditDetail"></app-approv-record-img>
            </tab>
          </tabset>
        </div>

        <hr>
        <div class="mt-2 mx-1 mx-md-5">
          <div class="row ">
            <div class="col-2">
              审批意见
            </div>
            <div class="col-10">
              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==true" (click)="auditCheck(true)"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>
              <button class="btn btn-info mx-2" *ngIf="auditStatus===true" (click)="auditCheck()"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>

              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==false" (click)="auditCheck(false)"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
              <button class="btn btn-danger mx-2" *ngIf="auditStatus===false" (click)="auditCheck()"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
            </div>

            <ng-container *ngIf="auditStatus===false">
              <div class="col-2 mt-2">
                拒绝原因
              </div>
              <div class="col-10 mt-2">
                <textarea class="w-100" [(ngModel)]="auditRejectReason">
                </textarea>
                <p class="text-danger" *ngIf="isAuditSubmit && !auditRejectReason">请输入拒绝原因</p>
              </div>
            </ng-container>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" (click)="auditSubmit(leaseSubjectAuditDetail.appro_record_info_id)"
                    class="btn btn-primary">确定
            </button>
            <button type="button" class="btn btn-secondary ml-4" data-dismiss="modal"
                    (click)="showLeaseChangeAuditModal.hide()">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--  合同费用变更审批  -->
<div class="modal fade " bsModal #showLeaseBillChangeAuditModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle"
                                             aria-hidden="true"></i>费用调整申请（审批单号：{{leaseBillFlowAuditResponse?.approv_num}}
          ）</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="showLeaseBillChangeAuditModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body" *ngIf="leaseBillFlowAuditResponse">

        <div class="">
          <tabset #staticTabs [justified]="true">
            <tab heading="申请信息">
              <div class="mt-2 mx-1 mx-md-5">
                <div class="row ">
                  <div class="col-4"><span
                    class="font-weight-bold">项目名称</span>：{{leaseBillFlowAuditResponse?.project_name}}
                  </div>
                  <div class="col-4"><span class="font-weight-bold">合同号</span>：
                    <button class="btn btn-link py-0"
                            (click)="to_lease(leaseBillFlowAuditResponse?.lease_id)">{{leaseBillFlowAuditResponse?.contract_num}}</button>
                  </div>
                  <div class="col-4"><span class="font-weight-bold">签约人</span>：{{leaseBillFlowAuditResponse?.name}}
                  </div>
                  <div class="col-4"><span
                    class="font-weight-bold">合同周期</span>：{{leaseBillFlowAuditResponse?.start_time|date:'yyyy.MM.dd'}}
                    -{{leaseBillFlowAuditResponse?.end_time|date:'yyyy.MM.dd'}}
                  </div>

                  <div class="col-4"><span class="font-weight-bold">变更内容</span>：<span
                    class="text-danger">费用调整申请</span></div>
                  <div class="col-4"><span
                    class="font-weight-bold">提交人</span>：{{leaseBillFlowAuditResponse?.submit_name}}
                  </div>
                  <div class="col-12"><span
                    class="font-weight-bold">提交时间</span>：{{leaseBillFlowAuditResponse?.submit_time|date:'yyyy.MM.dd HH:mm:ss'}}
                  </div>

                  <div class="col-12 mt-2 scrollx-head">
                    <table class="table table-bordered scrollx-body">
                      <thead>
                      <tr>
                        <td class="bg-gray-100">房间号</td>
                        <td class="bg-gray-100">账单类型</td>
                        <td class="bg-gray-100">账单周期</td>
                        <td class="bg-gray-100">应收时间</td>
                        <td class="bg-gray-100">应收金额</td>
                        <td class="bg-gray-100">应收金额调整为</td>
                        <td class="bg-gray-100">调整幅度</td>
                      </tr>
                      </thead>
                      <tbody *ngIf="leaseBillFlowAuditResponse.lease_bill_flow_audit_dtolist">
                      <tr *ngFor="let bill of leaseBillFlowAuditResponse.lease_bill_flow_audit_dtolist">
                        <td>{{bill.room_name}}</td>
                        <td>{{bill.bill_type_name}}</td>
                        <td>{{bill.bill_start_time|date:'yyyy-MM-dd'}}-{{bill.bill_end_time|date:'yyyy-MM-dd'}}</td>
                        <td>{{bill.plan_pay_time|date:'yyyy-MM-dd'}}</td>
                        <td>{{bill.bill_amount}}</td>
                        <td class="text-danger">{{bill.after_amount}}</td>
                        <td class="text-danger">{{bill.percent}}%</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12">
                    共计 <span
                    class="text-danger">{{leaseBillFlowAuditResponse.lease_bill_flow_audit_dtolist.length}}</span>
                    个账单，
                    调整差额总计 <span class="text-danger">{{leaseBillFlowAuditResponse.balance}}</span> 元，调整总幅度：
                    <span
                      class="text-danger">{{leaseBillFlowAuditResponse.balance_percent}}%</span>
                  </div>
                  <div class="col-12 mt-2">
                    <label>变更原因</label>
                    <div>
                      {{leaseBillFlowAuditResponse?.change_reason}}
                    </div>
                  </div>

                  <div class="col-12 mt-2" *ngIf="leaseBillFlowAuditResponse?.attachment_list">
                    <label>附件：</label>
                    <div>
                      <app-new-file-upload [type]="2"
                                           [accept]="'IMG'"
                                           [imgList]="leaseBillFlowAuditResponse?.attachment_list"
                                           [showDelBtn]="false"
                                           [showUploadBtn]="false"
                      >
                      </app-new-file-upload>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            <tab heading="审批记录信息">
              <app-approv-record-log-list
                [approvalResultResponse]="approvalResultResponse"></app-approv-record-log-list>
            </tab>
            <tab heading="审批流程图" (selectTab)="flowImgTabOnSelect(leaseBillFlowAuditResponse?.process_instance_id)">
              <app-approv-record-img *ngIf="leaseBillFlowAuditResponse"></app-approv-record-img>
            </tab>
          </tabset>
        </div>
        <hr>
        <div class="mt-2 mx-1 mx-md-5">
          <div class="row ">
            <div class="col-2">
              审批意见
            </div>
            <div class="col-10">
              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==true" (click)="auditCheck(true)"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>
              <button class="btn btn-info mx-2" *ngIf="auditStatus===true" (click)="auditCheck()"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>

              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==false" (click)="auditCheck(false)"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
              <button class="btn btn-danger mx-2" *ngIf="auditStatus===false" (click)="auditCheck()"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
            </div>

            <ng-container *ngIf="auditStatus===false">
              <div class="col-2 mt-2">
                拒绝原因
              </div>
              <div class="col-10 mt-2">
                <textarea class="w-100" [(ngModel)]="auditRejectReason">
              </textarea>
                <p class="text-danger" *ngIf="isAuditSubmit && !auditRejectReason">请输入拒绝原因</p>
              </div>
            </ng-container>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" (click)="auditSubmit(leaseBillFlowAuditResponse.appro_record_info_id)"
                    class="btn btn-primary">确定
            </button>
            <button type="button" class="btn btn-secondary ml-4" data-dismiss="modal"
                    (click)="showLeaseBillChangeAuditModal.hide()">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  关联房间  -->
<div class="modal fade" bsModal #relevanceRoomModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="relevanceRoomModal.hide();">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="confirm_scroll">
          <p>【{{relevanceRoomInfo?.gate_way_name}}】关联的房间号如下：</p>
          <div class="modal-body">
            <div class="col-12">
              <div class="row mb10">
                <ng-container *ngFor="let i of relevanceRoomInfo?.room_name_list">
                  <div class="col-12 col-md-3-1 mb10">
                    <div class="row">【{{i}}】</div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="part5">
          <div class="modal-footer text-center">
            <button type="button" class="btn btn-primary" (click)="relevanceRoomModal.hide();">关闭</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  房间使用期限变更审批  -->
<div class="modal fade " bsModal #showRoomAvailableDaysChangeAuditModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>
          房间使用期限调整审批（审批单号：{{changeRoomAvailableDaysFlowAuditResponse?.approv_num}}）</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="showRoomAvailableDaysChangeAuditModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body" *ngIf="changeRoomAvailableDaysFlowAuditResponse">

        <div class="">
          <tabset #staticTabs [justified]="true">
            <tab heading="申请信息">
              <div class="mt-2 mx-1 mx-md-5">
                <div class="row ">
                  <div class="col-4"><span
                    class="font-weight-bold">项目名称</span>：{{changeRoomAvailableDaysFlowAuditResponse?.project_name}}
                  </div>
                  <div class="col-4"><span class="font-weight-bold">合同号</span>：
                    <button class="btn btn-link py-0"
                            (click)="to_lease(changeRoomAvailableDaysFlowAuditResponse?.lease_id)">{{changeRoomAvailableDaysFlowAuditResponse?.contract_num}}</button>
                  </div>
                  <div class="col-4"><span
                    class="font-weight-bold">签约人</span>：{{changeRoomAvailableDaysFlowAuditResponse?.name}}</div>
                  <div class="col-4"><span
                    class="font-weight-bold">合同周期</span>：{{changeRoomAvailableDaysFlowAuditResponse?.start_time|date:'yyyy.MM.dd'}}
                    - {{changeRoomAvailableDaysFlowAuditResponse?.end_time|date:'yyyy.MM.dd'}}</div>

                  <div class="col-4"><span class="font-weight-bold">变更内容</span>：<span
                    class="text-danger">房间使用期限调整</span>
                  </div>
                  <div class="col-4"><span
                    class="font-weight-bold">提交人</span>：{{changeRoomAvailableDaysFlowAuditResponse?.submit_name}}
                  </div>
                  <div class="col-4"><span
                    class="font-weight-bold">提交时间</span>：{{changeRoomAvailableDaysFlowAuditResponse?.submit_time|date:'yyyy.MM.dd HH:mm:ss'}}
                  </div>

                  <div class="col-12 mt-2">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <td class="bg-gray-100" colspan="2">变更信息</td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="bg-gray-100" width="200px">变更前</td>
                        <td>首期房费在应付时间未付，仍可在未来<span
                          class="text-danger">{{changeRoomAvailableDaysFlowAuditResponse.change_before_dto.room_available_days}}</span>
                          天使用水电和门锁密码；{{changeRoomAvailableDaysFlowAuditResponse.change_before_dto.pay_end_date
                            | date:'yyyy.MM.dd'}}
                          过后仍未付清，系统将会对所有房间断水断电，作废门锁密码
                        </td>
                      </tr>
                      <tr>
                        <td class="bg-gray-100" width="200px">变更后</td>
                        <td>首期房费在应付时间未付，仍可在未来<span
                          class="text-danger">{{changeRoomAvailableDaysFlowAuditResponse.change_after_dto.room_available_days}}</span>天使用水电和门锁密码；{{changeRoomAvailableDaysFlowAuditResponse.change_after_dto.pay_end_date
                          | date:'yyyy.MM.dd'}}期限过后仍未付清，系统将会对所有房间断水断电，作废门锁密码
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 mt-2">
                    <label>变更原因</label>
                    <div>
                      {{changeRoomAvailableDaysFlowAuditResponse?.change_reason}}
                    </div>
                  </div>
                  <div class="col-12 mt-2" *ngIf="changeRoomAvailableDaysFlowAuditResponse?.attachment_list">
                    <label>附件：</label>
                    <div>
                      <app-new-file-upload [type]="2"
                                           [accept]="'IMG'"
                                           [imgList]="changeRoomAvailableDaysFlowAuditResponse?.attachment_list"
                                           [showDelBtn]="false"
                                           [showUploadBtn]="false"
                      >
                      </app-new-file-upload>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            <tab heading="审批记录信息">
              <app-approv-record-log-list
                [approvalResultResponse]="approvalResultResponse"></app-approv-record-log-list>
            </tab>
            <tab heading="审批流程图"
                 (selectTab)="flowImgTabOnSelect(changeRoomAvailableDaysFlowAuditResponse?.process_instance_id)">
              <app-approv-record-img *ngIf="changeRoomAvailableDaysFlowAuditResponse"></app-approv-record-img>
            </tab>
          </tabset>
        </div>
        <hr>
        <div class="mt-2 mx-1 mx-md-5">
          <div class="row ">
            <div class="col-2">
              审批意见
            </div>
            <div class="col-10">
              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==true" (click)="auditCheck(true)"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>
              <button class="btn btn-info mx-2" *ngIf="auditStatus===true" (click)="auditCheck()"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>

              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==false" (click)="auditCheck(false)"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
              <button class="btn btn-danger mx-2" *ngIf="auditStatus===false" (click)="auditCheck()"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
            </div>

            <ng-container *ngIf="auditStatus===false">
              <div class="col-2 mt-2">
                拒绝原因
              </div>
              <div class="col-10 mt-2">
                <textarea class="w-100" [(ngModel)]="auditRejectReason">
                </textarea>
                <p class="text-danger" *ngIf="isAuditSubmit && !auditRejectReason">请输入拒绝原因</p>
              </div>
            </ng-container>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" (click)="auditSubmit(changeRoomAvailableDaysFlowAuditResponse.appro_record_info_id)"
                    class="btn btn-primary">确定
            </button>
            <button type="button" class="btn btn-secondary ml-4" data-dismiss="modal"
                    (click)="showRoomAvailableDaysChangeAuditModal.hide()">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 线上收款弹窗 -->
<div class="modal fade" bsModal #notPayModal="bs-modal" [config]="{backdrop: 'static',keyboard: false}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>收款处理</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="notPayModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body p-5">
        <div class="row">
          <div class="col">支付时间：{{not_pay_dto.create_time | date:'yyyy.MM.dd HH:mm:ss' }}</div>
          <div class="col">
            支付方式：
            <span *ngIf="not_pay_dto.pay_channel == null">支付支付为空</span>
            <span *ngIf="not_pay_dto.pay_channel == 'ALIPAY'">支付宝收款</span>
            <span *ngIf="not_pay_dto.pay_channel == 'WEIXIN'">微信收款</span>
            <span *ngIf="not_pay_dto.pay_channel == 'CCBPAY'">建行龙支付收款</span>
          </div>
          <div class="col">支付金额：{{not_pay_dto.order_amount}}元</div>
        </div>
        <hr>
        <div class="text-orange">• 收款确认后资金将入账到对应的客户账户，且会自动关闭提醒，操作收款时可使用账户进行支付
        </div>
        <div class="text-orange">• 如有异常情况无需收款确认时，请手动关闭提醒</div>
        <div class="mt-5">
          处理结果:
          <label class="ml-3 mr-5 pointer">
            <input type="radio" name="cash" value="confirmMoney" [(ngModel)]="isConfirmMoney" [value]="true"/>确认收款
          </label>
          <label class="mr-5 pointer">
            <input type="radio" name="cash" value="closeRemind" [(ngModel)]="isConfirmMoney" [value]="false">关闭提醒
          </label>
        </div>
        <div *ngIf="isConfirmMoney">
          <div class="position-relative mt-3">
            付款客户:
            <input #clientUserNameRef type="text" [(ngModel)]="clientUserName" (input)="paymentMoneyDebounce()"
                   [disabled]="searchLoading" autofocus
                   placeholder="请精准输入客户全称（企业名称/个人姓名）" class="paymentStyle ml-3">
            <i class="fa fa-spin fa-spinner mx-1" *ngIf="searchLoading"></i>
            <div *ngIf="verifyStatement">
              <span class="text-danger verifyMargin mt-1 mr-1">*客户不存在，请确认客户名称是否正确</span>
              <i nz-icon nzType="question-circle" nzTheme="outline" (mouseover)="over(1)" (mouseout)="over(2)"></i>
              <div class="dissbox" [ngClass]="{'showTime': showTimeBlock}">
                <p class="mt-2">客户不存在场景：</p>
                <p>1、该客户未签约/入住，系统无该客户信息，您可先为该客户办理签约/入住；</p>
                <p>2、该客户已签约/入住，但是您输入的客户名称与客户办理签约/入住时记录的客户名称不一致，故无法匹配。</p>
              </div>
            </div>
          </div>
          <div class="mt-3">
            证件号码:
            <input #certCodeRef type="text" [(ngModel)]="certCode" (input)="cardNumberDebunce()"
                   [disabled]="searchLoading" autofocus
                   placeholder="请输入客户证件号码（统一信用代码/身份证号/护照号）" class="paymentStyle ml-3">
            <i class="fa fa-spin fa-spinner mx-1" *ngIf="searchLoading"></i>
            <div class="text-danger verifyMargin" *ngIf="cardVerify">*证件号错误，请确认该客户的证件号是否正确</div>
          </div>
          <div class="mt-3" *ngIf="clientNameCertcodeRsp?.exist_multiple_accounts">
            客商账户:
            <nz-select nzPlaceHolder="请选择资金归属账户名"
                       [(ngModel)]="userNameMoney"
                       (ngModelChange)="shiftFocus($event)"
                       class="paymentStyle ml-3"
                       [nzBorderless]="true">
              <nz-option *ngFor="let item of clientNameCertcodeRsp?.account_money_list"
                         [nzLabel]="item.account_money_name" [nzValue]="item.account_money_info_id"></nz-option>
            </nz-select>
          </div>
        </div>
        <div *ngIf="!isConfirmMoney">
          <div class="mt-3">
            关闭原因:
            <nz-select [(ngModel)]="not_pay_dto.remark" nzPlaceHolder="请选择关闭原因" class="paymentStyle ml-3"
                       [nzBorderless]="true">
              <nz-option nzValue="技术部测试金额" nzLabel="技术部测试金额"></nz-option>
              <nz-option nzValue="已按其它支付方式收款" nzLabel="已按其它支付方式收款"></nz-option>
              <nz-option nzValue="支付金额已退款" nzLabel="支付金额已退款"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="btn_privileges.SWEEP_CODE_PAY_HANDLE && isConfirmMoney" type="button" class="btn btn-primary"
                (click)="submitMoney()">提交
        </button>
        <button *ngIf="btn_privileges.SWEEP_CODE_PAY_HANDLE && !isConfirmMoney" type="button" class="btn btn-primary"
                (click)="saveNotPay()">提交
        </button>
        <button *ngIf="btn_privileges.SWEEP_CODE_PAY_HANDLE" type="button" class="btn btn-secondary"
                data-dismiss="modal" (click)="notPayModal.hide()">取消
        </button>

      </div>
    </div>
  </div>
</div>
<!-- 收款录入 -->

<div class="modal fade" bsModal #collectionMoney="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header bg-danger">
        <h4 class="modal-title pull-left"><i class="fa fa-edit" aria-hidden="true"></i>收款录入</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="collectionMoney.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="fileBox">
          <p class="mb-3">请上传付款凭证
            <span class="text-danger mr-1">*</span>
            <span class="text-gray-300">（每张限15M以内）</span>
          </p>
          <div class="uploads">
            <app-new-file-upload #appFileUpload [type]="2" [name]="'上传'" [accept]="'IMG'" [maxLength]="1"
                                 [imgList]="[license]" [showViewBtn]="true" [showUploadBtn]="true" [showDelBtn]="true"
                                 (fileChange)="fileChange($event,'license')">
            </app-new-file-upload>
          </div>
        </div>
        <div>
          <p class="font-weight-bold mt-3">请认真核对付款信息：</p>
          <div class="inputBox">
            <div class="mb-3">
              <div class="position-relative mt-3">
                <span class="mr-2">付款客户</span>
                <span class="text-danger mr-1">*</span>
                <input type="text" [(ngModel)]="accountMoneyName" (change)="collectionmentMoney()" maxlength="30"
                       placeholder="请输入客户名称" class="paymentStyle ml-3">
                <div *ngIf="!collectionverify">
                  <span class="text-danger verifyMargin mt-1 mr-1">*客户不存在，请确认客户名称是否正确</span>
                  <i nz-icon nzType="question-circle" nzTheme="outline" (mouseover)="over(1)" (mouseout)="over(2)"></i>
                  <div class="dissbox" [ngClass]="{'showTime': showTimeBlock}">
                    <p class="mt-2">客户不存在场景：</p>
                    <p>1、该客户未签约/入住，系统无该客户信息，您可先为该客户办理签约/入住；</p>
                    <p>2、该客户已签约/入住，但是您输入的客户名称与客户办理签约/入住时记录的客户名称不一致，故无法匹配。</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <span class="mr-2">客户账户</span>
              <span class="text-danger mr-1">*</span>
              <nz-select nzPlaceHolder="请选择资金归属账户名"
                         class="paymentStyle ml-3"
                         [(ngModel)]="clientUserNameMoneyId"
                         (ngModelChange)="clientshiftFocus($event)"
                         [nzBorderless]="true">
                <nz-option *ngFor="let option of queryAccountNameRsp?.account_money_list"
                           [nzLabel]="option.account_money_name" [nzValue]="option.account_money_info_id"></nz-option>
              </nz-select>
            </div>
            <div class="mb-3">
              <span class="mr-2">付款方式</span>
              <span class="text-danger mr-1">*</span>
              <label class="checkbox-inline ml-3">
                <input type="radio" name="type" value="转账" checked/>转账
              </label>
            </div>
            <div class="mb-3 ">
              <span class="mr-2">付款金额</span>
              <span class="text-danger mr-1">*</span>
              <input type="text" [(ngModel)]="uploadPaymentCertificateRsp.payment_amount"
                     value="{{uploadPaymentCertificateRsp.payment_amount}}" placeholder="请输入客户付款金额"
                     class="paymentStyle  ml-3">元
            </div>
            <div class="mb-3 ">
              <span class="mr-2">回执单号</span>
              <span class="text-danger mr-1">*</span>
              <input type="text" [(ngModel)]="uploadPaymentCertificateRsp.receipt_num"
                     placeholder="请输入转账回执单号"
                     class="paymentStyle  ml-3">
            </div>
            <p style="color: red">{{zzErrorText}}</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <!--        <button class="btn mr-3" *ngIf="!isBtn">确定</button>-->
        <button (click)="submitUser()" class="btn btn-danger mr-3">确定</button>
        <button class="btn" (click)="collectionMoney.hide()">取消</button>
      </div>
    </div>
  </div>
</div>
<!--冻结密码弹窗-->
<div class="modal fade" bsModal #frozenPasswordModal="bs-modal" [config]="{backdrop: 'static',keyboard: false}"
     tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 *ngIf="frozenPwdOperation == 'FROZEN_PWD'" class="modal-title pull-left"><i class="fa fa-info-circle"
                                                                                        aria-hidden="true"></i>冻结门锁密码
        </h4>
        <h4 *ngIf="frozenPwdOperation == 'UNFROZEN_PWD'" class="modal-title pull-left"><i class="fa fa-info-circle"
                                                                                          aria-hidden="true"></i>解冻门锁密码
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="frozenPasswordModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="row">
          <div class="col-12 text-blue text-right" (click)="openOperationLogModal()">
            <button class="btn btn-link">查看操作历史</button>
          </div>
        </div>
        <div class="row p-3">
          <div class="col-12 bold font-xl">您当前正在为<span class="text-green font-xl">【{{frozenPwdSignName}}】</span>的
            <div class="d-inline-block"
                 *ngIf="frozenPwdOperation == 'FROZEN_PWD' || frozenPwdOperation == 'UNFROZEN_PWD'">
              <span class="text-green font-xl">【{{frozenPwdContractNum}}】</span>
            </div>
            <span *ngIf="frozenPwdOperation == 'FROZEN_PWD'" class="font-xl">合同操作冻结门锁密码</span>
            <span *ngIf="frozenPwdOperation == 'UNFROZEN_PWD'" class="font-xl">合同操作解冻门锁密码</span>
          </div>
          <div *ngIf="frozenPwdOperation == 'FROZEN_PWD'" class="col-12 text-center mt-4 mb-3">
            冻结门锁密码后，
            <span *ngIf="frozenReason == 'RENT_OVERDUE'" class="text-danger">逾期未缴纳房费账单</span>
            <span *ngIf="frozenReason == 'LEASE_OVERDUE'" class="text-danger">逾期未退</span>
            房间将会作废现有门锁密码，客户将无法正常进出房间
          </div>
          <div *ngIf="frozenPwdOperation == 'UNFROZEN_PWD'" class="col-12 text-center mt-4 mb-3">
            解冻门锁密码后，对应房间将会恢复有效密码,客户可正常进出房间
          </div>
          <div *ngIf="frozenPwdOperation == 'FROZEN_PWD'" class="col-12 text-center">是否确认冻结?</div>
          <div *ngIf="frozenPwdOperation == 'UNFROZEN_PWD'" class="col-12 text-center">是否确认解冻?</div>
          <div class="col-12 mb10" *ngIf="frozenPwdOperation == 'FROZEN_PWD'">
            <label>附件：</label>
            <div>
              <app-new-file-upload [type]="2" [imgList]=this.frozenPwdAuditRequest.imgs
                                   accept="IMG|PDF|WORD|EXCEL|TXT|PPT"
                                   (fileChange)="setFiles1($event)"></app-new-file-upload>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button *ngIf="frozenPwdOperation == 'FROZEN_PWD'" type="button" class="btn btn-primary"
                (click)="confirmFrozen()">下一步
        </button>
        <button *ngIf="frozenPwdOperation == 'UNFROZEN_PWD'" type="button" class="btn btn-primary"
                (click)="unFrozenPwd()">确认
        </button>
        <button type="button" class="btn btn-secondary"
                data-dismiss="modal" (click)="frozenPasswordModal.hide()">取消
        </button>

      </div>
    </div>
  </div>
</div>
<!--冻结申请-->
<div class="modal fade" bsModal #frozenPasswordAuditModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header bg-warning">
        <h4 class="modal-title pull-left"><i class="fa fa-edit" aria-hidden="true"></i>冻结申请</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="frozenPasswordAuditModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div>您正在操作冻结房间门锁密码申请，请进行管理人员短信验证：</div>
        <div class=" mt-3">
          <div class="w200px ml-1">
            <select class="w-100" [(ngModel)]="frozenPwdAuditRequest.phone">
              <option value="">请选择</option>
              <option *ngFor="let man of auditList" [value]="man.user_phone">{{man.user_name}}({{man.user_phone}})
              </option>
            </select>
            <div *ngIf="!frozenPwdAuditRequest.phone" class="text-danger">审核用户不能为空</div>
          </div>
          <div class="w200px ml-1">
            <input class="w-100" type="text" placeholder="请输入短信验证码" maxlength="4"
                   [(ngModel)]="frozenPwdAuditRequest.code">
            <div *ngIf="!frozenPwdAuditRequest.code" class="text-danger">验证码不能为空</div>
            <div *ngIf="frozenPwdResult != 'SUCCESS' && frozenPwdAuditRequest.code"
                 class="text-danger">{{frozenPwdResult}}</div>
          </div>
          <ng-container>
            <button class="btn btn-primary ml-1" [disabled]="gray_btn"
                    (click)="getFrozenPwdAuditCode()">{{codeBtnStr}}</button>
          </ng-container>


        </div>
      </div>
      <div class="modal-footer">

        <button (click)="confirmFrozen()" class="btn btn-primary mr-3">确认冻结</button>
        <button class="btn" (click)="frozenPasswordAuditModal.hide()">取消</button>
      </div>
    </div>
  </div>
</div>
<!--冻结/解冻密码操作历史-->
<div class="modal fade" bsModal #frozenPwdOperationLogModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999" (click)="stopP($event)">
  <div class="modal-dialog modal-primary ">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>
          操作历史记录
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="frozenPwdOperationLogModal.hide()">
          <span aria-="true"><i class="icon icon-close" aria-="true"></i></span>
        </button>
      </div>
      <div class="model-body bg-white">
        <div class="col-12">
          <div class="row scrollx-head">
            <table class="table  table-fix-scroll sm-scroll scrollx-body scrollxy-width80">
              <thead>
              <tr>
                <th>操作时间</th>
                <th>操作人</th>
                <th>操作类型</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngIf="operationLogs && operationLogs.length > 0">
                <tr *ngFor="let item of operationLogs">
                  <td>{{item.create_time |date:'yyyy.MM.dd HH:mm:ss'}}</td>
                  <td>{{item.operation_peoson}}</td>
                  <td>{{item.operation_content}}</td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="frozenPwdOperationLogModal.hide()">关闭
        </button>
      </div>
    </div>
  </div>
</div>
<!--冻结密码提交申请成功弹框-->
<div class="modal fade " bsModal #approvModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary ">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="approvModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">

        <div class="col mt-2">
          <div class="row mx-5">
            <div class="text-center mt-2">
              冻结密码申请已提交成功,审批单号：<span class="text-danger">{{approvNum}}</span>
            </div>
            <div class="text-center my-2">
              冻结密码需走审批流程，您可在【合同详情-审批记录】模块查看审批进度。
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="approvModal.hide()"
                    class="btn btn-primary">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--冻结密码申请弹框-->
<div class="modal fade " bsModal #showFrozenPasswordAuditModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>
          冻结密码申请（审批单号：{{frozenPasswordFlowAuditResponse?.approv_num}}）</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="showFrozenPasswordAuditModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body" *ngIf="frozenPasswordFlowAuditResponse">
        <div>
          <tabset #staticTabs [justified]="true">
            <tab heading="申请信息">
              <div class="col-12 mb-10">
                <div class="row ">
                  <div class="col-4"><span class="font-weight-bold">申请内容</span>：
                    <span class="text-danger">冻结门锁密码</span>
                  </div>
                  <div class="col-4"><span
                    class="font-weight-bold">提交人</span>：{{frozenPasswordFlowAuditResponse?.submit_name}}
                  </div>
                  <div class="col-4"><span class="font-weight-bold">提交时间</span>：
                    {{frozenPasswordFlowAuditResponse?.submit_time|date:'yyyy.MM.dd HH:mm:ss'}}
                  </div>

                  <div class="col-12 mt-5">
                    <label class="font-weight-bold">申请原因：</label>
                    <div *ngIf="frozenPasswordFlowAuditResponse.frozen_type=='RENT_OVERDUE'">
                      由于【{{frozenPasswordFlowAuditResponse.sign_name}}】的
                      <button class="btn-link pl-0 pr-0"
                              (click)="to_lease(frozenPasswordFlowAuditResponse?.lease_id)">
                        【{{frozenPasswordFlowAuditResponse?.contract_num}}】
                      </button>
                      合同
                      <span class="text-danger">逾期未缴纳房费账单</span>， 申请将该合同下未付房费账单的房间进行冻结门锁密码
                    </div>
                    <div *ngIf="frozenPasswordFlowAuditResponse.frozen_type=='LEASE_OVERDUE'">
                      由于【{{frozenPasswordFlowAuditResponse.sign_name}}】的
                      <button class="btn-link  pl-0 pr-0"
                              (click)="to_lease(frozenPasswordFlowAuditResponse?.lease_id)">
                        【{{frozenPasswordFlowAuditResponse?.contract_num}}】
                      </button>
                      合同
                      <span class="text-danger">逾期未退</span>， 申请将该合同下未付房费账单的房间进行冻结门锁密码
                    </div>
                    <div *ngIf="frozenPasswordFlowAuditResponse.frozen_type=='SINGLE_FROZEN'">
                      申请将【{{frozenPasswordFlowAuditResponse.sign_name}}】的
                      <button class="btn-link pl-0 pr-0"
                              (click)="to_lease(frozenPasswordFlowAuditResponse?.lease_id)">
                        【{{frozenPasswordFlowAuditResponse?.contract_num}}】
                      </button>
                      合同下
                      <span class="text-danger">【{{frozenPasswordFlowAuditResponse.room_name}}】</span>房间进行冻结门锁密码
                    </div>
                  </div>
                  <div class="col-12 mt-2" *ngIf="frozenPasswordFlowAuditResponse?.attachment_list">
                    <label>附件：</label>
                    <div>
                      <app-new-file-upload [type]="2"
                                           [accept]="'IMG'"
                                           [imgList]="frozenPasswordFlowAuditResponse?.attachment_list"
                                           [showDelBtn]="false"
                                           [showUploadBtn]="false"
                      >
                      </app-new-file-upload>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            <tab heading="审批记录信息">
              <app-approv-record-log-list
                [approvalResultResponse]="approvalResultResponse"></app-approv-record-log-list>
            </tab>
            <tab heading="审批流程图"
                 (selectTab)="flowImgTabOnSelect(frozenPasswordFlowAuditResponse?.process_instance_id)">
              <app-approv-record-img *ngIf="frozenPasswordFlowAuditResponse"></app-approv-record-img>
            </tab>
          </tabset>
        </div>
        <hr>
        <div>
          <div class="row ">
            <div class="col-2">
              审批意见
            </div>
            <div class="col-10">
              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==true" (click)="auditCheck(true)"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>
              <button class="btn btn-info mx-2" *ngIf="auditStatus===true" (click)="auditCheck()"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>

              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==false" (click)="auditCheck(false)"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
              <button class="btn btn-danger mx-2" *ngIf="auditStatus===false" (click)="auditCheck()"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
            </div>

            <ng-container *ngIf="auditStatus===false">
              <div class="col-2 mt-2">
                拒绝原因
              </div>
              <div class="col-10 mt-2">
                <textarea class="w-100" [(ngModel)]="auditRejectReason">
                </textarea>
                <p class="text-danger" *ngIf="isAuditSubmit && !auditRejectReason">请输入拒绝原因</p>
              </div>
            </ng-container>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" (click)="auditSubmit(frozenPasswordFlowAuditResponse.appro_record_info_id)"
                    class="btn btn-primary">确定
            </button>
            <button type="button" class="btn btn-secondary ml-4" data-dismiss="modal"
                    (click)="showFrozenPasswordAuditModal.hide()">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--合同到期备注详情-->
<div class="modal fade " bsModal #remarkDetailModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary ">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>备注详情</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideRemarkDetailModal()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">

        <div class="col mt-2">
          <div class="font-weight-bold">进展详情：</div>
          <ng-container *ngIf="evolveDTOS && evolveDTOS.length >= 0">
            <div class="pb-3" *ngFor="let item of evolveDTOS;let i = index">
              <div class="row mx-1 mt-1">
                <div class="col-3 text-gray-300">{{item?.create_time|date:'yyyy.MM.dd HH:mm:ss'}}</div>
                <div class="col-9 text-gray-300">{{item.handler}}</div>
                <div class="col-12">{{item.remark}}</div>
              </div>
            </div>
          </ng-container>
          <hr>
          <div class="row mb10">
            <div class="col-12 mb10">增加进展：</div>
            <div class="col-12">
              <textarea class="w-100" rows="5" placeholder="请输入备注内容，限300字"
                        [(ngModel)]="evolveRemark"></textarea>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="confirmRemarkDetailModal()"
                    class="btn btn-primary mr-3">确认
            </button>
            <button type="button"
                    (click)="hideRemarkDetailModal()"
                    class="btn btn-secondary">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade " bsModal #leaseCheckoutNotificationModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary ">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>合同到期通知函</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="hideLeaseCheckoutNotificationModal()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">

        <div class="col mt-2">
          <div class="row yellow">
            <div>
              发送记录：
            </div>
            <div>
              <nz-steps nzSize="small">
                <ng-container *ngFor="let item of workbenchEmailHistoryDTOS">
                  <nz-step [nzStatus]="'process'" [nzTitle]=item.user_name
                           [nzDescription]="item.create_time | date:'yyyy-MM-dd'"></nz-step>
                </ng-container>
              </nz-steps>
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-right">收件人:</div>
            <div class="d-inline-block col-8 pl-0">
              <input class="w-100" [(ngModel)]="mailAddress" maxlength="300"
                     placeholder="请输入邮箱地址，多个邮箱之间请用分号“；”隔开">
            </div>
          </div>
          <hr>
          <div class="row mt-2">
            <div class="col-2 text-right">主题:</div>
            <div class="d-inline-block col-8 pl-0">
              <input class="w-100" [(ngModel)]="mailSubject" maxlength="300">
            </div>
          </div>
          <hr>
          <div class="row mt-2">
            <div class="col-2 text-right">正文:</div>
            <div class="d-inline-block col-8 pl-0">
              <div class="full">
                <umeditor (onReady)="fullInit()" [(ngModel)]="mailText" #fullText></umeditor>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="confirmSendEmail()"
                    class="btn btn-primary mr-3">发送
            </button>
            <button type="button"
                    (click)="hideLeaseCheckoutNotificationModal()"
                    class="btn btn-secondary">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade " bsModal #showLockPasswordModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>
          密码申请（审批单号：{{approLockPasswordRes?.approv_num}}）</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="showLockPasswordModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div>
          <tabset #staticTabs_p [justified]="true">
            <tab heading="申请信息" #tab_p>
              <div class="row" style="padding-left: 5px;">
                <div class="col-12 col-md-4 col-lg-4 mb10">审批类型：密码申请审批</div>
                <div class="col-12 col-md-4 col-lg-4 mb10">申请内容：{{approLockPasswordRes?.password_type}}</div>
                <div class="col-12 col-md-4 col-lg-4 mb10">
                  提交时间：{{approLockPasswordRes?.submit_time | date:"yyyy-MM-dd HH:mm:ss"}}</div>
                <div class="col-12 col-md-4 col-lg-4 mb10">提交人：{{approLockPasswordRes?.create_user_name}}</div>
                <div *ngIf="approLockPasswordRes?.room_count === 1" class="col-12 col-md-4 col-lg-4 mb10">
                  申请房间：{{approLockPasswordRes?.room_name}}</div>
                <div *ngIf="approLockPasswordRes?.room_count > 1" class="col-12 col-md-4 col-lg-4 mb10">
                  申请房间数：{{approLockPasswordRes?.room_count}}间
                </div>
                <div class="col-12 col-md-4 col-lg-4 mb10">密码有效时间：{{approLockPasswordRes?.time_text}}</div>
              </div>
              <div style="padding-left: 5px;">
                <p style="word-wrap: break-word">备注信息：{{approLockPasswordRes?.remark}}</p>
              </div>
              <div *ngIf="approLockPasswordRes?.unit_room_list">
                <table class="table table-bordered">
                  <tbody>
                  <tr>
                    <td>房间楼栋</td>
                    <td>房间号</td>
                  </tr>
                  <tr *ngFor="let info of approLockPasswordRes?.unit_room_list">
                    <td>{{info?.unit_name}}</td>
                    <td>
                      <span *ngFor="let rn of info?.room_names;let i=index">
                        {{i !== 0 ? "、" : ""}}
                        {{rn}}
                      </span>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>

            </tab>
            <tab heading="审批记录信息">
              <app-approv-record-log-list
                [approvalResultResponse]="approvalResultResponse"></app-approv-record-log-list>
            </tab>
            <tab heading="审批流程图" (selectTab)="flowImgTabOnSelect(approLockPasswordRes?.process_instance_id)">
              <app-approv-record-img *ngIf="approLockPasswordRes"></app-approv-record-img>
            </tab>
          </tabset>
        </div>
        <hr>
        <div>
          <div class="row ">
            <div class="col-2">
              审批意见
            </div>
            <div class="col-10">
              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==true" (click)="auditCheck(true)"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>
              <button class="btn btn-info mx-2" *ngIf="auditStatus===true" (click)="auditCheck()"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>

              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==false" (click)="auditCheck(false)"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
              <button class="btn btn-danger mx-2" *ngIf="auditStatus===false" (click)="auditCheck()"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
            </div>

            <ng-container *ngIf="auditStatus===false">
              <div class="col-2 mt-2">
                拒绝原因
              </div>
              <div class="col-10 mt-2">
                <textarea class="w-100" [(ngModel)]="auditRejectReason">
                </textarea>
                <p class="text-danger" *ngIf="isAuditSubmit && !auditRejectReason">请输入拒绝原因</p>
              </div>
            </ng-container>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" (click)="auditSubmit(approLockPasswordRes?.appro_record_info_id)"
                    class="btn btn-primary">确定
            </button>
            <button type="button" class="btn btn-secondary ml-4" data-dismiss="modal"
                    (click)="showLockPasswordModal.hide()">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade " bsModal #renterCheckoutDepositModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>
          住客退押审批（审批单号：{{approRenterCheckoutDeposit?.approv_num}}）</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="renterCheckoutDepositModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div>
          <tabset #staticTabs_p [justified]="true">
            <tab heading="申请信息" #tab_p>
              <div class="row" style="padding-left: 5px;">
                <div class="col-12 col-md-4 col-lg-4 mb10">审批类型：住客退押审批</div>
                <div class="col-12 col-md-4 col-lg-4 mb10">提交人：{{approRenterCheckoutDeposit?.commit_user}}</div>
                <div class="col-12 col-md-4 col-lg-4 mb10">
                  提交时间：{{approRenterCheckoutDeposit?.commit_time | date:"yyyy-MM-dd HH:mm:ss"}}</div>
                <div class="col-12 col-md-4 col-lg-4 mb10">住客姓名：{{approRenterCheckoutDeposit?.renter_name}}</div>
                <div class="col-12 col-md-4 col-lg-4 mb10">房间号：{{approRenterCheckoutDeposit?.room_name}}</div>
                <div class="col-12 col-md-4 col-lg-4 mb10">
                  退宿日期：{{approRenterCheckoutDeposit?.checkout_time | date:"yyyy-MM-dd"}}</div>
                <div style="cursor:pointer" (click)="goRenterCheckout(approRenterCheckoutDeposit?.renter_checkout_id)"
                     class="col-12 col-md-4 col-lg-4 mb10">退宿单号：<span
                  style="color: #1a7fb0">{{approRenterCheckoutDeposit?.renter_checkout_number}}</span></div>
                <div class="col-12 col-md-4 col-lg-4 mb10">
                  退款方式：{{approRenterCheckoutDeposit?.checkout_bill_pay_type_enum}}</div>
                <div class="col-12 col-md-4 col-lg-4 mb10">退款金额：{{approRenterCheckoutDeposit?.total}}</div>
              </div>
            </tab>
            <tab heading="审批记录信息">
              <app-approv-record-log-list
                [approvalResultResponse]="approvalResultResponse"></app-approv-record-log-list>
            </tab>
            <tab heading="审批流程图" (selectTab)="flowImgTabOnSelect(approRenterCheckoutDeposit?.process_instance_id)">
              <app-approv-record-img *ngIf="approRenterCheckoutDeposit"></app-approv-record-img>
            </tab>
          </tabset>
        </div>
        <hr>
        <div>
          <div class="row ">
            <div class="col-2">
              审批意见
            </div>
            <div class="col-10">
              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==true" (click)="auditCheck(true)"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>
              <button class="btn btn-info mx-2" *ngIf="auditStatus===true" (click)="auditCheck()"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>

              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==false" (click)="auditCheck(false)"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
              <button class="btn btn-danger mx-2" *ngIf="auditStatus===false" (click)="auditCheck()"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
            </div>

            <ng-container *ngIf="auditStatus===false">
              <div class="col-2 mt-2">
                拒绝原因
              </div>
              <div class="col-10 mt-2">
                <textarea class="w-100" [(ngModel)]="auditRejectReason">
                </textarea>
                <p class="text-danger" *ngIf="isAuditSubmit && !auditRejectReason">请输入拒绝原因</p>
              </div>
            </ng-container>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" (click)="auditSubmit(approRenterCheckoutDeposit?.appro_record_info_id)"
                    class="btn btn-primary">确定
            </button>
            <button type="button" class="btn btn-secondary ml-4" data-dismiss="modal"
                    (click)="renterCheckoutDepositModal.hide()">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade " bsModal #projectBasePriceModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>
          项目基础价格变更（审批单号：{{approProjectBasePrice?.approv_num}}）</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="projectBasePriceModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div>
          <tabset #staticTabs_project [justified]="true">
            <tab heading="申请信息" #tab_p>
              <div>{{approProjectBasePrice?.project_name}}</div>
              <table class="table table-bordered">
                <thead class="thead-light">
                <tr>
                  <td>调整项</td>
                  <td>调整前</td>
                  <td>调整后</td>
                </tr>
                </thead>
                <tbody>

                <tr *ngIf="approProjectBasePrice?.electric_price || approProjectBasePrice?.old_electric_price">
                  <td>电费定价</td>
                  <td *ngIf="approProjectBasePrice?.old_electric_price">
                    <p *ngIf="approProjectBasePrice?.old_electric_price?.unitive">
                      统一定价：{{approProjectBasePrice?.old_electric_price?.price}}元/度</p>
                    <p *ngIf="!approProjectBasePrice?.old_electric_price?.unitive">
                      峰谷平定价：
                    </p>
                    <p *ngIf="approProjectBasePrice?.old_electric_price?.feng_price">
                      峰{{approProjectBasePrice?.old_electric_price?.feng_price.price}}元/度</p>
                    <p *ngIf="approProjectBasePrice?.old_electric_price?.ping_price">
                      平{{approProjectBasePrice?.old_electric_price?.ping_price.price}}元/度</p>
                    <p *ngIf="approProjectBasePrice?.old_electric_price?.gu_price">
                      谷{{approProjectBasePrice?.old_electric_price?.gu_price.price}}元/度</p>
                    <p *ngIf="approProjectBasePrice?.old_electric_price?.feng_price">峰时段 <span
                      *ngFor="let time of approProjectBasePrice?.old_electric_price?.feng_price.time_list">{{time?.start_time}}
                      - {{time?.end_time}}&nbsp;</span></p>
                    <p *ngIf="approProjectBasePrice?.old_electric_price?.ping_price">平时段 <span
                      *ngFor="let time of approProjectBasePrice?.old_electric_price?.ping_price.time_list">{{time?.start_time}}
                      - {{time?.end_time}}&nbsp;</span></p>
                    <p *ngIf="approProjectBasePrice?.old_electric_price?.gu_price">谷时段 <span
                      *ngFor="let time of approProjectBasePrice?.old_electric_price?.gu_price.time_list">{{time?.start_time}}
                      - {{time?.end_time}}&nbsp;</span></p>
                  </td>
                  <td *ngIf="!approProjectBasePrice?.old_electric_price">未定价</td>
                  <td *ngIf="approProjectBasePrice?.electric_price">
                    <p *ngIf="approProjectBasePrice?.electric_price?.unitive">
                      统一定价： {{approProjectBasePrice?.electric_price?.price}}元/度</p>
                    <p *ngIf="!approProjectBasePrice?.electric_price?.unitive">
                      峰谷平定价
                    </p>
                    <p *ngIf="approProjectBasePrice?.electric_price?.feng_price">
                      峰{{approProjectBasePrice?.electric_price?.feng_price.price}}元/度</p>
                    <p *ngIf="approProjectBasePrice?.electric_price?.ping_price">
                      平{{approProjectBasePrice?.electric_price?.ping_price.price}}元/度</p>
                    <p *ngIf="approProjectBasePrice?.electric_price?.gu_price">
                      谷{{approProjectBasePrice?.electric_price?.gu_price.price}}元/度</p>
                    <p *ngIf="approProjectBasePrice?.electric_price?.feng_price">峰时段 <span
                      *ngFor="let time of approProjectBasePrice?.electric_price?.feng_price?.time_list">{{time?.start_time}}
                      - {{time?.end_time}}&nbsp;</span></p>
                    <p *ngIf="approProjectBasePrice?.electric_price?.ping_price">平时段 <span
                      *ngFor="let time of approProjectBasePrice?.electric_price?.ping_price?.time_list">{{time?.start_time}}
                      - {{time?.end_time}}&nbsp;</span></p>
                    <p *ngIf="approProjectBasePrice?.electric_price?.gu_price">谷时段 <span
                      *ngFor="let time of approProjectBasePrice?.electric_price?.gu_price?.time_list">{{time?.start_time}}
                      - {{time?.end_time}}&nbsp;</span></p>
                  </td>
                  <td *ngIf="approProjectBasePrice?.old_electric_price && !approProjectBasePrice?.electric_price">
                    删除该费用项（审批通过后立即生效）
                  </td>
                </tr>
                <tr *ngIf="approProjectBasePrice?.water_price || approProjectBasePrice?.old_water_price">
                  <td>水费定价</td>
                  <td *ngIf="approProjectBasePrice?.old_water_price">
                    <p>{{approProjectBasePrice?.old_water_price?.price}}元/吨</p></td>
                  <td *ngIf="!approProjectBasePrice?.old_water_price">未定价</td>
                  <td *ngIf="approProjectBasePrice?.water_price"><p>{{approProjectBasePrice?.water_price?.price}}
                    元/吨</p></td>
                  <td *ngIf="approProjectBasePrice?.old_water_price && !approProjectBasePrice?.water_price">
                    删除该费用项（审批通过后立即生效）
                  </td>
                </tr>
                <tr *ngIf="approProjectBasePrice?.hot_water_price || approProjectBasePrice?.old_hot_water_price">
                  <td>热水费定价</td>
                  <td *ngIf="approProjectBasePrice?.old_hot_water_price">
                    <p>{{approProjectBasePrice?.old_hot_water_price?.price}}元/吨</p></td>
                  <td *ngIf="!approProjectBasePrice?.old_hot_water_price">未定价</td>
                  <td *ngIf="approProjectBasePrice?.hot_water_price">
                    <p>{{approProjectBasePrice?.hot_water_price?.price}}元/吨</p></td>
                  <td *ngIf="approProjectBasePrice?.old_hot_water_price && !approProjectBasePrice?.hot_water_price">
                    删除该费用项（审批通过后立即生效）
                  </td>
                </tr>
                <tr *ngIf="approProjectBasePrice?.room_heating_price || approProjectBasePrice?.old_room_heating_price">
                  <td>房间暖气费定价</td>
                  <td *ngIf="approProjectBasePrice?.old_room_heating_price">
                    <p>{{approProjectBasePrice?.old_room_heating_price?.price}}元/平米/月</p>
                    <p>收费期间：{{approProjectBasePrice?.old_room_heating_price?.start_time}}
                      至{{approProjectBasePrice?.old_room_heating_price?.end_time}}</p>
                  </td>
                  <td *ngIf="!approProjectBasePrice?.old_room_heating_price">未定价</td>
                  <td *ngIf="approProjectBasePrice?.room_heating_price">
                    <p>{{approProjectBasePrice?.room_heating_price?.price}}元/平米/月</p>
                    <p>收费期间：{{approProjectBasePrice?.room_heating_price?.start_time}}
                      至{{approProjectBasePrice?.room_heating_price?.end_time}}</p>
                  </td>
                  <td
                    *ngIf="approProjectBasePrice?.old_room_heating_price && !approProjectBasePrice?.room_heating_price">
                    删除该费用项（审批通过后立即生效）
                  </td>
                </tr>
                <tr
                  *ngIf="approProjectBasePrice?.public_heating_price || approProjectBasePrice?.old_public_heating_price">
                  <td>公区暖气费定价</td>
                  <td *ngIf="approProjectBasePrice?.old_public_heating_price">
                    <p>{{approProjectBasePrice?.old_public_heating_price?.price}}元/平米/月</p>
                    <p>收费期间：{{approProjectBasePrice?.old_public_heating_price?.start_time}}
                      至{{approProjectBasePrice?.old_public_heating_price?.end_time}}</p>
                  </td>
                  <td *ngIf="!approProjectBasePrice?.old_public_heating_price">未定价</td>
                  <td *ngIf="approProjectBasePrice?.public_heating_price">
                    <p>{{approProjectBasePrice?.public_heating_price?.price}}元/平米/月</p>
                    <p>收费期间：{{approProjectBasePrice?.public_heating_price?.start_time}}
                      至{{approProjectBasePrice?.public_heating_price?.end_time}}</p>
                  </td>
                  <td
                    *ngIf="approProjectBasePrice?.old_public_heating_price && !approProjectBasePrice?.public_heating_price">
                    删除该费用项（审批通过后立即生效）
                  </td>
                </tr>
                <tr *ngIf="approProjectBasePrice?.change_room_price || approProjectBasePrice?.old_change_room_price">
                  <td>换房费定价</td>
                  <td *ngIf="approProjectBasePrice?.old_change_room_price">
                    <p>{{approProjectBasePrice?.old_change_room_price?.price}}元</p></td>
                  <td *ngIf="!approProjectBasePrice?.old_change_room_price">未定价</td>
                  <td *ngIf="approProjectBasePrice?.change_room_price">
                    <p>{{approProjectBasePrice?.change_room_price?.price}}元</p></td>
                  <td *ngIf="approProjectBasePrice?.old_change_room_price && !approProjectBasePrice?.change_room_price">
                    删除该费用项（审批通过后立即生效）
                  </td>
                </tr>
                <tr *ngIf="approProjectBasePrice?.sublet_price || approProjectBasePrice?.old_sublet_price">
                  <td>转租费定价</td>
                  <td *ngIf="approProjectBasePrice?.old_sublet_price">
                    <p>{{approProjectBasePrice?.old_sublet_price?.price}}元</p></td>
                  <td *ngIf="!approProjectBasePrice?.old_sublet_price">未定价</td>
                  <td *ngIf="approProjectBasePrice?.sublet_price"><p>{{approProjectBasePrice?.sublet_price?.price}}
                    元</p></td>
                  <td *ngIf="approProjectBasePrice?.old_sublet_price && !approProjectBasePrice?.sublet_price">
                    删除该费用项（审批通过后立即生效）
                  </td>
                </tr>
                <tr
                  *ngIf="approProjectBasePrice?.public_energy_price || approProjectBasePrice?.old_public_energy_price">
                  <td>公共能耗费定价</td>
                  <td *ngIf="approProjectBasePrice?.old_public_energy_price">
                    <p>{{approProjectBasePrice?.old_public_energy_price?.price}}元/天</p></td>
                  <td *ngIf="!approProjectBasePrice?.old_public_energy_price">未定价</td>
                  <td *ngIf="approProjectBasePrice?.public_energy_price">
                    <p>{{approProjectBasePrice?.public_energy_price?.price}}元/天</p></td>
                  <td
                    *ngIf="approProjectBasePrice?.old_public_energy_price && !approProjectBasePrice?.public_energy_price">
                    删除该费用项（审批通过后立即生效）
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="red2">
                以上费用调整的生效时间为：{{approProjectBasePrice?.effective_time | date:"yyyy-MM-dd HH:mm"}}</div>
            </tab>
            <tab heading="审批记录信息">
              <app-approv-record-log-list
                [approvalResultResponse]="approvalResultResponse"></app-approv-record-log-list>
            </tab>
            <tab heading="审批流程图" (selectTab)="flowImgTabOnSelect(approProjectBasePrice?.process_instance_id)">
              <app-approv-record-img *ngIf="approProjectBasePrice"></app-approv-record-img>
            </tab>
          </tabset>
        </div>
        <hr>
        <div>
          <div class="row ">
            <div class="col-2">
              审批意见
            </div>
            <div class="col-10">
              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==true" (click)="auditCheck(true)"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>
              <button class="btn btn-info mx-2" *ngIf="auditStatus===true" (click)="auditCheck()"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>

              <button class="btn btn-gray-300 mx-2" *ngIf="auditStatus!==false" (click)="auditCheck(false)"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
              <button class="btn btn-danger mx-2" *ngIf="auditStatus===false" (click)="auditCheck()"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
            </div>

            <ng-container *ngIf="auditStatus===false">
              <div class="col-2 mt-2">
                拒绝原因
              </div>
              <div class="col-10 mt-2">
                <textarea class="w-100" [(ngModel)]="auditRejectReason">
                </textarea>
                <p class="text-danger" *ngIf="isAuditSubmit && !auditRejectReason">请输入拒绝原因</p>
              </div>
            </ng-container>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" (click)="auditSubmit(approProjectBasePrice?.appro_record_info_id)"
                    class="btn btn-primary">确定
            </button>
            <button type="button" class="btn btn-secondary ml-4" data-dismiss="modal"
                    (click)="projectBasePriceModal.hide()">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-complain-modal #complainModal [complaintsInfoDTO]="compla_data"
                    [complaintsLogListResponse]="complain_log_list_reponse"
                    (refresh_list)="refreshList($event)"></app-complain-modal>


<!-- 入住限制住客详情 -->
<div class="modal fade " bsModal #showLimitDetailModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> 入住限制详情</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="showLimitDetailModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div>
          <table class="table table-bordered">
            <tbody>
            <tr>
              <td>签约人/住客</td>
              <td>限制事件内容</td>
              <td>账单类型</td>
              <td>账单周期</td>
              <td>应收日期</td>
              <td>距离执行天数</td>
            </tr>
            <tr *ngFor="let info of checkInLimitDetailResponse?.checkin_limit_lease_dtolist">
              <td>{{info?.owe_name}}</td>
              <td>{{info?.limit_content}}</td>
              <td>{{info?.bill_type}}</td>
              <td>{{info?.bill_start_time|date:'yyyy.MM.dd'}}-{{info?.bill_end_time|date:'yyyy.MM.dd'}}</td>
              <td>{{info?.plan_pay_time|date:'yyyy.MM.dd'}}</td>
              <td>{{info?.limit_days}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="showLimitDetailModal.hide()">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 退宿转账失败处理 -->
<div class="modal fade " bsModal #renterCheckoutErrorModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> 退押失败处理</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="renterCheckoutErrorModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body" *ngIf="renterCheckoutErrorDealReq">
        <div>
          <div class="">
            退款方式：
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="REFUND"
                     [(ngModel)]="renterCheckoutErrorDealReq.checkout_finance_type_enum"
              >
              <label class="form-check-label" for="inlineRadio1">转账</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                     value="ACCOUNT_MONEY"
                     [(ngModel)]="renterCheckoutErrorDealReq.checkout_finance_type_enum"
              >
              <label class="form-check-label" for="inlineRadio2">客商账户</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                     value="TRANSFER_IN"
                     [(ngModel)]="renterCheckoutErrorDealReq.checkout_finance_type_enum"
              >
              <label class="form-check-label" for="inlineRadio3">退款转收入</label>
            </div>
          </div>
          <div class="mt-4 mx-md-5" *ngIf="renterCheckoutErrorDealReq">
            <div *ngIf="renterCheckoutErrorDealReq.checkout_finance_type_enum=='REFUND'" class="">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label"><span class="text-danger">*</span>收款人姓名</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" [(ngModel)]="renterCheckoutErrorDealReq.refund_name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label"><span class="text-danger">*</span>开户行</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" [(ngModel)]="renterCheckoutErrorDealReq.open_bank">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label"><span class="text-danger">*</span>银行账号</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" [(ngModel)]="renterCheckoutErrorDealReq.bank_no">
                </div>
              </div>
            </div>
            <div *ngIf="renterCheckoutErrorDealReq.checkout_finance_type_enum=='ACCOUNT_MONEY'">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">客商账户：</label>
                <div class="col-sm-10">
                  <div class="text-btn">{{renterCheckoutErrorData?.name}}</div>
                </div>
              </div>
              <div class="mt-2">本人同意将【 <span
                class="text-danger font-3xl">￥{{renterCheckoutErrorData?.amount}}</span> 】退款转入客商账户。
              </div>
            </div>
            <div *ngIf="renterCheckoutErrorDealReq.checkout_finance_type_enum=='TRANSFER_IN'">
              <div class="mt-2">本人同意放弃剩余【<span
                class="text-danger font-3xl">￥{{renterCheckoutErrorData?.amount}}</span>】退款
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="renterCheckoutErrorModal.hide()">关闭
            </button>
            <button type="button" class="btn btn-info"
                    (click)="confirmRenterCheckoutError()">提交
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 预约看房 -->
<div class="modal fade" bsModal #appointmentAddModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>预约看房</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="appointmentAddModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12 ">
          <div class="row">
            <div class="col-12 col-md-4 col-xl-3 mt-2">
              <label>客户名称*</label>
              <div class=" ml-2">
                <input class="form-control" [(ngModel)]="appointSubmitReq.contacts_name" placeholder="请输入客户名称">
                <p *ngIf="!appointSubmitReq.contacts_name" class="text-danger">请输入名称</p>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 mt-2">
              <label>意向房型*</label>
              <div class=" ml-2">
                <select class="w-100" [(ngModel)]="appointSubmitReq.room_group_id">
                  <option value="">请选择</option>
                  <option *ngFor="let g of appointGroupList" [value]="g.group_id">{{ g.group_name }}</option>
                </select>
                <p *ngIf="!appointSubmitReq.room_group_id" class="text-danger">请选择意向房型</p>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 mt-2">
              <label>意向价格</label>
              <div class=" ml-2">
                <input class="form-control" [(ngModel)]="appointSubmitReq.quality_room_price"
                       placeholder="请输入意向价格">
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 mt-2">
              <label>联系方式*</label>
              <div class=" ml-2">
                <input class="form-control" [(ngModel)]="appointSubmitReq.contacts_phone" placeholder="请输入联系方式">
                <p *ngIf="!appointSubmitReq.contacts_phone" class="text-danger">请输入联系方式</p>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 mt-2">
              <label>预约日期*</label>
              <div class=" ml-2">
                <my-date-picker class="w-100" placeholder="年/月/日" [(ngModel)]="appointTime"
                                (ngModelChange)="searchAppoint()"
                                [options]="appointTimeOptions">
                </my-date-picker>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 mt-2">
              <label>时间段*</label>
              <div class=" ml-2">
                <select class="w-100" [(ngModel)]="appointSubmitReq.t">
                  <option value="">请选择</option>
                  <option *ngFor="let t of appointTimes" [value]="t.start">{{ formatAppointTime(t.start) }}
                    -{{ formatAppointTime(t.end) }} (余{{ t.residue_num }})
                  </option>
                </select>
                <p *ngIf="!appointSubmitReq.t" class="text-danger">请选择时间段</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" (click)="saveAppointment()" class="btn btn-primary">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
